import React, { useState, useEffect  } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import EmojiPicker from 'common/components/elements/emoji-mart';
import { createPortal } from 'react-dom';
import { Tooltip } from 'react-tooltip';

const EmojiContent = ({
   primaryIcon,
   primaryIconSize,
   switchShowEmojis,
   addEmoji,
   isMobile,
   style,
   className,
   top,
   id,
   xPositions,
   width,
   hideModalScrool,
   iconColor,
   darkIconColor,
   size,
   onSetShowEmojis,
   closeOnEmojiSelect,
   iconClassName = '',
   openPickerOnPortal = false,
   portalTooltipId,
}) => {
   const [showEmojis, setShowEmojis] = useState(false);
   useEffect(() => {
      if(!isMobile){
         window.addEventListener('click', closeOnClickOutside, true);
      }
      return () => {
         if(!isMobile) {
            window.removeEventListener('click', closeOnClickOutside, true);
         }
      };
   // eslint-disable-next-line
   }, []);

   const hideScrollForModal = bool => {
      if(hideModalScrool){
         const modal = document.querySelector('.modal-content');
         if(modal && !isMobile){
            modal.style.overflow = bool ? 'revert' : ''
         }
      }
   }

   function closeOnClickOutside(e) {
      if(isMobile){
         return
      } else {
         if(Boolean(portalTooltipId)) {
            const optionTooltip = document.querySelector(`#${ portalTooltipId }`)
            if(optionTooltip && optionTooltip.contains(e.target)) {
               return
            }
         }
         if(!e.target.closest(`#${ id }`)) {
            setShowEmojis(false);
            hideScrollForModal(false);
            if(onSetShowEmojis){
               onSetShowEmojis(false, true)
            }
         }
      }
   }
   if(isMobile) return null
   return (
      <div id={ id }>
         <div
            style={ style ? { ...style } : {} }
            className={ `flex h-7 w-8 items-center justify-center ${ className } notranslate` }>
            <div
               role='presentation'
               onClick={ (e) => {
                  const bool = !showEmojis;
                  setShowEmojis(bool);
                  if(onSetShowEmojis){
                     onSetShowEmojis(bool)
                  }
                  hideScrollForModal(bool);
                  e.stopPropagation();
                  switchShowEmojis();
               } }
               className={ `flex items-center hover:[&>span]:!text-secondary dark:hover:[&>span]:!text-secondary-dark justify-center ${ size } cursor-pointer` }
               data-tooltip-id={ portalTooltipId }
            >
               <Icon
                  name={ primaryIcon ? primaryIcon : 'emoji' }
                  color={ iconColor }
                  darkColor={ darkIconColor }
                  style={ { fontSize: primaryIconSize ? primaryIconSize : '20px' } }
                  className={ iconClassName }
                  iconClassName='text-placeholder dark:text-placeholder-dark '
               />
            </div>

         </div>
         {
            showEmojis && !isMobile && (
               !openPickerOnPortal ? (
                  <EmojiPicker
                     onEmojiSelect={  (e) => {
                        if(e?.id && e?.name && !!closeOnEmojiSelect) {
                           setShowEmojis(false);
                        }

                        addEmoji(e, () => {
                           setShowEmojis(false);
                           hideScrollForModal(false);
                           if(onSetShowEmojis){
                              onSetShowEmojis(false)
                           }
                        })
                     } }
                     style={ {
                        ...xPositions,
                        width: width,
                        position: 'absolute',
                        top: top,
                        zIndex: 60,
                     } }
                  />
               ) : (
                  createPortal(
                     <Tooltip
                        openOnClick
                        role='dialog'
                        variant={ window.site_dark_bg ? 'dark' : 'light' }
                        id={ portalTooltipId }
                        place='bottom-end'
                        clickable
                        isOpen
                        noArrow
                        style={ {
                           padding: 0,
                           borderRadius: '8px',
                        } }
                     >
                        <EmojiPicker
                           onEmojiSelect={  (e) => {
                              if(e?.id && e?.name && !!closeOnEmojiSelect) {
                                 setShowEmojis(false);
                              }

                              addEmoji(e, () => {
                                 setShowEmojis(false);
                                 hideScrollForModal(false);
                                 if(onSetShowEmojis){
                                    onSetShowEmojis(false)
                                 }
                              })
                           } }
                           style={ {
                              width: width,
                              zIndex: 60,
                           } }
                        />
                     </Tooltip>,
                     document.getElementById('tooltips')
                  )
               )

            )
         }
      </div>
   )
}

EmojiContent.defaultProps = {
   xPositions: {
      right: 0,
   },
   width: '352px',
   iconColor: 'placeholder',
   darkIconColor: 'placeholder-dark',
   size: 'w-5 h-5',
}
EmojiContent.propTypes = {
   primaryIcon: PropTypes.string,
   primaryIconSize: PropTypes.string,
   size: PropTypes.string,
   isMobile: PropTypes.bool,
   addEmoji: PropTypes.func,
   className: PropTypes.string,
   switchShowEmojis: PropTypes.func,
   top: PropTypes.string,
   style: PropTypes.object,
   xPositions: PropTypes.object,
   id: PropTypes.string,
   width: PropTypes.string,
   darkIconColor: PropTypes.string,
   iconColor: PropTypes.string,
   hideModalScrool: PropTypes.bool,
   onSetShowEmojis: PropTypes.func,
   closeOnEmojiSelect: PropTypes.bool,
   iconClassName: PropTypes.string,
   openPickerOnPortal: PropTypes.bool,
   portalTooltipId: PropTypes.string,
}
export default EmojiContent;
