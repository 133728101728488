import { useEffect, useRef, useState } from "react";
import { copyText, downloadMediaFromUrl, getDeviceType, getProtocol, isDarkSite } from "common/utils/utils.js";
import Modal from "../modal";
import Button from "common/components/elements/buttons/primary";
// import Intercom from "common/utils/Intercom";
import toast from "common/utils/toast";
import ImgFacebook from 'assets/images/social-media/facebook-new.svg'
import ImgSnapchat from 'assets/images/social-media/snapchat-new.svg'
import ImgX from 'assets/images/social-media/twitter-x.svg'
import ImgXLight from 'assets/images/social-media/twitter-x-light.svg'
// import ImgLinkedin from 'assets/images/social-media/linkedin.svg'
import ImgWhatsapp from 'assets/images/social-media/whatsapp.svg'
import ImgMessanger from 'assets/images/social-media/messanger.svg'
import html2canvas from "html2canvas";
import ShareOption from "./share-option";
import CustomizableShareQR from "./customizable-share-qr";
import IconLabel from "common/components/elements/icon-label";
import CustomizeQrModal from "./customize-qr-modal";
import PropTypes from 'prop-types'
// import { getCapitalizeSiteName } from "common/utils/helper";
import TruncateText from "common/components/elements/truncate-text";
import Loader from "common/components/elements/loader/circle";


const shareLinks = {
   facebook: 'https://www.facebook.com/sharer/sharer.php?u=',
   linkedin: 'https://www.linkedin.com/sharing/share-offsite/?url=',
   snapchat: 'https://www.snapchat.com/scan?attachmentUrl=',
   x: 'https://twitter.com/intent/tweet?url=',
   whatsapp: 'https://api.whatsapp.com/send?text=',
   messenger: 'fb-messenger://share/?link=',
   // messenger: 'https://www.facebook.com/dialog/send?link=',
   email: 'mailto:?body=',
};

const shareOptions = [
   {   type: 'x',  img: ImgX, title: 'Share on X' },
   {  type: 'facebook', img: ImgFacebook, title: 'Share on Facebook' },
   // {type: 'linkedin', img: ImgLinkedin,   title: 'Share on Linkedin',},
   { type: 'snapchat', img: ImgSnapchat,  title: 'Share on Snapchat' },
   { type: 'whatsapp', img: ImgWhatsapp, title: 'Share via Whatsapp' },
   { type: 'messenger', img: ImgMessanger, title: 'Share via Messenger' },
   { type: 'email', img: null, title: 'Share via Email', iconName: 'message' },
]

const ShareModal = ({
   isAdmin,
   onCloseModal,
   siteLink,
   onClickRegister,
   title = '',
   siteName,
   shareDescription,
   sitePrefix,
   savedQrStyles,
   updateQrStyles,
   screenWidth,
   appIcon,
   additionalInfo = null,
   referralCode,
   siteDomain,
   componentDidMountCallback,
}) => {

   const [shareUrl, setShareUrl] = useState('')
   const [isOpenCustomizeQRModal, setIsOpenCustomizeQRModal] = useState(false)
   const [isLoadingSaveQrStyles, setIsLoadingSaveQrStyles] = useState(false)
   const [isQrDownloading, setIsQrDownloading] = useState(false)
   const [isLoading, setIsLoading] = useState(true)
   let qrWrapperRef = useRef(null)
   const [headerTitle, setHeaderTitle] = useState(title)

   useEffect(() => {
      setTimeout(() => {
         setIsLoading(false)
      }, 500)

      if(componentDidMountCallback) componentDidMountCallback(true)

      return () => {
         if(componentDidMountCallback) componentDidMountCallback(false)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      let url = ''
      let headerTitle = title
      const routes = {
         livestream: 'recorded-streams',
         photoset: 'photosets',
         video: 'videos',
         store: 'store/details',
         story: 'stories',
      }

      if(!isAdmin){
         // url = window.location.pathname !== '/' ? `${ window.location.origin }${ window.location.pathname }` : window.location.origin;
         if('custom_domain' === document.querySelector('meta[name=domainType]')?.content) {
            url = window.location.origin
         } else {
            url = `${ window.location.origin }/${ siteLink }`;
         }
         console.log(window.location);
      } else {
         url = `${ getProtocol() }${ siteLink }`
      }
      let route = routes?.[additionalInfo?.contentType]
      url += route ? `/${ route }` : ''

      if(additionalInfo){
         const contentTitle = additionalInfo?.contentTitle?.replaceAll(' ', '-')
         url += `/${ additionalInfo?.contentId }-${ contentTitle }`
         headerTitle = `Share ${ additionalInfo?.contentTitle }`
      }

      if(isAdmin && !additionalInfo) {
         // headerTitle = `Share ${ getCapitalizeSiteName() }`
         headerTitle = `Share ${ siteName }`
         // url += referralCode ? `/?ref=rf${ referralCode || '' }` : ''
      }

      if(!isAdmin && !additionalInfo) {
         headerTitle = `Share ${ siteName }`
      }

      console.log({ url, siteLink, additionalInfo, isAdmin, siteName, title, referralCode, siteDomain });
      setShareUrl(url)
      setHeaderTitle(headerTitle)
   }, [siteLink, additionalInfo, isAdmin, siteName, title, referralCode, siteDomain])

   const onShare = (type) => {
      let shareLinkByType = shareLinks[type]
      switch (type) {
         case 'copy':
            copyText(shareUrl)
            toast.success('Sharing link has been copied')
            break;

         case 'email':
            window.open(
               `${ shareLinkByType }${ encodeURIComponent(shareUrl) }`
            )
            break;

         case 'other':
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if(navigator.share) {
               navigator.share({
                  title: siteName,
                  text: shareDescription,
                  url: shareUrl,
               })
                  .then(() => console.log('Successfully shared'))
            }
            break;

            //types that exists on "shareLinks" constants
         default:
            if('messenger' === type && 'desktop' === getDeviceType()) {
               shareLinkByType = 'https://www.facebook.com/dialog/send?link='
            }

            window.open(
               `${ shareLinkByType }${ encodeURIComponent(shareUrl) }`,
               `${ type }window`,
               "height=550, width=550, toolbar=0, location=0, menubar=0, directories=0,scrollbars=0"
            )
            break;
      }
   }

   // const onClickFindOutMore = () => {
   //    Intercom.showArticle('9845473')
   // }

   const openCloseCustomizeQRModal = (isOpen) => {
      setIsOpenCustomizeQRModal(isOpen)
   }

   const onClickDownloadQR = async () => {
      if(!qrWrapperRef) return;
      setIsQrDownloading(true)

      try {
         const canvas = await html2canvas(qrWrapperRef, {
            useCORS: true,
            backgroundColor: null,
         })

         downloadMediaFromUrl(canvas.toDataURL(), false, sitePrefix)
      } catch (error) {
         console.log(error);
         toast.error('Something went wrong')
      } finally {
         setIsQrDownloading(false)
      }
   }

   const onSaveQRSettings = (newSettings) => {
      setIsLoadingSaveQrStyles(true)
      updateQrStyles(newSettings, (resultType = 'succeed') => {
         if(resultType === 'succeed'){
            openCloseCustomizeQRModal(false)
            toast.success('Your changes have been saved')
         }
         setIsLoadingSaveQrStyles(false)
      })
   }

   return (
      <>
         <Modal
            onCloseModal={ onCloseModal }
            contentPosition={ screenWidth > 900 ? 'center' : 'bottom' }
            contentWidth={ screenWidth > 900 ? '720px' : '100%' }
            // otherClassName='max-tablet:max-h-[100vh] overflow-y-hidden'
         >
            <div className='flex flex-col'>
               <div className={ `flex items-center justify-end h-14 mr-[18px]` }>
                  <Button
                     iconName='close'
                     fontIconColor='secondary'
                     darkIconColor='secondary-dark'
                     backgroundColor='transparent'
                     onClick={ onCloseModal }
                     padding='none'
                     classNames='!w-fit'
                     fontIconSize='extraLarge'
                  />
               </div>
               <div className='flex flex-col gap-3 items-center'>
                  <IconLabel
                     isPrimary
                     name={ 'share' }
                     size={ '2xl' }
                     className='h-10 w-10 shrink-0'
                  />
                  <p
                     className='font-semibold text-major dark:text-major-dark text-xl cursor-default px-6 text-center'
                     dangerouslySetInnerHTML={ { __html: headerTitle } }
                  ></p>
                  <div className='flex flex-row justify-between px-6'>
                     <TruncateText
                        text={ shareUrl.replace(getProtocol(), '') }
                        textSize='14px'
                        textClass='text-placeholder text-sm cursor-text font-normal leading-5 select-text'
                     />
                     <Button
                        padding='0'
                        iconName='copy'
                        fontIconSize='base'
                        primaryColor
                        backgroundColor='transparent'
                        onClick={  () => onShare('copy') }
                        classNames='ml-1.5 !w-fit'
                        dataToolTipContent='Copy'
                     />
                  </div>
               </div>
            </div>
            <div
               class='relative w-full flex flex-col gap-6 mt-7 p-6 pt-0 overflow-y-auto ams-custom-scrool flex-1 max-tablet:max-h-[57vh]'
            >
               {
                  isLoading &&
                  <div className='absolute top-0 left-0 w-full h-full z-60 flex justify-center items-center'>
                     <Loader color='primary' />
                  </div>
               }
               <div
                  class={ `w-full flex flex-1 flex-wrap justify-center gap-4 ${ isLoading ? 'opacity-0' : '' }` }
               >
                  <div
                     class='flex flex-col gap-3 justify-center items-center'
                  >
                     <CustomizableShareQR
                        wrapperClass='w-[328px] h-[328px] bg-grey-main'
                        backgroundColor={ savedQrStyles?.isBgTransparent ? 'transparent' : savedQrStyles?.qrBackground }
                        qrColor={ savedQrStyles?.qrColor }
                        shareUrl={ shareUrl }
                        sitePrefix={ sitePrefix }
                        wrapperRef={ ref => qrWrapperRef = ref  }
                        appIcon={ appIcon }
                     />
                     <div
                        class='w-full flex items-center justify-between gap-[30px]'
                     >
                        <Button
                           text='Customize QR'
                           iconName='customize'
                           iconPosition='left'
                           classNames='!px-3.5 py-2 !w-fit'
                           textSize='small'
                           textColor='secondary dark:text-secondary-dark'
                           darkIconColor='secondary-dark'
                           iconClassName='text-xl text-secondary'
                           backgroundColor='transparent'
                           onClick={ () => openCloseCustomizeQRModal(true) }
                           textMarginX='0 pl-2'
                        />
                        <Button
                           text='Download QR'
                           iconName='download'
                           primaryColor
                           classNames='!px-3.5 py-2 !w-fit'
                           backgroundColor='transparent'
                           textClass='text-action !text-sm'
                           textSize='small'
                           onClick={ onClickDownloadQR }
                           isLoading={ isQrDownloading }
                           iconPosition='left'
                           textMarginX='0 pl-2'
                           loaderSize={ 5 }
                        />
                     </div>
                  </div>
                  <div
                     class='w-full min-w-[328px] flex-1 flex flex-col gap-2'
                  >
                     {
                        shareOptions.map(item => {
                           let img = item.type === 'x' && isDarkSite() ? ImgXLight : item.img
                           return (
                              <ShareOption
                                 { ...item }
                                 key={ item.type }
                                 img={ img }
                                 onClick={ () => onShare(item.type) }
                              />
                           )
                        })
                     }
                     {
                        typeof navigator?.share === 'function' && (
                           <ShareOption
                              img={ null }
                              title='More'
                              iconName='optons-horizontal'
                              onClick={ () => onShare('other') }
                              hideRightIcon
                           />
                        )
                     }
                  </div>
               </div>
               {/* {
                  !isAdmin && (
                     <div
                        class='w-full pt-4 flex flex-col'
                     >
                        <span class='text-major dark:text-major-dark font-medium leading-[18px]'>Create your own site</span>
                        <div
                           class='mt-3 w-full flex gap-2'
                        >
                           <Button
                              classNames='h-9 w-full'
                              onClick={ onClickRegister }
                              text='Sign up'
                              primaryColor
                              textSize='small'
                              textMarginX='0 pl-2'
                              borderRadius='large'
                           />
                           <Button
                              classNames='h-9 w-full border skin-primary-border'
                              text='Learn more'
                              backgroundColor='transparent'
                              primaryColor
                              onClick={ onClickFindOutMore }
                              textSize='small'
                              borderRadius='large'
                           />
                        </div>
                     </div>
                  )
               } */}
            </div>
         </Modal>
         {
            isOpenCustomizeQRModal && (
               <CustomizeQrModal
                  onCloseModal={ () => openCloseCustomizeQRModal(false) }
                  shareUrl={ shareUrl }
                  sitePrefix={ sitePrefix }
                  qrCustomizedState={ savedQrStyles }
                  onSaveQRSettings={ onSaveQRSettings }
                  isAdmin={ isAdmin }
                  qrDefaultStyles={ isAdmin ? {
                     qrColor: '#231FFF',
                     qrBackground: '#F9FAFB',
                     isBgTransparent: false,
                  } : savedQrStyles }
                  isLoadingSaveQrStyles={ isLoadingSaveQrStyles }
                  screenWidth={ screenWidth }
                  appIcon={ appIcon }
               />
            )
         }
      </>
   )
}


ShareModal.propTypes = {
   isAdmin: PropTypes.bool,
   onCloseModal: PropTypes.func,
   siteLink: PropTypes.string,
   onClickRegister: PropTypes.func,
   title: PropTypes.string,
   siteName: PropTypes.string,
   shareDescription: PropTypes.string,
   sitePrefix: PropTypes.string,
   savedQrStyles: PropTypes.func,
   updateQrStyles: PropTypes.func,
   screenWidth: PropTypes.number,
   appIcon: PropTypes.any,
   additionalInfo: PropTypes.object,
   referralCode: PropTypes.string,
   siteDomain: PropTypes.string,
   componentDidMountCallback: PropTypes.func,
}


export default ShareModal;
