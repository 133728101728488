import React from 'react';
import Icon from 'common/components/elements/icons';
import IconLabel from 'common/components/elements/icon-label';
import PropTypes from 'prop-types';
import cx from 'classnames';
import TruncateText from 'common/components/elements/truncate-text';
import Button from 'common/components/elements/buttons/primary';
import CircleLoader from 'common/components/elements/loader/circle';
import Intercom from 'common/utils/Intercom';

const OnboardingStep = ({
   step,
   className,
   onStepClick,
   isConnectTwitter,
   twitterUserName,
   isFetching,
   recommended,
   recommendedStateIcon,
   isModal,
}) => {
   let status = {}
   const getShowNotpointingState = () => {
      return step.not_pointing && 'domain' === step.slug;
   }
   if(isConnectTwitter){
      status = {
         icon: 'twitter-x',
         color: twitterUserName ? 'action' : 'secondary',
      }
   } else if(recommended){
      status = {
         icon: recommendedStateIcon,
         color: 'secondary',
      }
   } else {
      if(!step.status) {
         status = {
            icon: getShowNotpointingState() ? 'clock' :  'add',
            color: getShowNotpointingState() ? 'warning' : 'action',

         }
      } else {
         status = {
            icon: 'done',
            color: 'success',
            bgColor: 'bg-success-12',
         }
      }

   }

   return (
      <div
         onClick={
            isFetching ? null : (e) => onStepClick(e)
         }
         role='presentation'
         className={ cx({
            [`bg-panel hover:bg-hover flex items-center justify-between py-2.5 ${ className }`]: true,
            'cursor-not-allowed opacity-50': isFetching,
            'cursor-pointer': !isFetching,
            'rounded-lg lg:rounded-none px-4 lg:px-6': !isModal,
            'px-6': isModal,
         }) }

      >
         <div
            style={ {
               width: 'calc(100% - 24px)',
            } }
            className='flex'>
            <IconLabel
               name={ status.icon }
               color={ status.color }
            />
            {
               isConnectTwitter && (
                  <div
                     style={ {
                        width: 'calc(100% - 40px)',
                     } }
                     className='flex flex-col ml-4 select-none'>
                     {
                        !twitterUserName && (
                           <>
                              <span className='leading-5 text-major'> {step.title} </span>
                              <TruncateText
                                 className='mt-px'
                                 text={ step.description }
                                 textClass='text-secondary panel-description'
                                 textSize='12px'
                                 fontWeight='400'
                              />
                           </>
                        )
                     }
                     {
                        !!twitterUserName && (
                           <span className='leading-5 text-major'>
                              X account •  <span className=' text-success'>Connected </span>
                           </span>
                        )
                     }
                     {
                        twitterUserName &&
                           <TruncateText
                              className='mt-px'
                              text={ twitterUserName }
                              textClass='text-secondary panel-description'
                              textSize='14px'
                              fontWeight='400'
                           />
                     }

                  </div>
               )
            }
            {
               !isConnectTwitter && (
                  <div
                     className='w-full flex flex-col justify-center ml-2 gap-3'
                  >
                     <div
                        className='w-full flex justify-between flex-col lg:flex-row gap-3'
                     >
                        <div
                           className='w-full flex flex-col justify-center h-full'
                        >
                           <span
                              className='leading-5 text-major'
                           >
                              {step.title}
                           </span>
                           {
                              step.description && (
                                 <TruncateText
                                    text={ step.description }
                                    textClass='text-secondary panel-description'
                                    textSize='13px'
                                    fontWeight='400'
                                 />
                              )
                           }
                           {
                              step.moreInfoState && step.moreInfoState?.buttonText && (
                                 <Button
                                    text={ step.moreInfoState.buttonText }
                                    primaryColor
                                    backgroundColor='transparent'
                                    iconName='attention-outline'
                                    iconPosition='left'
                                    textSize='small'
                                    fontIconSize='base'
                                    textMarginX='0'
                                    padding={ 0 }
                                    classNames='gap-2 mt-1 !w-fit'
                                    onClick={ (e) => {
                                       e.preventDefault()
                                       e.stopPropagation()
                                       if(step.moreInfoState.articleId) {
                                          Intercom.showArticle(step.moreInfoState.articleId)
                                       }
                                    } }
                                    textClassName='!leading-5'
                                 />
                              )
                           }
                        </div>
                        {
                           getShowNotpointingState() && isModal && (
                              <WaitingForPointing />
                           )
                        }
                     </div>
                     {
                        getShowNotpointingState() && (
                           <div
                              className={ cx({
                                 'w-full flex flex-col lg:flex-row lg:items-center gap-3': true,
                                 'justify-center': !isModal,
                              }) }
                           >
                              {
                                 !isModal && (
                                    <WaitingForPointing />
                                 )
                              }
                              <Button
                                 primaryColor
                                 backgroundColor='transparent'
                                 text='I need help pointing my domain'
                                 iconName='attention-outline'
                                 textMarginX='0'
                                 padding='none'
                                 classNames={ cx({
                                    'gap-2 !w-fit intercom-i-need-help-pointing-my-domain': true,
                                    'col-start-2 col-end-5': !isModal,
                                    'col-start-1 col-end-4': isModal,
                                 }) }
                                 iconPosition='left'
                                 textSize='small'
                                 fontIconSize='base'
                                 onClick={ (e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                 } }
                                 textClassName='whitespace-nowrap'
                              />
                           </div>
                        )
                     }
                  </div>
               )
            }

         </div>
         <div className='h-5 w-5 flex items-center justify-center'>
            <Icon
               name='arrow-right'
               size='xl'
               color='secondary'
            />
         </div>
      </div>
   );
};

const WaitingForPointing = () => {

   return (
      <div
         className='flex items-center gap-2'
      >
         <CircleLoader
            size='5'
            color='warning'
            style={ {
               borderWidth: '2px',
            } }
            customColor
         />
         <span
            className='font-medium text-sm leading-[18px] text-warning whitespace-nowrap'
         >
            Waiting to be pointed
         </span>
      </div>
   )
}

OnboardingStep.propTypes = {
   step: PropTypes.object,
   className: PropTypes.string,
   onStepClick: PropTypes.func,
   isConnectTwitter: PropTypes.bool,
   twitterUserName: PropTypes.string,
   isFetching: PropTypes.bool,
   recommended: PropTypes.bool,
   recommendedStateIcon: PropTypes.string,
   isModal: PropTypes.bool,
};
OnboardingStep.defaultProps = {
   step: {},
   className: '',
   isModal: false,
};

export default React.memo(OnboardingStep);
