import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import { BlurhashCanvas } from "react-blurhash";
import PremiumLabel from 'sites/views/media-modules/premium-label';
import { MediaChip } from 'common/components/modules/media';


const MediaContentCard = ({
   src,
   count,
   blurhash,
   streamStatus,
   streamPrice,
   type,
   previewSrc,
   streamState,
   onClickUnlockButton,
   isAdmin,
   goToJoin,
   id,
}) => {

   const onClickButton = (e, isUnlock = true) => {
      if(type !== 'content_bundle') {
         e.stopPropagation()
         e.preventDefault()
      }
      if(!isUnlock && id){
         goToJoin(id, type)
      } else {
         onClickUnlockButton()
      }
   }

   let buttonState = {
      iconName: 'membership',
      text: 'Get membership',
      isUnlock: false,

   }
   if('unlock' === streamStatus) {
      buttonState = {
         iconName: 'unlocked',
         text: `Unlock for ${ streamPrice } USD`,
         isUnlock: true,
      }
   }
   if('pre_release' === streamStatus && 'unlock' === streamState) {
      buttonState = {
         iconName: 'unlocked',
         text: `Unlock for ${ streamPrice } USD`,
         isUnlock: true,
      }
   }
   if(type === 'content_bundle') {
      buttonState = {
         iconName: '',
         text: 'See the bundle',
         isUnlock: true,
      }
   }
   const isPremium = 'only_member_can_unlock' === streamStatus;
   const isPreRelease = 'pre_release' === streamStatus;
   return (
      <div
         className='absolute w-full h-auto z-0 flex flex-col justify-between inset-0 rounded-lg'
      >
         {
            isPreRelease &&
            <PremiumLabel
               className='mt-2 ml-2 absolute top-0 left-0 z-20'
               whithOpacit={ false }
               isPreRelease
               showNewStyle
               paddingY='0'
               paddingX='0'
            />
         }
         {
            isPremium &&
            <PremiumLabel
               className='mt-2 ml-2 absolute top-0 left-0 z-20'
               whithOpacit={ false }
            />
         }
         <div className='w-full h-full relative z-10 flex overflow-hidden rounded-lg'>
            <div
               className='absolute w-full h-full z-10 bg-no-repeat bg-cover inset-0 '
               style={ !blurhash ? {
                  filter: `blur(8px)`,
                  backgroundImage: `url(${ src })`,
               } : {} }
            />

            {
               !!blurhash && 'free' !== streamState && (
                  <BlurhashCanvas
                     hash={ blurhash }
                     punch={ 1 }
                     className={ `absolute w-full h-full z-0 flex flex-col justify-between inset-0 rounded-lg` }
                  />
               )
            }
            {
               (!blurhash || 'free' === streamState) && (
                  <>
                     {
                        previewSrc && (
                           <div className='h-full w-full flex items-center justify-center z-20'>
                              <video autoPlay loop playsInline muted poster={ src } className='rounded-lg h-full w-auto'>
                                 <source src={ previewSrc } type='video/mp4' />
                                 <source src={ previewSrc } type='video/ogg' />
                                 Your browser does not support the video tag.
                              </video>
                           </div>
                        )
                     }
                     {
                        !previewSrc && (
                           <div
                              className='absolute w-full h-full bg-no-repeat bg-contain bg-center z-20'
                              style={ {
                                 backgroundImage: `url(${ src })`,
                              } }
                           />
                        )
                     }
                  </>
               )
            }
            {
               streamStatus && !isAdmin && (
                  <div className='absolute bottom-0 z-30 w-full'>
                     <div className='flex flex-col px-3 pb-3 items-center'>
                        {
                           isPremium && (
                              <span className='text-xs text-major-dark opacity-75 text-center mb-2'>
                                 Only members can unlock this {type} for an additional {streamPrice} USD
                              </span>
                           )
                        }
                        <div
                           role='presentation'
                           className='h-9 w-full non-access-button'
                        >
                           <Button
                              { ...buttonState }
                              iconPosition='left'
                              fontIconSize='extraLarge'
                              borderRadius='large'
                              textSize='base'
                              fontWeight='medium'
                              classNames='pl-3 pr-4 h-full w-full'
                              padding='none'
                              onClick={ e => onClickButton(e, buttonState.isUnlock) }
                              primaryColor
                           />
                        </div>
                     </div>
                  </div>
               )
            }
            <div
               className='flex items-center justify-end w-full p-2 z-30 absolute right-0'
            >
               <MediaChip
                  countOrDuration={ count }
                  type={ type }
               />
            </div>
         </div>
      </div>
   )
}

MediaContentCard.defaultProps = {
   onClickUnlockButton: () => {},
};

MediaContentCard.propTypes = {
   count: PropTypes.any,
   src: PropTypes.string,
   blurhash: PropTypes.string,
   streamStatus: PropTypes.string,
   streamPrice: PropTypes.number,
   type: PropTypes.string,
   previewSrc: PropTypes.string,
   streamState: PropTypes.string,
   onClickUnlockButton: PropTypes.func,
   isAdmin: PropTypes.bool,
   goToJoin: PropTypes.func,
   id: PropTypes.any,
};

export default MediaContentCard
