import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import Modal from 'common/components/modules/modal';
import Loader from 'common/components/elements/loader/circle';
import Card from 'admin/views/uploading/card';
import './style.scss'
import CheckBox from 'common/components/elements/inputs/checkbox';
import cx from 'classnames'
import RadioLabel from 'common/components/elements/inputs/radio-label';
import ChatMessageContentState from 'admin/views/media-modules/chat-message-state';
import ContentPreviewIntoModal from 'common/components/modules/content-preview-into-modal';
import MediaChip from 'common/components/modules/media-chip';
import TruncateText from 'common/components/elements/truncate-text';

const VaultCard = ({
   desktopType,
   openDeleteConfirmModal,
   isChecked,
   selectCard,
   removeFile,
   isInsideModal,
   data = {},
   isDownloading,
   downloadOriginalFile,
   disabled,
   isMultipleSelect,
   onDeleteMessag,
   updateVaultMessageAction,
   updateMessageUnlockDetails,
   isDemoMode,
   goTo,
}) => {
   const [openOptionsModal, setOpenOptionsModal] = useState(false);
   const [previewModal, setPreviewModal] = useState({ isOpen: false, data: {} })
   const [animate, setAnimate] = useState('up');
   const { id, type: vaultType } = data

   let timerFetching = null;
   const closeOrOpenModal = (_bool) => {
      let time = _bool ? 100 : 800
      if(desktopType !== 'mobile'){
         time = 0
      }
      setAnimate(_bool ? 'up' : 'down');
      timerFetching = setTimeout(() => {
         setOpenOptionsModal(_bool);
      }, time);
   }

   const closeOnClickOutside = (e) => {
      if(isInsideModal) return
      if(!e.target.closest(`.vault-${ id }`)) {
         setOpenOptionsModal(false);
      }
   }

   useEffect(() => {
      return () => {
         clearTimeout(timerFetching);
      };
   }, [timerFetching]);

   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
   // eslint-disable-next-line
   }, []);

   const previewModalView = (
      previewModal.isOpen &&
         <ContentPreviewIntoModal
            type={ data.type === 'video' ? data.type : 'photoset' }
            isMobile={ desktopType === 'mobile' }
            data={ previewModal.data }
            closeModal={ (e) => {
               e.stopPropagation()
               e.preventDefault()
               setPreviewModal({ isOpen: false, data: {} })
            } }
         />
   )


   if(vaultType === 'video'){
      return (
         <VideoCard
            data={ data }
            removeFile={ removeFile }
            isDemoMode={ isDemoMode }
            isInsideModal={ isInsideModal }
            isMultipleSelect={ isMultipleSelect }
            isChecked={ isChecked }
            isDisabledCheckbox={ disabled }
            onDeleteMessag={ onDeleteMessag }
            updateVaultMessageAction={ updateVaultMessageAction }
            updateMessageUnlockDetails={ updateMessageUnlockDetails }
            setPreviewModal={ setPreviewModal }
            setOpenOptionsModal={ closeOrOpenModal }
            openOptionsModal={ openOptionsModal }
            openDeleteConfirmModal={ openDeleteConfirmModal }
            desktopType={ desktopType }
            isDownloading={ isDownloading }
            selectCard={ selectCard }
            downloadOriginalFile={ downloadOriginalFile }
            previewModalView={ previewModalView }
            goTo={ goTo }
            popupAnimation={ animate }
         />
      )

   } else {
      let status = data.uploadingStatus
      if(!data.uploadingStatus  && id) {
         status = 'complete'
      }
      switch (status) {
         case 'error':
            return (
               <div
                  className={ `relative h-full w-full bg-placeholder border border-divider rounded-label flex items-center justify-center ` }>
                  <div className='aspect-ratio-padding' />
                  <div
                     onClick={ (e) => e.stopPropagation() }
                     role='presentation'
                     className='absolute top-0 right-0 m-2 flex items-center'
                  >
                     <Button
                        textColor='major-dark'
                        backgroundColor='transparent'
                        iconName='cancel'
                        fontIconColor='secondary'
                        padding='none'
                        onClick={ () => removeFile(id, status)  }
                        borderRadius='large'
                        textSize='base'
                     />
                  </div>
                  <div className='flex flex-col items-center'>
                     <Icon name='warning' color='secondary' size='5xl' />
                     <p className='mt-5 text-error font-bold'>Error</p>
                     <p className='text-major text-sm'>Try again or contact support</p>
                  </div>
               </div>
            );
         case 'loading':
            return (
               <div
                  className={ `relative bg-placeholder w-full h-full rounded-label flex items-center justify-center` }>
                  <div className='aspect-ratio-padding' />
                  <div
                     onClick={ (e) => e.stopPropagation() }
                     role='presentation'
                     className='absolute top-0 right-0 m-2 flex items-center'
                  >
                     <Button
                        textColor='major-dark'
                        backgroundColor='transparent'
                        iconName='cancel'
                        fontIconColor='major-dark'
                        padding='none'
                        onClick={ () => {} }
                        borderRadius='large'
                        textSize='base'
                     />
                  </div>
                  <Loader color='major-dark' />
               </div>
            );
         case 'complete':
            return (
               <>
                  <CompleteCard
                     isInsideModal={ isInsideModal }
                     selectCard={ selectCard }
                     id={ id }
                     isChecked={ isChecked }
                     src={ data.image_thumb_src }
                     setOpenOptionsModal={ closeOrOpenModal }
                     openOptionsModal={ openOptionsModal }
                     openDeleteConfirmModal={ openDeleteConfirmModal }
                     desktopType={ desktopType }
                     isDownloading={ isDownloading }
                     downloadOriginalFile={ downloadOriginalFile }
                     disabled={ disabled }
                     chatMessages={ data.messages }
                     onDeleteMessag={ onDeleteMessag }
                     updateVaultMessageAction={ updateVaultMessageAction }
                     path={ data.src }
                     hash={ data.hash }
                     updateMessageUnlockDetails={ updateMessageUnlockDetails }
                     setPreviewModal={ setPreviewModal }
                     isDemoMode={ isDemoMode }
                     goTo={ goTo }
                     title={ data?.title }
                     popupAnimation={ animate }
                  />
                  {previewModalView}
               </>
            );
         default:
            return null;
      }
   }
}

const VideoCard = ({
   data,
   removeFile,
   isDemoMode,
   isInsideModal,
   isMultipleSelect,
   isChecked,
   isDisabledCheckbox,
   onDeleteMessag,
   updateVaultMessageAction,
   updateMessageUnlockDetails,
   setPreviewModal,
   setOpenOptionsModal,
   openOptionsModal,
   openDeleteConfirmModal,
   desktopType,
   isDownloading,
   selectCard,
   downloadOriginalFile,
   previewModalView,
   goTo,
   popupAnimation,
}) => {
   let percentage = data.video_optimization_percentage || data.percentage
   let status = data.video_optimization_status || data.uploadingStatus

   if(data.video_optimization_status === 'completed') {
      status = 'ready'
   } else if(data.video_optimization_status === 'mediainfo_error' || data.video_optimization_status === 'optimization_error' || data.video_optimization_status === 'other_error') {
      status = 'error'
   }

   const isReady = status === 'ready'

   return (
      <div
         role='presentation'
         onClick={ (e) => {
            e.stopPropagation()
            e.preventDefault()
            if(isInsideModal && !isDisabledCheckbox) {
               selectCard(data.id, !isChecked)
            }
            if(isReady && !isInsideModal){
            // if(!isInsideModal){
               goTo('VIDEO_VAULT_DETAILS')
            }
         } }
         className={ cx({
            'w-full cursor-pointer rounded-label': true,
            '!cursor-default': isDisabledCheckbox || !isReady,
            'h-full': !isReady,
         }) }
         data-tooltip-content='This content has already been added to a different bundle'
         data-tooltip-id={ isInsideModal && isDisabledCheckbox ? 'ams-top-tooltip' : null }
      >
         {/* <div className={ `w-full relative ${ isReady ? '' : 'h-full' }` }> */}
         <div className={ `w-full relative` }>
            <div className='aspect-ratio-padding' />
            <div className={ `absolute h-full group/card top-0 left-0 text-major-dark w-full overflow-hidden` } >
               <Card
                  posterImage={  data.video_poster_full_src }
                  onCancel={ () => removeFile(data, status) }
                  percentage={ percentage?.toString() }
                  uploadingStatus={ status }
                  type={ data.type }
                  isShowCancel={ false }
                  // isShowCancel={ !isReady }
                  className='h-full z-10 relative'
                  disabeldCancel={ isDemoMode }
                  dataToolTipContent={ isDemoMode ? 'Not available in demo' : undefined }
                  hasTitle={ true }
               >
                  <div
                     className={ cx({
                        [`flex justify-end p-2 pb-3 absolute top-0 left-0 w-full`]: true,
                        'justify-between': isInsideModal,
                     })
                     }
                     style={ {
                        background: 'linear-gradient(0deg,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
                     } }
                  >
                     {
                        isInsideModal &&
                        <div className='z-[4]'>
                           {
                              isMultipleSelect ?
                                 <CheckBox
                                    isChecked={ isChecked }
                                    onChange={ () => { } }
                                    disabled={ isDisabledCheckbox }
                                    className='w-6 h-6 '
                                    name={ `vault_checkbox_${ data.id }` }
                                 />
                                 :
                                 <RadioLabel
                                    checked={ isChecked }
                                    className={ '!p-0 ' }
                                    radioParentClassName='ml-0'
                                    disabled={ isDisabledCheckbox }
                                    labelClassName='!w-5 !h-5'
                                    id={ `vault_radio_${ data.id }` }
                                 />
                           }
                        </div>
                     }
                     {
                        !!data.video_duration &&
                        <MediaChip
                           countOrDuration={ data.video_duration }
                           type={ 'video' }
                        />
                     }
                  </div>
                  {
                     isInsideModal &&  data?.messages  && data?.messages?.length > 0 && (
                        <ChatMessageContentState
                           chatMessages={ data.messages }
                           onDeleteMessag={ onDeleteMessag }
                           updateMessage={ (messageId, isDeleteAll, resourceType, messageData, contentType) => updateVaultMessageAction({ ids: [data.id], data: null, messageId: messageId, isDeleteAll: isDeleteAll, resourceType: resourceType, messageData, contentType }) }
                           path={ data.src }
                           hash={ data.hash }
                           updateMessageUnlockDetails={ updateMessageUnlockDetails }
                           onClickPreview={ () => {
                              setPreviewModal({
                                 isOpen: true,
                                 data: {
                                    posterSrc: data.video_poster_full_src,
                                    duration: data.video_duration,
                                    hlsManifest: data.video_hls_manifest,
                                    isPreview: !data.video_hls_manifest && !!data.video_poster_full_src,
                                    src: data.video_hls_manifest || data.video_system_video_preview_job_id || data.custom_preview_video_full_src,
                                    isVaultVideo: true,
                                 },
                              })
                           } }
                           resourceId={ data?.id }
                        />
                     )
                  }
                  {
                     !data?.messages?.length && isInsideModal &&
                     <div className='hidden absolute top-0 left-0 w-full h-full group-hover/card:!flex justify-center items-center bg-major-60 dark:bg-grey-hover-dark-60'>
                        <Button
                           text='Preview'
                           iconName='play-default'
                           textColor='divider'
                           iconPosition='left'
                           textSize='small'
                           backgroundColor='transparent'
                           classNames='!w-fit !h-9 border border-grey-main items-center'
                           borderRadius='large'
                           padding='0 px-3.5 py-2'
                           textClassName='mr-0 !leading-[18px]'
                           onClick={ (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setPreviewModal({
                                 isOpen: true,
                                 data: {
                                    posterSrc: data.video_poster_full_src,
                                    duration: data.video_duration,
                                    hlsManifest: data.video_hls_manifest,
                                    isPreview: !data.video_hls_manifest && !!data.video_poster_full_src,
                                    src: data.video_hls_manifest || data.video_system_video_preview_job_id || data.custom_preview_video_full_src,
                                    isVaultVideo: true,
                                 },
                              })
                           } }
                        />
                     </div>
                  }
               </Card>
               {
                  openOptionsModal && (
                     <OptionsModal
                        closeModal={ () => setOpenOptionsModal(false) }
                        openDeleteConfirmModal={ () => {
                           if(status !== 'uploading' && status !== 'pending'){
                              openDeleteConfirmModal();
                           } else {
                              removeFile(data, status)
                           }
                           setTimeout(() => setOpenOptionsModal(false), 100)
                        } }
                        desktopType={ desktopType }
                        pageType={ data.type }
                        id={ data.id }
                        isDownloading={ isDownloading }
                        downloadOriginalFile={ downloadOriginalFile }
                        isDemoMode={ isDemoMode }
                        goToDetails={ () => goTo('VIDEO_VAULT_DETAILS') }
                        isCompletedItem={ isReady }
                        animate={ popupAnimation }
                        isDisabledDelete={ (data.percentage === 100 && data?.video_optimization_percentage !== 0) || status === 'optimizing' }
                     />
                  )
               }
            </div>
            {previewModalView}
         </div>
         {
            // isReady &&
            <div className='border border-divider rounded-b-label bg-panel p-3 min-h-[48px]'>
               <div className='flex justify-between items-center'>
                  <div className='flex items-center w-full'>
                     {/* {
                        isPinned &&
                           <Icon
                              name={ `pin` }
                              color={ `warning` }
                              darkColor={ `warning-dark` }
                              className={ `h-5 w-5 min-h-[20px] min-w-[16px] rotate-[35deg] mr-2` }
                              size={ `xl` }
                              data-tooltip-content={ 'Pinned' }
                              data-tooltip-id={ 'ams-top-tooltip' }
                           />
                     } */}
                     <TruncateText
                        className='pr-1'
                        text={ data.title || '' }
                        textClass={ `font-bold text-xl text-major dark:text-major-dark leading-6 ${ isDisabledCheckbox ? '!text-disabled' : '' }` }
                        textSize='20px'
                        fontWeight='700'
                        // width={ isInsideModal ? 'calc(100% - 28px)' : '100%' }
                     />
                  </div>
                  {
                     !isInsideModal &&
                     <div
                        className={ `flex justify-center items-center vault-${ data.id }` }
                     >
                        <Button
                           onClick={ (e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setOpenOptionsModal(!openOptionsModal);
                           } }
                           backgroundColor='transparent'
                           fontIconColor={ isDisabledCheckbox ? 'disabled' : 'secondary' }
                           darkIconColor='secondary-dark'
                           iconName='options-vertical'
                           fontIconSize='doubleLarge'
                           padding='0'
                           disabled={ isInsideModal }
                           classNames='!cursor-pointer'
                        />
                     </div>
                  }
               </div>
            </div>
         }
      </div>
   )
}

const CompleteCard = ({
   isInsideModal,
   selectCard,
   id,
   isChecked,
   src,
   setOpenOptionsModal,
   openOptionsModal,
   openDeleteConfirmModal,
   desktopType,
   isFullScreen,
   isDownloading,
   downloadOriginalFile,
   disabled,
   chatMessages,
   onDeleteMessag,
   updateVaultMessageAction,
   path,
   updateMessageUnlockDetails,
   hash,
   setPreviewModal,
   isDemoMode,
   title,
   goTo,
   popupAnimation,
}) => {

   return (
      <div
         role='presentation'
         onClick={ (e) => {
            e.stopPropagation()
            e.preventDefault()
            if(isInsideModal && !disabled) {
               selectCard(id, !isChecked)
            }
            if(!isInsideModal){
               goTo('IMAGE_VAULT_DETAILS')
            }
         } }
         className={ cx({
            'flex flex-col': true,
            'cursor-pointer': !isFullScreen || !disabled,
         }) }
         data-tooltip-content='This content has already been added to a different bundle'
         data-tooltip-id={ disabled ? 'ams-top-tooltip' : null }
      >
         <div
            className={ `w-full group/card relative` }
         >
            <div className='aspect-ratio-padding' />
            <div className={ `absolute top-0 left-0 text-major-dark w-full h-full overflow-hidden ${ isFullScreen ? '' : 'rounded-t-label' }` } >
               <div
                  style={ {
                     backgroundImage: `url(${ src })`,
                     filter: 'blur(24px)',
                     zIndex: '-1',
                  } }
                  className='bg-no-repeat bg-cover bg-center w-full h-full'
               />
               <img src={ src } alt='card' className='h-full w-full rounded-t-lg object-contain object-center absolute top-0 left-0' />
               <div className='absolute top-0 left-0 w-full rounded-lg h-full card-shadow **p-2 flex flex-col group/card justify-between z-[3]'>
                  {
                     isInsideModal && chatMessages && chatMessages?.length > 0 && (
                        <ChatMessageContentState
                           chatMessages={ chatMessages }
                           onDeleteMessag={ onDeleteMessag }
                           updateMessage={ (messageId, isDeleteAll, resourceType, messageData, contentType) => updateVaultMessageAction({ ids: [id], data: null, messageId: messageId, isDeleteAll: isDeleteAll, resourceType: resourceType, messageData, contentType }) }
                           path={ path }
                           hash={ hash }
                           updateMessageUnlockDetails={ updateMessageUnlockDetails }
                           contentType='vault_image'
                           onClickPreview={ () => {
                              setPreviewModal({
                                 isOpen: true,
                                 data: {
                                    photos: [src],
                                 },
                              })
                           } }
                           resourceId={ id }
                        />
                     )
                  }
                  {
                     !chatMessages?.length && isInsideModal &&
                     <div className='hidden absolute top-0 left-0 w-full h-full group-hover/card:!flex justify-center items-center bg-major-60 dark:bg-grey-hover-dark-60'>
                        <Button
                           text='Preview'
                           iconName='play-default'
                           textColor='divider'
                           iconPosition='left'
                           textSize='small'
                           backgroundColor='transparent'
                           classNames='!w-fit !h-9 border border-grey-main items-center'
                           borderRadius='large'
                           padding='0 px-3.5 py-2'
                           textClassName='mr-0 !leading-[18px]'
                           onClick={ (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setPreviewModal({
                                 isOpen: true,
                                 data: {
                                    photos: [src],
                                 },
                              })
                           } }
                        />
                     </div>
                  }
                  {
                     isInsideModal  && (
                        <div
                           className='w-full p-2 pb-3'
                           style={ {
                              background: 'linear-gradient(0deg,rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%)',
                           } }
                        >
                           <CheckBox
                              isChecked={ isChecked }
                              onChange={ () => {} }
                              onClick={ (e) => {
                                 e.stopPropagation()
                                 e.preventDefault()
                              } }
                              classNames={ `${ disabled ? '!cursor-default' : 'z-[3]' }` }
                              disabled={ disabled }
                           />
                        </div>
                     )
                  }
               </div>
            </div>
            {
               openOptionsModal && (
                  <OptionsModal
                     closeModal={ () => setOpenOptionsModal(false) }
                     openDeleteConfirmModal={ () => {
                        openDeleteConfirmModal();
                        setTimeout(() => {
                           setOpenOptionsModal(false)
                        }, 100)
                     } }
                     desktopType={ desktopType }
                     pageType={ 'image' }
                     id={ id }
                     isDownloading={ isDownloading }
                     downloadOriginalFile={ downloadOriginalFile }
                     isDemoMode={ isDemoMode }
                     goToDetails={ () => goTo('VIDEO_VAULT_DETAILS') }
                     animate={ popupAnimation }
                  />
               )
            }
         </div>
         <div className={ `border border-divider rounded-b-label bg-panel p-3 min-h-[48px]` }>
            <div className='flex justify-between items-center'>
               <div className='flex items-center w-full'>
                  {/* {
                        isPinned &&
                           <Icon
                              name={ `pin` }
                              color={ `warning` }
                              darkColor={ `warning-dark` }
                              className={ `h-5 w-5 min-h-[20px] min-w-[16px] rotate-[35deg] mr-2` }
                              size={ `xl` }
                              data-tooltip-content={ 'Pinned' }
                              data-tooltip-id={ 'ams-top-tooltip' }
                           />
                     } */}
                  <TruncateText
                     className='pr-1'
                     text={ title || '' }
                     textClass={ `font-bold text-xl text-major dark:text-major-dark leading-6 ${ disabled ? '!text-disabled' : '' }` }
                     textSize='20px'
                     fontWeight='700'
                     // width={ isInsideModal ? 'calc(100% - 28px)' : '100%' }
                  />
               </div>
               {
                  !isInsideModal &&
                  <div
                     className={ `flex justify-center items-center vault-${ id }` }
                  >
                     <Button
                        onClick={ (e) => {
                           e.stopPropagation();
                           e.preventDefault();
                           setOpenOptionsModal(!openOptionsModal);
                        } }
                        backgroundColor='transparent'
                        fontIconColor={ disabled ? 'disabled' : 'secondary' }
                        darkIconColor='secondary-dark'
                        iconName='options-vertical'
                        fontIconSize='doubleLarge'
                        padding='0'
                        disabled={ isInsideModal }
                        classNames='!cursor-pointer'
                     />
                  </div>
               }
            </div>
         </div>
      </div>
   )
}

const OptionsModal = ({
   /* eslint-disable */
   desktopType,
   closeModal,
   openDeleteConfirmModal,
   id,
   pageType,
   isDownloading,
   downloadOriginalFile,
   isDemoMode,
   goToDetails,
   isCompletedItem = true,
   animate,
   isDisabledDelete = false
   /* eslint-enable */
}) => {
   const view = (
      <div
         className={ `shadow dark:shadow-dark vault-${ id } w-full py-1 bg-panel overflow-hidden ${ desktopType === 'mobile' ? '' : 'border border-divider rounded-lg' }` }>
         {
            isCompletedItem &&
               <>
                  <Button
                     text='Edit'
                     textSize='base'
                     fontWeight='400'
                     textColor='major dark:text-major-dark'
                     classNames='h-10 py-2.5 px-4 hover:bg-hover'
                     iconName='pencil'
                     fontIconColor='secondary'
                     darkIconcolor='secondary-dark'
                     iconPosition='left'
                     alignment='end'
                     padding='none'
                     backgroundColor='transparent'
                     fontIconSize='extraLarge'
                     onClick={ (e) => {
                        e.stopPropagation()
                        e.preventDefault();
                        goToDetails()
                     } }
                     textMarginX='0 ml-3'
                  />
                  <Button
                     text={ pageType === 'video' ? `Download original file` : 'Download image' }
                     textSize='base'
                     fontWeight='400'
                     textColor='major'
                     classNames='h-10 py-2.5 px-4 hover:bg-hover'
                     iconName='download'
                     fontIconColor='secondary'
                     iconPosition='left'
                     alignment='end'
                     padding='none'
                     backgroundColor='transparent'
                     fontIconSize='extraLarge'
                     onClick={ (e) => {
                        e.stopPropagation()
                        e.preventDefault();
                        downloadOriginalFile(id, pageType)
                     } }
                     disabled={ isDownloading || isDemoMode }
                     dataToolTipContent={ !!isDemoMode ? 'Not available in demo' : '' }
                     textMarginX='0 ml-3'
                  />
               </>
         }
         <Button
            text={ `Delete this ${ pageType }` }
            onClick={ (e) => {
               e.stopPropagation()
               e.preventDefault();
               openDeleteConfirmModal()
            } }
            textSize='base'
            fontWeight='400'
            classNames='h-10 py-2.5 px-4 hover:bg-hover'
            backgroundColor='transparent'
            textColor='error'
            padding='none'
            iconName='delete'
            fontIconColor='error'
            iconPosition='left'
            alignment='end'
            fontIconSize='extraLarge'
            disabled={ isDisabledDelete || isDemoMode }
            dataToolTipContent={ isDemoMode ? 'Not available in demo' : '' }
            textMarginX='0 ml-3'
         />
      </div>
   )
   if(desktopType === 'mobile'){
      return (
         <Modal
            onClose={ closeModal }
            roundedModal='lg'
            animate={  animate  }
         >
            {view}
         </Modal>
      );
   } else {
      return (
         <div
            style={ { bottom: '0px', right: '10px' } }
            className='absolute z-30 bottom-0'>
            {view}
         </div>
      )
   }

}

CompleteCard.propTypes = {
   selectCard: PropTypes.func,
   setOpenOptionsModal: PropTypes.func,
   openDeleteConfirmModal: PropTypes.func,
   src: PropTypes.string,
   desktopType: PropTypes.string,
   title: PropTypes.string,
   isInsideModal: PropTypes.bool,
   openOptionsModal: PropTypes.bool,
   isChecked: PropTypes.bool,
   isFullScreen: PropTypes.bool,
   id: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
   ]),
   isDownloading: PropTypes.bool,
   downloadOriginalFile: PropTypes.func,
   disabled: PropTypes.bool,
   chatMessages: PropTypes.array,
   onDeleteMessag: PropTypes.func,
   updateVaultMessageAction: PropTypes.func,
   path: PropTypes.string,
   hash: PropTypes.string,
   updateMessageUnlockDetails: PropTypes.func,
   setPreviewModal: PropTypes.func,
   isDemoMode: PropTypes.bool,
   goTo: PropTypes.func,
   popupAnimation: PropTypes.string,
}

VaultCard.propTypes = {
   desktopType: PropTypes.string,
   openDeleteConfirmModal: PropTypes.func,
   selectCard: PropTypes.func,
   removeFile: PropTypes.func,
   data: PropTypes.object,
   isChecked: PropTypes.bool,
   isInsideModal: PropTypes.bool,
   isDownloading: PropTypes.bool,
   downloadOriginalFile: PropTypes.func,
   disabled: PropTypes.bool,
   isMultipleSelect: PropTypes.bool,
   onDeleteMessag: PropTypes.func,
   updateVaultMessageAction: PropTypes.func,
   updateMessageUnlockDetails: PropTypes.func,
   isDemoMode: PropTypes.bool,
   goTo: PropTypes.func,
}

VideoCard.propTypes = {
   desktopType: PropTypes.string,
   openDeleteConfirmModal: PropTypes.func,
   selectCard: PropTypes.func,
   removeFile: PropTypes.func,
   data: PropTypes.object,
   isChecked: PropTypes.bool,
   isInsideModal: PropTypes.bool,
   isDownloading: PropTypes.bool,
   downloadOriginalFile: PropTypes.func,
   isMultipleSelect: PropTypes.bool,
   onDeleteMessag: PropTypes.func,
   updateVaultMessageAction: PropTypes.func,
   updateMessageUnlockDetails: PropTypes.func,
   isDemoMode: PropTypes.bool,
   isDisabledCheckbox: PropTypes.bool,
   setPreviewModal: PropTypes.func,
   setOpenOptionsModal: PropTypes.func,
   openOptionsModal: PropTypes.bool,
   previewModalView: PropTypes.any,
   goTo: PropTypes.func,
   popupAnimation: PropTypes.string,
}

VaultCard.defaultProps = {
   disabled: false,
   isMultipleSelect: false,
   isDemoMode: false,
}

export default VaultCard;
