import { init as BuildEvaporate } from 'common/utils/evaporate';
import { createVault } from 'admin/api/AuthApi'
import { makeRandomString } from 'common/utils/utils';
import { isArray } from 'lodash'

export const uploadingVideo = (files, addOrUpdate, allVideos, startUpload) => {
   if(files) {
      const videosToAdd = [];
      files.forEach((element) => {
         const { file, hash } = element
         const { name } = file;
         const extension = name.substr(name.lastIndexOf('.') + 1).toLowerCase();
         const newFileRandomName = makeRandomString(32);
         const newFileName = `${ newFileRandomName }.${ extension }`;
         const uploadPath = `videos/${ newFileRandomName }/${ newFileName }`;
         const candidate = {
            id: makeRandomString(8),
            hash: hash,
            uploadingStatus: 'pending',
            uploadInfo: {
               file,
               uploadPath,
               extension,
               newFileRandomName,
               newFileName,
            },
            type: 'video',
         };
         videosToAdd.push(candidate)
      });
      if(videosToAdd.length === 0){
         return
      }
      if(addOrUpdate) {
         addOrUpdate(null, { vault: videosToAdd })
      }
   }
   let firstPendingVideo = null;
   let inProgressVideo = null;
   if(isArray(allVideos())) {
      allVideos().forEach((video) => {
         const { uploadingStatus } = video;
         if('pending' === uploadingStatus && !firstPendingVideo) {
            firstPendingVideo = video;
         }
         if('uploading' === uploadingStatus && !inProgressVideo) {
            inProgressVideo = video;
         }
      });
   }
   if(!isArray(allVideos())) {
      const video = allVideos()
      const { uploadingStatus } = video
      if('pending' === uploadingStatus && !firstPendingVideo) {
         firstPendingVideo = video;
      }
      if('uploading' === uploadingStatus && !inProgressVideo) {
         inProgressVideo = video;
      }
   }
   if(!inProgressVideo && firstPendingVideo) {
      BuildEvaporate().then((evaporate) => {
         evaporate.add({
            file: firstPendingVideo.uploadInfo?.file,
            name: firstPendingVideo.uploadInfo?.uploadPath,
            progress: (percent, stats) => {
               const formattedPercent = Math.round(percent * 100);
               const info = {
                  ...firstPendingVideo,
                  uploadingStatus: 'uploading',
                  percentage: formattedPercent,

               };
               if(addOrUpdate) {
                  addOrUpdate(firstPendingVideo.id, { id: firstPendingVideo.id, ...info })
               }
            },
            error: (mssg) => console.log('Error', mssg),
            paused: () => console.log('Paused'),
            pausing: () => console.log('Pausing'),
            resumed: () => console.log('Resumed'),
            cancelled: () => {
               console.log('Cancelled')
               // setTimeout(() => {
               //    if(startUpload) {
               //       startUpload()
               //    }
               // }, 10);
            },
            started: (fileKey) => {
               const info = {
                  ...firstPendingVideo,
                  uploadingStatus: 'uploading',
               };
               if(addOrUpdate) {
                  addOrUpdate(firstPendingVideo.id, { id: firstPendingVideo.id, ...info })
               }
            },
            uploadInitiated: (s3Id) => {},
            warn: (mssg) => console.log('Warning', mssg),
         }).then(
            (awsObjectKey) => {
               let src = `${ firstPendingVideo.uploadInfo.newFileRandomName }.${ firstPendingVideo.uploadInfo.extension }`;
               let originalName = firstPendingVideo?.uploadInfo?.file?.name
               let newVideo = {
                  hash: firstPendingVideo.hash.toString(),
                  type: 'video',
                  src,
                  original_name: originalName,
                  title: originalName,
                  preview_mode: 'disabled',
               }
               createVault({
                  data: [newVideo],
               }).then(({ data }) => {
                  const video = data[src];
                  const {
                     id,
                     video_optimization_status,
                  } = video;
                  const errorStatuses = [
                     'mediainfo_error',
                     'optimization_error',
                     'other_error',
                  ];
                  let status = null;
                  if(errorStatuses.includes(video_optimization_status)) {
                     status = 'error'
                  } else if('in_optimization_queue' === video_optimization_status) {
                     status = 'in_optimization_queue';
                  } else if('optimizing' === video_optimization_status) {
                     status = 'optimizing';
                  }
                  const info = {
                     ...video,
                     uploadingStatus: status,
                     id,
                  };
                  if(addOrUpdate) {
                     addOrUpdate(firstPendingVideo.id, { ...info })
                  }

                  setTimeout(() => {
                     if(startUpload) {
                        startUpload()
                     }
                  }, 10);
               });
            },
            (reason) => {
               console.log('File did not upload sucessfully:', reason)
            }
         );
      });
   }
}
export const cancelUplaod = (removeCandidate, videoRemove) => {
   if(!removeCandidate) return
   BuildEvaporate().then((evaporate) => {
      const { uploadInfo: { uploadPath } } = removeCandidate;
      const bucket = process.env.REACT_APP_AWS_S3_BUCKET;
      evaporate.cancel(`${ bucket }/${ uploadPath }`).then(() => {
         videoRemove(removeCandidate.id);
         setTimeout(() => {
            removeCandidate = null;
         }, 20);
      }).catch(() => {
         videoRemove(removeCandidate.id);
         setTimeout(() => {
            removeCandidate = null;
         }, 20);
      })
   });
}
