import PropTypes from 'prop-types';
import CreateEditModal from 'common/components/modules/modal-wrapper';
import { useSelector } from 'react-redux';

const DrmVideoPlaybackErrorModal = ({
   onCloseModal,
}) => {

   const screenWidth = useSelector(state => state?.common?.screenWidth)

   return (
      <CreateEditModal
         type='confirm'
         isMobile={ screenWidth <= 1024 }
         onCloseModal={ onCloseModal }
         icon='warning'
         iconColor='warning'
         title='Incognito mode disabled on this site'
         description={ `Video streaming is not available on this site in incognito mode. Please use Chrome's regular browsing mode.` }
         buttonText='Contact support'
         primaryButtonClassName='!px-[14px]'
         confirmButtonProperties={ {
            textMarginX: '0',
         } }
         action={ () => {
            const url = new URL(window.location.href);
            url.searchParams.set('trigger', 'contact-support-chrome-incognito');
            window.history.replaceState({}, '', url);
            if(window.Intercom) {
               window.Intercom('showNewMessage');
            }
         } }
         cancelText='Ok'
      />
   )
}

DrmVideoPlaybackErrorModal.propTypes = {
   onCloseModal: PropTypes.func,
}

export default DrmVideoPlaybackErrorModal;
