import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ChromePicker } from 'react-color';
import './index.css';

class ColorPicker extends Component {
   constructor(props) {
      super(props);
      this.state = {

      };
      this.top = 0
   }

   componentDidMount() {
      window.addEventListener('scroll', this.handleScroll);
   }

   componentWillUnmount() {
      window.removeEventListener('scroll', this.handleScroll);
   }

   handleScroll = () => {
      this.top = window.scrollY
      this.handleChangeColor(null)
   }

   handleChangeColor = (color) => {
      if(!color){
         return null
      } else {
         const { handleChangeColor } = this.props
         handleChangeColor(color)
      }
   }

   render() {
      const {
         color,
      } = this.props;
      const body = document.body
      return (
         <div
            onTouchStart={ () => {
               body.classList.add('body_non_scroll')
            } }
            onTouchMove={ () => {
               body.classList.add('body_non_scroll')
            } }
            onTouchEnd={ () => {
               body.classList.remove('body_non_scroll')
            } }
         >
            <ChromePicker
               onChange={ (color) => this.handleChangeColor(color) }
               color={ color }
            />
         </div>
      );
   }
}

ColorPicker.defaultProps = {
};

ColorPicker.propTypes = {
   color: PropTypes.string,
   handleChangeColor: PropTypes.func,

};
export default ColorPicker;
