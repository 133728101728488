const initialState = {
   isFetching: true,
   isFetchingByFilter: false,
   updateStatusFetching: false,
   openStatusMod: false,
   videosData: {},
   isNotCompletedFetching: false,
   isNewFetching: false,
   notCompletedList: [],

   isEmptyByFilter: false,
   isEmpty: false,
   fetchVideoById: {},
   createErrorsData: {},
   isFetchingById: true,
   updateButtonDisabled: false,
   deleteButtonDisabled: false,
   openConfirmDialog: false,
   isErrors: false,
   openEditTitelMod: false,
   errors: null,
   casts: [],
   key: '',
   isEmptyByFilterInsideModal: false,
   isEmptyInsideModal: false,
   videosDataInsideModal: {},
   isFetchingByFilterInsideModal: false,
   isFetchingInsideModal: true,
   isNewFetchingInsideModal: false,
   isInited: false,
   filters: {},
   filtersInsideModal: {},
   isDistoryFetching: false,
   bulkEditIds: null,

   isFetchingByBulkVideos: true,
   bulkVideos: { data: [], resources: {} },
   bulkErrors: {},
   bulkUpdateFetching: false,
   replaceState: {},
   warningModal: {
      isOpen: false,
      description: null,
      ids: null,
      multiple_bundles: null,
      reason: null,
      buttonText: null,
      title: null,
      buttonIconName: null,
      cancelText: null,
      secondaryButtonClassName: null,
   },
   initialVideoDataById: {},
   isFetchingRemoveDrmFromVideo: false,
};

export default initialState;
