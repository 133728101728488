import React from 'react'
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import cx from 'classnames'
import './style.scss'
import Icon from 'common/components/elements/icons';
import NumberFormatter from 'admin/utils/NumberFormatter';
import DateFormatter from 'common/utils/DateFormatter';
import { getCountState } from 'common/utils/utils';

const numberFormatter = new NumberFormatter();

const MediaButtonWrapper = ({
   children,
   size = 'sm',
   className = 'pl-[2px] pr-2',
   roundedClass = 'rounded',
}) => {
   if(!children) return null;
   return (
      <div
         className={ cx({
            [`bg-major-48 mms-media-button-wrapper inline-flex items-center justify-center w-fit ${ className } ${ roundedClass }`]: true,
            'mms-media-button-wrapper-lg': size === 'lg',
            'mms-media-button-wrapper-sm': size === 'sm',
         }) }

      >
         {children}
      </div>
   )
}

const OptionButton = ({
   iconName,
   onClick,
   dataToolTipContent,
}) => {

   return (
      <MediaButtonWrapper className='p-[2px]' hasOnlyIcon>
         <div className='w-5 h-5 flex items-center justify-center'>
            <Button
               iconName={ iconName }
               backgroundColor='transparent'
               fontIconColor='major-dark'
               fontIconSize='base'
               onClick={ onClick }
               dataToolTipContent={ dataToolTipContent }
               padding='none'
            />
         </div>
      </MediaButtonWrapper>
   )
}
const MediaChip = ({
   countOrDuration,
   type,
   className = '',
   name = 'photo',
   photoIcon = 'several-images',
}) => {
   if(!countOrDuration){
      return null
   }
   let newcount = countOrDuration;
   if('video' === type || type.includes('video')){
      newcount = DateFormatter.getSecondsConversion(countOrDuration);
   } else {
      const numberFormatter = new NumberFormatter();
      newcount = getCountState(numberFormatter.withCommas(countOrDuration), name, countOrDuration)
   }
   return (
      <MediaButtonWrapper
         className={ `gap-1 pl-[2px] pr-2 ${ className } ml-auto` }
      >

         <div className='h-5 w-5 flex items-center justify-center'>
            <Icon
               name={ 'photoset' === type ? photoIcon : 'play-default' }
               color='major-dark'
               size='sm'
            />
         </div>
         <span className='text-major-dark text-sm font-medium leading-[18px]'>
            { newcount }
         </span>
      </MediaButtonWrapper>
   )
}

const AdminBottonOptionsItem = ({
   iconName,
   text,
   onClick,
   disabled,
}) => {
   return (
      <div
         role='presentation'
         onClick={ (e) => {
            if(onClick && !disabled){
               onClick(e)
            }
         } }
         className={ cx({
            'flex gap-1': true,
            'cursor-pointer': onClick && !disabled,
            'cursor-default': !onClick || disabled,
         }) }
      >
         <div className='h-4 w-4 flex items-center justify-center'>
            <Icon name={ iconName } color='major-dark' size='base' />
         </div>
         <span className='text-sm text-major-dark leading-[15px]'>{text}</span>
      </div>
   )
}

const AdminBottonOptions = ({
   viewsCount,
   likesCount,
   commentsCount,
   disabled,
   totalTip,
   sentTip,
}) => {

   return (
      <MediaButtonWrapper
         className='px-[6px] m-2 gap-4'
      >
         <AdminBottonOptionsItem
            iconName='views'
            text={ numberFormatter.withCommas(viewsCount) }
         />
         <AdminBottonOptionsItem
            iconName='heart-filled'
            text={ numberFormatter.withCommas(likesCount) }
         />
         <AdminBottonOptionsItem
            iconName='comment'
            text={ numberFormatter.withCommas(commentsCount) }
         />
         <AdminBottonOptionsItem
            iconName='comment'
            text={ numberFormatter.withCommas(commentsCount) }
         />
         <AdminBottonOptionsItem
            iconName='tip'
            text={  `${ numberFormatter.withCommas(totalTip) } USD` }
            disabled={ disabled }
            onClick={ (e) => {
               e.preventDefault()
               e.stopPropagation()
               sentTip()

            } }
         />
      </MediaButtonWrapper>
   )
}
AdminBottonOptions.propTypes = {
   viewsCount: PropTypes.any,
   likesCount: PropTypes.any,
   commentsCount: PropTypes.any,
   totalTip: PropTypes.any,
   sentTip: PropTypes.func,
   disabled: PropTypes.bool,
};
AdminBottonOptionsItem.propTypes = {
   iconName: PropTypes.string,
   text: PropTypes.string,
   onClick: PropTypes.func,
   disabled: PropTypes.bool,
};
MediaChip.propTypes = {
   className: PropTypes.string,
   photoIcon: PropTypes.string,
   name: PropTypes.string,
   type: PropTypes.string,
   countOrDuration: PropTypes.any,
};
MediaButtonWrapper.propTypes = {
   children: PropTypes.any,
   size: PropTypes.string,
   className: PropTypes.string,
   roundedClass: PropTypes.string,
};
OptionButton.propTypes = {
   iconName: PropTypes.string,
   dataToolTipContent: PropTypes.string,
   onClick: PropTypes.func,
};

export {
   OptionButton,
   MediaButtonWrapper,
   MediaChip,
   AdminBottonOptions,
   AdminBottonOptionsItem,
}
