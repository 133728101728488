import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   // all members data /
   [types.UPDATET_STATE_ACTION]: (state, action) => {
      return {
         ...state,
         ...action.payload,
      }
   },
   [types.FETCH_DETAILS_START]: (state) => {
      return {
         ...state,
         isFetching: true,
         isEmpty: false,
         newPricing: {},
      }
   },
   [types.FETCH_DETAILS_COMPLETED]: (state, action) => {
      const  res = action.payload;
      return {
         ...state,
         isFetching: false,
         pricingsData: res,
         isEmpty: res.length === 0,
      }
   },
   [types.FETCH_DETAILS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetching: false,
         pricingsError: action.payload,
      }
   },
   [types.FETCH_DETAILS_BY_FILTER_START]: (state) => {
      return {
         ...state,
         isFetchingByFilter: true,
         isEmptyByFilter: false,
         isEmpty: false,
      }
   },
   [types.FETCH_DETAILS_BY_FILTER_COMPLETED]: (state, action) => {
      const  { data, filters } = action.payload;
      return {
         ...state,
         isFetchingByFilter: false,
         pricingsData: data,
         isEmptyByFilter: data.length === 0,
         membershipFilters: filters,
      }
   },
   [types.FETCH_DETAILS_BY_FILTER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingByFilter: false,
         pricingsError: action.payload,
      }
   },

   [types.FETCH_DETAILS_BY_ID_START]: (state) => {
      return {
         ...state,
         isFetchingById: true,
         detailsError: {},
      }
   },
   [types.FETCH_DETAILS_BY_ID_COMPLETED]: (state, action) => {
      const  res = action.payload;
      return {
         ...state,
         isFetchingById: false,
         activeData: res,
      }
   },
   [types.FETCH_DETAILS_BY_ID_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingById: false,
         isCheckedValidation: false,
         detailsError: action.payload,
      }
   },

   [types.ADD_NEW_PRICING_START]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         addPricingFetching: payload,
      }
   },
   [types.ADD_NEW_PRICING_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         pricingsData: [...state.pricingsData, payload],
         addPricingFetching: false,
         duplicatePricingFetching: false,

      }
   },
   [types.DELETE_MEMBERSHIP_COMPLETED]: (state, action) => {
      const { payload } = action;
      return {
         ...state,
         pricingsData: [...state.pricingsData].filter(membership => membership.id !== payload),
         deletePricingFetching: false,
         membershipInfo: {
            ...state.membershipInfo,
            active_memberships_count: state?.membershipInfo?.existing_memberships.filter(membership => membership.id !== payload).filter(e => e?.is_active)?.length,
            existing_memberships: state?.membershipInfo?.existing_memberships.filter(membership => membership.id !== payload),
         },
      }
   },
   [types.DELETE_MEMBERSHIP_START]: (state, action) => {
      return {
         ...state,
         deletePricingFetching: action.payload,
      }
   },
   [types.DUPLICATE_NEW_PRICING_START]: (state, action) => {
      return {
         ...state,
         duplicatePricingFetching: action.payload,
      }
   },
   [types.DUPLICATE_NEW_PRICING_FAILED]: (state, action) => {
      return {
         ...state,
         duplicatePricingFetching: false,
         duplicateError: action.payload,
      }
   },
   [types.UPDATE_DATA_START]: (state) => {
      return {
         ...state,
         isFetchingUpdate: true,
         detailsError: {},
      }
   },
   [types.UPDATE_DATA_COMPLETED]: (state, action) => {
      const  res = action.payload;

      const newList = [...state.pricingsData]
         .map(e => {
            let{ ...item } = e;
            if(item.id === res.id){
               item = {
                  ...item,
                  ...res,
               }
            }
            return item
         })
         .filter(it => {
            if(window?.location?.pathname === '/memberships/unpublished') {
               return !it?.is_active
            } else {
               return it?.is_active
            }
         })


      let newState = {
         pricingsData: newList,
         isEmpty: newList?.length === 0,
      }
      if(state.activeData.id === res.id){
         newState = {
            ...newState,
            activeData: {
               ...state.activeData,
               ...res,
            },
         }
      }
      if(state?.membershipInfo?.existing_memberships && state?.membershipInfo?.existing_memberships?.find(el => el?.id === res.id)){
         newState = {
            ...newState,
            membershipInfo: {
               ...state.membershipInfo,
               active_memberships_count: state?.membershipInfo?.existing_memberships.map(it => it?.id === res?.id ? ({ ...it, ...res }) : it).filter(e => e?.is_active)?.length,
               existing_memberships: state?.membershipInfo?.existing_memberships.map(it => it?.id === res?.id ? ({ ...it, ...res }) : it),
            },
         }
      }
      // if(state?.membershipInfo?.existing_memberships && state?.membershipInfo?.existing_memberships?.find(it => (it?.label_enabled && it?.custom_label))){
      //    newState = {
      //       ...newState,
      //       membershipInfo: {
      //          ...state.membershipInfo,
      //          existing_memberships: state?.membershipInfo?.existing_memberships.map(it => (it?.label_enabled && it?.custom_label) ? ({ ...it, custom_label: null, custom_label_color: null, label_enabled: false }) : it),
      //       },
      //    }
      // }
      // if(state?.pricingsData && state?.pricingsData?.find(it => (it?.label_enabled && it?.custom_label))){
      //    newState = {
      //       ...newState,
      //       pricingsData: state?.pricingsData.map(it => (it?.label_enabled && it?.custom_label) ? ({ ...it, custom_label: null, custom_label_color: null, label_enabled: false }) : it),
      //    }
      // }
      return {
         ...state,
         isFetchingUpdate: false,
         isOpenEditMembershipModal: false,
         ...newState,
      }
   },
   [types.UPDATE_DATA_FAILED]: (state, action) => {
      const updateErrors = { ...action?.payload?.data }
      if(updateErrors.error && updateErrors?.error.includes('pricing update error, promotion discount')) {
         updateErrors.promotion_discount = updateErrors?.error.replace('pricing update error, promotion discount.', '')
         delete updateErrors.error
      }

      // Handle error on editing membership with 100% discount, shoud not able to set first payment > 30 days
      if(updateErrors.error && updateErrors.data && 'promotion_discount_100' === updateErrors?.error) {
         updateErrors.promotion_discount_100 = updateErrors.data
         delete updateErrors.error
      }
      return {
         ...state,
         isFetchingUpdate: false,
         isCheckedValidation: false,

         detailsError: { ...updateErrors },
      }
   },

   [types.FETCH_AVAILABLE_DATA_START]: (state) => {
      return {
         ...state,
         isFetchingAvailable: true,
         addNewError: {},
         detailsError: {},
      }
   },
   [types.FETCH_AVAILABLE_DATA_COMPLETED]: (state, action) => {
      const  res = action.payload;
      return {
         ...state,
         isFetchingAvailable: false,
         membershipInfo: res,
      }
   },
   [types.FETCH_AVAILABLE_DATA_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchingAvailable: false,
         detailsError: action.payload,
      }
   },

   [types.CREATE_DATA_START]: (state, action) => {
      const  res = action.payload;

      let newState = {
         ...state,
         newPricing: res,
      }

      // if(state?.membershipInfo?.existing_memberships && state?.membershipInfo?.existing_memberships?.find(it => (it?.label_enabled && it?.custom_label))){
      //    newState = {
      //       ...newState,
      //       membershipInfo: {
      //          ...state.membershipInfo,
      //          existing_memberships: state?.membershipInfo?.existing_memberships.map(it => (it?.label_enabled && it?.custom_label) ? ({ ...it, custom_label: null, custom_label_color: null, label_enabled: false }) : it),
      //       },
      //    }
      // }
      // if(state?.pricingsData && state?.pricingsData?.find(it => (it?.label_enabled && it?.custom_label))){
      //    newState = {
      //       ...newState,
      //       pricingsData: state?.pricingsData.map(it => (it?.label_enabled && it?.custom_label) ? ({ ...it, custom_label: null, custom_label_color: null, label_enabled: false }) : it),
      //    }
      // }

      return {
         ...newState,
      }
   },
   [types.ADD_NEW_START]: (state, action) => {
      return {
         ...state,
         addNewError: {},
         isFechingCreate: action.payload,
      }
   },
   [types.ADD_NEW_FAILED]: (state, action) => {
      return {
         ...state,
         addNewError: action.payload,
         isFechingCreate: false,
         isCheckedValidation: false,
      }
   },

   [types.MEMBERSHIP_REORDER_FAILED]: (state, action) => {
      return {
         ...state,
         pricingsError: action.payload,
      }
   },

   [types.OPEN_CLOSE_NEW_MEMBERSHIP_MODAL]: (state, action) => {
      return {
         ...state,
         isOpenNewMembershipModal: action.payload,
      }
   },
   [types.CONTINUE_NEW_MEMBERSHIP_MODAL]: (state, action) => {
      return {
         ...state,
         isContinue: action.payload,
      }
   },
   [types.OPEN_CLOSE_EDIT_MEMBERSHIP_MODAL]: (state, action) => {
      return {
         ...state,
         isOpenEditMembershipModal: action.payload,
      }
   },
   [types.ADD_OR_CREATE_BENEFIT_START]: (state) => {
      return {
         ...state,
         isFetchingCreateAddBenefit: true,
         benefitErrors: {},
      }
   },
   [types.CHECK_VALIDATION_START]: (state, action) => {
      return {
         ...state,
         isCheckedValidation: action.payload,
         addNewError: {},
      }
   },
   [types.BENEFITS_FAILED]: (state, action) => {
      return {
         ...state,
         benefitErrors: action.payload,
      }
   },
   [types.UPDATE_BENEFITS_COMPLETED]: (state, action) => {
      const {
         data, actionType,
      } = action.payload;
      let newData = state?.membershipInfo?.benefits ? [...state.membershipInfo.benefits] : [];
      let newState = {};
      const { activeData } = state;

      switch (actionType) {
         case 'add':
            newData = [...newData, { ...data }];
            if(activeData && activeData.id) {
               if(data.membership_pricings.includes(activeData.id)){
                  newState = {
                     ...newState,
                     activeData: {
                        ...activeData,
                        benefits: [...activeData.benefits, data],
                     },
                  }
               }
            }
            break;
         case 'reorder':
            newData = [...data];
            break;
         case 'update':
            newData = [...newData].map(benefit => {
               let item = { ...benefit };
               if(item.id === data.id){
                  item = {
                     ...item,
                     ...data,
                  }
               }
               return item
            });
            if('membership_pricings' in data){
               let benefits = [...activeData.benefits];
               if([...data.membership_pricings].includes(activeData.id)){
                  if([...benefits].findIndex(benefit => benefit.id === data.id) < 0){
                     benefits = [...benefits, data]
                  }
               } else {
                  benefits = [...benefits].filter(benefit => benefit.id !== data.id)

               }

               newState = {
                  ...newState,
                  activeData: {
                     ...activeData,
                     benefits: benefits,
                  },
               }
            }
            break;

         case 'delete':
            newData = [...newData].filter(benefit => benefit.id !== data.id);
            if(activeData && activeData.id) {
               newState = {
                  ...newState,
                  activeData: {
                     ...activeData,
                     benefits: [...activeData.benefits].filter(el => el.id !== data.id),
                  },
               }
            }
            break;

         default:
            break;
      }
      return {
         ...state,
         membershipInfo: {
            ...state.membershipInfo,
            benefits: newData,
         },
         ...newState,
      }
   },
   [types.UPDATE_ACTIVE_MEMBERSHIP]: (state, action) => {
      const data = action.payload;
      let newState = {
         ...state,
      }
      if(state.activeData.id === data.id){
         newState = {
            ...newState,
            activeData: {
               ...state.activeData,
               ...data,
            },
         }
      }
      if(state?.membershipInfo?.existing_memberships && state?.membershipInfo?.existing_memberships?.find(el => el?.id === data.id)){
         newState = {
            ...newState,
            membershipInfo: {
               ...state.membershipInfo,
               active_memberships_count: state?.membershipInfo?.existing_memberships.map(it => it?.id === data?.id ? ({ ...it, ...data }) : it).filter(e => e?.is_active)?.length,
               existing_memberships: state?.membershipInfo?.existing_memberships.map(it => it?.id === data?.id ? ({ ...it, ...data }) : it),
            },
         }
      }
      return {
         ...state,
         isFetchingUpdate: false,
         isOpenEditMembershipModal: false,
         ...newState,
      }
   },
}

export default createReducer(initialState)(reducersMap);
