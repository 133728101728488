const initialState = {

   isFetchChatSettings: true,
   isFetchSettingsUpdate: false,
   settingsData: {},
   settingsErrorsData: {},
   ///////////////////////////
   isFetchMembers: true,
   isFetchNewMembers: false,
   isEmptyMembers: false,
   isEmptyMembersByFilter: false,
   isFetchMembersFilter: false,
   membersData: {},

   //////////////////////////
   conversationsList: {
      data: [],
   },
   isFetchConversation: true,
   groupConversation: {
      typers: {},
   },
   isFetchNewConversation: false,
   isFetchNewConversationByFilter: false,
   isEmptyconversationByFilter: false,
   isEmptyconversation: false,
   //////////////////////
   isFetchConversationMessages: false,
   isEmptyConversationMessages: false,
   conversationMessages: {},
   ///////////////////////
   likedMemberByMessage: {},
   /////////////////
   activeConverstaion: null,
   isOpenRightContent: false,
   showMiniChat: false,
   descktopUserList: [],
   descktopChatList: null,
   openDesctopDialogue: false,
   isSocketConnected: false,

   initedConverstionMessages: {},
   onlineUsers: [],

   memberByUuid: {
      isOpen: null,
      loadData: true,
      data: {},
   },
   soket: null,
   initedConversation: false,
   unreadPrivateMessagesCount: 0,

   openDkChatNotification: false,
   cancelFlag: false,
   fetchingConversationIds: [],
   conversationsFilter: {
      page: 1,
      sortBy: ['date:desc'],
      type: ['open'],
   },
   memberVideoDownloadMultipleWatermarks: false,
};

export default initialState;
