import React, { memo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Router from 'admin/routes/router';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import Button from 'common/components/elements/buttons/primary';
import Search from 'common/components/elements/inputs/search';
import EmptyState from 'common/components/modules/empty-states';
import './style.scss'
import TruncateText from 'common/components/elements/truncate-text';
import ManageTagCreatEditModal from 'admin/views/manage-resource/create-edit';
import toaster from 'common/utils/toast';
import IconLabel from 'common/components/elements/icon-label';
import ConfirmModal from 'common/components/modules/modal-wrapper';

const MODAL_SETTINGS = {
   collections: {
      icon: 'collection',
   },
   tags: {
      icon: 'tag-new',
   },
   casts: {
      icon: 'cast-members-new',
   },
}

const ModalContent = ({
   title,
   textKeyName,
   selectedItems,
   onSaveChange,
   searchTitle,
   manageTitle,
   list,
   isMobile,
   emptyDescription,
   onCloseModal,
   manageButtonLink,
   onSaveEditChange,
   tagsError,
   createTagsError,
   type,
}) => {
   const [selectedData, setSelectedData] = useState(selectedItems);
   const [offsetHeight, setOffsetHeight] = useState(0);
   const [isEmpty, setIsEmpty] = useState(list.length === 0)
   const [isEmptyByFilter, setIsEmptyByFilter] = useState(list.length === 0)
   const [searchValue, setSearchValue] = useState('')
   const [isShow, setIsShow] = useState(false)
   const [allTags, setAllTags] = useState(list)
   const [initialSelectedData, setInitialSelectedData] = useState([])
   const [showDisacardChanges, setShowDisacardChanges] = useState(false)

   useEffect(() => {
      setInitialSelectedData(selectedItems)
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      setIsEmpty(!list?.length)
      setIsEmptyByFilter(!list?.length)
      setAllTags(list)
   }, [list])

   useEffect(() => {
      const h = isMobile ? window.innerHeight - 72 : window.innerHeight < 650 ?  window.innerHeight - 72 : '640px';
      setOffsetHeight(h);
   }, [isMobile])

   function onAdd(item) {
      let addList = [...selectedData, item]

      setSelectedData(addList)
      toaster.success(`${ item[textKeyName] } has been added`)
   }

   function onRemove(item) {
      let addList =  [...selectedData].filter(elm => elm.id !== item.id)

      setSelectedData(addList)
      toaster.success(`${ item[textKeyName] } has been removed`)
   }

   function onSearch(value) {
      let newdataList = [];
      newdataList = list.filter(item => item[textKeyName] && item[textKeyName].toLowerCase().search(value.toLowerCase()) !== -1)

      setIsEmptyByFilter(newdataList.length === 0)
      setAllTags(newdataList)

      if(value && value.length === 25) {
         toaster.error('25 character limit has been reached',  { toastId: 'search_tags' })
         return
      }
   }

   function handleSaveChange() {
      const selectedId = selectedData && selectedData.map(it => it.id);
      onSaveChange(selectedId)
   }

   const onClickCloseModal = (closeAnyway = false) => {
      if(JSON.stringify(initialSelectedData) !== JSON.stringify(selectedData) && !closeAnyway) {
         setShowDisacardChanges(true)
         return
      }
      onCloseModal()
   }


   return (
      <>
         {
            !isShow && <div
               className='tags-modal-content overflow-y-hidden'
               style={ { minHeight: offsetHeight, height: offsetHeight } }
            >
               <div className='flex flex-col select-tag-modalcontent max-h-full'>
                  <div className='pb-4 items-center selcted-items'>
                     <div className='header-shadow px-4 pt-4 pb-5 flex flex-col gap-4'>
                        <Button
                           iconName='close'
                           fontIconColor='major'
                           darkIconcolor='major-dark'
                           classNames='h-[24px] w-[24px]'
                           backgroundColor='transparent'
                           padding='none'
                           onClick={ () => onClickCloseModal() }
                           alignment='start'
                        />
                        <div className='flex flex-col justify-center items-center gap-3'>
                           <IconLabel
                              color='action'
                              name={ MODAL_SETTINGS[type].icon }
                              size='2xl'
                              className='h-10 w-10'
                           />

                           <span className=' text-major dark:text-major-dark font-bold text-[20px] leading-[30px]'>{title}</span>
                        </div>
                     </div>
                     {
                        !isEmpty &&
                        <div className='z-10 mt-1  px-6'>
                           <Search
                              onChange={ (value) => {
                                 setSearchValue(value)
                                 onSearch(value)
                              }  }
                              placeholder={ searchTitle }
                              maxLength={ 25 }
                              height='h-10'
                              iconSize='xl'
                              classNames='md:w-80'
                              version='2'
                           />
                        </div>
                     }
                  </div>

                  <div
                     className={ `tags-list flex rounded-lg bg-panel dark:bg-panel-dark  flex-col overflow-y-auto items-content flex-1 justify-between ams-custom-scrool ` }>
                     <div className='flex-1'>
                        {
                           !isEmpty ? (
                              <>
                                 {
                                    !!isEmptyByFilter && (
                                       <div className='flex h-full flex-col mx-6 gap-[118px] '>
                                          {
                                             isEmptyByFilter && type === 'tags' &&
                                             <div
                                                role={ 'presentation' }
                                                onClick={ () => setIsShow(!isShow) }
                                                className={ `flex flex-1 items-center gap-3 cursor-pointer leading-5 font-medium z-0 skin-primary-text` }
                                             >
                                                <Icon size={ '[17px]' } name={ `tag-new` } primaryColor={ `${ window?.skinPrimaryColor }` } className={ ` w-10 text-[17px]  `  } />
                                                <span className={ `font-medium text-sm  hover:underline text-action` }>Create new tag: <span className='font-semibold'>"{searchValue}"</span></span>
                                             </div>
                                          }
                                          <EmptyState
                                             type='search'
                                             result={  searchValue }
                                          />
                                       </div>
                                    )
                                 }

                                 { !isEmptyByFilter && allTags.map(item => {
                                    let selected = false

                                    selectedData.forEach(selectedItem => {
                                       if(selectedItem.id === item.id) {
                                          selected = true
                                          return
                                       }
                                    })

                                    return (
                                       <div
                                          style={ { minHeight: '40px' } }
                                          className={ `flex relative items-center justify-between h-5 leading-5 font-medium z-0  px-6 ${ allTags.length >= 8 ? 'pr-3' : 'pr-6' }` }
                                          key={ item.id }
                                       >

                                          {
                                             type === 'tags' && (
                                                <div className='w-5 h-5'>
                                                   <Icon
                                                      name='tag-new'
                                                      size='xl'
                                                      className={ `${ selected ? 'skin-primary-text' : 'text-secondary dark:text-secondary-dark ' }` }
                                                   />
                                                </div>
                                             )
                                          }

                                          <div
                                             style={ {
                                                width: type === 'casts' ? 'calc(100% - 69px)' : 'calc(100% - 110px)',
                                             } }
                                             className='flex-1 pl-4 my-4 flex '>
                                             <TruncateText
                                                text={ item[textKeyName] }
                                                textClass='font-normal text-sm text-major'
                                                textSize='14px'
                                                fontWeight='400'
                                                className={ 'casts' === type ? 'notranslate' : '' }
                                             />

                                          </div>
                                          <div className='flex justify-between items-center cursor-pointer'>
                                             <Button
                                                text={ selected ? 'Remove' : 'Add' }
                                                textSize='small'
                                                textColor={ selected ? 'error dark:text-error-dark' : 'action dark:text-action-dark' }
                                                classNames={ type === 'tags' ? 'h-full w-full pr-1' : 'h-full w-full' }
                                                alignment='end'
                                                backgroundColor='transparent'
                                                textMarginX='0'
                                                onClick={ () => selected ? onRemove(item)  : onAdd(item) }
                                             />
                                          </div>
                                       </div>
                                    )
                                 }) }
                              </>
                           ) :
                              <div className='flex h-full items-center w-full m-auto'>
                                 <EmptyState
                                    noContentText={ emptyDescription  }
                                    noContentTextClassName='!mt-0'
                                 />
                              </div>
                        }
                     </div>
                     <div className='flex justify-center'>
                        <Link
                           to={ Router.route(manageButtonLink).getCompiledPath() }
                           target='_blank'
                        >
                           <div
                              className=' h-9  flex items-center justify-center cursor-pointer  leading-5 font-medium z-0'
                           >
                              <Button
                                 text={ manageTitle }
                                 secondIconName='go-to'
                                 primaryColor
                                 backgroundColor='transparent'
                                 textPosition='left'
                                 alignment='center'
                                 classNames='h-full pl-[14px] pr-[6px]'
                                 padding='none'
                                 textSize='small'
                                 fontIconSize='extraLarge'
                                 verticalAlignment='center'
                                 textMarginExistSecondIcon='[14px]'

                              />
                           </div>
                        </Link>
                     </div>

                  </div>
                  <div className='flex flex-col bottom-content flex-2 px-4 mb-6 mt-3 rounded-lg overflow-hidden h-9'>
                     <Button
                        onClick={ () => handleSaveChange() }
                        fontWeight='medium'
                        textSize='small'
                        text={ 'Done' }
                        disabled={ type === 'casts' && selectedData.length === 0 }
                        primaryColor
                        borderRadius='large'
                        classNames='h-9 w-[327px] mx-auto'
                        dataToolTipContent={ type === 'casts' && selectedData.length === 0 ? 'At least 1 model must be selected' : '' }
                     />
                  </div>
               </div>
            </div>
         }
         {
            isShow && type === 'tags' &&
            <div className='h-fit'>
               <ManageTagCreatEditModal
                  onSaveEditChange={ (_, name, cb) => {
                     onSaveEditChange(name, async (newTag, bool) => {
                        if(bool){
                           await setSelectedData([...selectedData, newTag])
                           await setAllTags([...list, newTag])
                        }
                     }, cb)
                  }  }
                  name={ searchValue }
                  onCloseModal={ () => {
                     setIsShow(!isShow)
                     onSearch('')
                     createTagsError({ success: true, name: '' })
                  } }
                  closeManageTagCreateEditModal={ () => {
                     setIsShow(!isShow)
                     onSearch('')
                  } }
                  tagsError={ tagsError }
                  createTagsError={ createTagsError }
               />
            </div>
         }
         {
            showDisacardChanges &&
            <ConfirmModal
               type='confirm'
               icon='cancel'
               iconColor='error'
               descriptionColorClass='text-secondary'
               onCloseModal={ () => {
                  setShowDisacardChanges(false)
               }  }
               title='Discard changes?'
               description='Changes you made so far will not be saved'
               cancelText='Keep editing'
               nonPrimaryColor
               contentWidth='375px'
               isMobile={ isMobile }
               iconSize='2xl'
               buttonText='Discard changes'
               textColor='error'
               action={ () => onClickCloseModal(true) }
               buttonClassName='mt-8'
               modalClassName='!z-[100]'
            />
         }
      </>
   );
};
ModalContent.defaultProps = {
   textKeyName: 'title',
};
ModalContent.propTypes = {
   list: PropTypes.array,
   selectedItems: PropTypes.array,
   title: PropTypes.string,
   onSaveChange: PropTypes.func,
   searchTitle: PropTypes.string,
   manageTitle: PropTypes.string,
   textKeyName: PropTypes.string,
   emptyDescription: PropTypes.string,
   // onClickMoreInfo: PropTypes.func,
   onCloseModal: PropTypes.func,
   isMobile: PropTypes.bool,
   // moreInfoText: PropTypes.string,
   manageButtonLink: PropTypes.string,
   type: PropTypes.string,
   onSaveEditChange: PropTypes.func,
   tagsError: PropTypes.object,
   createTagsError: PropTypes.func,
};

export default memo(ModalContent);
