import PropTypes from 'prop-types';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import Router from 'admin/routes/router';

const VideoDrmConfirmModals = ({
   isMobile,
   type,
   onConfirm,
   onCloseModal,
   videoTitle = '',
   isLoading,
}) => {
   const goToSettings = () => {
      window.open(`${ window.location.origin }${ Router.route('MY_SITE_ENHANCED_VIDEO_SECURITY').getCompiledPath() }`, '_blank', 'noopener,noreferrer');
   }

   const getModalState = () => {
      let result = null;

      switch (type) {
         case 'video_not_drm_setting_on':
         case 'video_drm_setting_off':
            result = {
               icon: 'warning',
               iconColor: 'warning',
               title: 'Enhanced Security',
               buttonText: 'Go to settings',
               nonPrimaryColor: true,
               textColor: 'secondary',
               buttonIconName: 'go-to',
               action: goToSettings,
               secondButtonTitle: 'Ok, replace',
               secondButtonAction: onConfirm,
               overrideSecondButtonProps: {
                  textColor: 'action',
                  textClassName: '',
                  primaryColor: true,
                  backgroundColor: 'transparent',
                  textMarginX: '0',
               },
               withoutCloseButton: false,
            }
            if('video_not_drm_setting_on' === type) {
               result.description = 'Enhanced Security will be added to this video as per your settings'
            } else {
               result.description = 'Enhanced Security will be removed from this video as per your settings'
            }
            break;

         case 'remove_drm_from_video':
            result = {
               icon: 'cancel',
               iconColor: 'error',
               title: 'Remove Enhanced Security?',
               description: `Are you sure you want to remove Enhanced Security from <span class="font-bold">${ videoTitle || ' ' }</span> video? This will require re-encoding and will NOT be reversible. This process will take some time.`,
               buttonText: 'Contact support',
               nonPrimaryColor: true,
               textColor: 'secondary',
               action: () => {
                  if(window.Intercom) {
                     window.Intercom('showNewMessage');
                  }
               },
               secondButtonTitle: 'Yes, remove Enhanced Security',
               secondButtonAction: onConfirm,
               overrideSecondButtonProps: {
                  textColor: 'error',
                  textClassName: '',
                  backgroundColor: 'transparent',
                  textMarginX: '0',
                  isLoading: isLoading,
                  loaderColor: 'error',
               },
            }
            break;
         default:
            break;
      }

      return result;
   }

   const modalState = getModalState()

   if(!modalState) {
      return null;
   }

   return (
      <ConfirmModal
         isMobile={ isMobile }
         type='confirm'
         textColor='secondary'
         primaryButtonClassName='gap-2 !px-[14px]'
         confirmButtonProperties={ {
            fontIconColor: 'secondary',
            fontIconSize: 'extraLarge',
            textMarginX: '0',
         } }
         secondButtonClassName='!px-[14px]'
         withoutCancelButton={ false }
         secondaryButtonClassName='!px-[6px]'
         onCloseModal={ onCloseModal }
         { ...modalState }
      />
   )
}

VideoDrmConfirmModals.propTypes = {
   isMobile: PropTypes.bool,
   type: PropTypes.string,
   onConfirm: PropTypes.func,
   onCloseModal: PropTypes.func,
   videoTitle: PropTypes.string,
   isLoading: PropTypes.bool,
}

export default VideoDrmConfirmModals;
