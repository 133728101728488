import axios from 'axios';
import Auth from 'admin/utils/Auth';
import store from 'admin/state/store';
import { push } from 'connected-react-router';
import Router from 'admin/routes/router';
import Settings from 'admin/fake-data/settings.json'
import { getProtocol, getSiteDomain } from 'common/utils/utils.js';
let controller = null;

const protocol = getProtocol();
const domain = getSiteDomain();

const apiUrl = `${ protocol }/${ domain }/api/v2`;
const amsUrl = `${ protocol }/${ domain }`;

// instance for auth requests
const client = axios.create();

client.interceptors.request.use((config) => {
   const result = Object.assign({}, config);
   result.headers.Authorization = `Bearer ${ Auth.getToken() }`;
   return config;
}, (error) => {
   return Promise.reject(error);
});

client.interceptors.response.use(
   response => {
      return response;
   },
   error => {
      if(error){
         if(error.response && error.response.status === 401) {
            Auth.logout();
            Auth.redirectSignIn('', () => {
               store.dispatch(push(Router.route('SIGN_IN').getCompiledPath()));
            });
         }
         throw error;
      }
   }
);

function cancelApiConfig(){
   let signal = {};
   if(controller){
      controller.abort();
   }
   controller = new AbortController();
   if(controller){
      signal = { signal: controller.signal };
   }
   return signal;
}

// We need to imort controllers, to get reason of aborting if need
export const controllersByTypes = {}
function multipleCancelApiConfig(type) {
   if(!type) return;
   let signal = {}
   let controller = controllersByTypes[type]
   if(controller) {
      controller.abort()
   }
   controllersByTypes[type] = new AbortController()
   controller = controllersByTypes[type]
   if(controller) {
      signal = { signal: controller.signal };
   }
   return signal;
}

// Function allows to abort api call by type from outside (manual)
export const manualAbortApiCall = (type, reason = 'ABORTED_MANUAL') => {
   const controller = controllersByTypes[type]

   if(controller && Boolean(controller.abort)) {
      controller.abort(reason)
   }

}

export function initSiteDetails() {
   return client.get(`${ apiUrl }/auth/init`);
}

export function acceptAgreement() {
   return client.post(`${ apiUrl }/auth/accept-agreement`);
}

export function getHome() {
   return client.get(`${ apiUrl }/home`);
}

export function getMyAccount() {
   return client.get(`${ apiUrl }/me`);
}

export function initSettingsData() {
   return new Promise((resolve, reject) => {
      setTimeout(() => {
         resolve(Settings);
      }, 1000);
   });
}

// Members START //
export function getMembers(query) {
   return client.get(`${ apiUrl }/members?${ query.join('&') }`);
}

export function getFilterMembers(query) {
   const config = multipleCancelApiConfig('getFilterMembers');
   return client.get(`${ apiUrl }/members?${ query.join('&') }`, config);
}

export const getMember = (id, primaryKey = 'id') => {
   return client.get(`${ apiUrl }/members/${ id }?primaryKey=${ primaryKey }`);
}

export function getMemberStatistics() {
   return client.get(`${ apiUrl }/members/statistic`);
}

export const getMemberActivities = (id, query = []) => {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/members/activities/${ id }?${ query.join('&') }`, config);
}
export const getGlobalActivities = (query = []) => {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/members/activities/global?${ query.join('&') }`, config);
}
export const getGlobalActivitiesCount = (query = []) => {
   return client.get(`${ apiUrl }/members/activities/global/count-statistics?${ query.join('&') }`);
}
export const getMemberActivitiesCount = (id, query = []) => {
   return client.get(`${ apiUrl }/members/activities/${ id }/count-statistics?${ query.join('&') }`);
}

export function getMemberships() {
   return client.get(`${ apiUrl }/membership-pricings`);
}

export function addFreeMember(id, data) {
   return client.put(`${ apiUrl }/members/free/${ id }`, data);
}

export function addNewPerson(data) {
   return client.post(`${ apiUrl }/members/free`, data);
}

export function updateMember(id, data, primaryKey = 'id') {
   return client.put(`${ apiUrl }/members/${ id }?primaryKey=${ primaryKey }`, data);
}

export function expireFreeMembership(id){
   return client.post(`${ apiUrl }/members/${ id }/expire-membership`)
}

export function disableMultipleMemberAllNotifications(data) {
   return client.post(`${ apiUrl }/members/multiple/disable-notifications`, data);
}
export function enableMultipleMemberAllNotifications(data) {
   return client.post(`${ apiUrl }/members/multiple/enable-notifications`, data);
}

export function muteMultipleMember(data) {
   return client.post(`${ apiUrl }/members/multiple/mute`, data);
}
export function unMuteMultipleMember(data) {
   return client.post(`${ apiUrl }/members/multiple/unmute`, data);
}
export function exportMembersDataCsv(params = []) {
   return client.get(`${ apiUrl }/members/get-csv-link?${ params.join('&') }`);
}
export function downloadMemberActivityCSV(id, params = []) {
   return client.get(`${ apiUrl }/members/activities/${ id }/get-csv-link?${ params.join('&') }`);
}
export function downloadMemberActivityPDF(id, params = []) {
   return client.get(`${ apiUrl }/members/activities/${ id }/get-pdf-link?${ params.join('&') }`);
}
export function downloadMemberActivityWebhookCSV(activityId) {
   return client.get(`${ apiUrl }/members/activities/${ activityId }/webhook/get-csv-link`);
}
export function downloadMemberActivityWebhookPDF(activityId) {
   return client.get(`${ apiUrl }/members/activities/${ activityId }/webhook/get-pdf-link`);
}
export function downloadGlobalMemberActivitiesCSV() {
   return client.get(`${ apiUrl }/members/activities/global/get-csv-link`);
}
export function downloadGlobalMemberActivitiesPDF() {
   return client.get(`${ apiUrl }/members/activities/global/get-pdf-link`);
}
export function downloadMemberActivityCSVById(activityId) {
   return client.get(`${ apiUrl }/members/activities/global/${ activityId }/get-csv-link`);
}
export function downloadMemberActivityPDFById(activityId) {
   return client.get(`${ apiUrl }/members/activities/global/${ activityId }/get-pdf-link`);
}
export function addMemberNote(id, data) {
   return client.post(`${ apiUrl }/members/${ id }/note`, data);
}
export function deleteMemberNote(id, noteId) {
   return client.delete(`${ apiUrl }/members/${ id }/note/${ noteId }`);
}
export function updateMemberNote(id, noteId, data) {
   return client.put(`${ apiUrl }/members/${ id }/note/${ noteId }`, data);
}

// Members END //

//Member tags start
export function initMembersTags(query = []) {
   return client.get(`${ apiUrl }/members-tags?${ query.join('&') }`);
}

export function addMembersTag(data) {
   return client.post(`${ apiUrl }/members-tags`, data);
}

export function getMembersTag(id) {
   return client.get(`${ apiUrl }/members-tags/${ id }`);
}

export function updateMembersTag(id, data) {
   return client.put(`${ apiUrl }/members-tags/${ id }`, data);
}

export function deleteMembersTag(id) {
   return client.delete(`${ apiUrl }/members-tags/${ id }`);
}

export function addTagToMember(data) {
   return client.post(`${ apiUrl }/members-tags/add-tag-to-member`, data);
}

export function addTagsToMembers(data) {
   return client.post(`${ apiUrl }/members-tags/add-tags-to-members`, data);
}

export function deleteTagFromMember(data) {
   return client.post(`${ apiUrl }/members-tags/delete-members-tag`, data);
}
export function deleteTags(data) {
   return client.post(`${ apiUrl }/members-tags/bulk/delete`, data);
}

export function getMemberTags(memberId) {
   return client.get(`${ apiUrl }/members-tags/get-specific-members-tags/${ memberId }`);
}

export function getMemberAllTags(memberId) {
   return client.get(`${ apiUrl }/members-tags/member-tags/${ memberId }`);
}


// get models
export function getModels() {
   return client.get(`${ apiUrl }/models`);
}
export function getModelsByFilter(filter) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/models?${ filter.join('&') }`, config);
}

export function getStories(params = []) {
   return client.get(`${ apiUrl }/stories?${ params.join('&') }`);
}
export function getStoriesForReorder(params = []) {
   return client.get(`${ apiUrl }/stories/reorder-data?${ params.join('&') }`);
}
export function createStories(data) {
   return client.post(`${ apiUrl }/stories`, data);
}
export function updateStoryData(id, data) {
   return client.put(`${ apiUrl }/stories/${ id }`, data);
}

export function getFilterStories(filter) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/stories?${ filter.join('&') }`, config);
}
export function getStatistics() {
   return client.get(`${ apiUrl }/stories/statistics`);
}

export function uploadStoryImage(base64, options = {}) {
   const { signal } = options;
   const config = {
      signal,
   };
   return client.post(`${ amsUrl }/image-uploader/story-image`, base64, config);
}
export function uploadPosterImage(file) {
   return client.post(`${ amsUrl }/image-uploader/story-poster-image`, file);
}

export function deleteStories(id) {
   return client.delete(`${ apiUrl }/stories/${ id }`);
}
export function getStoryById(id) {
   return client.get(`${ apiUrl }/stories/${ id }`);
}
export function getS3Policy() {
   return client.get(`${ apiUrl }/get-s3-policy`);
}

export function getStoryComments(id, type, param = []) {
   return client.get(`${ apiUrl }/${ type }/${ id }/comments?${ param.join('&') }`);
}
export function getStoryCommentsByFilter(id, param, type) {
   return client.get(`${ apiUrl }/${ type }/${ id }/comments?${ param.join('&') }`);
}

export function storyCommentLike(id, comment_id, type) {
   return client.post(`${ apiUrl }/${ type }/${ id }/comments/toggle-like`, { comment_id });
}

export function storyCommentDelete(id, comment_id, type) {
   return client.delete(`${ apiUrl }/${ type }/${ id }/comments/${ comment_id }`);
}
export function sendStoryComment(data, type) {
   return client.post(`${ apiUrl }/${ type }/comments`, data);
}

export function getStoryCommentById(resourceId, commentId, type) {
   return client.get(`${ apiUrl }/${ type }/${ resourceId }/comments/${ commentId }`);
}

export function getResourceById(resourceId, resourceType, query = '') {
   return client.get(`${ apiUrl }/${ resourceType }/${ resourceId }?${ query }`);
}

// Tips START //
export function getTipsSettings() {
   return client.get(`${ apiUrl }/settings?query=key=maximum_single_tip_amount_per_member,max_number_of_tips_per_day_per_member`);
}

export function getTips(query) {
   return client.get(`${ apiUrl }/tips?${ query.join('&') }`);
}

export function getTipsStatistics() {
   return client.get(`${ apiUrl }/tips/statistics`)
}
// Tips END //

// store api
export function initStoreData(param, canceled = false) {
   let config = {};
   if(canceled){
      config = multipleCancelApiConfig('fetchStoreData');
   }
   return client.get(`${ apiUrl }/products?${ param.join('&') }`, config);
}
export function initStoreDataForReorder(param, canceled = false) {
   let config = {};
   if(canceled){
      config = multipleCancelApiConfig('fetchStoreData');
   }   return client.get(`${ apiUrl }/products/reorder-data?${ param.join('&') }`, config);
}
export function initCategories() {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/product-collections`, config);
}
// export function initStatisticData() {
//    return client.get(`${ apiUrl }/products/statistic`);
// }

export function getFilterStoreData(param = []) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/products?${ param.join('&') }`, config);
}

export function getStoreById(id) {
   return client.get(`${ apiUrl }/products/${ id }`);
}

export function getStoreCollections(query = []) {
   return client.get(`${ apiUrl }/product-collections?${ query.join('&') }`);
}

export function updateStoreData(id, data) {
   return client.put(`${ apiUrl }/products/${ id }`, data);
}
export function createStore(id, data) {
   return client.post(`${ apiUrl }/products`, id, data);
}

export function uploadStoreImage(file) {
   return client.post(`${ amsUrl }/image-uploader/store-image-upload-v2`, file);
}

export function deleteProduct(id) {
   return client.delete(`${ apiUrl }/products/${ id }`);
}
export function validateStore(data) {
   return client.post(`${ apiUrl }/products/validate`, data);
}

export function getValidationNumbers() {
   return client.get(`${ apiUrl }/products/limits`);
}

export function getProdynctFileSize(bytes) {
   return client.get(`${ apiUrl }/products/get-file-size-info?file_size_in_bytes=${ bytes }`);
}


export function getCollectionBuId(id) {
   return client.get(`${ apiUrl }/product-collections/${ id }`);
}

export function updateCategory(id, data) {
   return client.put(`${ apiUrl }/product-collections/${ id }`, data);
}

export function createCategory(data) {
   return client.post(`${ apiUrl }/product-collections`, data);
}

export function deleteCategory(id) {
   return client.delete(`${ apiUrl }/product-collections/${ id }`);
}

export function downloadRequestOrderWebhookCsv(id) {
   return client.get(`${ apiUrl }/order-requests/${ id }/get-webhook-csv-link`);
}
export function downloadRequestOrderWebhookPDF(id) {
   return client.get(`${ apiUrl }/order-requests/${ id }/get-webhook-pdf-link`);
}

// content bundle

export function createNewBundleValidaton(data) {
   return client.post(`${ apiUrl }/content-bundle/validate`, data)
}

export function createNewBundle(data) {
   return client.post(`${ apiUrl }/content-bundle`, data)
}

export function deleteBundle(id) {
   return client.delete(`${ apiUrl }/content-bundle/${ id }`)
}

export function updateBundle(id, data) {
   return client.put(`${ apiUrl }/content-bundle/${ id }`, data)
}

export function getBundleById(id) {
   return client.get(`${ apiUrl }/products/${ id }`)
}

export function getDefaultAccessForStore() {
   return client.get(`${ apiUrl }/content-bundle/default-access`)
}

export function getDifferentAccessForStore() {
   return client.get(`${ apiUrl }/content-bundle/different-access`)
}

export function pinningStoreItems(id){
   return client.post(`${ apiUrl }/products/pin/${ id }`)
}

export function getBundleDefaultNotifications() {
   return client.get(`${ apiUrl }/content-bundle/info`)
}

//order requests forms api start

export function getProductsWithCustomForms(){
   return client.get(`${ apiUrl }/custom-forms/1/create`);
}

export function createOrderRequestForm(data){
   return client.post(`${ apiUrl }/custom-forms`, data);
}

export function updateOrderRequestForm(id, data){
   return client.put(`${ apiUrl }/custom-forms/${ id }`, data);
}

export function getOrderRequestFormById(id){
   return client.get(`${ apiUrl }/custom-forms/${ id }`);
}

export function deleteOrderRequestForm(id){
   return client.delete(`${ apiUrl }/custom-forms/${ id }`);
}

// export function getOrderRequestForms(query = []){
//    const config = cancelApiConfig();
//    return client.get(`${ apiUrl }/custom-forms?${ query.join('&') }`, config);
// }

//order requests forms api end


//order requests api start

export function getOrderRequests(query = [], isInit = false){
   let config = {}
   if(!isInit) {
      config = cancelApiConfig();
   }
   return client.get(`${ apiUrl }/order-requests?${ query.join('&') }`, config);
}

export function getOrderRequestById(id){
   return client.get(`${ apiUrl }/order-requests/${ id }`);
}

export function changeOrderStatusById(id, data){
   return client.post(`${ apiUrl }/${ id }/product-statuses`, data)
}

// order notes api start

export function createOrderNoteById(orderId, data){
   return client.post(`${ apiUrl }/${ orderId }/payment-notes`, data);
}

export function updateOrderNoteById(orderId, noteId, data){
   return client.put(`${ apiUrl }/${ orderId }/payment-notes/${ noteId }`, data);
}

export function deleteOrderNoteById(orderId, noteId){
   return client.delete(`${ apiUrl }/${ orderId }/payment-notes/${ noteId }`);
}

// order notes api end

//order requests api end

// start my account api
export function initPayoutPreferences() {
   return client.get(`${ apiUrl }/me/payout-preferences`);
}

export function updateProfileData(data) {
   return client.post(`${ apiUrl }/me`, data);
}
export function uploadProfileImage(file) {
   return client.post(`${ amsUrl }/image-uploader/models/profile-avatar`, file);
}
export function uploadLegalInfoImage(file) {
   return client.post(`${ amsUrl }/image-uploader/upload-image`, file);
}
export function uploadLegalInfoImageBase64(base64) {
   return client.post(`${ amsUrl }/image-uploader/upload-image-base64`, base64);
}
export function uploadLegalInfoImageBlobFile(blob) {
   return client.post(`${ amsUrl }/image-uploader/upload-image-blob`, blob);
}
export function updatePassowrd(data) {
   return client.post(`${ apiUrl }/me/update-password`, data);
}
export function updateEmail(data) {
   return client.post(`${ apiUrl }/me/update-email`, data);
}
export function resendCodeUpdateEmail(data) {
   return client.post(`${ apiUrl }/me/update-email-resend`, data);
}

export function getAddressInProfile(data){
   return client.post(`${ apiUrl }/me/autocomplete`, data)
}
export function getItemAddressInProfile(id){
   return client.get(`${ apiUrl }/me/autocomplete/${ id }`)
}

export function updateMyPayoutPreferences(data, query) {
   return client.post(`${ apiUrl }/me/payout-preferences?${ query }`, data);
}

export function getNotificationPreferences() {
   return client.get(`${ apiUrl }/me/notification-preferences`);
}
export function updateNotificationPreferences(data) {
   return client.post(`${ apiUrl }/me/notification-preferences`, data);
}
export function unsubscribeFromAllNotificationPreferences(data) {
   return client.post(`${ apiUrl }/me/notification-preferences/unsubscribe-all`, data);
}
export function setPassword(data) {
   return client.post(`${ apiUrl }/me/set-password`, data)
}
export function setPasswordVerify(data) {
   return client.post(`${ apiUrl }/me/verify-password`, data)
}
export function resendCodeSetPassword() {
   return client.post(`${ apiUrl }/me/set-password-resend`)
}

// start Membership pricings api

export function initMembershipPricings(query) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/membership-pricings?${ query.join('&') }`, config);
}
export function membershipPricingById(id) {
   return client.get(`${ apiUrl }/membership-pricings/${ id }`);
}
export function updateMembershipPricing(id, data) {
   return client.put(`${ apiUrl }/membership-pricings/${ id }`, data);
}
export function membershipPricingsReorder(data) {
   return client.post(`${ apiUrl }/membership-pricings/reorder`, data);
}
export function initMembershipPricingsAvailable() {
   return client.get(`${ apiUrl }/membership-pricings/create-pricing-info`);
}
export function addNewMembershipPricing(data) {
   return client.post(`${ apiUrl }/membership-pricings`, data);
}
export function deleteMembershipPricing(id) {
   return client.delete(`${ apiUrl }/membership-pricings/${ id }`);
}
export function duplicateMembershipPricing(id, data) {
   return client.post(`${ apiUrl }/membership-pricings/duplicate/${ id }`, data);
}
export function checkValidationForNewMembership(data) {
   return client.put(`${ apiUrl }/membership-pricings/check-validate`, data);
}
export function createBenefit(data) {
   return client.post(`${ apiUrl }/membership-benefits`, data);
}
export function deleteBenefit(id) {
   return client.delete(`${ apiUrl }/membership-benefits/${ id }`);
}
export function updateBenefit(id, data) {
   return client.put(`${ apiUrl }/membership-benefits/${ id }`, data);
}
export function benefitsReorder(data) {
   return client.post(`${ apiUrl }/membership-benefits/reorder`, data);
}
export function validateMultiplePricings(data) {
   return client.post(`${ apiUrl }/membership-pricings/multiple/pricing/validate`, data);
}
export function updateMultiplePricings(data) {
   return client.post(`${ apiUrl }/membership-pricings/multiple/pricing`, data);
}
export function replaceCustomLabel(data) {
   return client.post(`${ apiUrl }/membership-pricings/replace/label`, data);
}

export function loginAs(data) {
   return client.post(`${ apiUrl }/auth/new-login-as`, data);
}

export function getSitesColor() {
   return client.get(`${ apiUrl }/site-colors`);
}
export function createCustomPalette(data) {
   return client.post(`${ apiUrl }/site-colors`, data);
}
export function selectSitesColor(data) {
   return client.post(`${ apiUrl }/site-colors/select`, data);
}

// Start photosets api
export function getPhotosets(query = ['count=20'], canceled = false) {
   let config = {};
   if(canceled){
      config = multipleCancelApiConfig('getPhotosets');
   }
   return client.get(`${ apiUrl }/photosets?${ query.join('&') }`, config);
}

export function getPhotosetsById(id) {
   return client.get(`${ apiUrl }/photosets/${ id }`);
}
export function updatePhotosets(data, id) {
   return client.put(`${ apiUrl }/photosets/${ id }`, data);
}
export function deletePhotosets(id) {
   return client.delete(`${ apiUrl }/photosets/${ id }`);
}
export function uploadPhotosetsImage(file, canceled = false) {
   let signal = {};
   if(canceled){
      const controller = new AbortController();
      controller.abort();
      signal = { signal: controller.signal };
   }
   return client.post(`${ amsUrl }/image-uploader/photoset-image-upload-v2`, file, signal);
}
export function getPhotosetsForReorder(query = [''], canceled = false) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/photosets/reorder-data?${ query.join('&') }`, config);
}

export function createPhotos(data) {
   return client.post(`${ apiUrl }/photos`, data);
}
export function deletePhotos(id) {
   return client.delete(`${ apiUrl }/photos/${ id }`);
}
export function createNewPhotosets(data) {
   return client.post(`${ apiUrl }/photosets`, data);
}
export function createMultiplePhotos(data) {
   return client.post(`${ apiUrl }/photos/multiple-actions/`, data);
}
export function photosMultipleActionsMove(data) {
   return client.post(`${ apiUrl }/photos/multiple-actions/move`, data);
}
export function photosMultipleActionsDelete(data) {
   return client.post(`${ apiUrl }/photos/multiple-actions/destroy`, data);
}
export function reorderPhotosetsPhotos(id, data) {
   return client.post(`${ apiUrl }/photosets/${ id }/photos/reorder`, data);
}
export function photosetsStatistic() {
   return client.get(`${ apiUrl }/photosets/statistic`);
}

export function photosetsStepValidate(params, data) {
   return client.post(`${ apiUrl }/photosets/validate?tab=${ params }`, data);
}
export function destroyPhotosets(data, params = []) {
   return client.post(`${ apiUrl }/photosets/multiple-actions/destroy?${ params.join('&') }`, data);
}
export function getByIdsPhotosets(query = '') {
   return client.get(`${ apiUrl }/photosets/multiple-actions/get?${ query }`);
}
export function multiplePhotosetsUpdate(data) {
   return client.put(`${ apiUrl }/photosets/multiple-actions/update`, data);
}
export function getPhotosetPathsById(id) {
   return client.get(`${ apiUrl }/photosets/${ id }/photos`);
}
export function sortPhotosetPhotos(data) {
   return client.post(`${ apiUrl }/photosets/photos/order`, data);
}

export function getMediaDownloadLink(id, type, query = '') {
   return client.get(`${ apiUrl }/${ type }/${ id }/download?${ query }`);
}

export function saveMediaSettings(data){
   return client.post(`${ apiUrl }/save-media-settings`, data)
}
// start payout api

export function getPayouts(params) {
   return client.get(`${ apiUrl }/payouts?${ params }`);
}
export function exportPayoutCsv() {
   return client.get(`${ apiUrl }/payouts/get-csv-link?`);
}
export function getPayoutsById(id) {
   return client.get(`${ apiUrl }/payouts/${ id }`);
}
export function getPayoutsStatistic() {
   return client.get(`${ apiUrl }/payouts/statistic`);
}
export function getPayoutsWithdrawals() {
   return client.get(`${ apiUrl }/payouts/withdrawals`);
}
export function updateAutomaticWithdrawals(data) {
   return client.put(`${ apiUrl }/payouts/withdrawals/automatic-withdrawals`, data);
}
export function getPayoutsWithdrawalInfo(id, primaryKey = '') {
   return client.get(`${ apiUrl }/payouts/withdrawals/${ id }${ Boolean(primaryKey) ? `?primaryKey=${ primaryKey }` : '' }`);
}
export function downloadPdfReceipt(id) {
   return client.get(`${ apiUrl }/payouts/withdrawals/${ id }/download-pdf-receipt`);
}
export function downloadWithdrawalsCsv() {
   return client.get(`${ apiUrl }/payouts/withdrawals/get-csv-link`);
}
export function downloadCurrentWithdrawalCsv() {
   return client.get(`${ apiUrl }/payouts/withdrawals/get-current-payout-csv-link`);
}
export function withdrawNowApi() {
   return client.post(`${ apiUrl }/payouts/withdrawals/withdraw`);
}
export function checkIsWeekendApi() {
   return client.get(`${ apiUrl }/payouts/check-is-weekend`);
}
export function updateNowApi() {
   return client.post(`${ apiUrl }/payouts/update`);
}
export function commitWithdrawApi(data) {
   return client.post(`${ apiUrl }/payouts/withdrawals/commit-withdraw`, data);
}
export function getPayoutsAdjustments() {
   return client.get(`${ apiUrl }/payouts/adjustments`);
}
export function getCollapseAdjustment(id) {
   return client.get(`${ apiUrl }/payouts/withdrawals/${ id }/adjustments`);
}
export function getPayoutsHolds() {
   return client.get(`${ apiUrl }/payouts/holds`);
}
export function getPayoutsInit() {
   return client.get(`${ apiUrl }/payouts/init`);
}
export function getPayoutsHistory() {
   return client.get(`${ apiUrl }/payouts/change-history`);
}
export function getPayoutsPayoutSrvCountry() {
   return client.get(`${ apiUrl }/payouts/payout_srv/country-list`);
}
export function getPayoutsSepaCountry() {
   return client.get(`${ apiUrl }/payouts/sepa-country-list`);
}
export function createPayoutSrvUser() {
   return client.post(`${ apiUrl }/payouts/payout_srv/create-payout_srv-user`);
}
export function getPayoutSrvServicesForCountry(country) {
   return client.get(`${ apiUrl }/payouts/payout_srv/${ country }/services`);
}
export function getPayoutSrvAttributesForToken(token, currency) {
   return client.get(`${ apiUrl }/payouts/payout_srv/user-attributes-for-destination-token/${ token }/currency/${ currency }`);
}
export function addPayoutSrvAttributesForToken(token, currency, data, params = []) {
   return client.post(`${ apiUrl }/payouts/payout_srv/user-attributes-for-destination-token/${ token }/currency/${ currency }?${ params.join('&') }`, data);
}
export function setWalletPayoutMethod(type, data, params = []) {
   return client.post(`${ apiUrl }/payouts/wallets/${ type }?${ params.join('&') }`, data);
}
export function deleteCurrentPayoutMethod(params = []) {
   return client.delete(`${ apiUrl }/payouts/delete-current-payout-method?${ params.join('&') }`);
}
export function addPayoutNrvMethod(data, params = []) {
   return client.post(`${ apiUrl }/payouts/nrv/save-payout-method?${ params.join('&') }`, data);
}
// start transaction api

export function getTransaction(params) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/payments?${ params.join('&') }`, config);
}
export function exportTransactionCsv(params = []) {
   return client.get(`${ apiUrl }/payments/get-csv-link?${ params.join('&') }`);
}
export function getTransactionById(id) {
   return client.get(`${ apiUrl }/payments/${ id }`);
}

export function downloadTransactionWebhookCSV(transactionId) {
   return client.get(`${ apiUrl }/payments/${ transactionId }/webhook/get-csv-link`);
}
export function downloadTransactionWebhookPDF(transactionId) {
   return client.get(`${ apiUrl }/payments/${ transactionId }/webhook/get-pdf-link`);
}

export function getTransactionWalletActivity(params) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/wallet-payments?${ params.join('&') }`, config);
}
export function exportTransactionWalletActivityCsv(params = []) {
   return client.get(`${ apiUrl }/wallet-payments/get-csv-link?${ params.join('&') }`);
}
export function getTransactionWalletActivityById(id) {
   return client.get(`${ apiUrl }/wallet-payments/${ id }`);
}

// onboarding steps
export function initOnboardingSteps() {
   return client.get(`${ apiUrl }/steps`);
}
export function submitForReview() {
   return client.post(`${ apiUrl }/steps/submit-for-review`);
}
export function submitLaunchSite() {
   return client.post(`${ apiUrl }/steps/launch-site`);
}


export function loggedInEvent(user) {
   return client.post(`${ amsUrl }/api/canny-logged-in/`, user);
}

// Settings api

export function getSettings(query = []) {
   return client.get(`${ apiUrl }/settings?query[]=key=${ query.join(',') }`);
}
export function getTrackingCode() {
   return client.get(`${ apiUrl }/settings/get-tracking-code`);
}
export function getSEO() {
   return client.get(`${ apiUrl }/settings/seo`);
}
export function getSettingsStatistic() {
   return client.get(`${ apiUrl }/settings/statistic`);
}
export function updateSettings(data) {
   return client.post(`${ apiUrl }/settings`, data);
}

export function createCustomDomain(data) {
   return client.post(`${ apiUrl }/site-settings/create-custom-domain`, data);
}

export function validateCustomDomain(data) {
   return client.post(`${ apiUrl }/site-settings/validate-custom-domain`, data);
}
export function verifyCustomDomain() {
   return client.post(`${ apiUrl }/site-settings/verify`);
}

export function hasCustomDomain(data) {
   return client.post(`${ apiUrl }/site-settings/has-custom-domain`, data);
}
export function updateSiteName(data) {
   return client.put(`${ apiUrl }/site-settings/update-site-name`, data);
}

export function pointCustomDomain(domain) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/settings/domain/info?domain=${ domain }`, config);
}
// Content cards options
export function getContentCardsOptions() {
   return client.get(`${ apiUrl }/settings/content-card`);
}

export function updateContentCardsOptions(data) {
   return client.put(`${ apiUrl }/settings/content-card`, data);
}

// Legal pages

export function getTermsOfUse() {
   return client.get(`${ apiUrl }/settings/legal-pages/term-of-use`);
}
export function getPrivacyPolicy() {
   return client.get(`${ apiUrl }/settings/legal-pages/privacy-policy`);
}
export function getCookiePolicy() {
   return client.get(`${ apiUrl }/settings/legal-pages/cookie-policy`);
}
export function getGdrpPolicy() {
   return client.get(`${ apiUrl }/settings/legal-pages/gdrp-policy`);
}
export function get2257Compliance() {
   return client.get(`${ apiUrl }/settings/legal-pages/compliance`);
}
export function getDMCA() {
   return client.get(`${ apiUrl }/settings/legal-pages/dmca`);
}
export function getCCBill() {
   return client.get(`${ apiUrl }/settings/legal-pages/ccbill`);
}

export function updateTermsOfUse(body) {
   return client.put(`${ apiUrl }/settings/legal-pages/term-of-use`, { new_html_content: body });
}
export function updatePrivacyPolicy(body) {
   return client.put(`${ apiUrl }/settings/legal-pages/privacy-policy`, { new_html_content: body });
}
export function updateCookiePolicy(body) {
   return client.put(`${ apiUrl }/settings/legal-pages/cookie-policy`, { new_html_content: body });
}
export function updateGdrpPolicy(body) {
   return client.put(`${ apiUrl }/settings/legal-pages/gdrp-policy`, { new_html_content: body });
}
export function update2257Compliance(body) {
   return client.put(`${ apiUrl }/settings/legal-pages/compliance`, { new_html_content: body });
}
export function updateDMCA(body) {
   return client.put(`${ apiUrl }/settings/legal-pages/dmca`, { new_html_content: body });
}
export function updateCCBill(body) {
   return client.put(`${ apiUrl }/settings/legal-pages/ccbill`, { new_html_content: body });
}



// Cast members api

export function getCastMembers(query = []) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/casts?separate_admin_cast_member=1&${ query.join('&') }`,  config);
}

export const getCastMember = (id) => {
   return client.get(`${ apiUrl }/casts/${ id }`);
}

export function addNewCastMember(data, isValidate = false) {
   return client.post(`${ apiUrl }/casts${ isValidate ? '?validate=true' : '' }`, data);
}

export function uploadCastMemberImage(file) {
   return client.post(`${ amsUrl }/image-uploader/upload-image`, file);
}

export function updateCastMembers(id, data) {
   return client.put(`${ apiUrl }/casts/${ id }`, data);
}

export function deleteCastMembers(id) {
   return client.delete(`${ apiUrl }/casts/${ id }`);
}
export function getModelConsentRelease(query) {
   return client.get(`${ apiUrl }/casts/model-consent-release?${ query }`);
}


// start tags api
export function getTagsData() {
   return client.get(`${ apiUrl }/tags?count=all`);
}
export function deleteTag(id) {
   return client.delete(`${ apiUrl }/tags/${ id }`);
}
export function updateTag(id, data) {
   return client.put(`${ apiUrl }/tags/${ id }`, data);
}
export function createNewTag(data) {
   return client.post(`${ apiUrl }/tags`, data);
}
export function tagsReorder(data) {
   return client.post(`${ apiUrl }/tags/reorder`, data);
}

// start videos api

export function fetchVideos(params = []) {
   const config = multipleCancelApiConfig('fetchVideos');
   return client.get(`${ apiUrl }/videos?${ params.join('&') }`, config);
}

export function fetchVideosForReorder(params = []) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/videos/reorder-data?video_type=video${ params.join('&') }`, config);
}

export function getVideoById(id, query) {
   return client.get(`${ apiUrl }/videos/${ id }${ query }`);
}
export function getCountStatistics(type, query = []) {
   return client.get(`${ apiUrl }/${ type }/counts-statistics?${ query.join('&') }`);
}

export function updateVideo(id, data, query) {
   return client.put(`${ apiUrl }/videos/${ id }${ query }`, data);
}

export function createVideo(data) {
   return client.post(`${ apiUrl }/videos`, data);
}

export function removeVideo(id, apiQuery) {
   return client.delete(`${ apiUrl }/videos/${ id }${ apiQuery }`);
}

export function createPreviewVideo(id, data, type = 'videos') {
   return client.post(`${ apiUrl }/${ type }/${ id }/custom-preview`, data);
}
export function removePreviewVideo(id, type = 'videos') {
   return client.delete(`${ apiUrl }/${ type }/${ id }/custom-preview`);
}

export function removeDrmProtectionFromVideo(id) {
   return client.put(`${ apiUrl }/videos/${ id }/remove-drm`);
}

export function canUploadVideo() {
   return client.get(`${ apiUrl }/videos/can-upload`);
}
export function destroyVideos(data, params = []) {
   return client.post(`${ apiUrl }/videos/multiple-actions/destroy?${ params.join('&') }`, data);
}
export function multipleVideosUpdate(data) {
   return client.put(`${ apiUrl }/videos/multiple-actions/update`, data);
}
export function getByIdsVideos(query = '') {
   return client.get(`${ apiUrl }/videos/multiple-actions/get?${ query }`);
}
export function uploadCustomPoster(data) {
   return client.post(`${ amsUrl }/image-uploader/video-poster-v2`, data);
}

export async function getDrmVideoLicenseToken(id, drmType) {
   let licenseToken = ''

   try {
      const { data } = await client.get(`${ apiUrl }/videos/${ id }/license-token?drmType=${ drmType }`);
      licenseToken = data.license_token
   } catch (error) {
      console.log('getDrmVideoLicenseToken error: ', error);
   }

   return licenseToken;
}

export function getDrmVideoLicenseProxyUrl(id, drmType) {
   return `${ apiUrl }/videos/${ id }/get-license?drmType=${ drmType }`
}

export function getVideoPriceListForWatermarks(params = []) {
   const config = multipleCancelApiConfig('getVideoPriceListForWatermarks');
   return client.get(`${ apiUrl }/videos/price-list-for-watermarks?${ params.join('&') }`, config);
}

// start video collections api

export function getCollectionsData() {
   return client.get(`${ apiUrl }/collections?count=all`);
}
export function deleteCollection(id) {
   return client.delete(`${ apiUrl }/collections/${ id }`);
}
export function updateCollection(id, data) {
   return client.put(`${ apiUrl }/collections/${ id }`, data);
}
export function createNewCollection(data) {
   return client.post(`${ apiUrl }/collections`, data);
}
export function collectionsReorder(data) {
   return client.post(`${ apiUrl }/collections/reorder`, data);
}

export function fetchResources(type = 'video') {

   let resources = 'cast_members,tags,collections,'

   switch (type) {
      case 'videos_list':
      case 'video':
         resources += 'video_collections'
         break;
      case 'photosets_list':
      case 'photoset':
         resources += 'photoset_collections'
         break;
      case 'vaults':
         resources += 'vault_collections'
         break;
      default:
         break;
   }

   if(resources) {
      return client.get(`${ apiUrl }/filtration-resources?resources=${ resources }`);
   }
   return client.get(`${ apiUrl }/filtration-resources`);
}

export function defaultAccesses(param) {
   return client.get(`${ apiUrl }/default-accesses?types=${ param.join(',') }`);
}

export function applyAllMediaSettings(data){
   return client.post(`${ apiUrl }/apply-all-media-settings`, data)
}
export function deleteMySite(id, data){
   return client.post(`${ apiUrl }/site/delete/${ id }`, data)
}
export function getTemporaryHompage() {
   return client.get(`${ apiUrl }/settings/temporary-hompage`);
}
export function temporaryUploadBackground(file) {
   return client.post(`${ amsUrl }/image-uploader/temporary-background-images`, file);
}
export function initSiteSettingsData() {
   return client.get(`${ apiUrl }/settings/my-site-settings`);
}
export function initSiteHeader() {
   return client.get(`${ apiUrl }/settings/get-site-header-info`);
}
export function updateSiteHeaderVideoContent(data) {
   return client.post(`${ apiUrl }/settings/compress-header-video`, data);
}
export function siteHeaderUploadBackground(file) {
   return client.post(`${ amsUrl }/image-uploader/site-header`, file);
}

// about me
export function getAboutMe() {
   return client.get(`${ apiUrl }/settings/get-about-me`);
}

// site logo
export function logoImageUpload(file) {
   return client.post(`${ amsUrl }/image-uploader/logo-image-upload-2`, file);
}
export function faviconImageUpload(file) {
   return client.post(`${ amsUrl }/image-uploader/app-icon-upload`, file);
}
export function getSiteLogo() {
   return client.get(`${ apiUrl }/settings/get-logo-info`);
}

// my site blocking IP
export function getBlockingIpList(type = 'white', query = ['count = 30']){
   return client.get(`${ apiUrl }/blocking/${ type }-ips?${ query.join('&') }`)
}
export function deleteBlockingIp(type = 'white', id){
   return client.delete(`${ apiUrl }/blocking/${ type }-ips/${ id }`)
}
export function updateBlockingIp(type = 'white', id, data){
   return client.put(`${ apiUrl }/blocking/${ type }-ips/${ id }`, data)
}
export function createNewBlockingIp(type = 'white', data){
   return client.post(`${ apiUrl }/blocking/${ type }-ips`, data)
}
// my site blocking regions
export function getBlockingRegions(){
   return client.get(`${ apiUrl }/blocking/regions`)
}
export function blockingRegions(data){
   return client.post(`${ apiUrl }/blocking/regions`, data)
}
export function unBlockingRegions(key, id){
   return client.delete(`${ apiUrl }/blocking/regions/${ key }-${ id }`)
}
export function getRegionsData(){
   return client.get(`${ apiUrl }/blocking/regions/data`)
}


// shipping

export function getAllShippingZones(){
   return client.get(`${ apiUrl }/shipping-zone`)
}
export function getShippingCountriesData(){
   return client.get(`${ apiUrl }/shipping-zone/countries`)
}
export function updateShippingZone(id, data){
   return client.put(`${ apiUrl }/shipping-zone/${ id }`, data)
}
export function getShippingZoneStateInId(id){
   return client.get(`${ apiUrl }/shipping-zone/${ id }`)
}
export function deleteShippingZone(id){
   return client.delete(`${ apiUrl }/shipping-zone/${ id }`)
}

export function createNewShippingZone(data){
   return client.post(`${ apiUrl }/shipping-zone`, data)
}




//  start chat api
export function initChatSettingsData() {
   return client.get(`${ apiUrl }/chat/settings`);
}
export function updateChatSettingsData(data) {
   return client.put(`${ apiUrl }/chat/settings`, data);
}
export function fetchChatConversations(query = [], isFilter) {
   const config = multipleCancelApiConfig('fetchChatConversations');
   return client.get(`${ apiUrl }/chat/conversations?${ query.join('&') }`, config);
}
export function chatInit() {
   return client.get(`${ apiUrl }/chat/init`);
}

export function fetchChatConversationMessages(query = ['page=1'], id) {
   const config = multipleCancelApiConfig('fetchChatConversationMessages');
   return client.get(`${ apiUrl }/chat/conversations/${ id }/messages?${ query.join('&') }`, config);
}
export function fetchConversationById(id) {
   return client.get(`${ apiUrl }/chat/conversations/${ id }`);
}
export function getChatUnlcokedItem(id) {
   return client.get(`${ apiUrl }/chat/get-content/${ id }`);
}
export function getChatMessage(id) {
   return client.get(`${ apiUrl }/chat/messages/${ id }`);
}
export function fetchLikedMemberByMessage(query, id) {

   let queryData = []
   if(query) {
      queryData = query

   }
   return client.get(`${ apiUrl }/chat/messages/${ id }/liked-members?${ queryData.join('&') }`);
}
export function getConversationWithUser(uuid) {
   return client.get(`${ apiUrl }/chat/conversations/with-user/${ uuid }`);
}
export function createNewConversationWithUser(uuid) {
   return client.post(`${ apiUrl }/chat/conversations/with-user/${ uuid }`);
}
export function checkContentTypeInTheChat(data) {
   return client.post(`${ apiUrl }/chat/check-content-type`, data);
}
export function checkContentTypeInTheChatWithGroup(data) {
   return client.post(`${ apiUrl }/chat/check-content-type-with-group`, data);
}

export function getMessageAndConversationUnreadCount(id) {
   return client.get(`${ apiUrl }/chat/message-conversation-unread-count/${ id }`);
}

export function muteMember(uuid, data) {
   return client.post(`${ apiUrl }/members/${ uuid }/mute-member`, data);
}
export function unMuteMember(uuid) {
   return client.post(`${ apiUrl }/members/${ uuid }/unmute-member`);
}
export function toggleOpenCloseConversation(conversationId, type) {
   return client.post(`${ apiUrl }/chat/conversation/${ conversationId }/open-close`, { type })
}
export function togglePinUnpinConversation(userUuid) {
   return client.post(`${ apiUrl }/chat/conversation/${ userUuid }/pin-unpin`)
}

// domain redirects
export function getRedirectsData(){
   return client.get(`${ apiUrl }/redirects`)
}
export function deleteRedirectsData(id){
   return client.delete(`${ apiUrl }/redirects/${ id }`)
}
export function updateRedirectsData(id, data){
   return client.put(`${ apiUrl }/redirects/${ id }`, data)
}
export function createNewRedirectsData(data){
   return client.post(`${ apiUrl }/redirects`, data)
}

// mass-message
export function getActiveMemberships() {
   return client.get(`${ apiUrl }/mass-message/active-memberships`);
}
export function getMassMessageLimit() {
   return client.get(`${ apiUrl }/mass-message/get-mass-message-limits`);
}
export function getReceiversStatistics() {
   return client.get(`${ apiUrl }/mass-message/get-receivers-statistics`);
}
export function getReceiversCount(data) {
   return client.post(`${ apiUrl }/mass-message/get-receivers-count`, data);
}
export function sendTestMassMessage(data) {
   return client.post(`${ apiUrl }/mass-message/send-test`, data);
}
export function sendMassMessage(data) {
   return client.post(`${ apiUrl }/mass-message/send`, data);
}
export function validateMassMessage(data) {
   return client.post(`${ apiUrl }/mass-message/send/validate`, data);
}
export function updateMassMessage(id, data) {
   return client.put(`${ apiUrl }/mass-message/${ id }`, data);
}
export function checkChatContentTypeForMassMessage(data) {
   return client.post(`${ apiUrl }/mass-message/check-content-type`, data);
}

export function getMailingListMembers(params = []) {
   return client.get(`${ apiUrl }/mass-message/mailing-lists/members?${ params.join('&') }`);
}
export function getMailingListNonMembers(params = []) {
   return client.get(`${ apiUrl }/mass-message/mailing-lists/non-members?${ params.join('&') }`);
}
export function unsubscribeEmail(data) {
   return client.post(`${ apiUrl }/mass-message/mailing-lists/unsubscribe`, data);
}
export function getSubscriberNonMembers(id, query = []) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/mass-message/${ id }/non-members-subscribers?${ query.join('&') }`, config);
}


export function initSentMessages(query = [''], isInit = false) {
   let config = {}
   if(!isInit) {
      config = cancelApiConfig();
   }
   return client.get(`${ apiUrl }/mass-message${ Boolean(query.length) ? `?${ query.join('&') }` : '' }`, config);
   // return client.get(`${ apiUrl }/mass-message?count=all`);
}

export function exportSentMessagesCsv(params = []) {
   return client.get(`${ apiUrl }/mass-message/get-csv-link?${ params.join('&') }`);
}
export function deleteMassMessages(id) {
   return client.delete(`${ apiUrl }/mass-message/${ id }`);
}
export function getMassMessagesById(id) {
   return client.get(`${ apiUrl }/mass-message/${ id }`);
}
export function getRecipentsByMessageId(id, query = ['']) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/mass-message/${ id }/recipients?${ query.join('&') }`, config);
}
export function getPurchasedMembersByMessageId(id, query = ['']) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/mass-message/${ id }/purchased-members?${ query.join('&') }`, config);
}
export function unsendMessageApi(id) {
   return client.post(`${ apiUrl }/mass-message/unsend-message/${ id }`);
}

// start custom page aii
export function getCustomPages(query = ['page=1']){
   return client.get(`${ apiUrl }/custom-pages-v2?${ query.join('&') }`)
}
export function crateNewCustomPage(data){
   return client.post(`${ apiUrl }/custom-pages-v2`, data)
}
export function deleteCustomPage(id){
   return client.delete(`${ apiUrl }/custom-pages-v2/${ id }`)
}

export function getCustomPageAccessData(){
   return client.get(`${ apiUrl }/custom-pages-v2/different-access`)
}

export function getCustomPageById(id){
   return client.get(`${ apiUrl }/custom-pages-v2/${ id }`)
}
export function checkValidAccess(data) {
   return client.post(`${ apiUrl }/custom-pages-v2/check-valid-access/`, data);
}
export function updateCustomPageById(id, data){
   return client.put(`${ apiUrl }/custom-pages-v2/${ id }`, data)
}
export function uploadImage(file, canceled = false) {
   let signal = {};
   if(canceled){
      const controller = new AbortController();
      controller.abort();
      signal = { signal: controller.signal };
   }
   return client.post(`${ amsUrl }/image-uploader/upload-image-v2`, file, signal);
}

// Vault
export function getVault(params = ['page=1'], canceled = true) {
   let config = {};
   if(canceled){
      config = multipleCancelApiConfig('getVault');
   }
   return client.get(`${ apiUrl }/vault?${ params.join('&') }`, config);
}
export function deleteVaultFile(id) {
   return client.delete(`${ apiUrl }/vault/${ id }`);
}

export function createVault(data) {
   return client.post(`${ apiUrl }/vault`, data);
}

export function updateVault(id, data) {
   return client.put(`${ apiUrl }/vault/${ id }`, data);
}

export function createVaultVideo(data) {
   return client.post(`${ apiUrl }/vault/videos`, data);
}

export function getVaultById(id) {
   return client.get(`${ apiUrl }/vault/${ id }`);
}

export function checkVaultByHash(type, data) {
   return client.get(`${ apiUrl }/vault/check-item-by-hash?type=${ type }&data[]=${ data }`);
}

export function getVaultByIds(ids) {
   return client.get(`${ apiUrl }/vault/multiple/get?ids=${ ids }`);
}

export function vaultMultipleAction(data) {
   return client.post(`${ apiUrl }/vault/multiple/create`, data);
}

export function getMediaBlurhash(data) {
   return client.post(`${ amsUrl }/image-uploader/media-image-blurhash`, data);
}

export function getObjectUrl(paths, prefix = null) {
   const formattedPaths = paths.map((path) => `paths[]=${ path }`);
   let url = `${ apiUrl }/get-object-url?${ formattedPaths.join('&') }`;
   if(prefix) {
      url += `&pathName=${ prefix }`;
   }
   return client.get(url);
}
export function getReferalLink() {
   return client.get(`${ apiUrl }/referrals/one-link`);
}
export function getReferalSites(query = []) {
   const config = multipleCancelApiConfig('getReferalSites');
   return client.get(`${ apiUrl }/referrals/sites${ !!query?.length ? '?' + query.join('&') : '' }`, config);
}
export function getTwitterRedirectUrl() {
   return client.get(`${ apiUrl }/auth/twitter/get-redirect-url`);
}

export function verifyTwitter(oauth_token, oauth_verifier) {
   return client.get(`${ apiUrl }/auth/twitter/verify`, { params: { oauth_token: oauth_token, oauth_verifier: oauth_verifier } });
}

export function disconnectTwitter() {
   return client.post(`${ apiUrl }/auth/twitter/disconnect`);
}

export function notificationsInit(query = []) {
   return client.get(`${ apiUrl }/notifications/init${ !!query?.length ? '?' + query.join('&') : '' }`);
}

export function markAllAsSeenNotifications() {
   return client.post(`${ apiUrl }/notifications/mark-all-as-seen`);
}
export function markAllAsReadNotifications(id) {
   return client.post(`${ apiUrl }/notifications/mark-all-as-read`, { id });
}

// start live stream api
export function fetchLiveStreamRecordedStream(query = ['page=1'], canceled = false) {
   let config = {};
   if(canceled){
      config = multipleCancelApiConfig('fetchRecordedStreams');
   }
   return client.get(`${ apiUrl }/videos?video_type=livestream&${ query.join('&') }`, config);
}
export function fetchLiveStreamRecordedStreamsForReorder(query = ['page=1'], canceled = false) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/videos/reorder-data?video_type=livestream&${ query.join('&') }`, config);
}
export function fetchLiveStream() {
   return client.get(`${ apiUrl }/live-stream/init`);
}
export function deleteUpcomingStream() {
   return client.delete(`${ apiUrl }/live-stream/scheduled`);
}
export function updateLiveStreamDetails(key, data) {
   return client.put(`${ apiUrl }/live-stream/details/${ key }`, data);
}
export function fetchLiveStreamDetails(key) {
   return client.get(`${ apiUrl }/live-stream/details/${ key }`);
}
export function livestreamCheckValidate(data) {
   return client.put(`${ apiUrl }/live-stream/check-validate`, data);
}
export function createLivestream(data) {
   return client.post(`${ apiUrl }/live-stream/create`, data);
}
export function liveStreamStartSessionApi(data) {
   return client.post(`${ apiUrl }/live-stream/start-session`, data);
}
export function liveStreamEndSessionApi() {
   return client.post(`${ apiUrl }/live-stream/end-session`, {});
}
export function getLiveStreamMessages(query = ['page=1']) {
   return client.get(`${ apiUrl }/live-stream/chat-messages?${ query.join('&') }`);
}
export function getLiveStreamMessageLikedMembers(id) {
   return client.get(`${ apiUrl }/live-stream/chat-messages/${ id }/liked-members`);
}
export function createLiveStreamTipAction(data) {
   return client.post(`${ apiUrl }/live-stream/tip-actions`, data);
}
export function updateLiveStreamTipActionOthers(data, routename) {
   return client.post(`${ apiUrl }/live-stream/tip-actions/${ routename }`, data);
}
export function updateLiveStreamTipAction(data, id) {
   return client.put(`${ apiUrl }/live-stream/tip-actions/${ id }`, data);
}
export function deleteLiveStreamTipAction(id, data) {
   return client.delete(`${ apiUrl }/live-stream/tip-actions/${ id }`, data);
}
export function reorderLiveStreamTipActions(data) {
   return client.post(`${ apiUrl }/live-stream/tip-actions/reorder`, { data });
}

export function updateLiveStreamGlobalVisibilityOfTipActions(data) {
   return client.post(`${ apiUrl }/live-stream/tip-actions/change-global-visibility-of-tip-actions`, data);
}
export function updateLiveStreamFadeTipActionStatus(data) {
   return client.post(`${ apiUrl }/live-stream/tip-actions/change-fade-tip-action-status`, data);
}
export function updateLiveStreamFadeTipActionDuration(data) {
   return client.post(`${ apiUrl }/live-stream/tip-actions/change-fade-tip-action-duration`, data);
}
export function getTipHistory(query) {
   return client.get(`${ apiUrl }/live-stream/tip-history?${ query.join('&') }`);
}

export function createIntercomEvent(data) {
   return client.post(`${ apiUrl }/intercom`, data);
}

export function getContentTipHistoryById(id, type, query = '') {
   return client.get(`${ apiUrl }/${ type }/${ id }/tips-info${ query }`);
}

export function updateSiteLastActivity() {
   client.get(`${ apiUrl }/auth/last-activity`);
}

// start promotions api
export function uploadPromotionImage(file, canceled = false) {
   let signal = {};
   if(canceled){
      const controller = new AbortController();
      controller.abort();
      signal = { signal: controller.signal };
   }
   return client.post(`${ amsUrl }/image-uploader/promotion-image-upload`, file, signal);
}
export function createNewPromotion(data, query = []) {
   return client.post(`${ apiUrl }/promotions?${ query.join('&') }`, data)
}
export function fetchPromotions(query = []) {
   return client.get(`${ apiUrl }/promotions?${ query.join('&') }`)
}
export function promotionById(id) {
   return client.get(`${ apiUrl }/promotions/${ id }`)
}
export function editPromotion(id, data) {
   return client.put(`${ apiUrl }/promotions/${ id }`, data)
}
export function deletePromotion(id) {
   return client.delete(`${ apiUrl }/promotions/${ id }`)
}
export function expirePromotion(id) {
   return client.put(`${ apiUrl }/promotions/${ id }/expire`)
}
export function initPromotionsFilterData() {
   return client.get(`${ apiUrl }/promotions/get-for-filtering`)
}
export function initPromotionsInfo() {
   return client.get(`${ apiUrl }/promotions/info`)
}

// start outgoing notifications api

export function fetchOutgoingNotificationsSettings() {
   return client.get(`${ apiUrl }/outgoing-notifications-settings`)
}
export function getOutgoingNotificationSettings(slug) {
   return client.get(`${ apiUrl }/outgoing-notifications-settings/${ slug }`)
}
export function updateOutgoingNotificationSettings(slug, data) {
   return client.put(`${ apiUrl }/outgoing-notifications-settings/${ slug }`, data)
}
export function revertOutgoingNotificationSettings(slug, data) {
   return client.put(`${ apiUrl }/outgoing-notifications-settings/${ slug }/revert`, data)
}
export function createCustomOutgoingNotificationSettings(data) {
   return client.post(`${ apiUrl }/outgoing-notifications-settings/create-custom-notification`, data)
}
export function deleteOutgoingNotificationSetting(slug) {
   return client.delete(`${ apiUrl }/outgoing-notifications-settings/${ slug }`)
}

// end outgoing notifications api

export function getAffiliateProgramSettings() {
   return client.get(`${ apiUrl }/affiliate/settings`)
}
export function initAffiliateProgramSettings(data) {
   return client.post(`${ apiUrl }/affiliate/settings`, data)
}
export function changeAffiliateProgramStatus(data) {
   return client.put(`${ apiUrl }/affiliate/change-status`, data)
}
export function changeAffiliateProgramPercentages(data) {
   return client.put(`${ apiUrl }/affiliate/change-percentage`, data)
}
export function getAffiliatesList(query = []) {
   return client.get(`${ apiUrl }/affiliate/list?${ query.join('&') }`)
}
export function getMembersOfAffiliates(uniqueId, query = []) {
   return client.get(`${ apiUrl }/affiliate/members/${ uniqueId }?${ query.join('&') }`)
}



// start statistics API

// Total revenue
export function getTotalRevenueChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/total-revenue${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getCurrentDayStatistics(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/total-revenue/current-day-statistics${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getTopSpendingMembers(query) {
   const timezone = new Date().getTimezoneOffset();
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/statistics/total-revenue/top-spending-members${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`, config)
}
export function getTotalRevenueCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/total-revenue/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}

// Revenue Growth rate
export function getRevenueGrowthRateChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/revenue-growth-rate${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getRevenueGrowthRateCurrantDayStatistics(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/revenue-growth-rate/current-day-statistics${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getRevenueGrowthRateCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/revenue-growth-rate/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}

// Revenue Churn rate
export function getRevenueChurnRateChartData(query) {
   const timezone = new Date().getTimezoneOffset();

   return client.get(`${ apiUrl }/statistics/revenue-churn-rate${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getRevenueChurnRateCurrantDayStatistics() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/revenue-churn-rate/current-day-statistics?timezone=${ timezone }`)
}
export function getRevenueChurnRateCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/revenue-churn-rate/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}

// ENGAGEMENT active_users
export function getActiveUsersChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/active-users${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getActiveUsersCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/active-users/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}

// content views
export function getContentViewsChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/content-views${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getContentViewsCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/content-views/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}

// Most viewed content
export function getMostViewedContent(query, bool) {
   const timezone = new Date().getTimezoneOffset();
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/statistics/content-views/most-viewed-content${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`, !bool && config)
}

// content likes
export function getContentLikesChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/content-likes${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getContentLikesCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/content-likes/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}

// Most liked content
export function getMostLikedContent(query, bool) {
   const timezone = new Date().getTimezoneOffset();
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/statistics/content-likes/most-liked-content${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`, !bool && config)
}

// content comments
export function getContentCommentsChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/content-comments${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getContentCommentsCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/content-comments/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}


// Most commented content
export function getMostCommentedContent(query, bool) {
   const timezone = new Date().getTimezoneOffset();
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/statistics/content-comments/most-commented-content${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`, !bool && config)
}

// Content unlocks
export function getContentUnlocksChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/content-unlocks${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getContentUnlocksCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/content-unlocks/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
// Most unlocked content
export function getMostUnlockedContent(query, bool) {
   const timezone = new Date().getTimezoneOffset();
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/statistics/content-unlocks/most-unlocked-content${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`, !bool && config)
}
// Acquisition
// Unique visitors
export function getUniqueVisitorsChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/unique-visitors${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getUniqueVisitorsCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/unique-visitors/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getUniqueVisitorsCurrentDayData() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/unique-visitors/current-day-statistics?timezone=${ timezone }`)
}
// Traffic sources
export function getTrafficSourceChartData() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/traffic-sources?timezone=${ timezone }`)
}
export function getTrafficSourceCSVLink() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/traffic-sources/get-csv-link?timezone=${ timezone }`)
}
// Locations
export function getLocationsChartData() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/locations?timezone=${ timezone }`)
}
export function getLocationsCSVLink() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/locations/get-csv-link?timezone=${ timezone }`)
}
// Device type
export function getDevicesChartData() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/devices?timezone=${ timezone }`)
}
export function getDevicesCSVLink() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/devices/get-csv-link?timezone=${ timezone }`)
}
// Bounce rate
export function getBounceRateChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/bounce-rate${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getBounceRateCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/bounce-rate/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getBounceRateCurrentDayData() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/bounce-rate/current-day-statistics?timezone=${ timezone }`)

}
//Account creations
export function getAccountCreationsChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/account-creations${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getAccountCreationsCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/account-creations/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getAccountCreationsCurrentDayData() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/account-creations/current-day-statistics?timezone=${ timezone }`)

}
// Joins
export function getJoinsChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/joins${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getJoinsCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/joins/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getJoinsCurrentDayData() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/joins/current-day-statistics?timezone=${ timezone }`)
}
// Account Conversion rate
export function getAccountConvertionRateChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/account-conversion-rate${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getAccountConvertionRateCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/account-conversion-rate/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getAccountConvertionRateCurrentDayData(){
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/account-conversion-rate/current-day-statistics?timezone=${ timezone }`)
}
// Join Conversion rate
export function getJoinConvertionRateChartData(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/join-conversion-rate${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getJoinConvertionRateCSVLink(query) {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/join-conversion-rate/get-csv-link${ Boolean(query) ? `?${ query }&timezone=${ timezone }` : `?timezone=${ timezone }` }`)
}
export function getJoinConvertionRateCurrentDayData() {
   const timezone = new Date().getTimezoneOffset();
   return client.get(`${ apiUrl }/statistics/acquisition/join-conversion-rate/current-day-statistics?timezone=${ timezone }`)
}

//Content reordering

export function changeContentOrders(contentType, data) {
   return client.post(`${ apiUrl }/${ contentType }/reorder`, data)
}

// Pin counts

export function getPinnedCountStatistics(type, query = []) {
   return client.get(`${ apiUrl }/${ type }/counts-pinned?${ query.join('&') }`);
}

// Start 2fa auth
export function twoFACheckPassword(data) {
   return client.put(`${ apiUrl }/auth/2fa/check-password`, data);
}
export function twoFAGenerateSecretKey(data) {
   return client.post(`${ apiUrl }/auth/2fa/generate-secret-key`, data);
}
export function twoFAGenerateQrCode(data) {
   return client.post(`${ apiUrl }/auth/2fa/generate-qr-code`, data);
}
export function twoFAVerifyCode(data) {
   return client.post(`${ apiUrl }/auth/2fa/verify-code`, data);
}
export function twoFARemove(data) {
   return client.put(`${ apiUrl }/auth/2fa/remove-secret-key`, data);
}
export function twoFAGetNewBackupCodes() {
   return client.get(`${ apiUrl }/auth/2fa/generate-new-recovery-codes`);
}

// Connect wishlist - mms
export function connectToMyList() {
   return client.post(`${ apiUrl }/auth/connect-to-my-list`)
}
// Content categories
export function getAddedContentCategories() {
   return client.get(`${ apiUrl }/settings/content-category?count=all`)
}
export function getAllContentCategories() {
   return client.get(`${ apiUrl }/settings/content-category/all?count=all`)
}
export function toggleAddRemoveCategory(id) {
   return client.put(`${ apiUrl }/settings/content-category/${ id }`)
}
export function getContentStorage() {
   return client.get(`${ apiUrl }/settings/content-storage`)
}
export function updateContentStorage(data) {
   return client.put(`${ apiUrl }/settings/content-storage`, data)
}
export function getContactFormToggle() {
   return client.get(`${ apiUrl }/settings/contact-form`)
}
export function updateContactFormToggle(data) {
   return client.put(`${ apiUrl }/settings/contact-form`, data)
}

export function getPhotoCollectionPhotosById(id) {
   return client.get(`${ apiUrl }/photos/${ id }`);
}

// Repost content API

export function repostContent(contentType, id, data) {
   return client.post(`${ apiUrl }/${ contentType }/repost/${ id }`, data);
}


// Saved replies api

export function getReplies(query = ['']) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/chat/saved-replies?${ query.join('&') }`, config);
}
export function createReply(data) {
   return client.post(`${ apiUrl }/chat/saved-replies`, data);
}
export function updateReply(id, data) {
   return client.put(`${ apiUrl }/chat/saved-replies/${ id }`, data);
}
export function deleteReply(id) {
   return client.delete(`${ apiUrl }/chat/saved-replies/${ id }`);
}
export function replaceVariables(text, user_uuid) {
   return client.post(`${ apiUrl }/chat/replace-variables`, { text, user_uuid });
}
export function reorderReplies(data) {
   return client.post(`${ apiUrl }/chat/saved-replies/reorder`, { data });
}

// Demo mode
export function unlockDemoModePublicSite() {
   return client.post(`${ apiUrl }/demo-mode/unlock-public-site`)
}

// Polls
export function getPollInfo() {
   return client.get(`${ apiUrl }/polls/info`)
}
export function getPollVotersCount(accessSettings) {
   return client.post(`${ apiUrl }/polls/get-receivers-count`, accessSettings)
}
export function createPoll(data, isValidate = false) {
   return client.post(`${ apiUrl }/polls${ isValidate ? '?validate=true' : '' }`, data)
}
export function getPollsList(query = []) {
   const config = cancelApiConfig();
   return client.get(`${ apiUrl }/polls${ query && query.length > 0 ? `?${ query.join('&') }` : '' }`, config)
}
export function deletePoll(id) {
   return client.delete(`${ apiUrl }/polls/${ id }`)
}
export function updatePoll(id, data) {
   return client.put(`${ apiUrl }/polls/${ id }`, data)
}
export function getPollById(id) {
   return client.get(`${ apiUrl }/polls/${ id }`)
}
export function changePollStatus(id, updatedStatus) {
   return client.put(`${ apiUrl }/polls/${ id }/${ updatedStatus }`)
}

// Video security
export function getVideoSecuritySettings() {
   return client.get(`${ apiUrl }/settings/drm-protection`);
}
export function updateVideoSecuritySettings(data) {
   return client.put(`${ apiUrl }/settings/update-drm-state`, data)
}

export function markObjectAsTemporaryUpload(data) {
   return client.post(`${ apiUrl }/s3/mark-object-as-temporary-upload`, data);
}

// Qr customize
export function customizeQR(data) {
   return client.put(`${ apiUrl }/settings/customize-qr`, data);
}
