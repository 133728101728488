import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import Modal from 'common/components/modules/modal';
import cx from 'classnames';
import TruncateText from 'common/components/elements/truncate-text';
import DateFormatter from 'common/utils/DateFormatter';
import Router from 'admin/routes/router';

const getMemberType = (type) => {
   const memberStatus = {
      name: '',
      color: '',
   };
   switch (type) {
      case 'active':
         memberStatus.name = 'Active';
         memberStatus.color = '!text-success dark:text-secondary-dark ';
         memberStatus.bg = '!bg-success-12'
         break;
      case 'incomplete':
         memberStatus.name = 'Guest';
         memberStatus.color = '!text-warning';
         memberStatus.bg = '!bg-pending-12'
         break;
      case 'cancelled':
         memberStatus.name = 'Cancelled';
         memberStatus.color = '!text-error';
         memberStatus.bg = '!bg-error-12'
         break;
      case 'expired':
         memberStatus.name = 'Expired';
         memberStatus.color = 'text-secondary dark:text-secondary-dark ';
         memberStatus.bg = 'bg-secondary-12'
         break;
      case 'free':
         memberStatus.name = 'Complimentary';
         memberStatus.color = 'text-action';
         memberStatus.bg = 'bg-action-12'
         break;
      default:
         break;
   }
   return memberStatus;
};

const SubscribersMailCard = ({
   isMobile,
   item,
   onUnsubscribe,
   isFirst,
   isLast,
   isSubscribed,
   type,
   optionsClassName,
}) => {

   let unsubscribedStatus = '';

   if(item.joined_at){
      unsubscribedStatus = `• Joined ${ DateFormatter.getDateByTimzeonFormat(item.joined_at) }`
   } else if(item.membership_status === 'expired'){
      unsubscribedStatus = '• Expired'
   }  else if(item.membership_status === 'incomplete'){
      unsubscribedStatus = '• Never joined'
   }

   const [openOptionsModal, setOpenOptionsModal] = useState(false);
   const [animate, setAnimate] = useState(null);

   function closeOnClickOutside(e) {
      if(!e.target.closest(`.subscriber-${ item.id }`)) {
         openCloseModal(false);
      }
   }

   useEffect(() => {
      window.addEventListener('click', closeOnClickOutside, true);
      return () => {
         window.removeEventListener('click', closeOnClickOutside, true);
      };
   // eslint-disable-next-line
   }, []);

   function openCloseModal(value) {
      if(isMobile) {
         setAnimate(value ? 'up' : 'down');
         setTimeout(() => {
            setOpenOptionsModal(value);
         }, 800)
      } else {
         setOpenOptionsModal(value);
      }
   }
   return (
      <div className={ `${ isLast && isFirst ? 'one-mail-card' : 'mail-card' } select-none flex resource-card bg-panel dark:bg-panel-dark ${ type === 'members' ? 'py-2.5' : 'py-3' } px-4 justify-between items-center relative` }>
         <div className='flex card-info'>
            <div className='h-full flex flex-col w-full'>
               <TruncateText
                  text={ item.email }
                  textClass={ `leading-5 text-major text-sm  ${ type === 'members' ? ' hover:cursor-pointer mb-1' : '' } notranslate` }
                  fontWeight='500'
                  isLink={ !!item?.id && type === 'members' }
                  href={ Router.route('MEMBERS_SHOW').getCompiledPath({ id: item?.id }) }
                  target='_blank'
               />
               {
                  item.username && type === 'members' && (
                     <TruncateText
                        text={ `${ item.nickname || item.username } ${ unsubscribedStatus }` }
                        textClass='mb-1 text-xs leading-[14px] text-placeholder notranslate'
                        textSize='14px'
                        fontWeight='400'
                     />

                  )
               }
               {
                  !isSubscribed && item.date_of_unsubscribe_in_mailing_list && type === 'members' && (
                     <TruncateText
                        text={ `Date unsubscribed: ${ DateFormatter.getDateByTimzeonFormat(item.date_of_unsubscribe_in_mailing_list) }` }
                        textClass=' text-xs leading-[14px] text-placeholder'
                        fontWeight='400'
                     />

                  )
               }
               {
                  item.membership_status && type === 'members' && isSubscribed && (
                     <div className='mt-1'>
                        <span
                           className={
                              cx(
                                 'text-xs dark:text-major-dark text-center px-3 py-[3px] rounded-full h-7 leading-[18px]',
                                 getMemberType(item.membership_status).color,
                                 getMemberType(item.membership_status).bg
                              )
                           }
                        >
                           { getMemberType(item.membership_status).name }
                        </span>
                     </div>
                  )
               }
            </div>
         </div>
         {
            isSubscribed && (
               <div className='h-4 w-4'>
                  <Button
                     onClick={ () => openCloseModal(true) }
                     backgroundColor='transparent'
                     fontIconColor='secondary'
                     fontIconSize='doubleLarge'
                     iconName='options-vertical'
                     classNames='h-full w-full'
                     padding='none'
                     waveColor='rgba(33, 33, 33, 0.8)'
                  />
               </div>
            )
         }

         {
            openOptionsModal && (
               <SubscribersModalOptions
                  id={ item.id }
                  type={ type }
                  isMobile={ isMobile }
                  onUnsubscribe={ onUnsubscribe }
                  onCloseModal={ () => setOpenOptionsModal(false) }
                  animate={ animate }
                  email={ item.email }
                  optionsClassName={ optionsClassName }
               />
            )
         }
      </div>
   );
}

export const SubscribersModalOptions = ({
   type,
   onUnsubscribe,
   id,
   isMobile,
   onCloseModal,
   animate,
   email,
   firstActionText,
   firstActionIcon,
   firstAction,
   secondActionText,
   secondActionIcon,
   secondAction,
   top,
   hasDetails,
   optionsClassName,
   onClickDetails,
   hideDeleteOption = false,
}) => {

   const handleClick = (e) => {
      e.stopPropagation()
      if(secondAction){
         secondAction(true)
      } else {
         onUnsubscribe(email)
      }
   }

   const view = (
      <div
         className={ `child shadow dark:shadow-dark subscriber-${ id } flex flex-col w-full bg-panel dark:bg-panel-dark items-center justify-between ${ isMobile ? '' : 'border dark:border-divider-dark border-divider rounded-lg min-w-[240px]' } hover:overflow-hidden py-1` }>
         {
            hasDetails &&
            <div className='flex w-full h-10 items-center justify-between hover:bg-hover dark:hover:bg-hover-dark subscriber-button'>
               {
                  email ?
                     <a className='w-full' href={ `mailto:${ email }` }>
                        <Button
                           fontWeight='normal'
                           text={ firstActionText }
                           lineHeight='20px'
                           textSize='base'
                           textColor='major dark:text-major-dark'
                           classNames='h-full w-full'
                           iconName={ firstActionIcon }
                           fontIconColor='secondary'
                           iconPosition='left'
                           alignment='end'
                           padding='none py-2.5 px-4'
                           backgroundColor='transparent'
                           fontIconSize='extraLarge'
                           ignoreHover
                           textMarginX='0 pl-3'
                        />
                     </a>
                     :
                     <Button
                        fontWeight='normal'
                        text={ firstActionText }
                        lineHeight='20px'
                        textSize='base'
                        textColor='major dark:text-major-dark'
                        classNames='h-full w-full'
                        iconName={ firstActionIcon }
                        fontIconColor='secondary'
                        iconPosition='left'
                        alignment='end'
                        padding='none py-2.5 px-4'
                        backgroundColor='transparent'
                        fontIconSize='extraLarge'
                        onClick={ (e) => {
                           e.stopPropagation()
                           firstAction(id)
                        } }
                        ignoreHover
                        textMarginX='0 pl-3'
                     />
               }
            </div>
         }
         {
            Boolean(onClickDetails) &&
            <div className='flex w-full h-10 items-center justify-between hover:bg-hover dark:hover:bg-hover-dark'>
               <Button
                  fontWeight='normal'
                  text='Details'
                  textSize='base'
                  lineHeight='20px'
                  onClick={ onClickDetails }
                  iconName='attention-outline'
                  fontIconSize='large'
                  iconPosition='left'
                  classNames='h-full w-full'
                  backgroundColor='transparent'
                  fontIconColor='secondary'
                  textColor='major'
                  padding='none py-2.5 px-4'
                  alignment='end'
                  ignoreHover
                  textMarginX='0 pl-3'
               />
            </div>
         }
         {
            !hideDeleteOption &&
            <div className='flex w-full h-10 items-center justify-between hover:bg-hover dark:hover:bg-hover-dark subscriber-button'>
               <Button
                  fontWeight='normal'
                  text={ secondActionText }
                  textSize='base'
                  lineHeight='20px'
                  onClick={ handleClick }
                  iconName={ secondActionIcon }
                  fontIconSize='extraLarge'
                  iconPosition='left'
                  classNames='h-full w-full py-[10px]'
                  backgroundColor='transparent'
                  fontIconColor='error'
                  textColor='error'
                  padding='none py-2.5 px-4'
                  alignment='end'
                  ignoreHover
                  textMarginX='0 pl-3'
               />
            </div>
         }
      </div>
   )
   if(isMobile){
      return (
         <Modal
            onClose={ onCloseModal }
            roundedModal='lg'
            animate={ animate }
         >
            {view}
         </Modal>
      );
   } else {
      return (
         <div
            style={ { top: type === 'members' ? '57px' : top } }
            className={ `absolute z-30 right-0 w-[200px] ${ optionsClassName }` }>
            {view}
         </div>
      )
   }

}

SubscribersModalOptions.propTypes = {
   type: PropTypes.string,
   onUnsubscribe: PropTypes.func,
   onCloseModal: PropTypes.func,
   id: PropTypes.number,
   isMobile: PropTypes.bool,
   animate: PropTypes.any,
   email: PropTypes.any,
   firstActionText: PropTypes.string,
   firstActionIcon: PropTypes.string,
   secondActionText: PropTypes.string,
   secondActionIcon: PropTypes.string,
   hasDetails: PropTypes.bool,
   top: PropTypes.string,
   firstAction: PropTypes.func,
   secondAction: PropTypes.func,
   optionsClassName: PropTypes.string,
   onClickDetails: PropTypes.func,
   hideDeleteOption: PropTypes.bool,
};

SubscribersModalOptions.defaultProps = {
   onUnsubscribe: () => {},
   firstActionText: 'Send an email',
   firstActionIcon: 'message',
   secondActionText: 'Unsubscribe',
   secondActionIcon: 'close',
   hasDetails: true,
};

SubscribersMailCard.propTypes = {
   onUnsubscribe: PropTypes.func,
   item: PropTypes.object,
   isMobile: PropTypes.bool,
   isFirst: PropTypes.bool,
   isLast: PropTypes.bool,
   isSubscribed: PropTypes.bool,
   type: PropTypes.string,
   optionsClassName: PropTypes.string,
};

export default memo(SubscribersMailCard);
