import Button from 'common/components/elements/buttons/primary';
import Icon from 'common/components/elements/icons';
import PageLoader from 'common/components/modules/page-loading';
import PropTypes from 'prop-types'
import { useRef, useState, useEffect } from 'react';
import ImageUploaderOptions from './image-uploader-options';
import cx from 'classnames';
import WebcamCapture from 'common/components/modules/webcam';
import toast from 'common/utils/toast';
import CameraAccessDialogs from 'common/components/modules/webcam/camera-access-dialogs';
import intercomMoreInfo, { CONTACT_SUPPORT } from 'admin/utils/IntercomMoreInfo';
import { checkDeviceSupport } from 'common/components/modules/webcam/check-device-support';
import { flushSync } from 'react-dom';
import classNames from 'classnames';

const ImageUploader = ({
   handleChangeImage,
   loading,
   source,
   errorMessage,
   handleDeleteImage,
   isMobile,
   disabled,
   screenWidth,
   uploadImageText,
   dontShowErrorMessage,
   tooltipText,
   passEventOnFileChange,
   multiple = false,
   hideOptions = false,
   wrapperClassName,
   contentWrapperClassName,
   visibleOverflow = false,
   showErrorMessageOnCenter = false,
   iconName = 'cloud-upload-new',
   iconColor = 'secondary',
   uploadText,
   dontBlurCenterImage = false,
   inputName,
   inputClassName,
}) => {
   const [value, setValue] = useState('');
   const [isDragged, setIsDragged] = useState(false)

   const fileUploaderRef = useRef(null)
   // eslint-disable-next-line
   const [isInited, setIsInited] = useState(false)
   const [isOpenOptions, setIsOpenOptions] = useState(false)
   const [isOpenWebcamModal, setIsOpenWebcamModal] = useState(false)
   const [cameraAccessDialogData, setCameraAccessDialogData] = useState({ isOpen: false, type: 'allow' });
   const [isRefresh, setRefresh] = useState(false);


   useEffect(() => {
      setIsInited(true)
   }, [])


   function successCallback(stream) {
      window.localStream = stream;
      flushSync(() => {
         setIsOpenWebcamModal(true);
      });
      flushSync(() => {
         setCameraAccessDialogData({
            isOpen: false,
            type: 'allow',
         });
      });
   }

   function errorCallback(err) {
      if('Requested device not found' === err.message) {
         setIsOpenWebcamModal(false)
         toast.error('Your camera is off');
      } else {
         setCameraAccessDialogData({
            isOpen: false,
            type: 'block',
         })
      }
   }

   function checkDeviceStatus(hasWebcam, isSafari = false, errorName = '') {
      if(hasWebcam) {
         if(isSafari) {
            if(errorName === 'OverconstrainedError' || errorName === 'Error') {
               toast.error('Your camera is off');
            } else if(errorName === 'NotAllowedError') {
               setCameraAccessDialogData({
                  isOpen: true,
                  type: 'block',
               });
               setRefresh(true);
            } else {
               setIsOpenWebcamModal(true)
            }
         } else {
            !!navigator.permissions && navigator.permissions.query({ name: "camera" }).then(res => {
               if(res.state === "granted"){
                  // has permission
                  setIsOpenWebcamModal(true)
               }
               if(res.state === "denied"){
                  // no permission
                  setCameraAccessDialogData({
                     isOpen: true,
                     type: 'block',
                  });
               }
               if(res.state === "prompt") {
                  setCameraAccessDialogData({
                     isOpen: true,
                     type: 'allow',
                  });
                  let constraints = { video: true };
                  navigator.mediaDevices.getUserMedia(constraints)
                     .then(successCallback, errorCallback);
               }
            }).catch(res => {
               const query = navigator.permissions.query;
               navigator.permissions.query = (permissionDesc) => {
                  return query(permissionDesc).catch((e) => {
                     const { name } = permissionDesc;

                     if(name === 'camera') {
                        return new Promise((resolve, reject) => {
                           const { constraints, peerIdentity } = permissionDesc;

                           navigator.mediaDevices.getUserMedia({ video: constraints || true, peerIdentity })
                              .then((stream) => resolve({ name, state: 'granted', stream }))
                              .catch((err) => {
                                 if(err.name === 'PermissionDeniedError') {
                                    toast.error('Your camera is off');
                                    resolve({ name, state: 'denied' });
                                 } else {
                                    reject(err);
                                 }
                              });
                        });
                     }
                  })
               }
            })
         }
      } else {
         setIsOpenWebcamModal(false);
         toast.error('Your camera is off');
      }
   }

   function checkHasWebcam(){
      if(isRefresh){
         window.location.reload(true);
      } else {
         checkDeviceSupport(checkDeviceStatus)
            .then((res) => {})
            .catch(() => {})
      }
   }
   return (
      <>
         <input
            value={ value }
            type='file'
            ref={ el => fileUploaderRef.current = el }
            className={ classNames({
               'hidden': true,
               [`${ inputClassName }`]: Boolean(inputClassName),
            }) }
            onChange={ (e) => {
               setValue('');
               handleChangeImage(passEventOnFileChange ? e : e.target.files[0])
            } }
            accept='image/png,image/jpeg, image/webp'
            multiple={ multiple }
            name={ inputName }
         />
         <div
            className={ cx({
               'w-full h-full p-1 bg-panel rounded-lg border border-solid border-divider relative': true,
               'overflow-hidden': !visibleOverflow,
               'hover:bg-hover cursor-pointer': !isOpenOptions && !Boolean(source) && !disabled,
               '!bg-hover': isDragged,
               'border-divider': !Boolean(errorMessage),
               'border-error': Boolean(errorMessage),
               [`${ wrapperClassName }`]: Boolean(wrapperClassName),
            }) }
            onDragOver={ (e) => {
               e.preventDefault()
               e.stopPropagation()
               if(disabled || isOpenOptions || Boolean(source)) {
                  return
               }
               setIsDragged(true)
            } }
            onDragLeave={ (e) => {
               e.stopPropagation()
               e.preventDefault()
               if(disabled || isOpenOptions || Boolean(source)) {
                  return
               }
               setIsDragged(false)
            } }
            onDrop={ (e) => {
               e.stopPropagation()
               e.preventDefault()
               if(disabled || isOpenOptions || Boolean(source)) {
                  return
               }
               setIsDragged(false)
               handleChangeImage(passEventOnFileChange ? e : e.dataTransfer.files[0], false, true);
            } }
            role='presentation'
            onClick={ (e) => {
               e.preventDefault()
               if(disabled || isOpenOptions || Boolean(source)) {
                  return
               }
               if(fileUploaderRef.current){
                  fileUploaderRef.current.click()
               }
            } }
            data-tooltip-id={ tooltipText ? 'ams-top-tooltip' : null }
            data-tooltip-content={ tooltipText }
         >
            <div
               className={ cx({
                  'w-full h-full border border-dashed rounded-lg relative': true,
                  'border-placeholder': !Boolean(errorMessage),
                  'border-error': Boolean(errorMessage),
               }) }
            >
               <div
                  className={ cx({
                     'flex flex-col rounded-lg items-center justify-center h-68 z-9': true,
                     [`${ contentWrapperClassName }`]: Boolean(contentWrapperClassName),
                  }) }
               >
                  {
                     !hideOptions && (
                        !disabled && (screenWidth > 1024 || Boolean(source)) && (
                           Boolean(source) ? (
                              <div
                                 role='presentation'
                                 className='absolute right-[6px] top-[6px] w-9 h-9 rounded-lg  z-20 bg-major-20 backdrop-blur-[10px]'
                                 onClick={ (e) => {
                                    e.preventDefault()
                                    e.stopPropagation()
                                    setIsOpenOptions(true)
                                 } }

                              >
                                 <Icon
                                    name='options-vertical'
                                    color='major-dark'
                                    className='text-2xl absolute top-1.5 right-1.5'
                                    size='2xl'
                                 />
                              </div>
                           ) : (
                              <div
                                 className='absolute top-3 right-3 w-6 h-6 z-9'
                              >
                                 <Button
                                    iconName='options-vertical'
                                    backgroundColor='transparent'
                                    fontIconColor={ Boolean(source) ? 'major-dark' : 'secondary' }
                                    onClick={ (e) => {
                                       e.preventDefault()
                                       e.stopPropagation()
                                       setIsOpenOptions(true)
                                    } }
                                    classNames='z-15 w-full h-full'
                                    waveActive={ false }
                                    padding='none'
                                 />
                              </div>
                           )
                        )
                     )
                  }
                  {
                     loading ? (
                        <PageLoader withLoader />
                     ) : (
                        !source ? (
                           <>
                              <div
                                 className={ `w-10 h-10 bg-${ iconColor }-12 rounded-full flex items-center justify-center` }
                              >
                                 <Icon
                                    name={ iconName }
                                    size='2xl'
                                    color={ iconColor }
                                    // className='text-secondary'
                                 />
                              </div>
                              <span
                                 className='text-sm font-medium text-secondary mt-2 z-9'
                              >
                                 {
                                    showErrorMessageOnCenter && Boolean(errorMessage) ? (
                                       <span
                                          className='text-error'
                                       >
                                          { errorMessage }
                                       </span>
                                    ) : (
                                       uploadText ? (
                                          <>
                                             {uploadText}
                                          </>
                                       ) : (
                                          <>
                                             <span
                                                className='skin-primary-text hover:underline'
                                             >
                                                Click to upload&nbsp;
                                             </span>
                                             { !isMobile ? 'or drag and drop' : '' } image file here
                                          </>
                                       )
                                    )
                                 }
                              </span>
                           </>
                        ) : (

                           !dontBlurCenterImage ? (
                              <>
                                 <div
                                    style={ source && !loading && !errorMessage ? {
                                       backgroundImage: `url(${ source })`,
                                       filter: 'blur(20px)',
                                       zIndex: '1',
                                    } : {} }
                                    className='w-full h-full absolute top-0 right-0 bg-cover bg-center'
                                 />
                                 <img className='w-full h-full absolute top-0 right-0 object-contain object-center cursor-pointer z-1' src={ source } alt='' />
                              </>
                           ) : (
                              <img
                                 className='w-full h-full absolute top-0 right-0 rounded-lg cursor-pointer z-1'
                                 src={ source }
                                 alt=''
                              />
                           )

                        )
                     )
                  }
               </div>
            </div>
            {
               isOpenOptions && (
                  <ImageUploaderOptions
                     isMobile={ isMobile }
                     onCloseModal={ () => setIsOpenOptions(false) }
                     onClickDelete={ handleDeleteImage }
                     onClickUpload={ () => fileUploaderRef.current.click() }
                     onClickCapture={ () => {
                        setIsOpenOptions(false)
                        checkHasWebcam()
                     } }
                     hasSource={ Boolean(source) }
                     screenWidth={ screenWidth }
                     uploadImageText={ uploadImageText }
                  />
               )
            }
         </div>
         {
            !dontShowErrorMessage && Boolean(errorMessage) && (
               <div className='flex justify-end mt-1.5'>
                  <span className='text-error text-xs leading-[18px]'>{ errorMessage }</span>
               </div>
            )
         }
         {
            isOpenWebcamModal && (
               <WebcamCapture
                  onClose={ () => {
                     if(window.localStream && window.localStream.getVideoTracks && window.localStream.getVideoTracks() && window.localStream.getVideoTracks()[0]){
                        window.localStream.getVideoTracks()[0].stop();
                     }
                     setIsOpenWebcamModal(false);
                  } }
                  onChangeFile={ (src) => {
                     setIsOpenWebcamModal(false);
                     setIsOpenOptions(false)
                     fetch(src)
                        .then(res => res.blob())
                        .then(blob => {
                           const file = new File([blob], "image.jpeg", { type: "image/png" })
                           handleChangeImage(file, true)
                        })
                        .catch(() => {
                           toast.error('Something went wrong')
                        })
                  } }
                  title='Capture'
                  type={ 'image' }
               />
            )
         }
         {
            cameraAccessDialogData.isOpen && (
               <CameraAccessDialogs
                  onClose={ () => setCameraAccessDialogData({
                     isOpen: false,
                     type: 'allow',
                  }) }
                  type={ cameraAccessDialogData.type }
                  handleHelp={ () => intercomMoreInfo(CONTACT_SUPPORT) }
                  isMobile={ isMobile }
                  isNewStyles
               />
            )
         }
      </>
   )
}


ImageUploader.propTypes = {
   handleChangeImage: PropTypes.func,
   loading: PropTypes.bool,
   source: PropTypes.string,
   errorMessage: PropTypes.string,
   handleDeleteImage: PropTypes.func,
   isMobile: PropTypes.bool,
   disabled: PropTypes.bool,
   screenWidth: PropTypes.number,
   uploadImageText: PropTypes.string,
   dontShowErrorMessage: PropTypes.bool,
   tooltipText: PropTypes.string,
   passEventOnFileChange: PropTypes.bool,
   multiple: PropTypes.bool,
   hideOptions: PropTypes.bool,
   wrapperClassName: PropTypes.string,
   contentWrapperClassName: PropTypes.bool,
   visibleOverflow: PropTypes.bool,
   showErrorMessageOnCenter: PropTypes.bool,
   iconName: PropTypes.string,
   iconColor: PropTypes.string,
   uploadText: PropTypes.string,
   dontBlurCenterImage: PropTypes.bool,
   inputName: PropTypes.string,
   inputClassName: PropTypes.string,
}

export default ImageUploader;
