import { createSelector } from 'reselect'

const innerStateSelector = state => state.chat
const allStateSelector = state => state

const conversationMessagesSelector = createSelector(
   innerStateSelector,
   (state) => {
      const { activeConverstaion, initedConverstionMessages } = state
      let res = {
         data: [],
         isEmpty: true,
      }

      if(activeConverstaion && activeConverstaion.chat_room_id && initedConverstionMessages[activeConverstaion.chat_room_id]) {
         initedConverstionMessages[activeConverstaion.chat_room_id].isEmpty = initedConverstionMessages[activeConverstaion.chat_room_id].data.length === 0
         res = initedConverstionMessages[activeConverstaion.chat_room_id]
      }
      return res
   }
);
const unreadMessagesCountSelector = createSelector(
   innerStateSelector,
   (state) => {
      let groupUnreadCount = 0
      if(state.groupConversation &&  'number' === typeof state.groupConversation.unread_messages_count){
         groupUnreadCount = state.groupConversation.unread_messages_count
      }
      const { unreadPrivateMessagesCount } = state
      let count = unreadPrivateMessagesCount || 0;
      count = count + groupUnreadCount
      if(count > 100){
         count = '99+';
      }
      if(count < 0)count = 0;
      return count
   }
);
const conversationsListSelector = createSelector(
   innerStateSelector,
   (state) => {
      const { conversationsList, onlineUsers } = state
      let data = []
      if(conversationsList && conversationsList.data){
         data = [...conversationsList.data].map((i) => {
            let { ...elm } = i
            elm = {
               ...elm,
               is_online: !!onlineUsers.find(i => i.uuid === elm.user_uuid),
            }
            return elm
         })
      }

      return {
         ...conversationsList,
         data,
      }
   }
);
const likedMemberByMessageSelector = createSelector(
   innerStateSelector,
   (state) => {
      let { likedMemberByMessage, onlineUsers } = state

      let data = likedMemberByMessage.data
      if(data) {
         data = [...data].map(i => {
            const { ...elm } = i
            elm.is_online = !!onlineUsers.find(user => user.uuid === elm.uuid)
            return elm
         })
      }
      return { ...likedMemberByMessage, data }
   }
);

const descktopUserListSelector = createSelector(
   innerStateSelector,
   (state) => {
      let { conversationsList, descktopUserList, groupConversation, onlineUsers } = state
      let res = descktopUserList.map(i => {
         let { ...elm } = i;
         if(elm.chat_room_id === 'live_stream'){

         } else  if(elm.chat_room_id === groupConversation.chat_room_id) {
            elm = { ...groupConversation }
         } else {
            let item = {}
            if(conversationsList.data) {
               item = [...conversationsList.data].find(chat => chat.chat_room_id === elm.chat_room_id)
               if(item) {
                  item.is_online = !!onlineUsers.find(i => i.uuid === elm.user_uuid)
               }
            }
            elm = item || {}
         }
         return elm
      })
      return res
   }
   // (state) => (state.descktopUserList)
);

// start chat settings
const settingsDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.settingsData)
);
const settingsErrorsDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.settingsErrorsData)
);
const isFetchSettingsUpdateSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchSettingsUpdate)
);
const isFetchChatSettingsSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchChatSettings)
);
const unreadPrivateMessagesCountSelector = createSelector(
   innerStateSelector,
   (state) => (state.unreadPrivateMessagesCount)
);
const isTurnOnPrivateChatSelector = createSelector(
   innerStateSelector,
   state => state?.settingsData?.settings?.chat_private_is_active
)

// end chat settings

// start chat members
const membersDataSelector = createSelector(
   innerStateSelector,
   (state) => (state.membersData)
);
const isFetchMembersSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchMembers)
);
const isFetchMembersFilterSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchMembersFilter)
);
const isEmptyMembersSelector = createSelector(
   innerStateSelector,
   (state) => (state.isEmptyMembers)
);
const isEmptyMembersByFilterSelector = createSelector(
   innerStateSelector,
   (state) => (state.isEmptyMembersByFilter)
);
const isFetchNewMembersSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchNewMembers)
);
// end chat members

//start conversations

const isFetchConversationSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchConversation)
);
const groupConversationSelector = createSelector(
   innerStateSelector,
   (state) => (state.groupConversation)
);
const isFetchNewConversationSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchNewConversation)
);
const isFetchConversationByFilterSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchConversationByFilter)
);
const isEmptyconversationByFilterSelector = createSelector(
   innerStateSelector,
   (state) => (state.isEmptyconversationByFilter)
);

//end conversations
//start conversation messages
const isFetchConversationMessagesSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchConversationMessages)
);

const isEmptyConversationMessagesSelector = createSelector(
   innerStateSelector,
   (state) => (state.isEmptyConversationMessages)
);
const isFetchNewConversationMessagesSelector = createSelector(
   innerStateSelector,
   (state) => (state.isFetchNewConversationMessages)
);

//end conversation messages

const activeConverstaionSelector = createSelector(
   innerStateSelector,
   (state) => (state.activeConverstaion)
);
const chatByUserSelector = createSelector(
   innerStateSelector,
   (state) => (state.chatByUser)
);
const isOpenRightContentSelector = createSelector(
   innerStateSelector,
   (state) => (state.isOpenRightContent)
);

const descktopChatListSelector = createSelector(
   innerStateSelector,
   (state) => (state.descktopChatList)
);


const showMiniChatSelector = createSelector(
   innerStateSelector,
   (state) => (state.showMiniChat)
);
const openDesctopDialogueSelector = createSelector(
   innerStateSelector,
   (state) => (state.openDesctopDialogue)
);
const isSocketConnectedSelector = createSelector(
   innerStateSelector,
   (state) => (state.isSocketConnected)
);
const initedConverstionMessagesSelector = createSelector(
   innerStateSelector,
   (state) => (state.initedConverstionMessages)
);
const onlineUsersSelector = createSelector(
   innerStateSelector,
   (state) => (state.onlineUsers)
);
const memberByUuidSelector = createSelector(
   innerStateSelector,
   (state) => (state.memberByUuid)
);
const soketSelector = createSelector(
   innerStateSelector,
   (state) => (state.soket)
);
const initedConversationSelector = createSelector(
   innerStateSelector,
   (state) => (state.initedConversation)
);
const openDkChatNotificationSelector = createSelector(
   innerStateSelector,
   (state) => (state.openDkChatNotification)
);

const onlineMembersSelector = createSelector(
   allStateSelector,
   (state) => {
      return state.chat.onlineUsers?.filter(u => u?.uuid !== state.common?.authUser?.uuid)
   }
);

const fetchingConversationIdsSelector = createSelector(
   innerStateSelector,
   (state) => (state.fetchingConversationIds)
)

const conversationsFilterSelector = createSelector(
   innerStateSelector,
   (state) => (state.conversationsFilter)
)

const memberVideoDownloadMultipleWatermarksSelector = createSelector(
   innerStateSelector,
   state => state.memberVideoDownloadMultipleWatermarks
);

export {
   isFetchSettingsUpdateSelector,
   settingsDataSelector,
   settingsErrorsDataSelector,
   isFetchChatSettingsSelector,
   /////////////////////////////
   membersDataSelector,
   isFetchMembersSelector,
   isEmptyMembersSelector,
   isEmptyMembersByFilterSelector,
   isFetchMembersFilterSelector,
   isFetchNewMembersSelector,
   ///////////////////////////
   isFetchConversationSelector,
   conversationsListSelector,
   groupConversationSelector,
   isFetchNewConversationSelector,
   isFetchConversationByFilterSelector,
   isEmptyconversationByFilterSelector,
   ///////////////////////////
   isFetchConversationMessagesSelector,
   conversationMessagesSelector,
   isEmptyConversationMessagesSelector,
   isFetchNewConversationMessagesSelector,
   ////////////////////////
   activeConverstaionSelector,
   ///////////////////////
   likedMemberByMessageSelector,
   ///////////////////////
   chatByUserSelector,
   isOpenRightContentSelector,
   descktopChatListSelector,
   descktopUserListSelector,
   showMiniChatSelector,
   openDesctopDialogueSelector,
   isSocketConnectedSelector,

   initedConverstionMessagesSelector,
   unreadMessagesCountSelector,
   onlineUsersSelector,

   memberByUuidSelector,
   soketSelector,
   initedConversationSelector,
   unreadPrivateMessagesCountSelector,
   onlineMembersSelector,

   openDkChatNotificationSelector,
   fetchingConversationIdsSelector,
   conversationsFilterSelector,
   isTurnOnPrivateChatSelector,
   memberVideoDownloadMultipleWatermarksSelector,
}
