
const HOMEPAGE = 'HOMEPAGE';
const SIGN_IN = 'SIGN_IN';
const SIGN_IN_NOT_RECOGNIZED = 'SIGN_IN_NOT_RECOGNIZED';
const SIGN_IN_EMAIL = 'SIGN_IN_EMAIL';
const PASSWORD_RESET = 'PASSWORD_RESET';
const PASSWORD_RESET_TOKEN = 'PASSWORD_RESET_TOKEN';
const SIGN_UP = 'SIGN_UP';
const SIGN_UP_EMAIL = 'SIGN_UP_EMAIL';
const EMAIL_VERIFICATION = 'EMAIL_VERIFICATION';
const STORIES = 'STORIES';
const STORIES_COMMENTS = 'STORIES_COMMENTS';
const STORIES_SHOW = 'STORIES_SHOW';
const MEMBER_SUPPORT = 'MEMBER_SUPPORT';

const PHOTOSETS = 'PHOTOSETS';
const PHOTOSETS_INDIVIDUAL = 'PHOTOSETS_INDIVIDUAL';
const VIDEOS = 'VIDEOS';
const VIDEOS_INDIVIDUAL = 'VIDEOS_INDIVIDUAL';
const VIDEOS_GALLERIES = 'VIDEOS_GALLERIES';

const MY_ACCOUNT = 'MY_ACCOUNT';
const MY_ACCOUNT_CHANGE_PASSWORD = 'MY_ACCOUNT_CHANGE_PASSWORD';
const MY_ACCOUNT_CHANGE_EMAIL = 'MY_ACCOUNT_CHANGE_EMAIL';
const MY_ACCOUNT_MEMBERSHIP_DETAILS = 'MY_ACCOUNT_MEMBERSHIP_DETAILS';
const MY_ACCOUNT_CHANGE_MEMBERSHIP = 'MY_ACCOUNT_CHANGE_MEMBERSHIP';
const MY_ACCOUNT_PAYMENT_INFORMATION = 'MY_ACCOUNT_PAYMENT_INFORMATION';
const MY_ACCOUNT_MEMBERSHIP_CANCELLATION = 'MY_ACCOUNT_MEMBERSHIP_CANCELLATION';
const MY_ACCOUNT_MEMBERSHIP_CANCELLED = 'MY_ACCOUNT_MEMBERSHIP_CANCELLED';
const MY_ACCOUNT_DELETE = 'MY_ACCOUNT_DELETE';
const MY_ACCOUNT_NOTIFICATION_PREFERENCES = 'MY_ACCOUNT_NOTIFICATION_PREFERENCES';

const JOIN = 'JOIN';
const JOIN_OLD = 'JOIN_OLD';
const PAYMENT_DECLINED = 'PAYMENT_DECLINED';
const JOIN_TERMS_OF_USE = 'JOIN_TERMS_OF_USE';
const PAYMENT_APPROVED = 'PAYMENT_APPROVED';
const NOT_FOUND = 'NOT_FOUND';
const FEED = 'FEED';
const COMING_SOON = 'COMING_SOON';
const BYPASS = 'BYPASS';
const CONTACT_FORM = 'CONTACT_FORM';
const CONTACT_US = 'CONTACT_US';

const STORE = 'STORE';
const STORE_DETAILS = 'STORE_DETAILS';
const MY_ORDERS = 'MY_ORDERS';
const ORDER_DETAILS = 'ORDER_DETAILS'

const MY_DOWNLOADS = 'MY_DOWNLOADS';
const MY_UNLOCKS = 'MY_UNLOCKS';
const MY_MEDIA = 'MY_MEDIA'
const PURCHASE_MEMBERSHIP = 'PURCHASE_MEMBERSHIP';
const PURCHASE_VIDEO = 'PURCHASE_VIDEO';
const PURCHASE_PHOTOSET = 'PURCHASE_PHOTOSET';
const PURCHASE_STORE = 'PURCHASE_STORE';
const PURCHASE_WALLET = 'PURCHASE_WALLET';
const PURCHASE_TIP = 'PURCHASE_TIP';
const PURCHASE_VIDEO_UNLOCK = 'PURCHASE_VIDEO_UNLOCK';
const PURCHASE_PHOTO_UNLOCK = 'PURCHASE_PHOTO_UNLOCK';
const PURCHASE_VOICE_UNLOCK = 'PURCHASE_VOICE_UNLOCK';
const PURCHASE_LIVE_STREAM_RECORDS = 'PURCHASE_LIVE_STREAM_RECORDS';
const PURCHASE_LIVE_STREAM = 'PURCHASE_LIVE_STREAM';
const PURCHASE_CHAT_PAY_PER_MESSAGE = 'PURCHASE_CHAT_PAY_PER_MESSAGE';
const PURCHASE_MULTIPLE_ATTACHMENT = 'PURCHASE_MULTIPLE_ATTACHMENT';
const WALLET = 'WALLET';
const TIP = 'TIP';
const CUSTOM_PAGE = 'CUSTOM_PAGE';

const ERROR_PAGE = 'ERROR_PAGE';
const MEMBER_UNSUBSCRIBE = 'MEMBER_UNSUBSCRIBE';
const MAILING_LIST_UNSUBSCRIBE = 'MAILING_LIST_UNSUBSCRIBE';
const LIVE_STREAM_STUDIO = 'LIVE_STREAM_STUDIO'
const LIVE_STREAM_RECORDS_STREAM = 'LIVE_STREAM_RECORDS_STREAM'
const LIVE_STREAM_RECORD = 'LIVE_STREAM_RECORD'
const CHAT_UNLCOKED_CONTENT = 'CHAT_UNLCOKED_CONTENT';
const BUNDLE_VAULT = 'BUNDLE_VAULT';

const PROMOTION = 'PROMOTION';

const AFFILIATES_DETAILS = 'AFFILIATES_DETAILS';

const AUTO_TWEET_NOTIFICATION = 'AUTO_TWEET_NOTIFICATION';
const TIP_AUTO_TWEET_NOTIFICATION = 'TIP_AUTO_TWEET_NOTIFICATION';
const NEW_JOIN_AUTO_TWEET_NOTIFICATION = 'NEW_JOIN_AUTO_TWEET_NOTIFICATION';
const NOT_FOUND_PAGE = 'NOT_FOUND_PAGE';

const AGE_VERIFICATION = 'AGE_VERIFICATION';
const AGE_VERIFICATION_PROCESS = "AGE_VERIFICATION_PROCESS"

const MY_FAVORITES = "MY_FAVORITES"

const DIGITAL_DOWNLOADABLE_ITEM_DETAILS = "DIGITAL_DOWNLOADABLE_ITEM_DETAILS"

const SIGN_IN_WITH = "SIGN_IN_WITH"

const NEWSLETTER = "NEWSLETTER"

// Cast members
const CAST_MEMBERS = 'CAST_MEMBERS';
const CAST_MEMBER_PROFILE = 'CAST_MEMBER_PROFILE';

const getRoutes = (prefix = '') => {
   let homepageRoute = prefix + '';

   if('/' !== homepageRoute && '/' === homepageRoute[homepageRoute.length - 1]) {
      homepageRoute = homepageRoute.slice(0, -1);
   }
   let routes =  {
      [HOMEPAGE]: {
         path: homepageRoute,
         exact: true,
         topLevel: true,
         title: 'Home',
         siteMap: {
            url: homepageRoute,
            priority: 1.0,
         },
      },

      [SIGN_IN]: {
         path: prefix + 'sign-in',
         exact: true,
         topLevel: true,
         pageTitleSuffix: 'Sign in',
         siteMap: {
            url: prefix + '/sign-in',
            priority: 0.3,
         },
      },
      [SIGN_IN_EMAIL]: {
         path: prefix + 'sign-in-email',
         exact: true,
         topLevel: true,
         pageTitleSuffix: 'Sign in with email',
         siteMap: null,
      },
      [SIGN_IN_NOT_RECOGNIZED]: {
         path: prefix + 'sign-in/not-recognized',
         exact: true,
         topLevel: false,
         parentKey: SIGN_IN,
         pageTitleSuffix: 'Not recognized',
         siteMap: null,
      },
      [SIGN_UP]: {
         path: prefix + 'register',
         exact: true,
         topLevel: false,
         parentKey: SIGN_IN,
         title: 'Create account',
         pageTitleSuffix: 'Create account',
         siteMap: {
            url: prefix + '/register',
            priority: 0.3,
         },
      },
      [SIGN_UP_EMAIL]: {
         path: prefix + 'register-email',
         exact: true,
         topLevel: true,
         parentKey: SIGN_UP,
         title: 'Create account with email',
         pageTitleSuffix: 'Create account with email',
         siteMap: {
            url: prefix + '/register-email',
            priority: 0.3,
         },
      },
      [PASSWORD_RESET_TOKEN]: {
         path: prefix + 'password-reset/:token',
         exact: true,
         topLevel: true,
         pageTitleSuffix: 'Choose a new password',
         siteMap: null,
      },
      [PASSWORD_RESET]: {
         path: prefix + 'password-reset',
         exact: true,
         topLevel: false,
         title: 'Reset your password',
         parentKey: SIGN_IN,
         pageTitleSuffix: 'Reset your password',
         siteMap: {
            url: prefix + '/password-reset',
            priority: 0.3,
         },
      },
      [EMAIL_VERIFICATION]: {
         path: prefix + 'register/email-confirmation',
         exact: true,
         topLevel: false,
         parentKey: SIGN_UP,
         title: 'Email verification',
         pageTitleSuffix: 'Email verification',
         siteMap: {
            url: prefix + '/register/email-confirmation',
            priority: 0.3,
         },
      },
      [STORIES]: {
         path: prefix + 'stories',
         exact: true,
         title: 'Stories',
         topLevel: true,
         pageTitleSuffix: 'Stories',
         siteMap: {
            url: prefix + '/stories',
            priority: 1.0,
         },
      },
      [STORIES_COMMENTS]: {
         path: prefix + 'stories/:id/comments',
         exact: true,
         title: 'Comments',
         topLevel: false,
         parentKey: STORIES,
         pageTitleSuffix: 'Comments',
         siteMap: null,
      },
      [PHOTOSETS]: {
         path: prefix + 'photosets',
         exact: true,
         title: '',
         topLevel: true,
         pageTitleSuffix: 'Photosets',
         siteMap: {
            url: prefix + '/photosets',
            priority: 1.0,
         },
      },
      [PHOTOSETS_INDIVIDUAL]: {
         path: prefix + 'photosets/:id',
         exact: true,
         title: 'View photoset',
         topLevel: false,
         parentKey: PHOTOSETS,
         pageTitleSuffix: 'Photosets',
         siteMap: null,
      },
      [VIDEOS]: {
         path: prefix + 'videos',
         exact: true,
         title: '',
         topLevel: true,
         pageTitleSuffix: 'Videos',
         siteMap: {
            url: prefix + '/videos',
            priority: 1.0,
         },
      },
      [VIDEOS_INDIVIDUAL]: {
         path: prefix + 'videos/:id',
         exact: true,
         title: 'Watch',
         topLevel: false,
         parentKey: VIDEOS,
         pageTitleSuffix: 'Videos',
         siteMap: null,
      },
      [VIDEOS_GALLERIES]: {
         path: prefix + 'video-galleries',
         topLevel: true,
         exact: true,
         pageTitleSuffix: 'Videos',
         siteMap: null,
      },
      [STORIES_SHOW]: {
         path: prefix + 'stories/:id',
         exact: true,
         title: 'Story show',
         topLevel: false,
         parentKey: STORIES,
         pageTitleSuffix: 'Stories',
         siteMap: null,
      },
      [MEMBER_SUPPORT]: {
         path: prefix + 'member-support',
         exact: true,
         title: 'Support',
         topLevel: true,
         parentKey: MEMBER_SUPPORT,
         pageTitleSuffix: 'Member support',
         siteMap: {
            url: prefix + '/member-support',
            priority: 0.0,
         },
      },
      [MY_ACCOUNT]: {
         path: prefix + 'my-account',
         exact: true,
         title: 'My account',
         topLevel: true,
         pageTitleSuffix: 'My account',
         siteMap: {
            url: prefix + '/my-account',
            priority: 0.5,
         },
      },
      [MY_ACCOUNT_CHANGE_EMAIL]: {
         path: prefix + 'my-account/change-email',
         exact: true,
         title: 'Email',
         parentKey: MY_ACCOUNT,
         topLevel: false,
         pageTitleSuffix: 'Change email',
         siteMap: {
            url: prefix + '/my-account/change-email',
            priority: 0.2,
         },
      },
      [MY_ACCOUNT_CHANGE_PASSWORD]: {
         path: prefix + 'my-account/change-password',
         exact: true,
         title: 'Change password',
         parentKey: MY_ACCOUNT,
         topLevel: false,
         pageTitleSuffix: 'Change password',
         siteMap: {
            url: prefix + '/my-account/change-password',
            priority: 0.2,
         },
      },
      [MY_ACCOUNT_MEMBERSHIP_DETAILS]: {
         path: prefix + 'my-account/membership-details',
         exact: true,
         title: 'My membership',
         parentKey: MY_ACCOUNT,
         topLevel: false,
         pageTitleSuffix: 'My membership',
         siteMap: {
            url: prefix + '/my-account/membership-details',
            priority: 0.2,
         },
      },
      [MY_ACCOUNT_CHANGE_MEMBERSHIP]: {
         path: prefix + 'my-account/my-membership/upgrade-membership',
         exact: true,
         title: 'Upgrade membership',
         parentKey: MY_ACCOUNT_MEMBERSHIP_DETAILS,
         topLevel: false,
         pageTitleSuffix: 'Upgrade membership',
         siteMap: {
            url: prefix + '/my-account/my-membership/upgrade-membership',
            priority: 0.0,
         },
      },
      [MY_ACCOUNT_PAYMENT_INFORMATION]: {
         path: prefix + 'my-account/my-membership/change-membership/:id',
         exact: true,
         title: 'Payment information',
         parentKey: MY_ACCOUNT_CHANGE_MEMBERSHIP,
         topLevel: false,
         pageTitleSuffix: 'Change membership',
         siteMap: null,
      },
      [MY_ACCOUNT_MEMBERSHIP_CANCELLATION]: {
         path: prefix + 'my-account/membership-cancellation',
         exact: true,
         title: 'Cancel membership',
         parentKey: MY_ACCOUNT_MEMBERSHIP_DETAILS,
         topLevel: false,
         pageTitleSuffix: 'Cancel membership',
         siteMap: {
            url: prefix + '/my-account/membership-cancellation',
            priority: 0.0,
         },
      },
      [MY_ACCOUNT_MEMBERSHIP_CANCELLED]: {
         path: prefix + 'my-account/membership-cancelled',
         exact: true,
         title: 'Membership cancelled',
         parentKey: MY_ACCOUNT_MEMBERSHIP_DETAILS,
         topLevel: false,
         pageTitleSuffix: 'Membership cancelled',
         siteMap: null,
      },
      [MY_ACCOUNT_DELETE]: {
         path: prefix + 'my-account/delete',
         exact: true,
         title: 'Delete my account',
         parentKey: MY_ACCOUNT,
         topLevel: false,
         pageTitleSuffix: 'Delete my account',
         siteMap: null,
      },
      [MY_ACCOUNT_NOTIFICATION_PREFERENCES]: {
         path: prefix + 'my-account/notification-preferences',
         exact: true,
         title: 'Notification preferences',
         parentKey: MY_ACCOUNT,
         topLevel: false,
         pageTitleSuffix: 'Notification preferences',
         siteMap: null,
      },
      [JOIN_OLD]: {
         path: prefix + 'join',
         exact: true,
         pageTitleSuffix: 'Join',
         siteMap: null,
      },
      [JOIN]: {
         path: prefix + 'memberships',
         exact: true,
         title: 'Choose membership',
         topLevel: false,
         parentKey: HOMEPAGE,
         pageTitleSuffix: 'Memberships',
         siteMap: {
            url: prefix + '/memberships',
            priority: 0.5,
         },
      },
      [PAYMENT_DECLINED]: {
         path: prefix + 'payment-declined',
         exact: true,
         title: 'Declined',
         parentKey: JOIN,
         topLevel: false,
         cancel: true,
         pageTitleSuffix: 'Payment declined',
         siteMap: null,
      },
      [JOIN_TERMS_OF_USE]: {
         path: prefix + 'memberships/terms-of-use',
         exact: true,
         title: 'Terms of Use',
         parentKey: PURCHASE_MEMBERSHIP,
         topLevel: false,
         pageTitleSuffix: 'Terms of Use',
         siteMap: null,
      },
      [PAYMENT_APPROVED]: {
         path: prefix + 'payment-approved',
         exact: false,
         topLevel: true,
         pageTitleSuffix: 'Payment approved',
         siteMap: null,
      },
      [NOT_FOUND]: {
         path: prefix + 'not-found',
         topLevel: true,
         pageTitleSuffix: 'Not found',
         siteMap: null,
      },
      [NOT_FOUND_PAGE]: {
         path: prefix + '404-error',
         topLevel: true,
         pageTitleSuffix: '404 error',
         siteMap: null,
      },
      [FEED]: {
         path: prefix + 'feed',
         topLevel: true,
         exact: true,
         pageTitleSuffix: 'Feed',
         siteMap: null,
      },
      [COMING_SOON]: {
         path: prefix + 'coming-soon',
         pageTitleSuffix: 'Coming soon',
         siteMap: {
            url: prefix + '/coming-soon',
            priority: 0.5,
         },
      },
      [BYPASS]: {
         path: prefix + 'bypass/:token',
         topLevel: true,
         exact: true,
         siteMap: null,
      },
      [STORE]: {
         path: prefix + 'store',
         topLevel: true,
         exact: true,
         title: '',
         pageTitleSuffix: 'Store',
         siteMap: {
            url: prefix + '/store',
            priority: 1.0,
         },
      },
      [STORE_DETAILS]: {
         path: prefix + 'store/details/:id',
         topLevel: false,
         exact: true,
         title: 'Store details',
         parentKey: STORE,
         pageTitleSuffix: 'Store',
         siteMap: null,
      },
      [MY_ORDERS]: {
         path: prefix + 'store/my-orders',
         topLevel: false,
         exact: true,
         title: 'My orders',
         parentKey: STORE,
         pageTitleSuffix: 'My orders',
         siteMap: {
            url: prefix + '/store/my-orders',
            priority: 0.5,
         },
      },
      [ORDER_DETAILS]: {
         path: prefix + 'store/my-orders/:id',
         topLevel: false,
         exact: true,
         title: 'Order details',
         parentKey: MY_ORDERS,
         pageTitleSuffix: 'My orders',
         siteMap: null,
      },
      [CONTACT_FORM]: {
         path: prefix + 'contact-form',
         topLevel: true,
         exact: true,
         title: 'Contact form',
         pageTitleSuffix: 'Contact form',
         siteMap: {
            url: prefix + '/contact-form',
            priority: 0.0,
         },
      },
      [CONTACT_US]: {
         path: prefix + 'contact-us',
         topLevel: true,
         exact: true,
         title: 'Contact us',
         pageTitleSuffix: 'Contact us',
         siteMap: {
            url: prefix + '/contact-us',
            priority: 0.0,
         },
      },
      [MY_UNLOCKS]: {
         path: prefix + 'my-unlocks',
         exact: true,
         topLevel: true,
         pageTitleSuffix: 'My unlocks',
         siteMap: {
            url: prefix + '/my-unlocks',
            priority: 0.3,
         },
      },
      [MY_MEDIA]: {
         path: prefix + 'my-unlocks',
         exact: true,
         topLevel: true,
         pageTitleSuffix: 'My unlocks',
         siteMap: null,
      },
      [MY_DOWNLOADS]: {
         path: prefix + 'my-unlocks/downloads',
         exact: false,
         topLevel: true,
         pageTitleSuffix: 'My downloads',
         siteMap: null,
      },
      [PURCHASE_MEMBERSHIP]: {
         path: prefix + 'purchase/membership/:id',
         topLevel: false,
         exact: true,
         parentKey: JOIN,
         title: 'Payment information',
         pageTitleSuffix: 'Join',
         siteMap: null,
      },
      [PURCHASE_VIDEO]: {
         path: prefix + 'purchase/video/:id',
         topLevel: false,
         exact: true,
         parentKey: VIDEOS,
         title: 'Payment information',
         pageTitleSuffix: 'Video',
         siteMap: null,
      },
      [PURCHASE_LIVE_STREAM_RECORDS]: {
         path: prefix + 'purchase/record/:id',
         topLevel: false,
         exact: true,
         parentKey: LIVE_STREAM_STUDIO,
         title: 'Payment information',
         pageTitleSuffix: 'Recorded stream',
         siteMap: null,
      },
      [PURCHASE_CHAT_PAY_PER_MESSAGE]: {
         path: prefix + 'purchase/pay-per-message/:id',
         topLevel: false,
         exact: true,
         parentKey: HOMEPAGE,
         title: 'Payment information',
         pageTitleSuffix: 'PPM',
         siteMap: null,
      },
      [PURCHASE_PHOTOSET]: {
         path: prefix + 'purchase/photoset/:id',
         topLevel: false,
         exact: true,
         parentKey: PHOTOSETS,
         title: 'Payment information',
         pageTitleSuffix: 'Photoset',
         siteMap: null,
      },
      [PURCHASE_STORE]: {
         path: prefix + 'purchase/store/:id',
         topLevel: false,
         exact: true,
         parentKey: STORE,
         title: 'Payment information',
         pageTitleSuffix: 'Store item',
         siteMap: null,
      },
      [WALLET]: {
         path: prefix + 'my-wallet',
         topLevel: true,
         exact: true,
         title: 'My wallet',
         pageTitleSuffix: 'My wallet',
         siteMap: {
            url: prefix + '/my-wallet',
            priority: 0.3,
         },
      },
      [PURCHASE_WALLET]: {
         path: prefix + 'purchase/wallet/:id',
         topLevel: false,
         exact: true,
         parentKey: WALLET,
         title: 'Payment information',
         pageTitleSuffix: 'Wallet',
         siteMap: null,
      },
      [TIP]: {
         path: prefix + 'tip',
         topLevel: true,
         exact: true,
         title: 'Tip',
         pageTitleSuffix: 'Tip',
         siteMap: null,
      },
      [PURCHASE_TIP]: {
         path: prefix + 'purchase/tip/:id',
         topLevel: false,
         exact: true,
         parentKey: HOMEPAGE,
         title: 'Payment information',
         pageTitleSuffix: 'Tip',
         siteMap: null,
      },
      [PURCHASE_PHOTO_UNLOCK]: {
         path: prefix + 'purchase/photo-unlock/:id',
         topLevel: false,
         exact: true,
         parentKey: HOMEPAGE,
         title: 'Payment information',
         pageTitleSuffix: 'Photo',
         siteMap: null,
      },
      [PURCHASE_VOICE_UNLOCK]: {
         path: prefix + 'purchase/voice-unlock/:id',
         topLevel: false,
         exact: true,
         parentKey: HOMEPAGE,
         title: 'Payment information',
         pageTitleSuffix: 'Voice',
         siteMap: null,
      },
      [PURCHASE_VIDEO_UNLOCK]: {
         path: prefix + 'purchase/video-unlock/:id',
         topLevel: false,
         exact: true,
         parentKey: HOMEPAGE,
         title: 'Payment information',
         pageTitleSuffix: 'Video',
         siteMap: null,
      },
      [PURCHASE_LIVE_STREAM]: {
         path: prefix + 'purchase/live-stream/:id',
         topLevel: false,
         exact: true,
         parentKey: HOMEPAGE,
         title: 'Payment information',
         pageTitleSuffix: 'Livestream',
         siteMap: null,
      },
      [CUSTOM_PAGE]: {
         path: prefix + 'custom-pages/:id',
         exact: true,
         title: 'Custom page',
         topLevel: true,
         pageTitleSuffix: '',
         siteMap: null,
      },
      [ERROR_PAGE]: {
         path: prefix + 'error',
         exact: true,
         topLevel: true,
         pageTitleSuffix: 'Error',
         siteMap: null,
      },
      [MEMBER_UNSUBSCRIBE]: {
         path: prefix + 'mu/:uuid',
         exact: true,
         topLevel: true,
         siteMap: null,
      },
      [MAILING_LIST_UNSUBSCRIBE]: {
         path: prefix + 'mlu/:email',
         exact: true,
         topLevel: true,
         siteMap: null,
      },
      [LIVE_STREAM_STUDIO]: {
         path: prefix + 'livestream',
         exact: true,
         title: 'Livestream',
         topLevel: true,
         pageTitleSuffix: 'Livestream',
         siteMap: {
            url: prefix + '/livestream',
            priority: 1.0,
         },
      },
      [LIVE_STREAM_RECORDS_STREAM]: {
         path: prefix + 'recorded-streams',
         exact: true,
         title: 'Recorded streams',
         topLevel: true,
         pageTitleSuffix: 'Recorded streams',
         siteMap: null,
      },
      [LIVE_STREAM_RECORD]: {
         path: prefix + 'recorded-streams/:id',
         exact: true,
         title: 'Stream',
         topLevel: false,
         parentKey: LIVE_STREAM_RECORDS_STREAM,
         pageTitleSuffix: 'Recorded streams',
         siteMap: null,
      },
      [CHAT_UNLCOKED_CONTENT]: {
         path: prefix + 'chat-unlock-content/:id',
         topLevel: true,
         exact: true,
         parentKey: HOMEPAGE,
         pageTitleSuffix: 'Content',
         siteMap: null,
      },
      [BUNDLE_VAULT]: {
         path: prefix + 'bundle-vault/:id',
         topLevel: true,
         exact: true,
         parentKey: HOMEPAGE,
         pageTitleSuffix: 'Bundle vault',
         siteMap: null,
      },
      [PROMOTION]: {
         path: prefix + 'promotions/:id',
         topLevel: true,
         exact: true,
         title: 'Promotion',
         pageTitleSuffix: 'Promotions',
         siteMap: null,
      },
      [AFFILIATES_DETAILS]: {
         path: prefix + 'affiliates',
         topLevel: true,
         exact: true,
         title: 'Affiliates',
         pageTitleSuffix: 'Affiliates',
         siteMap: {
            url: prefix + '/affiliates',
            priority: 1.0,
         },
      },
      [AUTO_TWEET_NOTIFICATION]: {
         path: prefix + 'auto-posts-notification/:id',
         topLevel: true,
         exact: true,
         title: 'Auto post notification',
         pageTitleSuffix: 'Auto post notification',
         siteMap: null,
      },
      [TIP_AUTO_TWEET_NOTIFICATION]: {
         path: prefix + 'tip-post',
         topLevel: true,
         exact: true,
         title: 'Tip',
         pageTitleSuffix: 'Tip',
         siteMap: null,
      },
      [NEW_JOIN_AUTO_TWEET_NOTIFICATION]: {
         path: prefix + 'new-join-post',
         topLevel: true,
         exact: true,
         title: 'New join',
         pageTitleSuffix: 'New join',
         siteMap: null,
      },
      [AGE_VERIFICATION]: {
         path: prefix + 'age-verification',
         topLevel: true,
         exact: true,
         title: 'Age Verification',
         pageTitleSuffix: 'Age Verification',
         siteMap: {
            url: prefix + '/age-verification',
            priority: 0.0,
         },
      },
      [AGE_VERIFICATION_PROCESS]: {
         path: prefix + 'age-verification/process',
         topLevel: true,
         exact: true,
         title: 'Age Verification',
         pageTitleSuffix: 'Age Verification',
         siteMap: {
            url: prefix + '/age-verification/process',
            priority: 0.0,
         },
      },
      [MY_FAVORITES]: {
         path: prefix + 'my-favorites',
         topLevel: true,
         exact: true,
         title: 'My favorites',
         pageTitleSuffix: 'My favorites',
         siteMap: {
            url: prefix + '/my-favorites',
            priority: 0.5,
         },
      },
      [DIGITAL_DOWNLOADABLE_ITEM_DETAILS]: {
         path: prefix + 'digital-downloadabel-item-details/:id',
         topLevel: true,
         exact: true,
         siteMap: null,
      },
      [SIGN_IN_WITH]: {
         path: prefix + 'sign-in-with',
         topLevel: true,
         exact: true,
         siteMap: null,
      },
      [PURCHASE_MULTIPLE_ATTACHMENT]: {
         path: prefix + 'purchase/multiple-attachment/:id',
         topLevel: false,
         exact: true,
         parentKey: HOMEPAGE,
         title: 'Payment information',
         pageTitleSuffix: 'Payment information',
         siteMap: null,
      },

      [NEWSLETTER]: {
         path: prefix + 'newsletter',
         topLevel: true,
         exact: true,
         siteMap: null,
      },
      [CAST_MEMBERS]: {
         path: prefix + 'models',
         exact: true,
         title: 'Models',
         topLevel: true,
         pageTitleSuffix: 'Models',
         siteMap: null,
      },
      [CAST_MEMBER_PROFILE]: {
         path: prefix + 'models/:id',
         topLevel: false,
         exact: true,
         parentKey: CAST_MEMBERS,
         title: 'Model profile',
         pageTitleSuffix: 'Models',
         siteMap: null,
      },
   };

   return routes
}


export { getRoutes }
