export const FETCH_DETAILS = "settings/FETCH_DETAILS";
export const FETCH_DETAILS_COMPLETED = "settings/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "settings/FETCH_DETAILS_FAILED";

export const FETCH_MY_SITE_DETAILS = "settings/FETCH_MY_SITE_DETAILS";
export const FETCH_MY_SITE_DETAILS_COMPLETED = "settings/FETCH_MY_SITE_DETAILS_COMPLETED";
export const FETCH_MY_SITE_DETAILS_FAILED = "settings/FETCH_MY_SITE_DETAILS_FAILED";


export const FETCH_MY_SITE_HEADER = "settings/FETCH_MY_SITE_HEADER";
export const FETCH_MY_SITE_HEADER_COMPLETED = "settings/FETCH_MY_SITE_HEADER_COMPLETED";
export const FETCH_MY_SITE_HEADER_FAILED = "settings/FETCH_MY_SITE_HEADER_FAILED";

export const UPDATE_MY_SITE_HEADER = "settings/UPDATE_MY_SITE_HEADER";
export const UPDATE_MY_SITE_HEADER_COMPLETED = "settings/UPDATE_MY_SITE_HEADER_COMPLETED";
export const UPDATE_MY_SITE_HEADER_FAILED = "settings/UPDATE_MY_SITE_HEADER_FAILED";

// about me
export const FETCH_ABOUT_ME_DETAILS_START = 'mySite/FETCH_ABOUT_ME_DETAILS_START';
export const FETCH_ABOUT_ME_DETAILS_COMPLETED = 'mySite/FETCH_ABOUT_ME_DETAILS_COMPLETED';
export const UPDATE_ABOUT_ME_DETAILS_START = 'mySite/UPDATE_ABOUT_ME_DETAILS_START';
export const UPDATE_ABOUT_ME_DETAILS_COMPLETED = 'mySite/UPDATE_ABOUT_ME_DETAILS_COMPLETED';

/////////////////////////////////////////////////////////////////////////////////////////
export const UPDATE_SETTINGS_COMPLETED = "settings/UPDATE_SETTINGS_COMPLETED";

export const SET_SETTINGS_MODAL = "settings/SET_SETTINGS_MODAL";

export const FETCH_CONTENT_CARDS_OPTIONS = "settings/FETCH_CONTENT_CARDS_OPTIONS";

export const UPDATE_CONTENT_CARDS_OPTIONS = "settings/UPDATE_CONTENT_CARDS_OPTIONS";

export const UPDATE_STATE_CONTENT_CARDS = "settings/UPDATE_STATE_CONTENT_CARDS";

export const SET_SETTINGS_MODAL_BY_ID = "settings/SET_SETTINGS_MODAL_BY_ID";

export const FETCH_TRACKING_CODE_START = "settings/FETCH_TRACKING_CODE_START";
export const FETCH_TRACKING_CODE_COMPLETED = "settings/FETCH_TRACKING_CODE_COMPLETED";

export const FETCH_SEO_START = "settings/FETCH_SEO_START";
export const FETCH_SEO_COMPLETED = "settings/FETCH_SEO_COMPLETED";
export const UPDATE_SEO_START = "settings/UPDATE_SEO_START";
export const UPDATE_SEO_COMPLETED = "settings/UPDATE_SEO_COMPLETED";
export const UPDATE_SEO_FAILED = "settings/UPDATE_SEO_FAILED";

// Content categories
export const INIT_CONTENT_CATEGORIES_START = "settings/INIT_CONTENT_CATEGORIES_START"
export const INIT_CONTENT_CATEGORIES_COMPLETED = "settings/INIT_CONTENT_CATEGORIES_COMPLETED"
export const INIT_CONTENT_CATEGORIES_FAILED = "settings/INIT_CONTENT_CATEGORIES_FAILED"
export const SET_IS_FETCHING_GET_ALL_CATEGORIES = "settings/SET_IS_FETCHING_GET_ALL_CATEGORIES"
export const GET_ALL_CONTENT_CATEGORIES_COMPLETED = "settings/GET_ALL_CONTENT_CATEGORIES_COMPLETED"
export const TOGGLE_FETCHING_ADD_REMOVE_CATEGORY_START = "settings/TOGGLE_FETCHING_ADD_REMOVE_CATEGORY_START"
export const TOGGLE_ADD_REMOVE_CATEGORY_COMPLETED = "settings/TOGGLE_ADD_REMOVE_CATEGORY_COMPLETED"
export const SET_ORIGINAL_CONTENT_STORAGE_STATE = "settings/SET_ORIGINAL_CONTENT_STORAGE_STATE"

export const SET_VIDEO_SECURITY_STATE = "settings/SET_VIDEO_SECURITY_STATE"
