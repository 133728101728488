import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { screenWidthSelector, siteInfoSelector, viewAsModalSelector } from 'admin/state/modules/common/selectors';
import { setViewAsModal } from 'admin/state/modules/common/actions';
import { checkAndUnlockDemoPublicSiteOperation, loginAsOperation } from 'admin/state/modules/common/operations';
import ViewAsModal from 'common/components/modules/view-as';
// import Button from 'common/components/elements/buttons/primary';
import QueryParams from 'admin/utils/QueryParams';
class ViewAsContainer extends Component {
   static propTypes = {
      siteInfo: PropTypes.object.isRequired,
      screenWidth: PropTypes.number,
      loginAs: PropTypes.func,
      setViewAsModal: PropTypes.func,
      viewAsModal: PropTypes.object,
      checkAndUnlockDemoPublicSite: PropTypes.func,
   }

   componentDidMount(){
      const { setViewAsModal } = this.props;
      const modalType = QueryParams.getParam('open-modal');
      if('view-as' === modalType)  {
         setViewAsModal(true);
      } else {
         setViewAsModal(false);
      }
   }

   onAddCloseModal = bool => {
      const { setViewAsModal } = this.props;

      if(bool) {
         QueryParams.addQueryParam('open-modal', 'view-as');
      } else {
         QueryParams.removeQueryParam('open-modal');
      }
      setViewAsModal(bool)
   }

   goToView = async (data) => {
      const { viewAsModal, siteInfo, loginAs, checkAndUnlockDemoPublicSite } = this.props
      checkAndUnlockDemoPublicSite(() => {
         loginAs(data, siteInfo.site_url, viewAsModal.url)
         QueryParams.removeQueryParam('open-modal');
      })
   }

   render(){
      const {
         siteInfo,
         screenWidth,
         // loginAs,
         viewAsModal,
      } = this.props;
      return (
         <>
            {/* <div className='h-9 border border-divider' style={ {
               borderRadius: '8px',
            } }>
               <Button
                  text='View my site'
                  classNames='h-full px-3.5'
                  padding='none'
                  iconName='views'
                  iconPosition='left'
                  fontIconSize='extraLarge'
                  fontIconColor='secondary'
                  backgroundColor='grey-main'
                  textColor='secondary'
                  borderRadius='large'
                  textSize='base'
                  waveColor='rgba(33, 33, 33, 0.8)'
                  onClick={ () => this.onAddCloseModal(true) }
                  textClassName='whitespace-nowrap'
               />
            </div> */}
            {
               viewAsModal.isOpen && (
                  <ViewAsModal
                     onCloseModal={ () => this.onAddCloseModal(false) }
                     pricings={ siteInfo.pricings }
                     goToView={ this.goToView }
                     isMobile={ screenWidth <= 1024 }
                     isShowVisitor={ true }
                  />
               )
            }
         </>
      );
   }

}

const mapStateToProps = (state) => {
   return {
      screenWidth: screenWidthSelector(state),
      siteInfo: siteInfoSelector(state),
      viewAsModal: viewAsModalSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      setViewAsModal: (bool) => {
         dispatch(setViewAsModal(bool));
      },
      loginAs: (data, url, pageUrl) => {
         dispatch(loginAsOperation(data, url, pageUrl));
      },
      checkAndUnlockDemoPublicSite: (callback) => dispatch(checkAndUnlockDemoPublicSiteOperation(callback)),
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(ViewAsContainer);
