import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import NotificationMemberAvatar from 'common/components/modules/notifications/modules/notification-avatar';
import NotificationChip from 'common/components/modules/notifications/modules/chip';
import NotificationMedia from 'common/components/modules/notifications/modules/media';
import Button from 'common/components/elements/buttons/primary';
import GenerateColor from 'common/utils/generateTextColor.js'
import DateFormatter from 'common/utils/DateFormatter';
import NotificationCommentAction from 'common/components/modules/notifications/modules/comment-action';
import AlreadyRepliedContent from 'common/components/modules/notifications/modules/already-replied';
import RepliedContent from 'common/components/modules/notifications/modules/reply';
// import TipNotification from 'common/components/modules/notifications/modules/content/tip';
// import WalletNotification from 'common/components/modules/notifications/modules/content/wallet';
// import MembershipNotification from 'common/components/modules/notifications/modules/content/membership';
import Icon from 'common/components/elements/icons';
import toast from "common/utils/toast";
import { getTopPercentage, getMemberTotalSpent } from "common/utils/utils";
import TruncateText from 'common/components/elements/truncate-text';
import Waveform from 'common/components/modules/audio-player';

import './style.scss';

const NotificationItems = ({
   notification,
   isAdmin,
   onClickCard,
   onClickLike,
   onClickReply,
   avatars,
   isMobile,
   replyNotification,
   sendMessage,
   cancelReplyMessage,
   userAvatar,
   goToResourceOrMemberPage,
   goToOrderDetails,
}) => {
   const itemRef = useRef();
   const [isShow, setIsShow] = useState(true);
   const [isShowButton, setIsShowButton] = useState(false);

   useEffect(() => {
      let el = itemRef.current
      if(el && el.offsetHeight > 60 && notification?.key !== 'order_request') {
         setIsShowButton(true)
         setIsShow(false)
         itemRef.current.classList.add('truncate-3')
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const onShowMore = (bool) => {
      if(bool) {
         itemRef.current.classList.remove('truncate-3')
      } else {
         itemRef.current.classList.add('truncate-3')
      }
      setIsShow(bool)
   }
   const getPurchaseMedia = () => {
      let data = {
         iconName: !!(notification?.payload?.type === 'video') ? 'videos' : notification?.payload?.type === 'store' ? 'store' : 'photosets',
         text: isAdmin ? ` purchased ${ notification?.payload?.type === 'store' ? !!notification?.payload?.category_system_slug  ? 'custom video' : 'store item' : notification?.payload?.type }: ` : `You can now download your ${ notification.media && notification.media.type }.`,
      }
      if(notification?.payload?.type === 'record_stream'){
         data.text = ' purchased recorded stream:'
         data.iconName = 'livestream'
      }
      return data;
   }
   const getMessageLike = () => {
      let text = ` liked your message: `
      if('photo_unlock' === notification?.payload?.type){
         text = ` liked your photo message`
      }
      if('video_unlock' === notification?.payload?.type){
         text = ` liked your video message`
      }
      if('voice_message' === notification?.payload?.type){
         text = ` liked your voice message`
      }
      return text;
   }
   const types = {

      //////////////////////////////////////////////////////   COMPLETED

      // Comments and Like
      comment: {
         iconName: 'comment',
         iconColor: 'skin-primary-background',
         text: ` commented on your ${ notification?.payload?.type === 'record_stream' ? 'recorded stream' : notification?.payload?.type }: `,
         textType: 'string',
         contentAction: true,
         isLiked: notification?.payload?.liked,
         adminReply: notification?.payload?.admin_reply,
         memberId: notification?.user?.id,
         commentId: notification?.payload?.id,
         media: {
            type: notification?.payload?.type,
            src: notification?.payload?.resource_src,
            media_id: notification?.payload?.resource_id,
         },
      },
      comment_reply: {
         iconName: 'comment',
         iconColor: 'skin-primary-background',
         showTitle: true,
         title: isAdmin ? notification.user?.nickname || notification.user?.username : notification?.message_sender,
         text: ' replied to your comment: ',
         contentAction: false,
         memberId: notification?.user?.id,
         commentId: notification?.payload?.id,
         media: {
            type: notification?.payload?.type,
            src: notification?.payload?.resource_src,
            media_id: notification?.payload?.resource_id,
         },
      },
      comment_like: {
         iconName: 'heart-filled',
         iconColor: 'bg-likes',
         showTitle: true,
         title: isAdmin ? notification.user?.nickname || notification.user?.username : notification.message_sender,
         text: ` liked your comment: `,
         memberId: notification?.user?.id,
         commentId: notification?.payload?.id,
         media: {
            type: notification?.payload?.type,
            src: notification?.payload?.resource_src,
            media_id: notification?.payload?.resource_id,
         },
      },


      // Chat

      chat_message_likes: {
         iconName: 'heart-filled',
         iconColor: 'bg-likes',
         showTitle: true,
         title: isAdmin ? notification.user?.nickname || notification.user?.username : notification.message_sender,
         text: getMessageLike(),
         memberId: notification?.user?.id,
      },

      private_single_text: {
         iconName: 'chat',
         iconColor: 'bg-action',
         text: ` sent you a private message: `,
         isChip: isAdmin,
         chipColor: '#616784',
         amount: notification.payload?.message?.price === 0 ? 'free' : notification.payload?.message?.price,
         balance: getMemberTotalSpent(notification.user),
         isBalance: false,
         contentAction: true,
         isLiked: notification?.payload?.liked,
         adminReply: notification?.payload?.admin_reply,
         memberId: notification?.user?.id,
      },
      private_single_voice: {
         iconName: 'chat',
         iconColor: 'bg-action',
         text: ` sent you a private voice message`,
         isChip: isAdmin,
         chipColor: '#616784',
         amount: notification.payload?.message?.price === 0 ? 'free' : notification.payload?.message?.price,
         balance: getMemberTotalSpent(notification.user),
         isBalance: false,
         contentAction: true,
         isLiked: notification?.payload?.liked,
         adminReply: notification?.payload?.admin_reply,
         memberId: notification?.user?.id,
         media: {
            type: 'voice',
            src: notification?.payload?.message?.unlockDetails?.src,
            duration: notification?.payload?.message?.unlockDetails?.audio_duration,
         },
      },
      private_single_photo: {
         iconName: 'chat',
         iconColor: 'bg-action',
         text: ` sent you a private image message`,
         isChip: isAdmin,
         chipColor: '#616784',
         amount: notification.payload?.message?.price === 0 ? 'free' : notification.payload?.message?.price,
         balance: getMemberTotalSpent(notification.user),
         isBalance: false,
         contentAction: true,
         isLiked: notification?.payload?.liked,
         adminReply: notification?.payload?.admin_reply,
         memberId: notification?.user?.id,
         media: {
            type: 'photo',
            src: notification?.payload?.message?.unlockDetails?.cover,
         },
      },
      group_single_text: {
         iconName: 'chat',
         iconColor: 'bg-action',
         text: ` sent a message in group chat: `,
         contentAction: true,
         isLiked: notification?.payload?.liked,
         adminReply: notification?.payload?.admin_reply,
         memberId: notification?.user?.id,
      },
      group_single_text_reply: {
         iconName: 'chat',
         iconColor: 'bg-action',
         text: ` replied to your message in group chat: `,
         contentAction: true,
         isLiked: notification?.payload?.liked,
         memberId: notification?.user?.id,
      },

      // Content sales
      purchase_media: {
         iconName: getPurchaseMedia().iconName,
         iconColor: 'skin-primary-background',
         title: 'Thanks for your purchase! ',
         text: getPurchaseMedia().text,
         isChip: true,
         chipColor: '#616784',
         amount: notification?.payload?.amount,
         balance: notification?.payload?.balance,
         media: {
            type: notification?.payload?.type,
            src: notification?.payload?.resource_src,
            media_id: notification?.payload?.resource_id,
            name: notification?.payload?.resource_name,
         },
         memberId: notification?.user?.id,
      },
      // Tips
      tip: {
         iconName: isAdmin ? 'tip' : '',
         iconColor: 'bg-action',
         showTitle: true,
         title: isAdmin ? notification.user?.nickname || notification.user?.username : notification.message_sender,
         text: isAdmin ? getAdminTipCardTitle(notification?.payload) : ' Thank you for the tip!',
         isChip: !('livestream' === notification?.payload?.type && notification?.payload?.tip_action_name),
         chipColor: '#616784',
         amount: notification?.payload?.amount,
         balance: notification?.payload?.balance,
         media: !!notification?.payload?.type ? {
            type: notification?.payload?.type,
            src: notification?.payload?.resource_src,
            media_id: notification?.payload?.resource_id,
            name: notification?.payload?.resource_name,
         } : {},
         memberId: notification?.user?.id,
      },

      //Order requests
      order_request: {
         iconName: 'store',
         iconColor: 'skin-primary-background',
         orderTitle: getOrderRequestData(notification?.payload?.order_request_status_name).mainTitle,
         text: getOrderRequestData(notification?.payload?.order_request_status_name).text,
         isChip: true,
         chipColor: '#231FFF',
         amount: notification?.payload?.amount,
         balance: notification?.payload?.balance,
         media: {
            type: notification?.payload?.type,
            src: notification?.payload?.resource_src,
            media_id: notification?.payload?.resource_id,
            name: notification?.payload?.resource_name,
         },
         memberId: notification?.user?.id,
         goToLink() {
            goToOrderDetails(notification?.payload?.order_request_id)
         },
         gotToLinkButtonText: 'See the order',
         paymentDeclined: getOrderRequestData(notification?.payload?.order_request_status_name).paymentDeclined,
         paymentSuccessful: getOrderRequestData(notification?.payload?.order_request_status_name).paymentSuccessful,
         orderRequestStatusChangeMessage: notification?.payload?.order_request_status_note,
         order_request_decline_reason: notification?.payload?.order_request_decline_reason,
      },
   };

   const {
      [notification.key]: notificationItem,
   } = types;

   function getAdminTipCardTitle(data) {
      let message = '';
      if(!!data?.type) {
         let type = data?.type;
         if('record_stream' === data?.type){
            type = 'recorded stream'
         }
         message = " tipped on your " + type + ' ';
      } else {
         message = ' sent you a tip';
      }
      return message;
   }

   function getMessageDate(date) {
      if(!date) {
         return '';
      }
      const day = DateFormatter.diff(date)
      let res = null
      if(Math.round(day) < 1){
         res = `Today ${ DateFormatter.convertToUserTz(date, '• hh:mm A') }`
      } else if(Math.round(day) < 2) {
         res = `Yesterday ${ DateFormatter.convertToUserTz(date, '• hh:mm A') }`
      } else if(Math.round(day) >= 2){
         res = DateFormatter.convertToUserTz(date, 'MMM DD, YYYY • hh:mm A')
      }
      return res
   }

   let avatar = notification?.user?.avatar_compressed_src;
   if(notification.key.includes('group')) {
      avatar = avatars
   }
   let isChat = true;
   if(['new_comments', 'comment_replies', 'comment_likes'].includes(notification.category)) {
      isChat = false;
   }

   let user = notification?.user;

   if(!notificationItem) {
      return null;
   }

   const goToMedia = (mediaId, type, commentId) => {
      if(mediaId) {
         goToResourceOrMemberPage(mediaId, false, type, commentId);
      }
   }

   function getOrderRequestData(status) {

      let state = {
         mainTitle: '',
         text: '',
         paymentDeclined: false,
         paymentSuccessful: false,
         iconColor: 'skin-primary-background',
         iconStyles: {
            color: 'action',
         },
      }

      switch (status) {
         case 'received':
            state = {
               ...state,
               mainTitle: 'New order request',
               text: ' has requested a ',
            }
            break;
         case 'canceled':
            state = {
               ...state,
               mainTitle: 'Order cancellation',
               text: ' canceled order for ',
               iconColor: '',
               iconStyles: {
                  color: 'action',
               },
            }
            break;
         case 'accepted':
            state = {
               ...state,
               mainTitle: 'Payment successful',
               paymentSuccessful: true,
               iconColor: '',
               iconStyles: {
                  color: 'action',
               },
            }
            break;

         default:
            break;
      }

      return state
   }


   return (
      <div
         style={
            !notification.is_read ?
               { backgroundColor: GenerateColor.generateRGBColor('#231FFF', 0.04) }
               : {} }
         className={ `py-4 pb-2 mb-2 bg-panel dark:bg-panel-dark ${ notification.is_read ? '' : 'pl-2 cursor-pointer' } ${ isMobile ? '' : 'rounded-lg' }` }
         role='presentation'
         onClick={ () => onClickCard(notification) }
      >
         <div className='px-4'>
            <div className='flex justify-between w-full pb-2'>
               <div className='flex flex-1'>
                  <NotificationMemberAvatar
                     isRead={ !!notification.is_read }
                     avatar={ avatar }
                     iconName={ notificationItem && notificationItem.iconName ? notificationItem.iconName : null }
                     iconColor={ notificationItem && notificationItem.iconColor ? notificationItem.iconColor : null }
                     onClick={ () => {
                        if(!!notification?.is_user_already_deleted) {
                           toast.remove('The member has been deleted');
                        } else {
                           goToResourceOrMemberPage(notificationItem?.memberId, true);
                        }
                     } }
                     username={ isAdmin  ? notification?.user?.nickname || notification?.payload?.user_name || notification?.user?.username : Boolean(notification?.payload?.user_name) ? notification?.payload?.user_name : notification?.user?.username }
                     iconStyles={ {
                        // color:
                     } }
                  />
                  <div className='flex flex-1 flex-col ml-4 pb-1'>
                     {
                        notificationItem && (
                           !notificationItem.component ? (
                              <>
                                 {
                                    !!notificationItem?.orderTitle &&
                                       <p className='text-major dark:text-major-dark font-bold leading-5 mb-1'>
                                          {notificationItem?.orderTitle}
                                       </p>
                                 }
                                 {
                                    !!notificationItem?.paymentSuccessful ?
                                       <div>
                                          Payment for {
                                             notificationItem?.media?.name && (
                                                <span
                                                   className={ `cursor-pointer hover:underline hover:font-medium` }
                                                   role='presentation'
                                                   onClick={ () => goToMedia(notificationItem?.media?.media_id, notificationItem?.media?.type) }
                                                >
                                                   {notificationItem?.media?.name} {notification.category === 'tip' && !!notification?.payload?.tip_note ? ': ' : '' }
                                                </span>
                                             )
                                          }
                                          order is completed
                                       </div>
                                       :
                                       <div ref={ itemRef } className='text-major dark:text-major-dark leading-tight pr-4 overflow-hidden'>
                                          <div
                                             className={ `notranslate font-medium inline-block ${ notificationItem?.memberId ? 'cursor-pointer hover:underline' : '' }` }
                                             role='presentation'
                                             onClick={ () => {
                                                if(!!notification?.is_user_already_deleted) {
                                                   toast.remove('The member has been deleted');
                                                } else {
                                                   goToResourceOrMemberPage(notificationItem?.memberId, true);
                                                }
                                             } }
                                             dangerouslySetInnerHTML={ { __html: isAdmin ? (notificationItem?.showTitle ? notificationItem?.title : notification?.user?.nickname || notification?.user?.username) : notificationItem?.title } }
                                          />
                                          {
                                             !!notificationItem.membership_name && (
                                                <span className='block my-1 font-medium'> { notificationItem?.membership_name }</span>
                                             )
                                          }

                                          <span>
                                             {
                                                'livestream' === notification?.payload?.type && notification?.payload?.tip_action_name ? (
                                                   <>&nbsp;tipped <span className='font-medium notranslate'>{ notification?.payload?.amount }USD</span> for { notification?.payload.tip_action_name }</>
                                                ) : <>{notificationItem.text}</>
                                             }
                                             {!notificationItem?.media?.name && notification.category === 'tip' && !!notification?.payload?.tip_note ? ': ' : ' '}
                                          </span>
                                          {
                                             notificationItem?.media?.name && (
                                                <span
                                                   className={ `cursor-pointer hover:underline hover:font-medium` }
                                                   role='presentation'
                                                   onClick={ () => goToMedia(notificationItem?.media?.media_id, notificationItem?.media?.type) }
                                                >
                                                   {notificationItem?.media?.name} {notification.category === 'tip' && !!notification?.payload?.tip_note ? ': ' : '' }
                                                </span>
                                             )
                                          }
                                          {
                                             notification?.payload?.text && ['text_message', 'photo_unlock', 'private_mass_message'].includes(notification?.payload?.type) && !['multiple_attachment'].includes(notification?.payload?.type) && (
                                                <span
                                                   className={ `text-secondary dark:text-secondary-dark font-normal ` }
                                                   role='presentation'
                                                >
                                                   { notification.payload.text }
                                                </span>
                                             )
                                          }
                                          {
                                             ['multiple_attachment'].includes(notification?.payload?.type) &&
                                             <span
                                                className={ `text-secondary dark:text-secondary-dark font-normal ` }
                                                role='presentation'
                                             >Multiple attachment!</span>
                                          }
                                          {
                                             ['new_comments', 'comment_replies', 'comment_likes', 'tip'].includes(notification.category) ? (
                                                <span
                                                   className={ `text-secondary dark:text-secondary-dark font-normal ${ (notification.category !== 'tip') ? 'cursor-pointer hover:underline hover:font-medium' : '' }` }
                                                   role='presentation'
                                                   onClick={ () => goToMedia(notificationItem?.media?.media_id, notificationItem?.media?.type,  notificationItem?.commentId) }
                                                >
                                                   { notification.category === 'tip' ? notification?.payload?.tip_note : notification?.payload?.text }
                                                </span>
                                             ) : (
                                                <div
                                                   className='text-secondary dark:text-secondary-dark font-normal'
                                                   dangerouslySetInnerHTML={ { __html: notification?.payload?.text?.subject } }
                                                />
                                             )
                                          }
                                       </div>
                                 }
                                 {
                                    'voice' === notificationItem?.media?.type && notificationItem?.media?.src && (

                                       <div className='flex flex-col mt-3 bg-grey-main dark:bg-panel-dark p-3 rounded-full w-[205px]'>
                                          <Waveform
                                             type='mp3'
                                             duration={ notificationItem.media.duration }
                                             url={ notificationItem.media.src }
                                             colorState={ {
                                                waveColor: window.site_dark_bg ? '#e0e0e01f' : '#6670851f',
                                                progressColor: window.site_dark_bg ? '#E0E0E0' : '#667085',
                                             } }
                                          />
                                       </div>
                                    )
                                 }
                                 {
                                    !!notificationItem?.orderRequestStatusChangeMessage &&
                                    <p className='text-major dark:text-major-dark text-base font-normal mb-1 mt-3'>{notificationItem?.orderRequestStatusChangeMessage}</p>
                                 }
                                 <div className='flex justify-between'>
                                    {
                                       notificationItem.isChip && (
                                          <div className='flex flex-col items-start gap-3'>
                                             <NotificationChip
                                                color={ notificationItem.chipColor }
                                                amount={ notificationItem.amount }
                                                // balance={ notificationItem.balance }
                                                // isBalance={ notificationItem.isBalance }
                                             />
                                             {
                                                !!notificationItem?.gotToLinkButtonText &&
                                                <Button
                                                   text={ notificationItem?.gotToLinkButtonText }
                                                   textSize='base'
                                                   backgroundColor='transparent'
                                                   classNames='h-full'
                                                   padding='none'
                                                   textMarginX='0'
                                                   primaryColor
                                                   onClick={ notificationItem?.goToLink }
                                                />
                                             }
                                          </div>
                                       )
                                    }
                                    {
                                       isShowButton && (
                                          <>
                                             {
                                                !notificationItem.isChip && (
                                                   <div></div>
                                                )
                                             }
                                             <div className='flex justify-end pt-2 pb-1'>
                                                <div
                                                   className='flex justify-center items-center w-auto'
                                                   style={ { width: '93px' } }
                                                >
                                                   <Button
                                                      text={ isShow ? 'Show less' : 'Show more' }
                                                      textSize='base'
                                                      backgroundColor='transparent'
                                                      classNames='h-full'
                                                      padding='none'
                                                      textMarginX='0'
                                                      primaryColor
                                                      onClick={ () => onShowMore(!isShow) }
                                                   />
                                                </div>
                                             </div>
                                          </>
                                       )
                                    }
                                 </div>
                              </>
                           ) : (
                              notificationItem.component
                           )
                        )
                     }
                  </div>
               </div>
               {
                  'voice' !== notificationItem?.media?.type && !!notificationItem?.media && !!notificationItem?.media?.src && (
                     <NotificationMedia
                        src={ notificationItem.media.src }
                        type={ notificationItem.media.type }
                        isResourceDeleted={ notification?.payload?.is_resource_deleted }
                        onClick={ () => goToMedia(notificationItem?.media?.media_id, notificationItem?.media?.type) }
                     />
                  )
               }
            </div>
            {
               !!notificationItem.adminReply && (
                  <AlreadyRepliedContent
                     date={ getMessageDate(notificationItem?.adminReply?.created_at) }
                     message={ notificationItem?.adminReply?.text }
                     isChat={ isChat }
                  />
               )
            }
            <div className={ `flex justify-between items-start mt-1 ${ notification.is_read ? 'pl-1' : '' }` } >
               <div className='mr-2'>
                  <span className='text-secondary dark:text-secondary-dark mt-2 text-xs'>{getMessageDate(notification.created_at)}</span>
                  {
                     !!user && (
                        <div className='flex flex-col mt-1'>
                           {
                              (!!user?.pricing_name || !!user?.pricing?.name)  && (
                                 <div className='flex items-center mb-1 gap-1'>
                                    {/* <div className='flex mr-1 rounded-full border border-success users-info-icon'>
                                       <Icon name='membership' color='success' style={ { fontSize: '7.71px' } } />
                                    </div> */}
                                    <Icon name='membership'
                                       color='secondary'
                                       darkColor='secondary-dark'
                                       style={ { fontSize: '12px' } } />
                                    {/* <span className='text-xs text-secondary dark:text-secondary-dark  membership-name truncate' >
                                       { user?.pricing_name || user?.pricing?.name }
                                    </span> */}
                                    <TruncateText
                                       text={ user?.pricing_name || user?.pricing?.name }
                                       textClass='text-xs text-secondary dark:text-secondary-dark'
                                    />
                                 </div>
                              )
                           }
                           {
                              !!getMemberTotalSpent(user) && (
                                 <div className='flex items-center gap-1'>
                                    {/* <div className='flex mr-1 rounded-full border dark:border-divider-dark border-divider users-info-icon'>
                                       <Icon name='money-2' color='secondary dark:text-secondary-dark' style={ { fontSize: '7.71px' } }  />
                                    </div> */}
                                    <Icon name='money-2'
                                       color='secondary'
                                       darkColor='secondary-dark'
                                       style={ { fontSize: '12px' } }  />
                                    <span className='text-xs text-secondary dark:text-secondary-dark ' style={ { lineHeight: '14px' } }>
                                       Total spent: { getMemberTotalSpent(user, true)} <span className='notranslate'>USD</span>
                                       {
                                          0 < getMemberTotalSpent(user, true) && !!getTopPercentage(user?.top_percentage, true) && ` • Top ${ getTopPercentage(user?.top_percentage, true) }%`
                                       }
                                    </span>
                                 </div>
                              )
                           }
                        </div>
                     )
                  }
               </div>
               {
                  notificationItem && notificationItem.isBottomContent && (
                     <div className='flex justify-center items-center h-auto w-auto' >
                        <Button
                           text={ notificationItem.bottomContent.text }
                           textSize='base'
                           iconName={ notificationItem.bottomContent.icon }
                           iconPosition='left'
                           fontIconSize='large'
                           backgroundColor='transparent'
                           classNames='h-full'
                           onClick={ () => notificationItem.bottomContent.handleAction() }
                           padding='none'
                           primaryColor
                        />
                     </div>
                  )
               }
               {
                  notificationItem?.contentAction && (
                     <NotificationCommentAction
                        onClickLike={ () => onClickLike(notification) }
                        onClickReply={ () => {
                           onClickReply();
                        } }
                        isLiked={ notificationItem?.isLiked }
                     />
                  )
               }
               {/* {
               !isAdmin && notification.type === 'expired' && (
                  <div className='flex w-auto justify-end'>
                     <div className='flex justify-center items-center h-9 w-36' >
                        <Button
                           text='Join again'
                           textSize='base'
                           backgroundColor='transparent'
                           classNames='h-full'
                           onClick={ goToJoin }
                           padding='none'
                           primaryColor
                        />
                     </div>
                  </div>
               )
            } */}
            </div>
         </div>
         {
            replyNotification && replyNotification.id === notification.id && (
               <RepliedContent
                  replyMessage={ replyNotification }
                  sendMessage={ sendMessage }
                  cancelReplyMessage={ cancelReplyMessage }
                  userAvatar={ userAvatar }
                  isAdmin={ isAdmin }
               />
            )
         }
      </div>
   );
};

NotificationItems.propTypes = {
   notification: PropTypes.object,
   replyNotification: PropTypes.object,
   isAdmin: PropTypes.bool,
   isMobile: PropTypes.bool,
   onClickCard: PropTypes.func,
   onClickLike: PropTypes.func,
   onClickReply: PropTypes.func,
   sendMessage: PropTypes.func,
   cancelReplyMessage: PropTypes.func,
   userAvatar: PropTypes.string,
   avatars: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
   ]),
   goToResourceOrMemberPage: PropTypes.func,
   goToOrderDetails: PropTypes.func,
};
NotificationItems.defaultProps = {
   notification: {
      type: 'comment',
   },
   onClickCard: () => {},
   onClickReply: () => {},
   onClickLike: () => {},
   sendMessage: () => {},
   goToJoin: () => {},
   goToOrderDetails: () => {},
};

export default React.memo(NotificationItems);
