import PropTypes from 'prop-types';
import ModalWrapper from 'common/components/modules/modal-wrapper';

const OnboardingStepsModalWrapper = ({
   children,
   onClose,
   isMobile,
   siteStatus,
}) => {

   const getModalState = () => {
      let title = 'Build your site'
      let description;
      switch (siteStatus) {
         case 'in_progress':
            description = 'Complete the steps to submit for review'
            break;
         case 'ready_to_submit_for_review':

            break;
         case 'ready_for_approval':

            break;
         case 'ready_to_launch':
            title = 'All steps completed'
            break;
         case 'submitted_for_review':
         case 'ams_reviewing':
            description = 'Your site is being reviewed by our team.'
            break;
         case 'additional':
            description = 'Complete the additional steps before resubmitting your site for review'
            break;
         case 'submitted_to_payment_processors':
            description = 'Your site is being reviewed by payment processors.'
            break;

         default:
            break;
      }

      return {
         title,
         description,
      }
   }

   return (
      <ModalWrapper
         onCloseModal={ onClose }
         contentWidth='742px'
         type='action'
         withoutConfirmButton
         title={ getModalState().title }
         isMobile={ isMobile }
         icon='build-your-site'
         className='!px-0 lg:!px-6 max-h-full flex-1 overflow-y-hidden'
         maxScreenVersion='v2'
         modalWrapperClassName='flex flex-col'
         closeBtnClassName='!w-[fit-content] shrink-0'
      >
         <div
            className='w-full flex flex-col items-center gap-6 max-h-full flex-1 overflow-y-hidden'
         >
            {
               getModalState().description && (
                  <span
                     className='text-center text-secondary'
                  >
                     {getModalState().description}
                  </span>
               )
            }
            {children}
         </div>
      </ModalWrapper>
   )
}

OnboardingStepsModalWrapper.propTypes = {
   children: PropTypes.any,
   onClose: PropTypes.func,
   isMobile: PropTypes.bool,
   siteStatus: PropTypes.string,
}

export default OnboardingStepsModalWrapper;
