import * as types from "./types";
import createReducer from "../../utils/createReducer";

import initialState from './initialState';

let reducersMap = {
   [types.FETCH_CAST_MEMBERS_LIST_START]: (state, action) => {
      const { isFetchingByFilter, isFetchingNextPage } = action.payload

      let newState = {
         isFetchingList: !isFetchingByFilter && !isFetchingNextPage,
         isFetchingByFilter: isFetchingByFilter,
         isFetchingNextPage: isFetchingNextPage,
      }

      return {
         ...state,
         ...newState,
      }
   },
   [types.FETCH_CAST_MEMBERS_LIST_COMPLETED]: (state, action) => {
      const { list, isFetchingNextPage } = action.payload

      let newState = {
         isFetchingList: false,
         isFetchingByFilter: false,
         isFetchingNextPage: false,
      }

      if(isFetchingNextPage) {
         newState.list = {
            ...list,
            data: [...state.list.data, ...list.data],
         }
      } else {
         newState = {
            ...newState,
            list: { ...list },
         }
      }

      return {
         ...state,
         ...newState,
      }
   },
   [types.OPEN_CLOSE_CONFIRM_DIALOGS]: (state, action) => {
      const { isOpen, type, castMemberData } = action.payload

      return {
         ...state,
         confirmDialogs: {
            ...state.confirmDialogs,
            isOpen,
            type,
            castMemberData,
         },
      }
   },
   [types.SET_IS_FETCHING_ACTION_WITH_CAST_MEMBER]: (state, action) => {

      return {
         ...state,
         isFetchingActionWithCastMember: action.payload,
      }
   },
   [types.DELETE_CAST_MEMBER_COMPLETED]: (state, action) => {
      const { id } = action.payload;

      return {
         ...state,
         isFetchingActionWithCastMember: false,
         list: {
            ...state.list,
            data: state.list.data.filter(cast => cast.id !== id),
         },
      }
   },
   [types.SET_IS_FETCHING_CAST_MEMBER_DETAILS]: (state, action) => {

      return {
         ...state,
         isFetchingCastMemberDetails: action.payload,
      }
   },
   [types.SET_IS_FETCHING_CREATE_CAST_MEMBER]: (state, action) => {

      return {
         ...state,
         isFetchingCreate: action.payload,
      }
   },
   [types.FETCH_CREATE_EDIT_CAST_MEMBER_FAILED]: (state, action) => {
      const errors = action.payload
      let updatedState = {
         isFetchingCreate: false,
         isFetchingUpdate: false,
         createEditErrors: errors || {},
      }

      if(errors) {
         const keysArray = Object.keys(errors);
         const addressKeys = [
            'city_town',
            'street',
            'zip_postal_code',
         ];
         let isAddressError = keysArray.find(key => addressKeys.includes(key));
         let isAddressError2 = keysArray.find(key => 'address' === key)

         if(isAddressError || isAddressError2){
            let key = 'manual';
            if(!isAddressError){
               key = 'auto';
            }

            updatedState.castMemberAddressEditMode = key

         }
      }

      return {
         ...state,
         ...updatedState,
      }
   },
   [types.RESET_ERRORS]: (state, action) => {
      const { errors, isAll } = action.payload

      let newErrors = {}

      if(!isAll) {
         newErrors = {
            ...state.createEditErrors,
            ...errors,
         }
      }

      return {
         ...state,
         createEditErrors: newErrors,
      }
   },
   [types.SET_IS_FETCHING_UPDATE_CAST_MEMBER]: (state, action) => {

      return {
         ...state,
         isFetchingUpdate: action.payload,
      }
   },
   [types.SET_CAST_MEMBER_ADDRESS_EDIT_MODE]: (state, action) => {

      return {
         ...state,
         castMemberAddressEditMode: action.payload,
      }
   },
   [types.TOGGLE_LIST_VIEW_TYPE]: (state) => {

      return {
         ...state,
         listViewType: 'card' === state.listViewType ? 'table' : 'card',
      }
   },
}

export default createReducer(initialState)(reducersMap);
