import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';
import { isEmpty } from "lodash";
// import DateFormatter from 'common/utils/DateFormatter';

const reducersMap = {

   // start chat settings
   [types.FETCH_CHAT_SETTINGS_REQUEST]: (state) => {
      return {
         ...state,
         isFetchChatSettings: true,
      };
   },
   [types.FETCH_CHAT_SETTINGS_COMPLETED]: (state, action) => {
      const modifyObj = (obj) => Object.entries(obj).map(([key, value]) => {
         let sendKey = ''
         if(!!value.usd_terms){
            sendKey = 'chat_private_access_members_different'
            value.access = value.access?.type ? {
               ...value.access,
               id: value.id,
            } : null
         } else {
            let name = value.name === 'Expired' ? 'expired' : 'guest'
            sendKey = `chat_private_${ name }_members_payed_messaging_payload`
            value.access = {
               ...value.access,
            }
         }

         if(value.name === 'Expired' || value.name === 'Guests'){
            value.type = value.name === 'Expired' ? 'expired' : 'guest';
         }
         if(value.access?.type) {
            value.access = {
               ...value.access,
               sendKey,
            }
         } else {
            value.access = null;
         }
         return value
      });
      let differentMembers = modifyObj(action.payload.settings.chat_private_active_different_members_payed_messaging_payload)
      let differentOthers = modifyObj(action.payload.settings.chat_private_active_different_others_payed_messaging_payload)
      return {
         ...state,
         isFetchChatSettings: false,
         settingsData: {
            ...action.payload,
            settings: {
               ...action.payload.settings,
               chat_private_active_different_members_payed_messaging_payload: differentMembers,
               chat_private_active_different_others_payed_messaging_payload: differentOthers,
               chat_private_active_different_members_payed_messaging_payload_array: differentMembers,
               chat_private_active_different_others_payed_messaging_payload_array: differentOthers,
            },
         },
      };
   },
   [types.FETCH_CHAT_SETTINGS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchChatSettings: false,
      };
   },
   [types.UPDATE_CHAT_SETTINGS_REQUEST]: (state) => {
      return {
         ...state,
         isFetchSettingsUpdate: true,
      };
   },
   [types.UPDATE_CHAT_SETTINGS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchSettingsUpdate: false,
         settingsData: {
            ...state.settingsData,
            settings: {
               ...state.settingsData.settings,
               ...action.payload,
            },
         },
         settingsErrorsData: {},
      };
   },
   [types.CLEAR_CHAT_SETTINGS_ERROR]: (state, action) => {
      return {
         ...state,
         settingsErrorsData: {},
      };
   },
   [types.UPDATE_CHAT_SETTINGS_FAILED]: (state, action) => {
      return {
         ...state,
         settingsErrorsData: action.payload.data,
         isFetchSettingsUpdate: false,
      };
   },
   // end chat settings

   // start chat members lilst
   [types.FETCH_CHAT_MEMBERS_REQUEST]: (state) => {
      return {
         ...state,
         isFetchMembers: true,
      };
   },
   [types.FETCH_CHAT_MEMBERS_COMPLETED]: (state, action) => {
      return {
         ...state,
         isFetchMembers: false,
         membersData: action.payload,
         isEmptyMembers: action.payload.data.length === 0,
      };
   },
   [types.FETCH_CHAT_MEMBERS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchMembers: false,
      };
   },

   [types.CHAT_MEMBERS_FILTER_REQUEST]: (state, action) => {
      let newState = {
         isFetchMembersFilter: true,
      }
      if(action.payload){
         newState = {
            isFetchNewMembers: true,
         }
      }
      return {
         ...state,
         ...newState,
      };
   },
   [types.CHAT_MEMBERS_FILTER_COMPLETED]: (state, action) => {
      const {
         data,
         bool,
      } =  action.payload
      let newState = {
         isFetchMembersFilter: false,
         membersData: data,
         isEmptyMembersByFilter: data.data.length === 0,
      }
      if(bool){
         newState = {
            isFetchNewMembers: false,
            membersData: {
               ...data,
               data: [
                  ...state.membersData.data, ...data.data,
               ],
            },
         }
      }
      return {
         ...state,
         ...newState,
      };
   },
   [types.CHAT_MEMBERS_FILTER_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchMembersFilter: false,
         isFetchNewMembers: false,
      };
   },
   // end members list

   // start conversation
   [types.FETCH_CHAT_CONVERSATIONS_REQUEST]: (state, action) => {
      let newState = {}
      if(action.payload) {
         newState = {
            isFetchConversationByFilter: true,
         }
      } else {
         newState = {
            isFetchConversation: true,
            isFetchConversationByFilter: false,
         }
      }
      return {
         ...state,
         ...newState,
         // isFetchConversationMessages: false,
         isEmptyconversationByFilter: false,
         isEmptyconversation: false,
      };
   },
   [types.FETCH_CHAT_CONVERSATIONS_COMPLETED]: (state, action) => {
      const {
         data,
         group,
         unread_private_messages_count,
      } = action.payload.data
      const { searchText, isFilter, isChatInited } = action.payload

      // Need to save conversations on new messages list after filter
      let savedConversationsOnNewMessagesList = []

      // if(isFilter) {
      //    state.conversationsList.data.forEach(chat => {
      //       // push if dont exist on new filtered data
      //       if(chat.isNewMessage && !data.data.some(el => el.chat_room_id === chat.chat_room_id)) {
      //          savedConversationsOnNewMessagesList.push(chat)
      //       }
      //    })
      // }

      let newState = {}
      if(isFilter && isChatInited) {
         newState = {
            isFetchConversationByFilter: false,
            isFetchConversation: false,
            isEmptyconversationByFilter: data.data.length === 0,
         }
      } else {
         newState = {
            isFetchConversation: false,
            isFetchConversationByFilter: false,
            groupConversation: group,
            isEmptyconversation: data.data.length === 0,
            initedConversation: true,
            unreadPrivateMessagesCount: unread_private_messages_count,
         }
      }
      const fakemessage = state.conversationsList.data.find(i => i.isFake)
      let noResult = false
      if(fakemessage && searchText) {
         noResult = true
         if(fakemessage.username) {
            noResult = !fakemessage.username.includes(searchText)
         }
      }
      newState = {
         ...newState,
         conversationsList: {
            ...data,
            data: [...data.data, ...savedConversationsOnNewMessagesList],
         },
      }
      if(fakemessage) {
         newState = {
            ...newState,
            conversationsList: {
               ...data,
               data: [{ ...fakemessage, hidden: noResult }, ...data.data, ...savedConversationsOnNewMessagesList],
            },
         }
      }
      return {
         ...state,
         ...newState,
      };
   },
   [types.FETCH_CHAT_CONVERSATIONS_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchConversation: false,
         isFetchConversationByFilter: false,
         isEmptyconversationByFilter: false,
         isEmptyconversation: false,
      };
   },
   [types.ADD_REMOVE_CONVERSATION_ITEM_ACTION]: (state, action) => {
      const { data, search, hidden } = action.payload;
      let conversationData = state.conversationsList.data
      const index = conversationData.findIndex(elm => elm.chat_room_id === data.chat_room_id)
      if(index < 0) {
         let noResult = false
         const member = { username: data.username }
         if(member && search) {
            noResult = false
            if(member.screen_name) {
               noResult = true
            } else {
               if(member.email) {
                  noResult = !member.username.includes(search) && !member.email.includes(search)
               } else {
                  noResult = !member.username.includes(search)
               }
            }
         }
         conversationData = [{ ...data, hidden: noResult || hidden }, ...conversationData]
         return {
            ...state,
            conversationsList: {
               ...state.conversationsList,
               data: conversationData,
            },
         };
      } else {
         return {
            ...state,
         }
      }

   },
   //end conversation

   // start conversation messages
   [types.FETCH_CHAT_CONVERSATION_MESSAGES_REQUEST]: (state) => {
      return {
         ...state,
         isFetchConversationMessages: true,
      };
   },
   [types.FETCH_CHAT_CONVERSATION_MESSAGES_COMPLETED]: (state, action) => {

      return {
         ...state,
         isFetchConversationMessages: false,

         initedConverstionMessages: {
            ...state.initedConverstionMessages,
            ...action.payload,
         },
         // isEmptyConversationMessages: action.payload.data.length === 0,
      };
   },
   [types.FETCH_CHAT_CONVERSATION_MESSAGES_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchConversationMessages: false,
      };
   },
   [types.FETCH_CHAT_NEW_CONVERSATION_REQUEST]: (state) => {
      return {
         ...state,
         isFetchNewConversation: true,
      };
   },
   [types.FETCH_CHAT_NEW_CONVERSATION_COMPLETED]: (state, action) => {
      let newList = [...action.payload.data]
      // eslint-disable-next-line array-callback-return
      newList = [...action.payload.data].filter(element => {
         const { ...el } = element
         let item = [...state.conversationsList.data].find(item => item.chat_room_id === el.chat_room_id)
         if(!item){
            return { ...el }
         }
      })
      return {
         ...state,
         isFetchNewConversation: false,
         conversationsList: {
            ...action.payload,
            data: [...state.conversationsList.data, ...newList],
         },
      };
   },
   [types.FETCH_CHAT_NEW_CONVERSATION_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchNewConversation: false,
      };
   },
   [types.FETCH_NEW_MESSAGES_REQUEST]: (state) => {
      return {
         ...state,
         isFetchNewConversationMessages: true,
      };
   },
   [types.FETCH_NEW_MESSAGES_COMPLETED]: (state, action) => {
      const id = state.activeConverstaion.chat_room_id
      let data = [...state.initedConverstionMessages[id].data, ...action.payload.data]
      return {
         ...state,
         isFetchNewConversationMessages: false,
         initedConverstionMessages: {
            ...state.initedConverstionMessages,
            [id]: {
               ...state.initedConverstionMessages,
               ...action.payload,
               data: data,
            },
         },
      };
   },
   [types.FETCH_NEW_MESSAGES_FAILED]: (state, action) => {
      return {
         ...state,
         isFetchNewConversationMessages: false,
      };
   },
   //end conversation messages

   [types.FETCH_LIKED_MEMBER_BY_MESSSAGE_REQUEST]: (state, action) => {
      let newData = {}
      if(!!action.payload) {
         newData = {
            fetchingScroll: false,
         }
      } else {
         newData = {
            fetching: false,
         }
      }
      return {
         ...state,
         likedMemberByMessage: {
            ...newData,
            data: [],
         },
      };
   },
   [types.FETCH_LIKED_MEMBER_BY_MESSSAGE_COMPLETED]: (state, action) => {
      const {
         data,
         isPagination,
      } = action.payload

      let newData = {
         ...data,
      }
      if(isPagination) {
         newData = {
            ...newData,
            fetchingScroll: false,
         }
      } else {
         newData = {
            ...newData,
            fetching: false,
         }
      }
      return {
         ...state,
         likedMemberByMessage: {
            ...newData,
         },
      };
   },
   [types.FETCH_LIKED_MEMBER_BY_MESSSAGE_FAILED]: (state, action) => {
      return {
         ...state,
         likedMemberByMessage: {
            fetching: false,
            fetchingScroll: false,
            data: [],
         },
      };
   },

   /////////////////////////////////////////////////
   [types.SET_CHAT_CONVERSTION]: (state, { payload }) => {
      let newState = {}
      let member = {}
      let newActiveConversationState = {}
      if(payload === null) {
         newState = {
            activeConverstaion: null,
            memberByUuid: {
               isOpen: false,
               loadData: true,
            },
            cancelFlag: true,
         }
      } else {
         if(!payload.member && !payload?.isGroup) {
            let chatItem = [...state.conversationsList.data].find(el => el.chat_room_id === payload.chat_room_id)
            if(chatItem && chatItem.member) {
               member = {
                  member: chatItem.member,
               }
            }
         }
         newState = {
            activeConverstaion: {
               ...state.activeConverstaion,
               ...payload,
               isGroup: !!payload?.conversation_name,
            },
         }

         //Remove closed or opened conversation from list
         if(Boolean(newState.activeConverstaion.chat_room_id) && state.conversationsList.data.some(item => Boolean(item.changed_to_type))) {
            const openCloseFilterStatus = state.conversationsFilter?.type?.[0] || 'open'

            let newConversationsData = state.conversationsList.data.filter(item => !Boolean(item.changed_to_type) || item.changed_to_type === openCloseFilterStatus || newState.activeConverstaion.chat_room_id === item.chat_room_id)

            newConversationsData = newConversationsData.map(item => {

               if(item.changed_to_type === openCloseFilterStatus) {
                  return {
                     ...item,
                     changed_to_type: null,
                  }
               }

               return item
            })
            newState.conversationsList = {
               ...state.conversationsList,
               data: newConversationsData,
            }
         }

         if(Number(payload?.chat_room_id) && Number(payload.chat_room_id) === Number(state.activeConverstaion?.chat_room_id)) {
            newActiveConversationState = {
               ...state.activeConverstaion,
            }
         }

         if(payload?.member) {
            newState.memberByUuid = {
               ...state.memberByUuid,
               loadData: false,
            }
         }

      }
      return {
         ...state,
         cancelFlag: false,
         ...newState,
         activeConverstaion: {
            ...newActiveConversationState,
            ...member,
            ...payload,
            // isGroup: !!payload?.conversation_name,
         },
      };
   },


   [types.IS_MOBILE_RIGHT_CONTENT]: (state, action) => {
      return {
         ...state,
         isOpenRightContent: action.payload,
      };
   },

   [types.SET_SHOW_DESCTOP_DIALOGUE]: (state, action) => {
      return {
         ...state,
         openDesctopDialogue: action.payload,
      };
   },

   [types.START_DESCTOP_DIALOGUE_COMPLETED]: (state, action) => {
      const {
         data, bool,
      } = action.payload
      let newState = {}
      if(data) {
         let descktopUserList = [...state.descktopUserList]
         const isExited = [...state.descktopUserList].find(i => i.chat_room_id === data.chat_room_id)
         if(!isExited) {
            if(state.descktopUserList.length > 4) {
               descktopUserList.pop()
            }
            descktopUserList = [{ ...data }, ...descktopUserList]
            newState = {
               ...newState,
               descktopUserList: descktopUserList,
            }
         }
      }

      return {
         ...state,
         ...newState,
         showNavigationList: false,
         openDesctopDialogue: data.isOpen && bool,
         showMiniChat: !!bool,
      };
   },
   [types.SET_OPEN_DESCTOP_DIALOGUE]: (state, action) => {
      const newState = {
         openDesctopDialogue: action.payload,
      }
      if(!action.payload) {
         newState.activeConverstaion = {}
      }
      return {
         ...state,
         // openDesctopDialogue: action.payload,
         ...newState,
      };
   },
   [types.SET_SOCKET_CONNECTED_ACTION]: (state, action) => {
      return {
         ...state,
         isSocketConnected: action.payload,
      };
   },

   [types.CREATE_CONVERSATION]: (state, action) => {
      const {
         conversation,
         member,
         search,
      } =  action.payload
      const {
         user_avatar,
         username,
         user_uuid,
         chat_room_id,
      } = conversation
      const id = chat_room_id || user_uuid
      const fakeData = {
         user_uuid,
         username: username,
         user_avatar: user_avatar,
         chat_room_id: id,
         isFake: !chat_room_id,
         ...conversation,
      }
      let newState = {}
      let noResult = false
      // const isFakeItem = state.conversationsList.data.find(e => e.isFake)
      if(member && search) {
         noResult = false
         if(member.screen_name) {
            noResult = true
         } else {
            if(member.email) {
               noResult = !member.username.includes(search) && !member.email.includes(search)
            } else {
               noResult = !member.username.includes(search)
            }
         }
      }
      if(!chat_room_id) {
         if(state.conversationsList.data.findIndex(e => e.isFake) < 0) {
            const newData = [...state.conversationsList.data]
            let indexToInsert = 0
            const lastPinnedConversationIndex = newData.findLastIndex(el => el.is_pinned)
            indexToInsert = lastPinnedConversationIndex + 1
            newData.splice(indexToInsert, 0, { ...fakeData, hidden: noResult })
            newState = {
               ...newState,
               conversationsList: {
                  ...state.conversationsList,
                  data: [...newData],
               },
            }
         } else {
            newState = {
               ...newState,
               conversationsList: {
                  ...state.conversationsList,
                  data: [...state.conversationsList.data].map(e => {
                     let { ...i } = e
                     if(i.isFake){
                        i = fakeData
                     }
                     return i
                  }),
               },
            }
         }
      }
      if(chat_room_id && !noResult) {
         let item = {}
         let conversationsData = [...state.conversationsList.data].filter(elm => {
            let { ...i } = elm
            if(!!i.isFake){
               item = {
                  ...fakeData,
                  isFake: false,
               }
               return null
            } else {
               return i
            }
         })
         let indexToInsert = 0
         if(!item?.is_pinned) {
            const lastPinnedConversationIndex = conversationsData.findLastIndex(el => el.is_pinned)
            indexToInsert = lastPinnedConversationIndex + 1
         }
         if(!isEmpty(item)){
            conversationsData.splice(indexToInsert, 0, item)
         }
         newState = {
            ...newState,
            conversationsList: {
               ...state.conversationsList,
               data: conversationsData,
            },
         }
         if(!state.initedConverstionMessages[chat_room_id]) {
            newState = {
               ...newState,
               initedConverstionMessages: {
                  ...state.initedConverstionMessages,
                  [chat_room_id]: {
                     data: [],
                     isEmpty: true,
                  },
               },
            }
         }
      }
      return {
         ...state,
         activeConverstaion: { ...fakeData, member },
         ...newState,

      };
   },
   ///////////////////////////////////////////
   [types.ON_LIKE_MESSAGE]: (state, action) => {
      const {
         conversationId,
         isLiked,
         messageId,
         isMyLiked,
      } = action.payload
      if(!state.initedConverstionMessages[conversationId]){
         return { ...state }
      }
      let data = [...state.initedConverstionMessages[conversationId].data].map(elm => {
         let { ...i } = elm
         if(i.id === messageId){
            let count = elm.likes_count_from_other_users || 0
            if(isLiked) {
               count = ++count
            }
            if(!isLiked && count > 0) {
               count = --count
            }
            if(!isMyLiked) {
               i = {
                  ...i,
                  likes_count_from_other_users: count,
               }
            }
            if(isMyLiked) {
               i = {
                  ...i,
                  is_liked_by_user: isLiked,
               }
            }
         }
         return i
      })
      return {
         ...state,
         initedConverstionMessages: {
            ...state.initedConverstionMessages,
            [conversationId]: {
               ...state.initedConverstionMessages[conversationId],
               data: data,
            },
         },

      };
   },
   [types.ON_DELETE_MESSAGE]: (state, action) => {
      const {
         conversationId,
         messageId,
      } = action.payload
      let newState = {}
      if(!state.initedConverstionMessages[conversationId]){
         return { ...state }
      }
      const index = state.initedConverstionMessages[conversationId].data.findIndex(i => i.id === messageId)
      const message =  index > -1 ? [...state.initedConverstionMessages[conversationId].data][index] : null
      let data = [...state.initedConverstionMessages[conversationId].data].filter(i => i.id !== messageId)

      if(index === 0) {
         let last_message = {}
         let message = data[0]
         if(message) {
            last_message = {
               text: message.text,
               user_uuid: message.user.uuid,
               created_at: message.created_at,
            }
         }

         if(!message?.text) {
            switch (message?.type) {
               case 'photo_unlock':
                  let photos_count =  message.unlock_details.photos_count
                  last_message.text = photos_count === 1 ? 'New photo!' : `${ photos_count } new photos!`
                  break;
               case 'video_unlock':
                  last_message.text = 'New video!'
                  break;
               case 'multiple_attachment':
                  last_message.text = 'Multiple attachments!'
                  break;
               case 'voice_message':
                  last_message.text = 'Voice message!'
                  break;

               default:
                  break;
            }
         }

         if(state.conversationsList.data.findIndex(i => i.chat_room_id === conversationId) > -1) {
            newState = {
               ...newState,
               conversationsList: {
                  ...state.conversationsList,
                  data: [...state.conversationsList.data].map((chatitem, index) => {
                     let { ...item } = chatitem
                     if(item.chat_room_id === conversationId) {
                        item.last_message = last_message
                     }
                     return item
                  }),
               },

            }
         }
         if(conversationId === state.groupConversation.chat_room_id) {
            newState = {
               ...newState,
               groupConversation: {
                  ...state.groupConversation,
                  last_message: last_message,
               },
            }
         }

      }

      if(!!data?.length && !!message) {
         data.forEach(item => {
            if((!!item.parent && item?.parent?.id === messageId) || (!!item.parentMessage && item?.parentMessage?.id === messageId)) {
               item.parent = null
               item.parentMessage = null
            }
         })
      }

      return {
         ...state,
         ...newState,
         initedConverstionMessages: {
            ...state.initedConverstionMessages,
            [conversationId]: {
               ...state.initedConverstionMessages[conversationId],
               data: data,
            },
         },
      };
   },
   [types.ON_ADD_MESSAGE]: (state, action) => {
      const {
         id, message, isMyMessage, notIncrement,
      } = action.payload.data

      let { search, dontNeedToAddConversationToList } = action.payload
      let inited = {
         data: [],
      }
      let newState = { ...state }
      const LAST_MESSAGE_ALLOWED_MESSAGE_TYPES = ['text_message', 'photo_unlock', 'video_unlock', 'voice_message', 'order_request_message', 'multiple_attachment']

      if(id === state.groupConversation.chat_room_id) {
         let lastMessageText = message.text

         if(!message.text) {
            if('photo_unlock' === message.type){
               let photos_count =  message.unlock_details.photos_count
               lastMessageText = photos_count === 1 ? 'New photo!' : `${ photos_count } new photos!`
            }
            if('video_unlock' === message.type){
               lastMessageText = 'New video!'
            }
            if('voice_message' === message.type){
               lastMessageText = 'Voice message!'
            }
            if('multiple_attachment' === message.type){
               lastMessageText = 'Multiple attachments!'
            }
         }

         let unreadCount = state.groupConversation.unread_messages_count || 0
         newState = {
            ...newState,
            groupConversation: {
               ...state.groupConversation,
               last_message: {
                  text: lastMessageText,
                  user_uuid: message.user.uuid,
                  username: message.user.username || message.user.screen_name,
                  nickname: message.user.nickname,
               },
            },
         }
         if(!isMyMessage) {
            newState = {
               ...newState,
               groupConversation: {
                  ...newState.groupConversation,
                  unread_messages_count: unreadCount + 1,
               },
            }
         }
      } else if(LAST_MESSAGE_ALLOWED_MESSAGE_TYPES.includes(message.type)) {
         let index = state.conversationsList.data.findIndex(i => i.chat_room_id === id)
         let noResult = false
         if(message.user && search) {
            const { user } = message
            noResult = false
            if(user.screen_name) {
               noResult = true
            } else {
               noResult = !user.username.includes(search) && !user.email.includes(search)
            }
         }

         let lastMessageText = message.text

         if(!message.text) {
            if('photo_unlock' === message.type){
               let photos_count =  message.unlock_details.photos_count
               lastMessageText = photos_count === 1 ? 'New photo!' : `${ photos_count } new photos!`
            }
            if('video_unlock' === message.type){
               lastMessageText = 'New video!'
            }
            if('voice_message' === message.type){
               lastMessageText = 'Voice message!'
            }
            if('multiple_attachment' === message.type){
               lastMessageText = 'Multiple attachments!'
            }
         }

         if(index < 0){
            let chatItem = {
               chat_room_id: id,
               last_message: {
                  text: lastMessageText,
                  user_uuid: message.user.uuid,
               },
            }
            let fakeItem = [...state.conversationsList.data].find(elm => elm.isFake)
            if(isMyMessage && fakeItem) {
               chatItem = {
                  ...chatItem,
                  user_uuid: fakeItem.user_uuid,
                  username: fakeItem.username,
                  user_avatar: fakeItem.user_avatar,
                  unread_messages_count_of_second_participant: 1,
               }
            } else {
               chatItem = {
                  ...chatItem,
                  user_uuid: message.user.uuid,
                  username: message.user.username || message.user.screen_name,
                  user_avatar: message.user.avatar_compressed_src || message.user.avatar_src,
                  unread_messages_count: 1,
               }
            }
            let newstate = [...state.conversationsList.data].filter(i => !i.isFake)
            let data = [{ ...chatItem, hidden: noResult || dontNeedToAddConversationToList }, ...newstate];
            newState = {
               ...newState,
               conversationsList: {
                  ...state.conversationsList,
                  data: data,
               },
            }

         }
         if(index > -1 && !noResult) {

            const isNotification  = ['mass_message', 'promo_video', 'promo_photoset'].includes(message.type);
            let conversationsData = []
            if(isNotification) {
               // only update last message without the change ordering
               conversationsData = [...state.conversationsList.data].map(elm => {
                  let { ...i } = elm
                  if(i.chat_room_id === id){
                     i = {
                        ...i,
                        last_message: {
                           text: lastMessageText,
                           user_uuid: message.user.uuid,
                           user_avatar: message.user.avatar_compressed_src || message.user.avatar_src,
                        },
                     }
                     if(!isMyMessage && !notIncrement) {
                        i.unread_messages_count = elm.unread_messages_count + 1
                     }
                     if(isMyMessage){
                        let unread_messages_count_of_second_participant = elm.unread_messages_count_of_second_participan || 0
                        i.unread_messages_count_of_second_participant = unread_messages_count_of_second_participant + 1
                     }
                  }
                  return i
               })

            } else {
               let item = {};
               // update conversation and change ordering
               conversationsData = [...state.conversationsList.data].filter(elm => {
                  let { ...i } = elm

                  if(i.chat_room_id === id){
                     i = {
                        ...i,
                        last_message: {
                           text: lastMessageText,
                           user_uuid: message.user.uuid,
                           user_avatar: message.user.avatar_compressed_src || message.user.avatar_src,
                        },
                     }
                     if(!isMyMessage && !notIncrement) {
                        i.unread_messages_count = elm.unread_messages_count + 1
                     }
                     if(isMyMessage){
                        let unread_messages_count_of_second_participant = elm.unread_messages_count_of_second_participan || 0
                        i.unread_messages_count_of_second_participant = unread_messages_count_of_second_participant + 1
                     }
                     item = { ...i }
                     return null
                  } else {
                     return i
                  }
               })
               if(item) {
                  let indexToInsert = 0
                  // Insert after pinned chats
                  if(!item.is_pinned) {
                     const lastPinnedConversationIndex = conversationsData.findLastIndex(el => el.is_pinned)
                     indexToInsert = lastPinnedConversationIndex + 1
                  }
                  if(item.hidden && !dontNeedToAddConversationToList) {
                     item.hidden = false
                  }
                  item.type = 'open'
                  conversationsData.splice(indexToInsert, 0, item)
               }
            }

            newState = {
               ...newState,
               conversationsList: {
                  ...state.conversationsList,
                  data: conversationsData,
               },
               unreadPrivateMessagesCount: !isMyMessage ? state.unreadPrivateMessagesCount + 1 : state.unreadPrivateMessagesCount,
            }

         }
      }
      if(state.initedConverstionMessages[id]){
         inited = state.initedConverstionMessages[id]
      }
      let data = [{ ...message }, ...inited.data]
      if(state.activeConverstaion && state.activeConverstaion.chat_room_id === id) {
         let unreadCount = 0
         let unreadCountParticipant = 0
         if(state.activeConverstaion.unread_messages_count  && !isMyMessage) {
            unreadCount =  state.activeConverstaion.unread_messages_count
         }
         if(state.activeConverstaion.unread_messages_count_of_second_participant  && !isMyMessage) {
            unreadCountParticipant =  state.activeConverstaion.unread_messages_count_of_second_participant
         }
         newState = {
            ...newState,
            activeConverstaion: {
               ...state.activeConverstaion,
               unread_messages_count: !!notIncrement ? unreadCount : unreadCount + 1,
               unread_messages_count_of_second_participant: !!notIncrement ? unreadCountParticipant : unreadCountParticipant + 1,
               isFake: false,
            },
         }
      }
      if(id && state.initedConverstionMessages[id]) {
         newState = {
            ...newState,
            initedConverstionMessages: {
               ...state.initedConverstionMessages,
               [id]: {
                  ...inited,
                  data: data,
                  isEmpty: false,
               },
            },
         }
      }
      return {
         ...state,
         ...newState,
      };
   },

   [types.ON_UPDATE_MESSAGE]: (state, action) => {
      const {
         conversationId,
         message,
         messageId,
         newDataWithPreview,
      } = action.payload

      if(!state.initedConverstionMessages[conversationId]){
         return { ...state }
      }

      const index = state.initedConverstionMessages[conversationId].data.findIndex(i => i.id === messageId)
      const updatedMessage =  index > -1 ? [...state.initedConverstionMessages[conversationId].data][index] : null
      let newState = {}

      if(index === 0) {
         let last_message = {}
         if(updatedMessage) {
            last_message = {
               text: message.text,
               user_uuid: updatedMessage?.user?.uuid,
               created_at: updatedMessage.created_at,
            }
         }

         if(!message?.text) {
            switch (updatedMessage?.type) {
               case 'photo_unlock':
                  let photos_count =  updatedMessage.unlock_details.photos_count
                  last_message.text = photos_count === 1 ? 'New photo!' : `${ photos_count } new photos!`
                  break;
               case 'video_unlock':
                  last_message.text = 'New video!'
                  break;
               case 'multiple_attachment':
                  last_message.text = 'Multiple attachments!'
                  break;
               case 'voice_message':
                  last_message.text = 'Voice message!'
                  break;

               default:
                  last_message.text = updatedMessage?.text || ''
                  break;
            }
         }

         if(state.conversationsList.data.findIndex(i => i.chat_room_id === conversationId) > -1) {
            newState = {
               conversationsList: {
                  ...state.conversationsList,
                  data: [...state.conversationsList.data].map((chatitem, index) => {
                     let { ...item } = chatitem
                     if(item.chat_room_id === conversationId) {
                        item.last_message = last_message
                     }
                     return item
                  }),
               },

            }
         }
         if(conversationId === state.groupConversation.chat_room_id) {
            newState = {
               groupConversation: {
                  ...state.groupConversation,
                  last_message: last_message,
               },
            }
         }

      }

      let data = [...state.initedConverstionMessages[conversationId].data].map(elm => {
         let { ...i } = elm

         if(i.id === messageId){
            let newData = {}
            if(newDataWithPreview?.type === 'multiple_attachment' && newDataWithPreview?.attachments?.length) {
               newData = {
                  attachments: i?.unlock_details?.attachments?.map(it => {
                     if(['video', 'video_vault'].includes(it?.resource_type)){
                        const videoItem = newDataWithPreview?.attachments?.find(item => item.src === it.src)
                        if(videoItem){
                           return {
                              ...it,
                              system_preview_video_full_src: videoItem?.system_preview_video_full_src,
                              custom_preview_video_full_src: videoItem?.custom_preview_video_full_src,
                           }
                        }
                     }


                     return it
                  }),
               }
            } else if(newDataWithPreview?.type === 'single_attachment' && newDataWithPreview?.previews){
               newData = {
                  ...newDataWithPreview.previews,
               }
            }


            i = {
               ...i,
               ...message,
               unlock_details: {
                  ...i?.unlock_details,
                  ...message?.unlock_details,
                  ...newData,
               },
            }

         }

         if(!!i?.parent && i?.parent?.id === message?.id) {
            if(!!message.unlock_details) {
               i.parent.unlock_details = message.unlock_details
            }

            if(!!message?.text) {
               i.parent.text = message.text
            }

            if(!!message?.duration_in_minutes) {
               i.parent.duration_in_minutes = message?.duration_in_minutes
               i.parent.show_duration_countdown_to_member = message?.show_duration_countdown_to_member
               i.parent.unsend_date = message?.unsend_date
            }
         }

         return i
      })

      return {
         ...state,
         ...newState,
         initedConverstionMessages: {
            ...state.initedConverstionMessages,
            [conversationId]: {
               ...state.initedConverstionMessages[conversationId],
               data: data,
            },
         },
      };
   },
   [types.UPDATE_CONVERSATION_DATA]: (state, action) => {
      const {
         conversationId,
         data,
      } = action.payload
      let newState = {}
      if(conversationId === state.groupConversation.chat_room_id) {
         newState = {
            ...newState,
            groupConversation: {
               ...state.groupConversation,
               ...data,
            },
         }
      } else {
         newState = {
            conversationsList: {
               ...state.conversationsList,
               data: [...state.conversationsList.data].map(elm => {
                  let { ...i } = elm

                  if(i.chat_room_id === conversationId){
                     i = {
                        ...i,
                        ...data,

                     }
                  }
                  return i
               }),
            },

         }
      }
      if(state.activeConverstaion && state.activeConverstaion.chat_room_id === conversationId) {
         newState = {
            ...newState,
            activeConverstaion: {
               ...state.activeConverstaion,
               ...data,
            },
         }
      }
      return {
         ...state,
         ...newState,

      };
   },

   [types.SET_ONLINE_USERS_ACTIONS]: (state, action) => {
      const {
         data, action: memberAction,
      } = action.payload
      let users = [...state.onlineUsers]
      switch (memberAction) {
         case 'push':
            users = [...users, { ...data }]
            break;
         case 'pop':
            users = [...users].filter(item => item.uuid !== data.uuid)
            break;

         default:
            users = [...data]
            break;
      }
      return {
         ...state,
         onlineUsers: users,
      };
   },
   [types.SET_TYPER_ACTION]: (state, action) => {
      const {
         data, isGroup, typers,
      } = action.payload;
      let newState = { ...state }
      if(isGroup) {
         newState = {
            ...newState,
            groupConversation: {
               ...state.groupConversation,
               typers: typers,
               user_is_typing: !isEmpty(typers),
            },
         }
      } else {
         const item = state.conversationsList.data.find(i => i.chat_room_id === data)
         if(item) {
            newState = {
               ...newState,
               conversationsList: {
                  ...state.conversationsList,
                  data: state.conversationsList.data.map(i => {
                     const { ...elm } = i
                     if(elm.chat_room_id === data) {
                        elm.user_is_typing = !isEmpty(typers)
                     }
                     return elm
                  }),
               },
            }
         }
      }
      if(state.activeConverstaion && state.activeConverstaion.chat_room_id === data) {
         newState = {
            ...newState,
            activeConverstaion: {
               ...state.activeConverstaion,
               typers: typers,
            },
         }
      }

      return newState
   },
   [types.SET_MUTE_MEMBER]: (state, action) => {
      const { info } = action.payload;
      let newSata = {}
      if(state.activeConverstaion && state.activeConverstaion.user_uuid === info.userUuid) {
         newSata = {
            activeConverstaion: {
               ...state.activeConverstaion,
               muted_since: info ? info.muted_since : null,
               muted_period_in_seconds: info ? info.mute_period_in_seconds : null,
               user_muted: info ? !!info.mute_period_in_seconds : false,
            },
         }
      }
      const conversationData = state.conversationsList ? state.conversationsList.data : []
      let data = [...conversationData].map((item) => {
         let { ...elm } = item
         if(elm.user_uuid === info.userUuid){
            elm.muted_since = info ? info.muted_since : null;
            elm.muted_period_in_seconds = info ? info.mute_period_in_seconds : null;
            elm.user_muted = info ? !!info.mute_period_in_seconds : false;
         }
         return elm

      })
      return {
         ...state,
         ...newSata,
         conversationsList: {
            ...state.conversationsList,
            data,
         },
      }
   },
   [types.FETCH_MEMBER_BY_UUID]: (state, action) => {
      const { data, isOpen, loadData, isGroup } = action.payload
      let newState = {}
      if(data) {
         if(isGroup) {
            newState = {
               ...newState,
               groupConversation: {
                  ...state.groupConversation,
                  member: { ...data },
               },
            }
         } else if(data.uuid && [...state.conversationsList.data].findIndex(item => item.user_uuid === data.uuid) > -1) {
            newState = {
               ...newState,
               conversationsList: {
                  ...state.conversationsList,
                  data: [...state.conversationsList.data].map(i => {
                     let { ...chat } = i;
                     if(data.uuid ===  i.user_uuid) {
                        chat.member = { ...data }
                     }
                     return chat
                  }),
               },
            }
         }
         if(!!state.activeConverstaion && (!state.activeConverstaion.user_uuid || state.activeConverstaion.user_uuid === data.uuid) && !state.cancelFlag) {
            newState = {
               ...newState,
               activeConverstaion: {
                  ...state.activeConverstaion,
                  member: {
                     ...data,
                  },
               },
            }
         }
      }
      return {
         ...state,
         ...newState,
         memberByUuid: {
            ...state.memberByUuid,
            isOpen,
            loadData,
         },
      }
   },
   [types.SET_SOKET]: (state, action) => {
      return {
         ...state,
         soket: action.payload,
      }
   },
   [types.CLEAR_CHAT_INFO]: (state, action) => {
      return {
         ...state,
         ...initialState,
         isSocketConnected: state.isSocketConnected,
      }
   },

   [types.SET_BLOCK_MEMBER]: (state, action) => {
      const {
         uuid,
         banned,
      } = action.payload
      let data = {}
      data = {
         activeConverstaion: {
            ...state.activeConverstaion,
            user_banned: banned,
            member: {
               ...state.activeConverstaion.member,
               banned,
            },
         },
      }
      if([...state.conversationsList.data].find(item => item.user_uuid === uuid)) {
         data = {
            ...data,
            conversationsList: {
               ...state.conversationsList,
               data: [...state.conversationsList.data].map(i => {
                  let { ...chat } = i;
                  if(uuid ===  chat.user_uuid) {
                     chat.user_banned = banned
                     if(chat.member) {
                        chat.member.banned = banned
                     }
                  }
                  return chat
               }),
            },
         }
      }

      return {
         ...state,
         ...data,
      }
   },

   [types.CANCEL_NEW_CONVERSATION]: (state, action) => {

      let newstate = {}
      if(state.activeConverstaion.isFake) {
         newstate = {
            activeConverstaion: {},
         }
      }
      if(state.descktopUserList && [...state.descktopUserList].find(chat => chat.isFake)) {
         newstate = {
            ...newstate,
            descktopUserList: [...state.descktopUserList].filter(chat => !chat.isFake),
            openDesctopDialogue: !state.activeConverstaion.isFake,
         }
      }
      return {
         ...state,
         ...newstate,
         conversationsList: {
            ...state.conversationsList,
            data: [...state.conversationsList.data].filter(chat => !chat.isFake),
         },
      }
   },
   [types.UPDATE_UNREAD_PM_MESSAGE_COUNT]: (state, action) => {
      return {
         ...state,
         unreadPrivateMessagesCount: state.unreadPrivateMessagesCount + action.payload,
      }
   },
   [types.UPDATE_CHAT_CONVERSATION_LIST]: (state, action) => {
      return {
         ...state,
         conversationsList: action.payload,
      }
   },

   [types.SET_OPEN_DK_CHAT_NOTIFICATIONS]: (state, action) => {
      return {
         ...state,
         openDkChatNotification: action.payload,
      }
   },

   [types.UPDATE_ONLINE_USER_USERNAME]: (state, action) => {
      return {
         ...state,
         onlineUsers: state.onlineUsers.map(user => {
            if(action.payload.uuid === user.uuid){
               user.username = action.payload.newUsername
            }
            return user;
         }),
      }
   },
   [types.FETCHING_CONVERSATION_START]: (state, action) => {
      const conversationId = action.payload
      return {
         ...state,
         fetchingConversationIds: [...state.fetchingConversationIds, conversationId],
      }
   },
   [types.FETCHING_CONVERSATION_COMPLETED]: (state, action) => {
      const conversation = action.payload

      return {
         ...state,
         fetchingConversationIds: state.fetchingConversationIds.filter(id => id !== conversation.chat_room_id),

      }
   },
   [types.FETCHING_CONVERSATION_FAILED]: (state, action) => {
      const conversationId = action.payload

      return {
         ...state,
         fetchingConversationIds: state.fetchingConversationIds.filter(id => id !== conversationId),
      }
   },
   [types.UPDATE_CONVERSATION_FROM_LIST]: (state, action) => {
      const { conversationId, data } = action.payload
      return {
         ...state,
         conversationsList: {
            ...state.conversationsList,
            data: state.conversationsList.data.map(item => {
               if(item.chat_room_id !== conversationId) return item
               return {
                  ...item,
                  ...data,
               }
            }),
         },
      }
   },
   [types.TOGGLE_PIN_UNPIN_CONVERSATION_COMPLETED]: (state, action) => {
      const { conversationId, newConversationsList } = action.payload

      const filteredFetchingConversationIds = state.fetchingConversationIds.filter(id => id !== conversationId)

      if(Boolean(newConversationsList)) {
         return {
            ...state,
            fetchingConversationIds: filteredFetchingConversationIds,
            conversationsList: newConversationsList,
         }
      }

      const pinnedConversation = state.conversationsList.data.find(item => item.chat_room_id === conversationId)
      pinnedConversation.is_pinned = true
      const filteredConversationsList = state.conversationsList.data.filter(item => item.chat_room_id !== conversationId)
      return {
         ...state,
         fetchingConversationIds: filteredFetchingConversationIds,
         conversationsList: {
            ...state.conversationsList,
            data: [pinnedConversation, ...filteredConversationsList],
         },
      }
   },
   [types.UPDATE_CONVERSATIONS_FILTER]: (state, action) => {
      const { updatedFields, allFilters } = action.payload

      if(Boolean(allFilters)) {
         return {
            ...state,
            conversationsFilter: { ...allFilters },
         }
      }

      return {
         ...state,
         conversationsFilter: {
            ...state.conversationsFilter,
            ...updatedFields,
         },
      }
   },
   [types.UPDATE_CONVERSATION_MESSAGES_BY_ID]: (state, action) => {
      const {
         activeConversation,
         // nickname,
         // memberId,
         member_nickname,
         username,
         memberUUID,
      } = action.payload

      let conversationId = activeConversation?.chat_room_id
      let groupChatConversationId = state?.groupConversation?.chat_room_id

      let currentConversationMessages = state.initedConverstionMessages?.[conversationId]
      let currentGroupConversationMessages = state.initedConverstionMessages?.[groupChatConversationId]


      let updatedConversationMessagesData = currentConversationMessages?.data
         ?.map((msg, ind) => {
            if(msg?.user?.id === activeConversation?.member?.id) {
               return {
                  ...msg,
                  user: {
                     ...msg.user,
                     nickname: member_nickname,
                  },

               }
            } else {
               return msg
            }
         })
         ?.map((msg, ind) => {
            if(msg?.parent?.user?.username === activeConversation?.member?.username) {
               return {
                  ...msg,
                  parent: {
                     ...msg.parent,
                     user: {
                        ...msg.parent?.user,
                        nickname: member_nickname,
                     },
                  },
               }
            } else {
               return msg
            }
         })

      let updatedGroupConversationMessagesData = currentGroupConversationMessages?.data

      if(!!groupChatConversationId && !!currentGroupConversationMessages?.data) {
         updatedGroupConversationMessagesData = currentGroupConversationMessages?.data
            ?.map((msg, ind) => {
               if(msg?.user?.id === activeConversation?.member?.id) {
                  return {
                     ...msg,
                     user: {
                        ...msg.user,
                        nickname: member_nickname,
                     },

                  }
               } else {
                  return msg
               }
            })
            ?.map((msg, ind) => {
               if(msg?.parent?.user?.username === activeConversation?.member?.username) {
                  return {
                     ...msg,
                     parent: {
                        ...msg.parent,
                        user: {
                           ...msg.parent?.user,
                           nickname: member_nickname,
                        },
                     },
                  }
               } else {
                  return msg
               }
            })
      }


      let currentConversationsList = state.conversationsList

      let updatedConversationList = currentConversationsList?.data
         .map((conv, ind) => {
            if(
               conv?.username === activeConversation?.member?.username
               // || conv?.member?.id === memberId
            ) {
               return {
                  ...conv,
                  // nickname,
                  member_nickname,
                  member: {
                     ...conv?.member,
                     nickname: member_nickname,
                  },
               }
            } else {
               return conv
            }
         })
         .map((conv, ind) => {
            if(
               conv?.username === username
            ) {
               return {
                  ...conv,
                  // nickname,
                  member_nickname,
                  member: {
                     ...conv?.member,
                     nickname: member_nickname,
                  },
               }
            } else {
               return conv
            }
         })
         .map((conv, ind) => {
            if(
               !!username && memberUUID === conv?.user_uuid
            ) {
               return {
                  ...conv,
                  member: {
                     ...conv?.member,
                     username,
                  },
                  username,
               }
            } else {
               return conv
            }
         })

      let updatedGroupConversation = state.groupConversation

      if(updatedGroupConversation?.last_message?.username === username) {
         updatedGroupConversation = {
            ...updatedGroupConversation,
            last_message: {
               ...updatedGroupConversation.last_message,
               nickname: member_nickname,
            },
         }
      }
      if(updatedGroupConversation?.member && updatedGroupConversation?.member?.username  === username) {
         updatedGroupConversation = {
            ...updatedGroupConversation,
            member: {
               ...updatedGroupConversation?.member,
               nickname: member_nickname,
            },
         }
      }

      let onlineUsers = state?.onlineUsers

      onlineUsers = onlineUsers.map((user) => {
         if(user?.username === username) {
            return {
               ...user,
               nickname: member_nickname,
            }
         } else {
            return {
               ...user,
            }
         }
      })

      let newState = {
         ...state,
         conversationsList: {
            ...state.conversationsList,
            data: updatedConversationList,
         },
         groupConversation: updatedGroupConversation,
         onlineUsers,
      }
      if(!!conversationId) {
         newState = {
            ...state,
            initedConverstionMessages: {
               ...state.initedConverstionMessages,
               [conversationId]: {
                  ...state.initedConverstionMessages[conversationId],
                  data: updatedConversationMessagesData,
               },
            },
            conversationsList: {
               ...state.conversationsList,
               data: updatedConversationList,
            },
            groupConversation: updatedGroupConversation,
            onlineUsers,
         }

      }

      if(!!groupChatConversationId && !!updatedGroupConversationMessagesData) {
         newState = {
            ...state,
            initedConverstionMessages: {
               ...state.initedConverstionMessages,
               [conversationId]: {
                  ...state.initedConverstionMessages[conversationId],
                  data: updatedConversationMessagesData,
               },
               [groupChatConversationId]: {
                  ...state.initedConverstionMessages[conversationId],
                  data: updatedGroupConversationMessagesData,
               },
            },
            conversationsList: {
               ...state.conversationsList,
               data: updatedConversationList,
            },
            groupConversation: updatedGroupConversation,
            onlineUsers,
         }
      }

      if(!!newState?.initedConverstionMessages
      // && conversationId === groupChatConversationId
      ) {
         let initedConversationsData = Object.entries(newState?.initedConverstionMessages)

         initedConversationsData = initedConversationsData
            .filter(it => it[0] !== undefined)
            .map((couple) => {
               const id = couple[0]
               const messagesData = couple[1]
               if(messagesData?.data) {
                  return [
                     id,
                     {
                        ...messagesData,
                        data: messagesData?.data.map((cnv) => {
                           if(cnv?.user?.username === username) {

                              return {
                                 ...cnv,
                                 user: {
                                    ...cnv?.user,
                                    nickname: member_nickname,
                                 },
                              }
                           } else {
                              return cnv
                           }
                        }),
                     },
                  ]
               } else {
                  return couple
               }

            })
         const updatedInitedConversationsData = Object.fromEntries(initedConversationsData)

         newState = {
            ...state,
            initedConverstionMessages: updatedInitedConversationsData,
            conversationsList: {
               ...state.conversationsList,
               data: updatedConversationList,
            },
            groupConversation: updatedGroupConversation,
            onlineUsers,
         }
      }


      return newState;
   },
   [types.CHAT_INIT_COMPLETED]: (state, action) => {
      const {
         group,
         unread_private_messages_count,
         member_video_download_multiple_watermarks,
      } = action.payload
      return {
         ...state,
         groupConversation: group,
         unreadPrivateMessagesCount: unread_private_messages_count,
         memberVideoDownloadMultipleWatermarks: member_video_download_multiple_watermarks,
      }
   },
};

export default createReducer(initialState)(reducersMap);
