
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
import GenerateColor from 'common/utils/generateTextColor.js'
import Modal from 'common/components/modules/modal';


const PreviewButton = ({
   onClick,
   iconName,
   tooltipText,
}) => {
   return (
      <div data-tooltip-content={ tooltipText } data-tooltip-id='ams-top-tooltip'>
         <Button
            iconName={ iconName }
            onClick={ onClick }
            padding='1'
            fontIconColor='major-dark'
            backgroundColor='transparent'
            classNames='h-full w-full items-center justify-center'
            fontIconSize='doubleLarge'
            iconPosition='left'
            waveActive={ false }
            alignment='end'
            textSize='base'
         />
      </div>
   )
}

const PreviewVideoPlayer = ({
   isLockedForSFW,
   onLoadedData,
   muted,
   autoPlay,
   previewSrc,
   videoClassName,
   loaded,
   videoRef,
   onTimeUpdate,
}) => {
   return (
      <video
         ref={ videoRef }
         style={ {
            transition: 'display 0.2s',
            filter: isLockedForSFW ? 'blur(32px)' : '',
         } }
         onLoadedData={ onLoadedData }
         muted={ muted }
         autoPlay={ autoPlay }
         onTimeUpdate={ _ => {
            if(onTimeUpdate){
               onTimeUpdate()
            }
         } }
         loop
         playsInline
         className={ `h-full w-full absolute top-0 left-0 z-1 ${ loaded ? 'hidden' : 'block' } ${ videoClassName }` }>
         <source src={ previewSrc } type='video/mp4' />
         <source src={ previewSrc } type='video/ogg' />
         Your browser does not support the video tag.
         <track kind='captions' muted={ muted } />
      </video>
   )
}
const PreviewVideoVolumeButton = ({
   setMuted,
   muted,
}) => {
   return (
      <PreviewButton
         tooltipText={ muted ? 'Unmute' : 'Mute' }
         iconName={ muted ? 'volume-off' : 'volume-on' }
         onClick={ e => {
            e.preventDefault()
            setMuted(!muted)
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation();
         } }
      />
   )
}
const PreviewVideoFullscreenButton = ({
   setOpenFullScreen,
   openFullscreen,
}) => {
   return (
      <PreviewButton
         tooltipText='Full screen'
         iconName={ openFullscreen ? 'fullscreen-exit' : 'full-screen' }
         onClick={ e => {
            e.preventDefault();
            setOpenFullScreen();
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
         } }
      />
   )
}
const PreviewVideoProgress = ({
   width,
}) => {
   return (
      <div
         style={ {
            background: GenerateColor.generateRGBColor(window.skinPrimaryColor, 0.12),
         } }
         className='h-[3px] w-full rounded-lg flex overflow-hidden z-30'>
         <div
            className='h-full'
            style={ {
               background: window.skinPrimaryColor,
               width: `${ width }%`,
               transition: 'all 0.75s ease-out',
            } }
         />
      </div>
   )
}
const PreviewVideoFullScreen = ({
   openFullscreen,
   setOpenFullScreen,
   showVolumeButton,
   autoPlay,
   previewSrc,
}) => {
   const [loaded, setLoaded] = useState(true);
   const [muted, setMuted] = useState(true);
   const [width, setWidth] = useState(0);
   const [progressWidth, setProgressWidth] = useState(0);
   const [isPlayVideo, setIsPlayVideo] = useState(true);
   const videoRef = useRef(null)
   const progressContainerRef = useRef(null)
   let interval = null;
   useEffect(() => {
      return () => {
         clearInterval(interval);
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   const onLoadedData = (e) => {
      interval = setTimeout(() => {
         setWidth(100);
      }, 10)
      interval = setTimeout(() => {
         setLoaded(false);
      }, 710)
   }
   return (
      <Modal
         isCloseAction={ false }
         contentPosition='full-screen'
      >
         <div
            role='presentation'
            onClick={ (e) => {
               e.preventDefault();
               e.stopPropagation()
            } }
            className='flex h-full relative bg-black'>
            {
               loaded && (
                  <PreviewVideoProgress
                     width={ width }
                  />
               )
            }
            <PreviewVideoPlayer
               autoPlay={ autoPlay }
               previewSrc={ previewSrc }
               onLoadedData={ onLoadedData }
               loaded={ loaded }
               videoRef={ videoRef }
               onTimeUpdate={ () => {
                  const video = videoRef.current;
                  const progress = (video.currentTime / video.duration) * 100;
                  setProgressWidth(progress)
               } }
               muted={ muted }
            />
            {
               !loaded && (
                  <>
                     <div className='flex h-6 w-6 items-center justify-center absolute top-0 right-0 m-4 z-2'>
                        <PreviewButton
                           iconName='close'
                           onClick={ _ => setOpenFullScreen(false) }
                        />
                     </div>
                     <div className='px-4 w-full justify-between flex gap-3 py-2 z-2 absolute bottom-0 left-0 bg-major-20'>
                        <div className='flex flex-1 gap-3 items-center'>
                           <div className='flex h-6 w-6'>
                              <PreviewButton
                                 onClick={ _ => {
                                    if(videoRef.current){
                                       if(isPlayVideo){
                                          videoRef.current.pause()
                                          setIsPlayVideo(false);
                                       } else {
                                          videoRef.current.play()
                                          setIsPlayVideo(true);
                                       }
                                    }
                                 } }
                              />
                           </div>
                           <div
                              onClick={ (e) => {
                                 const video = videoRef.current
                                 const progressContainer = progressContainerRef.current
                                 const rect = progressContainer.getBoundingClientRect();
                                 const clickPosition = e.clientX - rect.left;
                                 const clickRatio = clickPosition / rect.width;
                                 video.currentTime = clickRatio * video.duration;
                              } }
                              ref={ progressContainerRef }
                              role='presentation'
                              className='w-full h-1 rounded-full cursor-pointer bg-placeholder'>
                              <div
                                 className='h-full bg-panel rounded-lg'
                                 style={ {
                                    transition: 'width 0.1s linear',
                                    width: `${ progressWidth }%`,
                                 } }
                                 id='progress-bar'></div>
                           </div>
                        </div>
                        <div className='flex gap-3'>
                           <div className='flex h-6 w-6'>
                              <PreviewVideoFullscreenButton
                                 openFullscreen={ openFullscreen }
                                 setOpenFullScreen={ _ => setOpenFullScreen(false) }
                              />
                           </div>
                           {
                              showVolumeButton && (
                                 <div className='flex h-6 w-6'>
                                    <PreviewVideoVolumeButton
                                       muted={ muted }
                                       setMuted={ setMuted }
                                    />
                                 </div>
                              )
                           }

                        </div>
                     </div>
                  </>
               )
            }
         </div>
      </Modal>
   )
}

PreviewButton.propTypes = {
   onClick: PropTypes.func,
   iconName: PropTypes.string,
   tooltipText: PropTypes.string,
}
PreviewVideoPlayer.propTypes = {
   previewSrc: PropTypes.string,
   onLoadedData: PropTypes.func,
   autoPlay: PropTypes.bool,
   videoClassName: PropTypes.string,
   muted: PropTypes.bool,
   isLockedForSFW: PropTypes.bool,
   loaded: PropTypes.bool,
   videoRef: PropTypes.any,
   onTimeUpdate: PropTypes.func,
}
PreviewVideoVolumeButton.propTypes = {
   setMuted: PropTypes.func,
   muted: PropTypes.bool,
}
PreviewVideoFullscreenButton.propTypes = {
   setOpenFullScreen: PropTypes.func,
   openFullscreen: PropTypes.bool,
}
PreviewVideoFullScreen.propTypes = {
   setOpenFullScreen: PropTypes.func,
   openFullscreen: PropTypes.bool,
   showVolumeButton: PropTypes.bool,
   autoPlay: PropTypes.bool,
   previewSrc: PropTypes.string,
}
PreviewVideoProgress.propTypes = {
   width: PropTypes.number,
}

export {
   PreviewVideoVolumeButton,
   PreviewVideoFullscreenButton,
   PreviewVideoPlayer,
   PreviewVideoProgress,
   PreviewVideoFullScreen,
}
