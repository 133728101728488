
import classNames from "classnames";
import html2canvas from "html2canvas";
import { useEffect, useRef, useState } from "react";
import Modal from "../../modal";
import Button from "common/components/elements/buttons/primary";
import CustomizableShareQR from "../customizable-share-qr";
import  ColorPicker  from 'common/components/elements/color-picker';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import toast from "common/utils/toast";
import { downloadMediaFromUrl } from "common/utils/utils";
import IconLabel from "common/components/elements/icon-label";
import HorizontalTabs from "../horizontal-tabs";
import RadioLabel from "common/components/elements/inputs/radio-label";
import Input from "common/components/elements/inputs/inputs";
import PropTypes from "prop-types";


const tabs = [
   {
      name: 'QR color',
      value: 'qr_color',
   },
   {
      name: 'Background color',
      value: 'qr_background',
   },
]

const CustomizeQrModal = ({
   isAdmin,
   onCloseModal,
   shareUrl,
   sitePrefix,
   qrDefaultStyles,
   qrCustomizedState,
   onSaveQRSettings,
   isLoadingSaveQrStyles,
   screenWidth,
   appIcon,
}) => {
   const [activeTabValue, setActiveTabValue] = useState('qr_color')
   const [qrColor, setQrColor] = useState(qrCustomizedState.qrColor)
   const [qrBackground, setQrBackground] = useState(qrCustomizedState.qrBackground)
   const [isBgTransparent, setIsBgTransparent] = useState(qrCustomizedState.isBgTransparent)
   const [isOpenDiscardModal, setIsOpenDiscardModal] = useState(false);
   const [isQrDownloading, setIsQrDownloading] = useState(false)

   let qrWrapperRef = useRef(null)

   const openCloseDiscardModal = (bool) => {
      setIsOpenDiscardModal(bool)
   }

   useEffect(() => {
      const { qrColor: savedQrColor, qrBackground: savedQrBackground, isBgTransparent: savedIsBgTransparent } = qrCustomizedState;

      setQrColor(savedQrColor)
      setQrBackground(savedQrBackground)
      setIsBgTransparent(savedIsBgTransparent)

   }, [qrCustomizedState]);


   const onChangeTab = (tabValue) => {
      setActiveTabValue(tabValue)
   }

   const onTransparentChange = (isTransparent) => {
      setIsBgTransparent(isTransparent)
   }

   const onColorChange = (color) => {
      let hex = color && color.hex ? color.hex : color

      switch (activeTabValue) {
         case 'qr_color':
            setQrColor(hex)
            break;

         case 'qr_background':
            setQrBackground(hex)
            break;

         default:
            break;
      }
   }

   const onClickRevertToDefault = () => {
      setQrColor(qrDefaultStyles.qrColor)
      setQrBackground(qrDefaultStyles.qrBackground)
      setIsBgTransparent(qrDefaultStyles.isBgTransparent)
   }

   const onClickSaveChanges = () => {
      onSaveQRSettings({ qrColor, qrBackground, isBgTransparent })
   }

   const onClickDownload = async () => {
      if(!qrWrapperRef) return;

      setIsQrDownloading(true)

      try {
         const canvas = await html2canvas(qrWrapperRef, {
            useCORS: true,
            backgroundColor: null,
         })
         downloadMediaFromUrl(canvas.toDataURL(), false, sitePrefix)
      } catch (error) {
         console.log(error);
         toast.error('Something went wrong')
      } finally {
         setIsQrDownloading(false)
      }
   }

   const handleCloseModal = () => {
      if(
         isAdmin && checkDiff()
      ) {
         openCloseDiscardModal(true)
         return;
      }
      onCloseModal()
   }

   const checkDiff = () => {
      return qrColor !== qrCustomizedState.qrColor || qrBackground !== qrCustomizedState.qrBackground || isBgTransparent !== qrCustomizedState.isBgTransparent
   }

   const isDefaultSettings = () => {
      return qrColor !== qrDefaultStyles.qrColor || qrBackground !== qrDefaultStyles.qrBackground || isBgTransparent !== qrDefaultStyles.isBgTransparent
   }

   return (
      <>
         <Modal
            onCloseModal={ onCloseModal }
            contentPosition={ screenWidth > 900 ? 'center' : 'bottom' }
            contentWidth={ screenWidth > 900 ? '720px' : 'auto' }
         >
            <div className='flex flex-col'>
               <div className={ `flex items-center justify-end h-14 mr-[18px]` }>
                  <Button
                     iconName='close'
                     fontIconColor='secondary'
                     darkIconColor='secondary-dark'
                     backgroundColor='transparent'
                     onClick={ handleCloseModal }
                     padding='none'
                     classNames='!w-fit'
                     fontIconSize='extraLarge'
                  />
               </div>
               <div className='flex flex-col gap-3 items-center'>
                  <IconLabel
                     isPrimary
                     name={ 'customize' }
                     size={ '2xl' }
                     className='h-10 w-10 shrink-0'
                  />
                  <p className='font-semibold text-major dark:text-major-dark text-xl'>Customize QR</p>
               </div>
            </div>
            <div
               class='w-full mt-2 flex flex-col gap-6 p-6 pt-0'
            >
               <HorizontalTabs
                  tabs={ tabs }
                  activeTabValue={ activeTabValue }
                  onChangeTab={ onChangeTab }
               />
               <div
                  class='w-full flex gap-4 justify-center flex-wrap lg:flex-nowrap overflow-y-auto ams-custom-scrool max-tablet:max-h-[37vh]'
               >
                  <CustomizableShareQR
                     wrapperClass='w-[328px] h-[328px] shrink-0'
                     shareUrl={ shareUrl }
                     sitePrefix={ sitePrefix }
                     backgroundColor={ isBgTransparent ? 'transparent' : qrBackground }
                     qrColor={ qrColor }
                     wrapperRef={ (ref) => qrWrapperRef = ref }
                     appIcon={ appIcon }
                  />
                  <div
                  // class='w-full flex flex-col flex-1 min-w-[339px] rounded-lg border border-divider py-1'
                     class={ classNames({
                        'w-full flex flex-col flex-1 min-w-[328px] rounded-lg [@media(min-width:720px)]:border border-divider dark:border-divider-dark': true,
                        '[@media(min-width:720px)]:p-3': 'qr_color' === activeTabValue,
                        'py-1': 'qr_background' === activeTabValue,
                        // 'bg-hover': 'qr_background' === activeTabValue && !isBgTransparent,
                     }) }
                  >
                     {
                        'qr_color' === activeTabValue && (
                           <div className='flex flex-col'>
                              <ColorPicker
                                 handleChangeColor={ (color) => onColorChange(color) }
                                 color={ qrColor }
                              />
                              {
                                 <div
                                    class='flex items-center gap-2'
                                 >
                                    <Input
                                       height='10'
                                       maxlength='7'
                                       value={ '#' + qrColor?.split('')?.filter(char => char !== '#')?.join('') }
                                       inputClassName='!py-2.5'
                                       onChange={ (_, value) => onColorChange(value) }
                                    />
                                 </div>
                              }
                           </div>
                        )
                     }
                     {
                        'qr_background' === activeTabValue && (
                           <>
                              <RadioLabel
                                 label='Transparent background'
                                 id='transparent_background'
                                 onChange={ () => onTransparentChange(true) }
                                 labelClassName={ classNames({
                                    'flex-row-reverse !justify-between hover:bg-hover dark:hover:bg-hover-dark !h-fit': true,
                                    'bg-hover dark:bg-hover-dark': isBgTransparent,
                                 }) }
                                 checked={ isBgTransparent }
                                 paddingY='2.5'
                              />
                              <div
                                 class='w-full flex flex-col h-full'
                              >
                                 <RadioLabel
                                    label='Color background'
                                    id='color_background'
                                    onChange={ () => onTransparentChange(false) }
                                    labelClassName={ classNames({
                                       'flex-row-reverse !justify-between hover:bg-hover dark:hover:bg-hover-dark !h-fit': true,
                                       'bg-hover dark:bg-hover-dark': !isBgTransparent,
                                    }) }
                                    paddingY='2.5'
                                    checked={ !isBgTransparent }
                                 />
                                 {
                                    !isBgTransparent && (
                                       <div
                                          class='w-full py-2 px-3 bg-hover dark:bg-hover-dark'
                                       >
                                          <ColorPicker
                                             handleChangeColor={ (color) => onColorChange(color) }
                                             color={ qrBackground }
                                          />
                                          {
                                             <div
                                                class='flex items-center gap-2'
                                             >
                                                <Input
                                                   height='10'
                                                   inputClassName='!py-2.5'
                                                   value={ '#' + qrBackground?.split('')?.filter(char => char !== '#')?.join('') }
                                                   onChange={ (_, value) => onColorChange(value) }
                                                   maxlength='7'
                                                />
                                             </div>
                                          }
                                       </div>
                                    )
                                 }
                              </div>
                           </>
                        )
                     }
                  </div>
               </div>
               <div
                  class='w-full flex flex-col items-center gap-3 mt-2'
               >
                  <Button
                     text='Revert to default QR'
                     iconName='revert'
                     classNames='h-9 '
                     iconPosition='left'
                     textSize='small'
                     textColor='secondary dark:text-secondary-dark'
                     fontIconColor='secondary'
                     darkIconColor='secondary-dark'
                     disabled={ !isDefaultSettings() }
                     onClick={ onClickRevertToDefault }
                     backgroundColor='transparent'
                  />
                  {
                     isAdmin ? (
                        <>
                           <Button
                              primaryColor
                              text='Save changes'
                              textSize='small'
                              onClick={ onClickSaveChanges }
                              isLoading={ isLoadingSaveQrStyles }
                              classNames='h-9'
                              borderRadius='large'
                           />
                           <Button
                              text='Cancel'
                              classNames='h-9 '
                              textSize='small'
                              textColor='secondary'
                              backgroundColor='transparent'
                              onClick={ handleCloseModal }
                           />
                        </>
                     ) : (
                        <Button
                           primaryColor
                           text='Download'
                           iconName='download'
                           iconPosition='left'
                           textSize='small'
                           onClick={ onClickDownload }
                           classNames='h-9'
                           borderRadius='large'
                           isLoading={ isQrDownloading }
                        />
                     )
                  }
               </div>
            </div>
         </Modal>
         {
            isOpenDiscardModal &&
               <ConfirmModal
                  type='confirm'
                  icon='cancel'
                  iconColor='error'
                  descriptionColorClass='text-secondary'
                  onCloseModal={ () => {
                     openCloseDiscardModal(false)
                  }  }
                  title='Discard changes?'
                  description='Changes you have made so far will not be saved'
                  cancelText='Keep editing'
                  nonPrimaryColor
                  contentWidth='375px'
                  isMobile={ screenWidth < 1025 }
                  iconSize='2xl'
                  buttonText='Discard changes'
                  textColor='error'
                  action={ () => {
                     onCloseModal()
                  } }
                  buttonClassName='mt-8'
                  modalClassName='!z-[100]'
               />
         }
      </>
   )
}

CustomizeQrModal.propTypes = {
   isAdmin: PropTypes.bool,
   onCloseModal: PropTypes.func,
   shareUrl: PropTypes.string,
   sitePrefix: PropTypes.string,
   qrDefaultStyles: PropTypes.object,
   qrCustomizedState: PropTypes.object,
   onSaveQRSettings: PropTypes.func,
   isLoadingSaveQrStyles: PropTypes.bool,
   screenWidth: PropTypes.number,
   appIcon: PropTypes.any,
};


export default CustomizeQrModal;
