import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
   videosDataSelector,
   isVideosDataFetching,
   isNewFetchingSelector,
   isFetchingByFilterSelector,
   isEmptySelector,
   isEmptyByFilterSelector,
   deleteButtonDisabledSelector,

   videosDataInsideModal,
   isVideosDataInsideModalFetching,
   isFetchingByFilterInsideModalSelector,
   isNewFetchingInsideModalSelector,
   isEmptyByFilterInsideModalSelector,
   isEmptyInsideModalSelector,
   isInitedSelector,
   filtersSelector,
   isDistoryFetchingSelector,
   warningModalSelector,
   filtersInsideModalSelector,
   isFetchingRemoveDrmFromVideoSelector,

} from 'admin/state/modules/videos/selectors';
import {
   resourcesSelector,
   mediaDownloadButtonSelector,
   countesStateSelector,
} from 'admin/state/modules/resources/selectors';
import { downloadMediaOperation, fetchMediaCountsStateOperation } from 'admin/state/modules/resources/operations';
import {
   fetchVideosOperation,
   fetchVideosByFilterOperation,
   videosNextPageOperation,
   deleteVideoOperation,
   destroyVideosOperation,
   pinVideoOperations,
   removeDrmProtectionOperation,
} from 'admin/state/modules/videos/operations';
import {
   clearState,
   setFilterData,
   setBulkIds,
   reorderVideos,
   changeInitedState,
   // updateBundleStateInData,
   setWarningModal,
   updateMessageAction,
} from 'admin/state/modules/videos/actions';
import QueryParams from 'admin/utils/QueryParams';
import VideosMainPage from 'admin/views/videos/index';
import PageLoader from 'common/components/modules/page-loading';
import toast from 'common/utils/toast';
import { startUploadOperation } from 'admin/state/modules/uploads/operations';
import EmptyState from 'common/components/modules/empty-states';
import intercomMoreInfo, { MORE_INFO_ON_VIDEOS } from 'admin/utils/IntercomMoreInfo';
import { authUserSelector, contentMetaInfoSelector, screenWidthSelector, siteInfoSelector, siteStepsSelector } from 'admin/state/modules/common/selectors';
import DeleteModal from 'admin/views/manage-resource/delete-modal';
import Modal from 'common/components/modules/modal';
import Router from 'admin/routes/router';
import { push } from 'connected-react-router';
import PullRefresh from 'admin/utils/PullRefresh.js';
import PullToRefresh from 'pulltorefreshjs';
import { checkAndUnlockDemoPublicSiteOperation, loginAsOperation, siteDetailsRefreshOperation } from 'admin/state/modules/common/operations';
import { isEmpty } from 'lodash'
import { getCountState } from 'common/utils/utils'
import { isMobileAndTablet } from 'common/utils/utils';
import TippersModal from 'admin/views/tips/tippers-details-modal'
import { changeContentOrders, getContentTipHistoryById,
   // getVideoById,
   getPinnedCountStatistics } from 'admin/api/AuthApi';
import CheckboxLabel from 'common/components/elements/inputs/checkbox-label';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import { contentMetaInfo } from 'admin/state/modules/common/actions';
import { history } from 'admin/state/store';
import { generateUriSegment } from 'common/utils/mediaRouteHelper';
// import { generateUriSegment } from 'common/utils/mediaRouteHelper';

import { getAllowedVideoExtentions } from 'common/utils/utils';
import VideoDrmConfirmModals from 'admin/views/videos/moduls/video-drm-confirm-modals';

class VideosContainer extends Component {
   static propTypes = {
      list: PropTypes.object,
      resources: PropTypes.object,
      isFetching: PropTypes.bool.isRequired,
      fetchVideos: PropTypes.func.isRequired,
      startUpload: PropTypes.func.isRequired,
      fetchVideosByFilter: PropTypes.func,
      videosNextPage: PropTypes.func,
      isNewFetching: PropTypes.bool,
      isFetchingByFilter: PropTypes.bool,
      isEmptyByFilter: PropTypes.bool,
      isEmpty: PropTypes.bool,
      screenWidth: PropTypes.number,
      deleteButtonDisabled: PropTypes.bool,
      deleteVideo: PropTypes.func,
      goTo: PropTypes.func,
      siteInfo: PropTypes.object,
      refreshSiteDetails: PropTypes.func,
      mediaDownloadButton: PropTypes.bool,
      downloadFile: PropTypes.func,
      isInsideModal: PropTypes.bool,
      onSelectThisVideo: PropTypes.func,
      isFetchingInsideModal: PropTypes.bool,
      listInsideModal: PropTypes.object,
      isFetchingByFilterInsideModal: PropTypes.bool,
      isNewFetchingInsideModal: PropTypes.bool,
      isEmptyByFilterInsideModal: PropTypes.bool,
      isEmptyInsideModal: PropTypes.bool,
      clearState: PropTypes.func,
      filters: PropTypes.object,
      isInited: PropTypes.bool,
      // setFilterData: PropTypes.func,
      destroyVideos: PropTypes.func,
      isDistoryFetching: PropTypes.bool,
      setBulkIds: PropTypes.func,
      fetchCountsState: PropTypes.func,
      countesState: PropTypes.object,

      showNewStyles: PropTypes.bool,
      showCheckboxes: PropTypes.bool,
      selections: PropTypes.array,
      reorderVideos: PropTypes.func,
      contentMetaInfo: PropTypes.object,
      updateContentMetaInfo: PropTypes.func,
      loginAs: PropTypes.func,
      changeInitedState: PropTypes.func,
      // showAllContent: PropTypes.bool,
      // updateBundleStateInData: PropTypes.func,
      pinVideo: PropTypes.func,
      contentType: PropTypes.string,
      prevetInsideModalDiscard: PropTypes.func,
      bundleId: PropTypes.any,
      setWarningModal: PropTypes.func,
      warningModal: PropTypes.object,
      isMultipleSelect: PropTypes.bool,
      conversationId: PropTypes.number,
      onDeleteMessag: PropTypes.func,
      updateMessageAction: PropTypes.func,
      isMultipleAttachments: PropTypes.bool,
      updateMessageUnlockDetails: PropTypes.func,
      filtersInsideModal: PropTypes.object,
      hideScheduledContents: PropTypes.bool,
      hideUnpublishedContents: PropTypes.bool,
      checkAndUnlockDemoPublicSite: PropTypes.func,
      authUser: PropTypes.object,
      removeDrmProtection: PropTypes.func,
      isFetchingRemoveDrmFromVideo: PropTypes.bool,
      isCastMemberProfilePage: PropTypes.bool,
      castMemberId: PropTypes.number,
      showOnlyList: PropTypes.bool,
   };

   static defaultProps = {
      // showAllContent: false,
      isMultipleSelect: true,
      hideScheduledContents: false,
      hideUnpublishedContents: false,
   }

   state = {
      videoDataWithTips: null,
      pinnedCount: {},
   }

   constructor(props) {
      super(props);

      const tab = QueryParams.getParam('tab');
      const activeTab = this.getNeedyTabName(tab);
      this.state = {
         activeTab: activeTab,
         uploadLimitReached: false,
         openDeletModal: false,
         emptyFilters: true,
         isTippersModalOpen: false,
         contentId: null,
         isReorderMode: !props.isInsideModal && history.location.pathname === '/videos/reorder',
         confirmModal: {
            isOpen: false,
         },
         reorderedData: {
            newData: [],
            newIndex: null,
         },
         removeDrmConfirmModalData: null,
      };
      this.onShowData = {};
      this.fileUploader = React.createRef();
      this.filter = {
         count: 20,
         page: 1,
      }
      if(props.isInsideModal) {
         this.filter = {
            ...this.filter,
            // status: props.showAllContent ? [] : 'published,unpublished',
            insideModal: 1,
            hideScheduledContents: !!this.props?.hideScheduledContents,
            hideUnpublishedContents: !!this.props?.hideUnpublishedContents,
            ...props.filtersInsideModal,
         }
      }
      if(props.isCastMemberProfilePage) {
         this.filter = {
            ...this.filter,
            casts: String(props.castMemberId),
         }
      }
      this.deleteVideoId = null
      this.keepInitedState = !!props.isMultipleAttachments
      this.key = !props.isInsideModal ? '' : 'InsideModal'
      this.alreadyFetched = false
      if(!props.isInsideModal && !props.isCastMemberProfilePage){
         this.generateUrlQuery(props.filters, true)
      }
   }

   componentDidMount() {
      const {
         fetchVideos,
         fetchVideosByFilter,
         refreshSiteDetails,
         siteInfo: {
            site_status: siteStatus,
         },
         isInsideModal,
         isInited,
         fetchCountsState,
         conversationId,
         listInsideModal,
         isCastMemberProfilePage = false,
      } = this.props;
      if(window.videoScrollTop && isInited) {
         window.scroll({
            top: window.videoScrollTop,
            left: 0,
         });
      }
      const params = QueryParams.getFilters()
      const emptyFilterParams = isEmpty(params)
      this.key = !isInsideModal ? '' : 'InsideModal'

      if(conversationId){
         this.filter = {
            ...this.filter,
            conversation: conversationId,
         }
      }
      const query = this.generateQuery();
      if(!isInsideModal) {
         fetchCountsState(query);
         this.setState({
            emptyFilters: emptyFilterParams,
         })
      } else {
         const {
            clearState,
            isMultipleAttachments,
            // filters,
            filtersInsideModal,
         } = this.props
         if(isMultipleAttachments) {
            this.filter = {
               ...this.filter,
               // ...filters,
               ...filtersInsideModal,
            }
            const { count,
               // status
            } = this.filter
            let otherKeys = Object.keys(this.filter).filter(it => !(['conversation', 'count', 'insideModal', 'page', 'status', 'hideScheduledContents', 'hideUnpublishedContents'].includes(it)))

            this.setState({
               // emptyFilters: (count === 20 && status === 'published,unpublished') && (otherKeys.length === 0),
               emptyFilters: count === 20 && otherKeys.length === 0,
            })

            clearState({ filtersInsideModal: this.filter  })
         } else {
            clearState({ filtersInsideModal: {} })
         }
      }
      if(isInsideModal){
         let element = document.querySelector('#select-from-videos')
         if(element) {
            element.scrollTo({ top: 0 })
            element.addEventListener('scroll', this.handleScroll);
         }
      } else {
         window.addEventListener('scroll', this.handleScroll);
      }

      if(params){
         const newDialogData = {
            isPublish: !!Number(params.publish),
            isScheduled: !!Number(params.scheduled),
            schedulDate: params.publishDate,
            id: params.id,
         }
         this.setState({
            isAdded: params.add === 'success',
            dialogData: newDialogData,
         });
      }

      let param = []

      if('live' !== siteStatus) {
         refreshSiteDetails();
      }

      PullToRefresh.init({
         ...PullRefresh.init(),
         onRefresh: (done) => {
            this.filter = { ...this.filter, page: 1 };
            const param = this.generateQuery()
            if(param){
               fetchVideosByFilter(param, this.key, this.state.isReorderMode)
               done();
            } else {
               fetchVideos(param, this.key, emptyFilterParams, this.state.isReorderMode, isCastMemberProfilePage)
               done();
            }
         },
      });

      if(!isInited || isCastMemberProfilePage) {
         if(history.location.pathname === '/videos/reorder' && !isInsideModal) {
            this.filter = {
               count: 20,
               page: 1,
               status: ['published'],
            }
         }
         param = this.generateQuery()
         fetchVideos(param, this.key, emptyFilterParams, this.state.isReorderMode, isCastMemberProfilePage);
      } else if(isInsideModal && !listInsideModal?.data) {
         param = this.generateQuery()
         fetchVideos(param, this.key, emptyFilterParams, this.state.isReorderMode);
      }

      if(!isInsideModal){
         getPinnedCountStatistics('videos')
            .then(resp => {
               this.setState({
                  pinnedCount: {
                     ...resp?.data,
                  },
               })
            })
            .catch(err => console.log(err))
      }
   }
   componentWillUnmount() {
      const { isInsideModal, clearState, isMultipleAttachments } = this.props
      this.onCloseWarningModal();

      if(isMultipleAttachments && isInsideModal) {
         clearState({ filtersInsideModal: this.filter, isInited: true  })
      } else {
         clearState({ filters: this.keepInitedState ? this.filter : {}, isInited: this.keepInitedState })
      }

      window.videoScrollTop = window.pageYOffset
      if(isInsideModal){
         let element = document.querySelector('#select-from-videos')
         if(element) element.removeEventListener('scroll', this.handleScroll);
      } else {
         window.removeEventListener('scroll', this.handleScroll);
      }
      PullRefresh.destroyAll();
   }

   componentDidUpdate(){
      const { isFetchingInsideModal, prevetInsideModalDiscard, isInsideModal } = this.props

      if(!!prevetInsideModalDiscard && isInsideModal) {
         prevetInsideModalDiscard(isFetchingInsideModal)
      }
   }

   handleAddNewVideo = () => {
      this.fileUploader.current.click();
   };

   handleScroll = (e) => {
      const { isFetching, isInsideModal, isFetchingInsideModal, isFetchingByFilterInsideModal } = this.props
      if(!isInsideModal && isFetching) return
      if(isInsideModal && (isFetchingInsideModal || isFetchingByFilterInsideModal)) return
      const { scrollHeight, clientHeight, scrollTop } = isInsideModal ? e.target : document.documentElement;
      const { isAdded } = this.state;
      let diff = (scrollHeight - scrollTop) - clientHeight
      if(diff < 100 && !isAdded) {
         this.nextPage()
      }
   }

   nextPage = async () => {
      const {
         videosNextPage, isNewFetching, list, isNewFetchingInsideModal, listInsideModal, isInsideModal,
      } = this.props;
      let query = [];
      let fetching = isNewFetching;
      let data = list;
      if(isInsideModal) {
         fetching = isNewFetchingInsideModal
         data = listInsideModal
      }
      const { current_page: currentPage, last_page: pages } = data
      if(currentPage < pages && !fetching) {
         this.filter = { ...this.filter, page: currentPage + 1 };
         query = this.generateQuery();
         await videosNextPage(query, this.state.isReorderMode);
      } else {
         return
      }
   }

   onChangeTab = tab => {
      const { activeTab } = this.state;
      if(tab === activeTab) {
         return;
      }
      switch (tab) {
         case 'All videos':
            this.setState(() => ({
               activeTab: tab,
            }), () => {
               QueryParams.setQueryParam('tab', 'all');
            });
            break;
         case 'Statistics':
            this.setState(() => ({
               activeTab: tab,
            }), () => {
               QueryParams.setQueryParam('tab', 'statistics');
            });
            break;
         default:
            break;
      }
   };

   getNeedyTabName = key => {
      let name = '';
      switch (key) {
         case 'all':
            name = 'All videos';
            break;
         case 'statistics':
            name = 'Statistics';
            break;
         default:
            name = 'All videos';
            break;
      }
      return name;
   };

   handleFileChange = async (e) => {
      const allowedExtentions = getAllowedVideoExtentions();
      const files = e.target.files;
      const filtered = [];
      Array.from(files).forEach((file) => {
         const { type } = file;
         if(allowedExtentions.indexOf(type.toLowerCase()) !== -1) {
            filtered.push(file);
         }
      });
      this.fileUploader.current.value = '';
      if(0 === filtered.length) {
         toast.error('You may upload only video files')
         return;
      }
      const { startUpload, goTo } = this.props;
      startUpload(filtered);
      goTo(Router.route('UPLOADS').getCompiledPath());
   }

   onShowChange = (data) => {
      this.onShowData = {
         ...this.onShowData,
         ...data,
      }
   }

   generateQuery = () => {
      // const { isInsideModal, contentType } = this.props
      const query = [];
      Object.keys(this.filter).forEach((key) => {
         if(key){
            if(this.filter[key]) {
               'conversation' !== key && 'insideModal' !== key &&  key !== 'count' && key !== 'page' && key !== 'sortBy' && key !== 'hideScheduledContents' && key !== 'hideUnpublishedContents' ?
                  query.push(`query[]=${ key }=${ encodeURIComponent(this.filter[key]) }`)
                  :
                  query.push(`${ key }=${ encodeURIComponent(this.filter[key]) }`);
            }
         }
      });

      // if(!!isInsideModal && contentType === 'store' && (!('status' in this.filter) || this.filter.status === '')) {
      //    query.push(`query[]=status=published,unpublished`)
      // }

      return query;
   };

   generateUrlQuery = (filters, inited) => {
      if(filters && !isEmpty(filters)) {
         const urlParams = {};
         Object.keys(filters).forEach((key) => {
            if(key){
               if(key !== 'count' && key !== 'page') {
                  urlParams[key] = filters[key]
               }
               if(key === 'casts') {
                  urlParams.castmembers = filters[key]
                  delete urlParams.casts
               }
               if(!filters[key]) {
                  delete urlParams[key]
               }
            }
         });
         QueryParams.defineFilters(urlParams)
      }
      let params = QueryParams.getFilters()

      if(params.castmembers) {
         params.casts = params.castmembers
         delete params.castmembers
      }
      this.filter = { count: 20, page: 1, ...params };
      if(!inited) {
         this.setState({
            emptyFilters: isEmpty(params),
         })
      }
   };

   onClosepublishedDialog = () => {
      this.setState({
         isAdded: false,
      });
      this.onShowData = {};
      QueryParams.deletQueryParam();
   }
   onFilterChange = (value, key) => {
      let data = value
      if(!!Array.isArray(value)) {
         data = value.join(',')
      }
      const { isInsideModal } = this.props
      if(!isInsideModal) {
         let urlKey = key
         if('casts' === key){
            urlKey = 'castmembers'
         }
         let filtersData = {}
         filtersData = { ...this.filter, [urlKey]: data }
         this.generateUrlQuery(filtersData)
      } else {
         this.filter = { ...this.filter, count: 20, page: 1, [key]: data };
         let emptyFilters = true
         Object.keys(this.filter).forEach((key) => {
            if(!['conversation', 'count', 'hideScheduledContents', 'hideUnpublishedContents', 'insideModal', 'page'].includes(key)) {
               if(!!this.filter[key]) emptyFilters = false
            }
         })

         this.setState({ emptyFilters })
      }

      let query = this.generateQuery()
      const { fetchVideosByFilter, fetchCountsState } = this.props
      fetchVideosByFilter(query, this.key, this.state.isReorderMode)
      if(!isInsideModal) fetchCountsState(query);
   }

   onClearAllFilters = () => {
      this.filter = {
         count: 20,
         page: 1,
      }
      const { isInsideModal, conversationId, hideScheduledContents, hideUnpublishedContents } = this.props
      if(isInsideModal) {
         this.filter = {
            ...this.filter,
            // status: 'published',
            insideModal: 1,
            hideScheduledContents,
            hideUnpublishedContents,
         }
         if(conversationId){
            this.filter = {
               ...this.filter,
               conversation: conversationId,
               // status: 'published,unpublished',
            }
         }
      } else {
         QueryParams.removeAllQueryParam()
      }
      this.setState({
         emptyFilters: true,
      })
      let query = this.generateQuery()
      const { fetchVideosByFilter, fetchCountsState } = this.props
      fetchVideosByFilter(query, this.key, this.state.isReorderMode)
      if(!isInsideModal) fetchCountsState(query);
   }

   openDeleteConfirmModal  = (id = null, open = false) => {

      this.setState({
         ...this.state,
         openDeletModal: open,
      })

      this.deleteVideoId = id;
   }

   getState = () => {
      const {
         list,
         isFetching,
         isNewFetching,
         isFetchingByFilter,
         isEmptyByFilter,
         isEmpty,
         isInsideModal,
         isFetchingInsideModal,
         listInsideModal,
         isFetchingByFilterInsideModal,
         isNewFetchingInsideModal,
         isEmptyByFilterInsideModal,
         isEmptyInsideModal,
      } = this.props;
      let data = list
      let loading = isFetching
      let loadFilter = isFetchingByFilter
      let loadNewData = isNewFetching
      let emptyByFilter = isEmptyByFilter
      let empty = isEmpty
      if(isInsideModal){
         data = listInsideModal
         loading = isFetchingInsideModal
         loadFilter = isFetchingByFilterInsideModal
         loadNewData = isNewFetchingInsideModal
         emptyByFilter = isEmptyByFilterInsideModal
         empty = isEmptyInsideModal
      }
      return { data, loading, loadFilter, loadNewData, emptyByFilter, empty }
   }

   onBulkEdit = data => {
      const { goTo, setBulkIds } = this.props
      let query = this.generateQuery();
      query = query.filter(el => el.includes('query[]='))
      setBulkIds({ ...data, params: query })
      const title = `Edit ${ getCountState(data.count, 'video') }`
      Router.route('VIDEOS_BULK_EDIT').setTitle(title)
      goTo(Router.route('VIDEOS_BULK_EDIT').getCompiledPath());
      Router.route('VIDEOS_BULK_EDIT').setParent('VIDEOS')
   }

   onClickDetailsLink = () => {
      const { isCastMemberProfilePage } = this.props;
      if(!isCastMemberProfilePage) {
         this.keepInitedState = true
      }
   }

   destroyVideos = (data, callBack) => {
      const { destroyVideos } = this.props
      let q = this.generateQuery();
      q = q.filter(el => el.includes('query[]='))
      destroyVideos(data, callBack, q)
   }

   handleCloseModalByUrl = () => {
      this.onTipModalClose()
   }

   onTipModalClose = () => {
      this.setState((...state) => {
         return {
            state,
            isTippersModalOpen: !this.state.isTippersModalOpen,
         }
      })
   }
   onClickSetId = (id) => {
      this.setState((...state) => {
         return {
            state,
            contentId: id,
         }
      })
   }

   fetchContentTipsData = (id) => {
      // eslint-disable-next-line no-unused-vars
      let data = getContentTipHistoryById(id, 'videos').then(res => {
         this.setState((...state) => {
            return {
               state,
               videoDataWithTips: res.data,
            }
         })
      }).catch((err) => {
         this.setState((...state) => {
            return {
               state,
               storyDataWithTips: [],
            }
         })
      })
   }

   cleanTipsState = () => {
      this.setState((...state) => {
         return {
            state,
            videoDataWithTips: null,
         }
      })


   }

   onSortEnd = async ({ newData, newIndex }, bool = false) => {
      // eslint-disable-next-line no-unused-vars
      const { reorderVideos, contentMetaInfo: { dont_show_content_reorder_message_video } } = this.props
      try {
         // if(!bool && !dont_show_content_reorder_message_video) {
         //    this.setState({
         //       confirmModal: {
         //          ...this.state.confirmModal,
         //          isOpen: true,
         //       },
         //       reorderedData: { newData, newIndex },
         //    })
         // } else {
         //    this.setState({
         //       confirmModal: {
         //          ...this.state.confirmModal,
         //          isOpen: false,
         //       },
         //       reorderedData: { newData: [], newIndex: null },
         //    })
         //    let replace_prev_id = !!newData[newIndex - 1] ? newData[newIndex - 1].id : null
         //    let replaced_id = newData[newIndex].id
         //    let replace_next_id = !!newData[newIndex + 1] ? newData[newIndex + 1].id : null
         //    if(!!replace_next_id && !newData[newIndex + 1].is_published) {
         //       replace_next_id = null
         //    }
         //    if(!!replace_prev_id && !newData[newIndex - 1].is_published) {
         //       replace_prev_id = null
         //    }
         //    const { data } = await changeContentOrders('videos', { replaced_id, replace_next_id, replace_prev_id, dont_show_message_again: dont_show_content_reorder_message_video })
         //    newData.forEach((item, index) => {
         //       if(item.id === replaced_id) {
         //          newData[index].publish_date = data
         //       }
         //    })
         //    reorderVideos(newData)
         //    toast.success('Content has been reordered')
         // }

         const orderedData = newData.map((item, index) => {
            return {
               id: item.id,
               order: newData.length - index,
               name: item?.name,
            };
         })

         this.setState({
            reorderedData: { newData, newIndex },
         })

         await changeContentOrders('videos', { data: orderedData })

         reorderVideos(newData)
         toast.success('Content has been reordered')
      } catch (error) {
      }
   }

   onStatusFilter = () => {
      const { goTo, changeInitedState } = this.props
      changeInitedState()
      let route = history.location.pathname === '/videos' ? 'VIDEOS_REORDER' : 'VIDEOS'
      goTo(Router.route(`${ route }`).getCompiledPath())
   }

   onCloseWarningModal = () => {
      const { setWarningModal }  = this.props;
      setWarningModal({
         isOpen: false,
         description: null,
         ids: null,
         multiple_bundles: null,
         buttonText: null,
         title: null,
         buttonIconName: null,
      });
   }
   onActionWarningModal = () => {
      const { warningModal } = this.props;
      this.onCloseWarningModal();
      if(!['paid_for_unlock_or_download', 'sent_in_chat'].includes(warningModal.reason)){
         if(warningModal?.multiple_bundles){
            window.open(`${ Router.route('STORE').getCompiledPath() }?q={"type":"content_bundle_item"}`);
         } else {
            window.open(Router.route('STORE_EDIT_BUNDLE').getCompiledPath({ id: generateUriSegment(warningModal.ids?.[0]) }));
         }
      }
   }

   openCloseRemoveDrmConfirmModal = (data = null) => {
      this.setState({
         removeDrmConfirmModalData: data,
      })
   }

   render() {
      const {
         list,
         resources,
         screenWidth,
         deleteButtonDisabled,
         deleteVideo,
         siteInfo: {
            site_status: siteStatus,
            site_url: siteUrl,
            can_add_content: allowAddContent,
         },
         refreshSiteDetails,
         mediaDownloadButton,
         downloadFile,
         isInsideModal,
         onSelectThisVideo,
         // filters,
         isDistoryFetching,
         countesState,
         showNewStyles,
         showCheckboxes,
         selections,
         contentMetaInfo,
         updateContentMetaInfo,
         loginAs,
         pinVideo,
         contentType,
         bundleId,
         warningModal,
         isMultipleSelect,
         onDeleteMessag,
         updateMessageAction,
         isMultipleAttachments,
         updateMessageUnlockDetails,
         checkAndUnlockDemoPublicSite,
         authUser,
         removeDrmProtection,
         isFetchingRemoveDrmFromVideo,
         showOnlyList,
      } = this.props;
      const {
         activeTab,
         openDeletModal,
         emptyFilters,
         contentId,
         isTippersModalOpen,
         videoDataWithTips,
         isReorderMode,
         confirmModal,
         pinnedCount,
         removeDrmConfirmModalData,
      } = this.state;

      const { data, loading, loadFilter, loadNewData, emptyByFilter, empty } = this.getState()
      const allowedExtentions = getAllowedVideoExtentions();
      const accept =  allowedExtentions.join(',')
      if(loading) {
         return (
            <div className='flex h-full w-full'>
               <PageLoader withLoader={ isInsideModal } type='nonTab' customStyle='h-full w-full' />
            </div>
         )
      }
      if(empty) {
         return (
            <div className='flex h-full items-center'>
               <input
                  type='file'
                  ref={ this.fileUploader }
                  style={ { display: 'none' } }
                  onChange={ this.handleFileChange }
                  multiple
                  accept={ `.mov,${ accept }` }
               />
               <EmptyState
                  icon='videos'
                  noContentText='You don’t have any videos'
                  buttonText={ isInsideModal ? null : allowAddContent ? 'Add new video' : null }
                  className='h-full'
                  onAdd={ isInsideModal ? null : this.handleAddNewVideo }
                  linkButtonText='More info on videos'
                  linkButtonIcon='attention-outline'
                  onLinkClick={ () => { intercomMoreInfo(MORE_INFO_ON_VIDEOS) } }
                  buttonDisabled={ siteStatus === 'demo_mode' }
                  buttonIconSize='extraLarge'
                  buttonHeight='h-11'
               />
            </div>
         )
      }
      return (
         <>
            <input
               type='file'
               ref={ this.fileUploader }
               style={ { display: 'none' } }
               onChange={ this.handleFileChange }
               multiple
               accept={ `.mov,${ accept },video/*` }
            />
            <VideosMainPage
               storyCard={ data && !isReorderMode ? data.data : data?.data?.filter(video => !video.is_pinned)  }
               onTabsMenuChange={ this.onChangeTab }
               tabsMenuActiveItem={ activeTab }
               onClickCreate={ this.handleAddNewVideo }
               resources={ resources }
               onFilterChange={ this.onFilterChange }
               isNewFetching={ loadNewData }
               isFetchingByFilter={ loadFilter }
               isEmptyByFilter={ emptyByFilter }
               endList={ data.current_page === data.last_page }
               desktopType={ screenWidth > 768 ? 'descktop' : 'mobile' }
               openDeleteConfirmModal={ (id) => {
                  this.openDeleteConfirmModal(id, true)
               } }
               onMoreInfoVideos={ () => { intercomMoreInfo(MORE_INFO_ON_VIDEOS) } }
               onClearAllFilters={ this.onClearAllFilters }
               emptyFilters={ emptyFilters }
               downloadOriginalFile={ downloadFile }
               isDownloading={ mediaDownloadButton }
               isInsideModal={ isInsideModal }
               onSelectThisVideo={ onSelectThisVideo }
               totalCount={ countesState?.videos?.total || data.total }
               onBulkEdit={ this.onBulkEdit }
               filters={ this.filter }
               destroyButtonDisabled={ isDistoryFetching }
               destroy={ this.destroyVideos }
               onClickDetailsLink={ this.onClickDetailsLink  }
               siteUrl={ siteUrl }
               onClickOpenTips={ this.onTipModalClose }
               onClickSetId={ (id) => {
                  this.fetchContentTipsData(id)
                  this.onClickSetId(id)
               } }
               allowAddContent={ allowAddContent }
               showNewStyles={ showNewStyles }
               showCheckboxes={ showCheckboxes }
               selections={ selections }
               countState={ isReorderMode ? { total: pinnedCount?.not_pinned_total } : countesState?.videos  }
               screenWidth={ screenWidth }
               isReorderMode={ isReorderMode }
               setReorderMode={ () => this.onStatusFilter(isReorderMode) }
               onSortEnd={ this.onSortEnd }
               onClickPreview={ () => {
                  loginAs({ id: null, type: 'guest' }, siteUrl, 'videos')
               } }
               pinVideo={ (id, bool) => pinVideo(id, Boolean(bool)) }
               contentType={ contentType }
               bundleId={ bundleId }
               isMultipleSelect={ isMultipleSelect }
               onDeleteMessag={ onDeleteMessag }
               updateMessage={ updateMessageAction }
               isMultipleAttachments={ isMultipleAttachments }
               isDemoMode={ siteStatus === 'demo_mode' }
               updateMessageUnlockDetails={ updateMessageUnlockDetails }
               checkAndUnlockDemoPublicSite={ checkAndUnlockDemoPublicSite }
               appId={ authUser.app_id }
               removeDrmProtection={ (data) => {
                  this.openCloseRemoveDrmConfirmModal(data)
               } }
               showOnlyList={ showOnlyList }
            />
            {
               openDeletModal && (
                  <Modal
                     contentPosition={ screenWidth < 701 ? 'bottom' : 'center' }
                     onClose={ () => this.openDeleteConfirmModal() }
                     isCloseAction
                     otherClassName={ screenWidth < 701 ? 'w-full' : '!w-[375px]' }
                  >
                     <DeleteModal
                        type='video'
                        isDisabled={ deleteButtonDisabled }
                        deleteCollection={ () => {
                           const params = QueryParams.getFilters();
                           const emptyFilterParams = isEmpty(params);
                           deleteVideo(this.deleteVideoId, (error) => {

                           }, emptyFilterParams);
                           if('live' !== siteStatus) {
                              setTimeout(() => {
                                 refreshSiteDetails();
                              }, 300)
                           }
                           this.openDeleteConfirmModal();
                           if(list.data && list.data.length < 10){
                              this.nextPage();
                           }
                        } }
                        onClickCancel={ () => this.openDeleteConfirmModal() } />
                  </Modal>

               )
            }
            {
               contentId && isTippersModalOpen && (
                  <Modal
                     fullScreenMode={ isMobileAndTablet() }
                     contentPosition='responsive'
                     onClose={ () => this.handleCloseModalByUrl() }
                     otherClassName='tipper sm:!rounded-xlg'
                  >
                     <TippersModal
                        onModalClose={ this.onTipModalClose }
                        content={ videoDataWithTips }
                        contentId={ contentId }
                        onTipsModalClose={ this.cleanTipsState }
                     />
                  </Modal>
               )
            }
            {
               confirmModal.isOpen &&
                  <ConfirmModal
                     title='Publication date change'
                     type='confirm'
                     icon='warning'
                     iconColor='warning'
                     description='Reordering has the effect of changing the publication date of content that is being reordered. Are you sure you want to reorder content?'
                     contentWidth='375px'
                     buttonText='Yes, I want to reorder content'
                     // buttonClassName='mt-6 !text-sm'
                     action={ () => this.onSortEnd({ newData: this.state.reorderedData.newData, newIndex: this.state.reorderedData.newIndex }, true) }
                     cancelButtonAction={ () => {
                        updateContentMetaInfo({
                           ...contentMetaInfo,
                           dont_show_content_reorder_message_video: false,
                        })
                        this.setState({
                           ...this.state,
                           confirmModal: {
                              isOpen: false,
                           },
                           reorderedData: {
                              newData: [],
                              newIndex: null,
                           },
                        })
                     } }
                     isPreventDeafault={ false }
                     isMobile={ screenWidth < 700 }
                  >
                     <div className='w-full mt-8 flex items-center justify-center'>
                        <CheckboxLabel
                           name='dont_show_message_again'
                           checked={ contentMetaInfo?.dont_show_content_reorder_message_video }
                           className='flex justify-center'
                           textColor='secondary'
                           label='Don’t show again'
                           onChange={ () => {
                              updateContentMetaInfo({
                                 ...contentMetaInfo,
                                 dont_show_content_reorder_message_video: !contentMetaInfo?.dont_show_content_reorder_message_video,
                              })
                           } }
                           padding='0'
                        />
                     </div>
                  </ConfirmModal >
            }
            {
               warningModal.isOpen &&
               <ConfirmModal
                  onCloseModal={ _ => {
                     if('paid_for_unlock_or_download' !== warningModal.reason){
                        this.onCloseWarningModal()
                     }
                  }
                  }
                  isCloseAction
                  type='confirm'
                  icon='warning'
                  withoutCloseButton
                  iconColor='warning'
                  title={ warningModal.title }
                  description={ warningModal?.description }
                  cancelText={ warningModal.cancelText }
                  buttonText={ warningModal.buttonText }
                  buttonIconName={ warningModal.buttonIconName }
                  secondaryButtonClassName={ warningModal.secondaryButtonClassName }
                  buttonClassName='mt-8'
                  action={ this.onActionWarningModal }
                  iconSize='2xl'
                  isMobile={ screenWidth < 701 }
               />
            }
            {
               removeDrmConfirmModalData && removeDrmConfirmModalData?.id && (
                  <VideoDrmConfirmModals
                     isMobile={ screenWidth <= 1024 }
                     onCloseModal={ () => this.openCloseRemoveDrmConfirmModal(null) }
                     onConfirm={ () => {
                        removeDrmProtection(removeDrmConfirmModalData.id, () => {
                           toast.success(`Video protection removed from ${ removeDrmConfirmModalData.title }`)
                           this.openCloseRemoveDrmConfirmModal(null)
                        })
                     } }
                     type='remove_drm_from_video'
                     videoTitle={ removeDrmConfirmModalData?.title }
                     isLoading={ isFetchingRemoveDrmFromVideo }
                  />
               )
            }
         </>
      )
   }
}

const mapStateToProps = state => ({
   list: videosDataSelector(state),
   listInsideModal: videosDataInsideModal(state),
   isFetching: isVideosDataFetching(state),
   isFetchingInsideModal: isVideosDataInsideModalFetching(state),
   resources: resourcesSelector(state),
   isNewFetching: isNewFetchingSelector(state),
   isFetchingByFilter: isFetchingByFilterSelector(state),
   isEmpty: isEmptySelector(state),
   isEmptyByFilter: isEmptyByFilterSelector(state),
   screenWidth: screenWidthSelector(state),
   siteInfo: siteInfoSelector(state),
   deleteButtonDisabled: deleteButtonDisabledSelector(state),
   deletsiteStepseButtonDisabled: siteStepsSelector(state),
   mediaDownloadButton: mediaDownloadButtonSelector(state),
   isFetchingByFilterInsideModal: isFetchingByFilterInsideModalSelector(state),
   isNewFetchingInsideModal: isNewFetchingInsideModalSelector(state),
   isEmptyByFilterInsideModal: isEmptyByFilterInsideModalSelector(state),
   isEmptyInsideModal: isEmptyInsideModalSelector(state),
   isInited: isInitedSelector(state),
   filters: filtersSelector(state),
   isDistoryFetching: isDistoryFetchingSelector(state),
   countesState: countesStateSelector(state),
   contentMetaInfo: contentMetaInfoSelector(state),
   warningModal: warningModalSelector(state),
   filtersInsideModal: filtersInsideModalSelector(state),
   authUser: authUserSelector(state),
   isFetchingRemoveDrmFromVideo: isFetchingRemoveDrmFromVideoSelector(state),
});

const mapDispatchToProps = dispatch => ({
   fetchVideos: (params = {}, key, emptyFilters, isReorderMode, isCastMemberProfilePage) => {
      dispatch(fetchVideosOperation(params, key, emptyFilters, isReorderMode, isCastMemberProfilePage));
   },
   fetchCountsState: (params) => {
      dispatch(fetchMediaCountsStateOperation('videos', params));
   },
   fetchVideosByFilter: (params = [], key, isReorderMode) => {
      dispatch(fetchVideosByFilterOperation(params, key, isReorderMode));
   },
   startUpload: (videos) => {
      dispatch(startUploadOperation(videos))
   },
   videosNextPage: (params) => {
      dispatch(videosNextPageOperation(params))
   },
   deleteVideo: (id, callBack, emptyFilterParams) => {
      dispatch(deleteVideoOperation(id, 'list', null, callBack, emptyFilterParams))
   },
   goTo: (location) => {
      dispatch(push(location))
   },
   refreshSiteDetails: () => {
      dispatch(siteDetailsRefreshOperation())
   },
   downloadFile: (id) => dispatch(downloadMediaOperation(id, 'videos')),
   clearState: data => dispatch(clearState(data)),
   setFilterData: data => dispatch(setFilterData(data)),
   destroyVideos: (ids, callBack, q) => dispatch(destroyVideosOperation(ids, callBack, q)),
   setBulkIds: (ids) => dispatch(setBulkIds(ids)),
   reorderVideos: (data) => dispatch(reorderVideos(data)),
   updateContentMetaInfo: info => dispatch(contentMetaInfo(info)),
   loginAs: (data, url, pageUrl) => {
      dispatch(loginAsOperation(data, url, pageUrl));
   },
   changeInitedState: () => dispatch(changeInitedState()),
   // updateBundleStateInData: (data, bool) => dispatch(updateBundleStateInData(data, bool)),

   pinVideo: (id, bool) => dispatch(pinVideoOperations(id, bool)),
   setWarningModal: data => {
      dispatch(setWarningModal(data))
   },
   updateMessageAction: data => dispatch(updateMessageAction(data)),
   checkAndUnlockDemoPublicSite: (callback) => dispatch(checkAndUnlockDemoPublicSiteOperation(callback)),
   removeDrmProtection: (id, callback) => dispatch(removeDrmProtectionOperation(id, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VideosContainer);
