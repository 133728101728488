import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Radio from 'common/components/elements/inputs/radio';
import './style.scss';
import TruncateText from 'common/components/elements/truncate-text';
import Icon from 'common/components/elements/icons';

const RadioLabel = ({
   label,
   labelType,
   className,
   disabled,
   onChange,
   id,
   checked,
   children,
   isShowBorder,
   paddingY,
   otherCheckboxIcon,
   newStyle,
   showNewRadioStyles,
   labelSize,
   radioClassName,
   radioButtonTooltipText,
   iconName,
   hasError,
   labelColor,
   notShowTooltip,
   radioParentClassName,
   labelClassName,
   isSelectedState,
   htmlLabelWrapperClassName,
   labelTooltipText,
   textClassName = '',
   truncateTextProperties,
}) => {
   return (

      <label
         htmlFor={ `radio-label_${ id }` }
         className={ `w-full h-full ${ labelClassName } ${ isSelectedState && checked ? 'bg-hover dark:bg-hover-dark' : '' }` }
         data-tooltip-id={ labelTooltipText ? 'ams-top-tooltip' : '' }
         data-tooltip-content={ labelTooltipText ? labelTooltipText : '' }
      >
         <div
            className={ `w-full select-none flex items-center justify-between cursor-pointer py-${ paddingY } radio-label-box-sizing ${ isShowBorder ? checked ? 'checked-paddingX rounded-lg border-2 skin-secondary-border' : 'unchecked-paddingX rounded-lg border dark:border-divider-dark border-divider '  : 'px-4' } ${ className }` }
         >
            {
               !children && label && (
                  <>
                     {
                        iconName &&
                        <Icon
                           name={ iconName }
                           className={ `${ checked ? 'skin-primary-text' : '' }` }
                        />
                     }
                     {
                        labelType === 'string' && (
                           <TruncateText
                              text={ label }
                              textClass={ `${ labelColor } ${ newStyle ? 'text-sm' : '' } !text-${ labelSize }` }
                              textSize={ labelSize === 'base' ? '16px' : '14px' }
                              fontWeight='400'
                              width='calc(100% - 28px)'
                              newStyle={ true }
                              notShowTooltip={ notShowTooltip }
                              className={ textClassName }
                              { ...truncateTextProperties }
                           />
                        )
                     }
                     {
                        labelType === 'html' && (
                           <div
                              dangerouslySetInnerHTML={ { __html: label } }
                              className={ htmlLabelWrapperClassName || '' }
                           />
                        )
                     }
                  </>
               )
            }
            {
               children && (
                  children
               )
            }
            <div
               className={ radioParentClassName }
               data-tooltip-id={ radioButtonTooltipText ? 'ams-top-tooltip' : '' }
               data-tooltip-content={ radioButtonTooltipText ? radioButtonTooltipText : '' }
            >
               <Radio
                  id={ `radio-label_${ id }` }
                  onChange={ onChange }
                  checked={ checked }
                  disabled={ disabled }
                  otherCheckboxIcon={ otherCheckboxIcon }
                  className={ radioClassName }
                  hasError={ hasError }
               />
            </div>
         </div>
      </label>
   );
};

RadioLabel.propTypes = {
   label: PropTypes.string,
   labelType: PropTypes.any,
   className: PropTypes.string,
   disabled: PropTypes.bool,
   checked: PropTypes.bool,
   onChange: PropTypes.func,
   id: PropTypes.any,
   isShowBorder: PropTypes.bool,
   children: PropTypes.any,
   paddingY: PropTypes.string,
   otherCheckboxIcon: PropTypes.bool,
   newStyle: PropTypes.bool,
   showNewRadioStyles: PropTypes.bool,
   labelSize: PropTypes.oneOf(['base', 'sm']),
   radioClassName: PropTypes.string,
   radioButtonTooltipText: PropTypes.string,
   iconName: PropTypes.string,
   labelColor: PropTypes.string,
   hasError: PropTypes.bool,
   notShowTooltip: PropTypes.bool,
   radioParentClassName: PropTypes.string,
   labelClassName: PropTypes.string,
   isSelectedState: PropTypes.bool,
   htmlLabelWrapperClassName: PropTypes.string,
   labelTooltipText: PropTypes.string,
   textClassName: PropTypes.string,
   truncateTextProperties: PropTypes.object,
};
RadioLabel.defaultProps = {
   labelType: 'string',
   className: '',
   id: 'default',
   paddingY: '3',
   checked: false,
   isShowBorder: false,
   otherCheckboxIcon: false,
   onChange: () => {},
   newStyle: false,
   showNewRadioStyles: true,
   labelSize: 'base',
   radioClassName: '',
   iconName: '',
   labelColor: 'dark:text-major-dark text-major',
   notShowTooltip: false,
   radioParentClassName: 'ml-2',
   labelClassName: '',
   isSelectedState: false,
};

export default memo(RadioLabel);
