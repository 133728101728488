import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Icon from 'common/components/elements/icons';
import Checkbox from 'common/components/elements/inputs/checkbox-label';
import Radio from 'common/components/elements/inputs/radio-label';
import CircleLoader from 'common/components/elements/loader/circle';
import Search from 'common/components/elements/inputs/search';
import EmptyState from 'common/components/modules/empty-states';
import TruncateText from 'common/components/elements/truncate-text';
import Avatar from '../../avatar';
import DatePicker from 'common/components/elements/inputs/date-picker';
import moment from 'moment';
// import Button from 'common/components/elements/buttons/primary';
import IconLabel from 'common/components/elements/icon-label';
import Flag from 'common/components/elements/flags';

const MobileFilterContent = ({
   sortOptions,
   onFilterChange,
   selectedSortValue,
   sortOptionValueKey,
   sortOptionNameKey,
   sortName,
   disabledSortOptions,
   disabledSortOptionTooltipText,
   filtersList,
   onDownloadCsv,
   isLoadingCsv,
   onDownloadPdf,
   isLoadingPdf,
}) => {

   return (
      <div
         className='w-full flex flex-col gap-2 overflow-y-auto max-h-[calc(100vh-68px)] ams-custom-scrool'
      >
         {
            Boolean(sortOptions) && (
               <div
                  className='w-full flex flex-col gap-1.5 px-4 pt-4'
               >
                  <span className='text-major font-semibold'>Sort</span>
                  <div
                     className='w-full flex flex-wrap gap-1.5'
                  >
                     {
                        sortOptions.map(option => {
                           const isActive = option[sortOptionValueKey] === selectedSortValue
                           const isDisabled = Array.isArray(disabledSortOptions) && disabledSortOptions.includes(option[sortOptionValueKey])
                           return (
                              <div
                                 key={ option[sortOptionValueKey] }
                                 className={ classNames({
                                    'mt-1.5 px-2 py-1 border border-divider rounded-[50px] h-6 flex items-center justify-center gap-1 ': true,
                                    'cursor-pointer': !isDisabled,
                                    '': isDisabled,
                                 }) }
                                 role='presentation'
                                 onClick={ () => {
                                    if(isDisabled) return;
                                    onFilterChange(option[sortOptionValueKey], option[sortOptionNameKey], sortName)
                                 } }
                                 data-tooltip-id='ams-top-tooltip'
                                 data-tooltip-content={ isDisabled ? disabledSortOptionTooltipText : null }
                              >
                                 {
                                    isActive && (
                                       <Icon
                                          name='done'
                                          color='action'
                                       />
                                    )
                                 }
                                 <span
                                    className={ classNames({
                                       'text-sm leading-5': true,
                                       'text-secondary': !isActive && !isDisabled,
                                       'text-action': isActive && !isDisabled,
                                       'text-disabled': isDisabled,
                                    }) }
                                 >
                                    { option[sortOptionNameKey] }
                                 </span>
                              </div>
                           )
                        })
                     }
                  </div>
               </div>
            )
         }
         {
            Boolean(filtersList) && (
               <div
                  className={ classNames({
                     'w-full flex flex-col gap-2': true,
                     'pt-4': !Boolean(sortOptions),
                  }) }
               >
                  <div className='px-4'>
                     <span className='text-major font-semibold'>Filter</span>
                  </div>
                  <div
                     className='w-full flex flex-col'
                  >
                     {
                        filtersList.map(item => {
                           if(item.hide) return null
                           return (
                              <FilterListItem
                                 key={ item.title }
                                 data={ item }
                                 onFilterChange={ onFilterChange }
                              />
                           )
                        })
                     }
                  </div>
               </div>
            )
         }
         {/* {
            (Boolean(onDownloadCsv) || Boolean(onDownloadPdf)) && (
               <div
                  className='w-full flex items-center justify-between px-4'
               >
                  {
                     Boolean(onDownloadPdf) && (
                        <Button
                           text='Download PDF'
                           backgroundColor='transparent'
                           classNames='!w-[max-content] h-9 px-3.5 py-2 gap-2'
                           textColor='action'
                           textSize='small'
                           textClassName='!m-0 !leading-5'
                           borderRadius='large'
                           iconName='download'
                           iconPosition='left'
                           fontIconColor='action'
                           alignment='center'
                           onClick={ onDownloadPdf }
                           isLoading={ isLoadingPdf }
                           primaryColor
                           hideIconOnLoading
                        />
                     )
                  }
                  {
                     Boolean(onDownloadCsv) && (
                        <Button
                           text='Download CSV'
                           backgroundColor='transparent'
                           classNames='!w-[max-content] h-9 px-3.5 py-2 gap-2'
                           textColor='action'
                           textSize='small'
                           textClassName='!m-0 !leading-5'
                           borderRadius='large'
                           iconName='download'
                           iconPosition='left'
                           fontIconColor='action'
                           alignment='center'
                           onClick={ onDownloadCsv }
                           isLoading={ isLoadingCsv }
                           primaryColor
                           hideIconOnLoading
                        />
                     )
                  }
               </div>
            )
         } */}
      </div>
   )
}

MobileFilterContent.propTypes = {
   sortOptions: PropTypes.array,
   selectedSortValue: PropTypes.string,
   sortOptionValueKey: PropTypes.string,
   sortOptionNameKey: PropTypes.string,
   onFilterChange: PropTypes.func,
   sortName: PropTypes.string,
   disabledSortOptions: PropTypes.array,
   disabledSortOptionTooltipText: PropTypes.string,
   filtersList: PropTypes.array,
   onDownloadCsv: PropTypes.func,
   isLoadingCsv: PropTypes.bool,
   onDownloadPdf: PropTypes.func,
   isLoadingPdf: PropTypes.bool,
}

// Filter list item
const FilterListItem = ({
   data,
   onFilterChange,
}) => {

   const {
      type,
      title,
      isMultiple,
      optionNameKey,
      optionValueKey,
      selectedFilter,
      filterName,
      onOpenFilter,
      isLoading,
      isSearchable,
      searchPlaceholder,
      onSearch,
      isEmpty,
      isEmptyByFilter,
      onScrollContent,
      isLoadingNextPage,
      isLoadingBySearch,
      useMemberAvatar,
      avatarSrcKey,
      images,
      useImagesAsOptions,
      useIconLabel,
      useSpanAsIcon,
      labelClassName,
      date,
      dateStartKey,
      dateEndKey,
      secondTextKey,
      checkBoxClassName,
      optionIconKey,
      optionIconColorKey,
      disabled,
      disabledOptions,
      labelTooltipText,
      showSelectAll,
      isSelectedAll,
      contentId,
      checkboxMargin,
      selectedLabelClassName,
      itemContentClassName,
      isFlag = false,
      className = '',
   } = data;

   const nameKey = optionNameKey || 'name'
   const valueKey = optionValueKey || 'value'

   const iconKey = optionIconKey || 'icon'
   const iconColor = optionIconColorKey || 'iconColor'

   const avatarKey = avatarSrcKey || 'avatar_compressed_src'

   const rangeStartKey = dateStartKey || 'from'
   const rangeEndKey = dateEndKey || 'to'

   const [isOpen, setIsOpen] = useState(false)
   const [filteredOptions, setFilteredOptions] = useState(data.options)

   useEffect(() => {
      setFilteredOptions(data.options)
   }, [data.options])

   const handleSearchChange = (value) => {
      if(Boolean(onSearch)) {
         onSearch(value)
      } else {
         if(value) {
            const newOptions = [...data.options].filter(el => {
               return el[nameKey].toLowerCase().indexOf(value.trim().toLowerCase()) !== -1;
            })
            setFilteredOptions(newOptions)
         } else {
            setFilteredOptions(data.options)
         }
      }
   }
   return (
      <div
         className='w-full flex flex-col gap-2'
      >
         <div
            className={ classNames({
               'w-full flex items-center justify-between px-4 py-2 hover:bg-hover': true,
               'cursor-pointer': !disabled,
               [className]: className,
            }) }
            role='presentation'
            onClick={ (e) => {
               if(disabled) return;
               setIsOpen(!isOpen)
               if(!isOpen) {
                  setTimeout(() => {
                     e.target.scrollIntoView({ block: 'center' });
                  })
               }
               if(Boolean(onOpenFilter)) {
                  onOpenFilter(!isOpen)
               }
            } }
         >
            <span
               className={ classNames({
                  'text-major': !disabled,
                  'text-disabled': disabled,
                  'font-semibold': Boolean(selectedFilter.name),
               }) }
            >
               {title}
               {
                  Boolean(selectedFilter.name) && ': '
               }
               {
                  Boolean(selectedFilter.name) && (
                     <span className='font-normal'>
                        {selectedFilter.name}
                     </span>
                  )
               }
            </span>
            <Icon
               name={ isOpen ? 'arrow-up' : 'arrow-down' }
               size='xl'
               color={ disabled ? 'disabled' : 'secondary' }
            />
         </div>
         {
            isOpen && (
               <div
                  className='w-full flex flex-col'
               >
                  {
                     isLoading ? (
                        <div className='w-full min-h-20 flex items-center justify-center'>
                           <CircleLoader color='primary' />
                        </div>
                     ) : (
                        <div
                           className={ `flex flex-col bg-grey-main mx-4 mb-2 rounded-lg overflow-hidden ${ itemContentClassName || '' }` }
                        >
                           {
                              isEmpty && !isEmptyByFilter ? (
                                 <div className='flex items-center w-full mb-4'>
                                    <EmptyState
                                       noContentText={ `No ${ title }` }
                                    />
                                 </div>
                              ) : (
                                 <>
                                    {
                                       isSearchable && (
                                          <div className='px-4 my-2'>
                                             <Search
                                                placeholder={ searchPlaceholder || 'Search' }
                                                onChange={ (e) => {
                                                   handleSearchChange(e);
                                                } }
                                             />
                                          </div>
                                       )
                                    }
                                    {
                                       isLoadingBySearch ? (
                                          <div className='w-full min-h-20 flex items-center justify-center'>
                                             <CircleLoader color='primary' />
                                          </div>
                                       ) : (
                                          isEmptyByFilter || (filteredOptions && filteredOptions.length === 0) ? (
                                             <EmptyState
                                                type='search'
                                                className='mt-4'
                                             />
                                          ) : (
                                             <div
                                                id={ contentId || '' }
                                                className={ classNames({
                                                   'w-full flex flex-col': true,
                                                   'overflow-y-auto ams-custom-scrool show-scrool-on-mobile': Boolean(data.optionsMaxHeight),
                                                   [`${ data.optionsListClassName }`]: Boolean(data.optionsListClassName),
                                                }) }
                                                style={ {
                                                   maxHeight: Boolean(data.optionsMaxHeight) ? data.optionsMaxHeight : 'none',
                                                } }
                                                onScroll={ onScrollContent ?  (e) => onScrollContent(e) : null }
                                             >
                                                {
                                                   isMultiple && showSelectAll && (
                                                      <div className='w-full py-2.5 px-4 flex items-center justify-end'>
                                                         <Checkbox
                                                            name={ `select_all_filter_item_${ filterName }` }
                                                            onChange={ () => {
                                                               onFilterChange('select_all', 'Select all', filterName, isMultiple)
                                                            } }
                                                            checked={ isSelectedAll }
                                                            position='right'
                                                            padding='0'
                                                            className='items-center'
                                                            labelClassName='!w-[max-content]'
                                                         >
                                                            <span className='text-sm text-major'>Select all</span>
                                                         </Checkbox>
                                                      </div>
                                                   )
                                                }
                                                {
                                                   Array.isArray(filteredOptions) && filteredOptions.map(option => {

                                                      let isSelected = false
                                                      if(isMultiple && Boolean(selectedFilter.value) && selectedFilter.value.includes(option[valueKey])) {
                                                         isSelected = true
                                                      } else if(Boolean(selectedFilter.value) && selectedFilter.value === option[valueKey]) {
                                                         isSelected = true
                                                      }

                                                      const isDisabled = Array.isArray(disabledOptions) && disabledOptions.includes(option[valueKey])

                                                      let iconView = null

                                                      if(useIconLabel) {
                                                         iconView = (
                                                            <IconLabel
                                                               name={ option[iconKey] }
                                                               color={ option[iconColor] }
                                                               size='base'
                                                               className='w-6 h-6 min-w-6 shrink-0'
                                                            />
                                                         )
                                                      } else if(useSpanAsIcon) {
                                                         iconView = (
                                                            <span>{option[iconKey]}</span>
                                                         )
                                                      } else if(option.icon) {
                                                         iconView = (
                                                            <Icon
                                                               name={ option[iconKey] }
                                                               size='lg'
                                                               color={ option[iconColor] || 'secondary' }
                                                            />
                                                         )
                                                      } else if(isFlag) {
                                                         iconView = (
                                                            <Flag
                                                               country={ option[optionValueKey] }
                                                               className='!px-0 flex justify-center items-center mr-3'
                                                               width='20px'
                                                               height='20px'
                                                            />
                                                         )
                                                      }

                                                      const view = (
                                                         useImagesAsOptions ? (
                                                            <div className='w-full h-full flex items-center justify-center'>
                                                               <img
                                                                  className={ classNames({
                                                                     'h-auto object-contain object-center max-h-full': true,
                                                                     'w-auto': 'crypto' === option[nameKey],
                                                                     'w-full': 'crypto' !== option[nameKey],
                                                                  }) }
                                                                  src={ images[option[nameKey]] }
                                                                  alt={ option[nameKey] }
                                                               />
                                                            </div>
                                                         ) : (
                                                            <div
                                                               className='w-full flex items-center gap-2'
                                                               data-tooltip-id='ams-top-tooltip'
                                                               data-tooltip-content={ isDisabled ? labelTooltipText : null }
                                                            >
                                                               {iconView}
                                                               {
                                                                  useMemberAvatar && (
                                                                     <div className='w-6 h-6 relative shrink-0' >
                                                                        <Avatar
                                                                           avatarSrc={ option[avatarKey] }
                                                                           username={ option[nameKey] }
                                                                        />
                                                                     </div>
                                                                  )
                                                               }
                                                               <div
                                                                  className='w-full flex flex-col gap-1'
                                                               >
                                                                  {
                                                                     Boolean(option[nameKey]) && (
                                                                        <TruncateText
                                                                           text={ option[nameKey] }
                                                                           textClass={ classNames({
                                                                              'leading-5': true,
                                                                              'text-major **group-hover:underline': !isDisabled,
                                                                              'text-disabled': isDisabled,
                                                                           }) }
                                                                           innerTextAsHTML={ option.innerTextAsHTML }
                                                                        />
                                                                     )
                                                                  }
                                                                  {
                                                                     Boolean(option[secondTextKey]) && (
                                                                        <span className='text-sm text-secondary'>{ option[secondTextKey] }</span>
                                                                     )
                                                                  }
                                                               </div>
                                                            </div>
                                                         )
                                                      )

                                                      if(isMultiple) {
                                                         return (
                                                            <Checkbox
                                                               key={ option[valueKey] }
                                                               name={ `${ option[valueKey] }_${ filterName }_filter` }
                                                               onChange={ () => {
                                                                  onFilterChange(option[valueKey], option[nameKey], filterName, isMultiple)
                                                               } }
                                                               checked={ isSelected }
                                                               position='right'
                                                               padding=' py-2 px-4'
                                                               className={ `h-full ${ checkBoxClassName || '' }` }
                                                               labelClassName={ `group hover:bg-hover ${ labelClassName ? labelClassName : '' } ${ isSelected && Boolean(selectedLabelClassName) ? selectedLabelClassName : '' }` }
                                                               disabled={ isDisabled }
                                                               margin={ checkboxMargin || '3' }
                                                            >
                                                               {view}
                                                            </Checkbox>
                                                         )
                                                      } else {
                                                         return (
                                                            <Radio
                                                               key={ option[valueKey] }
                                                               id={ `${ option[valueKey] }_filter` }
                                                               onChange={ () => {
                                                                  onFilterChange(option[valueKey], option[nameKey], filterName, isMultiple)
                                                               } }
                                                               checked={ isSelected }
                                                               position='right'
                                                               padding=' py-2 px-4'
                                                               className={ `h-full ${ checkBoxClassName || '' }` }
                                                               labelClassName={ `group hover:bg-hover ${ labelClassName ? labelClassName : '' } ${ isSelected && Boolean(selectedLabelClassName) ? selectedLabelClassName : '' }` }
                                                               disabled={ isDisabled }
                                                            >
                                                               {view}
                                                            </Radio>
                                                         )
                                                      }
                                                   })
                                                }
                                                {
                                                   type === 'date_range' && (
                                                      <div
                                                         className='px-4 py-2 w-full flex flex-col'
                                                      >
                                                         <DatePicker
                                                            type='date'
                                                            value={ date[rangeStartKey] ? moment(date[rangeStartKey]).format('YYYY-MM-DD') : '' }
                                                            name={ rangeStartKey }
                                                            onChangeDate={ (name, date) => {
                                                               const momentDate = date ?  moment(date).format('YYYY-MM-DD') : null
                                                               onFilterChange(momentDate, name, filterName, isMultiple)
                                                            } }
                                                            placeholder='Select start date'
                                                            isStart={ true }
                                                            pickerStyle={ {
                                                               height: '40px',
                                                            } }
                                                            pickerWrapperClassName='hover:bg-grey-lighter cursor-pointer hover:rounded-lg w-full mobile-filter-date-picker'
                                                            label='From'
                                                            labelFontWeight='medium'
                                                            maxValue={ Boolean(date[rangeEndKey]) ? date[rangeEndKey] : moment().format('YYYY-MM-DD') }
                                                            calendarIconColor={ Boolean(date[rangeStartKey]) ? 'secondary' : 'placeholder' }
                                                            minValue={ moment('2010-01-01').startOf('year').format('YYYY-MM-DD') }
                                                            datePickerClassName='bg-panel'
                                                         />
                                                         <div
                                                            className='mt-4 w-full'
                                                         >
                                                            <DatePicker
                                                               type='date'
                                                               value={ date[rangeEndKey] ? moment(date[rangeEndKey]).format('YYYY-MM-DD') : '' }
                                                               name={ rangeEndKey }
                                                               onChangeDate={ (name, date) => {
                                                                  const momentDate = date ?  moment(date).format('YYYY-MM-DD') : null
                                                                  onFilterChange(momentDate, name, filterName, isMultiple)
                                                               } }
                                                               placeholder='Select end date'
                                                               isStart={ true }
                                                               pickerStyle={ {
                                                                  height: '40px',
                                                               } }
                                                               pickerWrapperClassName='hover:bg-grey-lighter cursor-pointer hover:rounded-lg w-full mobile-filter-date-picker'
                                                               label='To'
                                                               labelFontWeight='medium'
                                                               calendarIconColor={ Boolean(date[rangeEndKey]) ? 'secondary' : 'placeholder' }
                                                               minValue={ Boolean(date[rangeStartKey]) ? date[rangeStartKey] : moment('2010-01-01').startOf('year').format('YYYY-MM-DD') }
                                                               datePickerClassName='bg-panel'
                                                            />
                                                         </div>
                                                      </div>
                                                   )
                                                }
                                                {
                                                   isLoadingNextPage && (
                                                      <div className='w-full min-h-15 flex items-center justify-center'>
                                                         <CircleLoader color='primary' />
                                                      </div>
                                                   )
                                                }
                                             </div>
                                          )
                                       )
                                    }
                                 </>
                              )
                           }
                        </div>
                     )
                  }
               </div>
            )
         }
      </div>
   )
}

FilterListItem.propTypes = {
   data: PropTypes.object,
   onFilterChange: PropTypes.func,
}



export default MobileFilterContent;
