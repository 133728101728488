import React from 'react'
import PropTypes from 'prop-types';
import CreateEditModal from 'common/components/modules/modal-wrapper';
import Button from 'common/components/elements/buttons/primary';
// import { ARTICLE_ID_ON_BLUR_WHEN_LOCKED } from 'common/utils/intercom-articles';

const OutgoingDisabledModal = ({
   action,
   onCloseModal,
   isMobile,
}) => {
   return (
      <CreateEditModal
         type='confirm'
         icon='warning'
         iconColor='warning'
         title='Outgoing notifications disabled'
         description='Since your site is not yet live, outgoing notifications are not sent'
         descriptionColorClass='text-secondary dark:text-secondary-dark'
         action={ action }
         onCloseModal={ () => {
            if(window.Intercom) {
               window.Intercom('show');
            }
            onCloseModal();
         } }
         cancelText='Contact support'
         buttonText='Ok'
         contentWidth='375px'
         isMobile={ isMobile }
      />
   )
}

const TweetDisabledModal = ({
   action,
   isMobile,
   onCloseModal,
   disabled,
}) => {
   return (
      <CreateEditModal
         type='confirm'
         icon='warning'
         iconColor='warning'
         title='Auto tweet disabled'
         description='Auto Tweet will not be sent because the selected thumbnail is set to blur'
         descriptionColorClass='text-secondary dark:text-secondary-dark'
         action={ action }
         onCloseModal={ onCloseModal }
         // withoutCancelButton
         withoutCloseButton
         buttonText='Ok, continue'
         contentWidth='375px'
         isMobile={ isMobile }
         disabled={ disabled }
      />
   )
}
const TweetPostNotBlure = ({
   isMobile,
}) => {
   // OLD modal
   return (
      <CreateEditModal
         type='action'
         icon='twitter-x'
         iconColor='action'
         descriptionColorClass='text-secondary'
         action={ () => {

         } }
         onCloseModal={ () => {

         } }
         title='Auto post (tweet) image NOT blurred'
         // description='Changes you made so far will not be saved'
         cancelText='Keep editing'
         buttonText='Cancel'
         nonPrimaryColor
         textColor='error'
         contentWidth='375px'
         isMobile={ isMobile }
         buttonClassName='text-error'
         iconSize='2xl'
         buttonBackgroundColor='transparent'
         withoutCloseButton
         otherClassName={ `ams-custom-scrool` }
         closeOnClickOutside
         secondButtonTitle='Yes, post on X'
         secondButtonClassName='mb-3'
         secondButtonPosition='top'
         secondButtonAction={ () => {

         } }
         overrideSecondButtonProps={ {
            fontIconSize: 'large',
            textColor: 'action',
            fontIconColor: 'action',
            // isLoading: isLoadingPostToXButton,
            loaderColor: 'action',
         } }
         buttonTextClassName={ '!text-secondary' }
         isCloseAction
         titleClassName='text-center !leading-6'
      >
         <p className='text-secondary text-base leading-5 text-center mb-5'>
            The thumbnail that will be posted to X will NOT be blurred
         </p>
         <div className='flex items-center justify-center my-3'>
            <Button
               text={ `More info on blur when locked` }
               borderRadius='large'
               onClick={ (e) => {
                  e.stopPropagation()
                  e.preventDefault()
               // Intercom.showArticle(ARTICLE_ID_ON_BLUR_WHEN_LOCKED)
               } }
               padding='none'
               classNames='w-fit !h-9 intercom-more-info-on-blur-when-locked'
               fontWeight='medium'
               iconName={ `attention-outline` }
               textSize='small'
               textColor='action dark:text-action-dark'
               fontIconColor='action'
               darkIconColor='action-dark'
               backgroundColor='transparent'
               iconPosition='left'
               iconSize='small'
               fontIconSize='large'
            />
         </div>
      </CreateEditModal>
   )
}

TweetPostNotBlure.propTypes = {
   isMobile: PropTypes.bool,
};
TweetDisabledModal.propTypes = {
   action: PropTypes.func,
   onCloseModal: PropTypes.func,
   isMobile: PropTypes.bool,
   disabled: PropTypes.bool,
};

OutgoingDisabledModal.propTypes = {
   onCloseModal: PropTypes.func,
   action: PropTypes.func,
   isMobile: PropTypes.bool,
};

export default OutgoingDisabledModal;
export { TweetDisabledModal };
