
//init data
export const FETCH_DETAILS_START = "membership/FETCH_DETAILS_START";
export const FETCH_DETAILS_COMPLETED = "membership/FETCH_DETAILS_COMPLETED";
export const FETCH_DETAILS_FAILED = "membership/FETCH_DETAILS_FAILED";

//filter
export const FETCH_DETAILS_BY_FILTER_START = "membership/FETCH_DETAILS_BY_FILTER_START";
export const FETCH_DETAILS_BY_FILTER_COMPLETED = "membership/FETCH_DETAILS_BY_FILTER_COMPLETED";
export const FETCH_DETAILS_BY_FILTER_FAILED = "membership/FETCH_DETAILS_BY_FILTER_FAILED";

// get by id
export const FETCH_DETAILS_BY_ID_START = "membership/FETCH_DETAILS_BY_ID_START";
export const FETCH_DETAILS_BY_ID_COMPLETED = "membership/FETCH_DETAILS_BY_ID_COMPLETED";
export const FETCH_DETAILS_BY_ID_FAILED = "membership/FETCH_DETAILS_BY_ID_FAILED";

//update
export const UPDATE_DATA_START = "membership/UPDATE_DATA_START";
export const UPDATE_DATA_COMPLETED = "membership/UPDATE_DATA_COMPLETED";
export const UPDATE_DATA_FAILED = "membership/UPDATE_DATA_FAILED";


export const FETCH_AVAILABLE_DATA_START = "membership/FETCH_AVAILABLE_DATA_START";
export const FETCH_AVAILABLE_DATA_COMPLETED = "membership/FETCH_AVAILABLE_DATA_COMPLETED";
export const FETCH_AVAILABLE_DATA_FAILED = "membership/FETCH_AVAILABLE_DATA_FAILED";

//ADD
export const CREATE_DATA_START = "membership/CREATE_DATA_START";
export const ADD_NEW_START = "membership/ADD_NEW_START";
export const ADD_NEW_FAILED = "membership/ADD_NEW_FAILED";

export const MEMBERSHIP_REORDER_FAILED = "membership/MEMBERSHIP_REORDER_FAILED";
export const OPEN_CLOSE_NEW_MEMBERSHIP_MODAL = "membership/OPEN_CLOSE_NEW_MEMBERSHIP_MODAL";
export const CONTINUE_NEW_MEMBERSHIP_MODAL = "membership/CONTINUE_NEW_MEMBERSHIP_MODAL";
export const OPEN_CLOSE_EDIT_MEMBERSHIP_MODAL = "membership/OPEN_CLOSE_EDIT_MEMBERSHIP_MODAL";

// benefits types
export const ADD_OR_CREATE_BENEFIT_START = "membership/ADD_OR_CREATE_BENEFIT_START";
export const UPDATE_BENEFITS_COMPLETED = "membership/UPDATE_BENEFITS_COMPLETED";
export const BENEFITS_FAILED = "membership/BENEFITS_FAILED";


export const CHECK_VALIDATION_START = "membership/CHECK_VALIDATION_START";
export const ADD_NEW_PRICING_COMPLETED = "membership/ADD_NEW_PRICING_COMPLETED";
export const ADD_NEW_PRICING_START = "membership/ADD_NEW_PRICING_START";
export const DELETE_MEMBERSHIP_COMPLETED = "membership/DELETE_MEMBERSHIP_COMPLETED";
export const DELETE_MEMBERSHIP_START = "membership/DELETE_MEMBERSHIP_START";
export const DUPLICATE_NEW_PRICING_START = "membership/DUPLICATE_NEW_PRICING_START";
export const UPDATET_STATE_ACTION = "membership/UPDATET_STATE_ACTION";
export const DUPLICATE_NEW_PRICING_FAILED = "membership/DUPLICATE_NEW_PRICING_FAILED";

export const UPDATE_ACTIVE_MEMBERSHIP = "membership/UPDATE_ACTIVE_MEMBERSHIP";
