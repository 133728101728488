import React, { memo } from 'react';
import PropTypes from 'prop-types';
// import ReactJWPlayerContainer from 'common/components/modules/react-jw-player';
import VideoJsHlsPlayer from 'common/components/modules/video-js-hls-player';
import cx from 'classnames';

import './style.scss';

const Video = ({
   src,
   poster,
   addView,
   isPreview,
   dashUri,
   isDrmProtected,
   getLicenseToken,
   getDrmVideoLicenseProxyUrl,
   appId,
   drmAddingInProgress,
}) => {

   // const playlist = [{
   //    file: src,
   //    image: poster,
   // }];
   return (
      <div
         className={ cx({
            'w-full h-full relative overflow-hidden video-details-card': true,
            'is-preview-video': isPreview,
         }) }
      >
         {/* <ReactJWPlayerContainer
            playlist={ playlist }
            onVideoPlay={ addView }
            isAutoPlay={ true }
         /> */}
         <VideoJsHlsPlayer
            src={ src }
            poster={ poster }
            onVideoPlay={ addView }
            isAutoPlay={ true }
            dashUri={ dashUri }
            isDrmProtected={ isDrmProtected && !isPreview && !drmAddingInProgress }
            getLicenseToken={ getLicenseToken }
            getDrmVideoLicenseProxyUrl={ getDrmVideoLicenseProxyUrl }
         />
      </div>
   );
};

Video.propTypes = {
   src: PropTypes.string,
   poster: PropTypes.string,
   addView: PropTypes.func,
   isPreview: PropTypes.bool,
   dashUri: PropTypes.string,
   isDrmProtected: PropTypes.bool,
   getLicenseToken: PropTypes.func,
   getDrmVideoLicenseProxyUrl: PropTypes.func,
   appId: PropTypes.string,
   drmAddingInProgress: PropTypes.bool,
};

export default memo(Video);
