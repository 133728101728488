import React from "react";
import PropTypes from "prop-types";
import Icon from "common/components/elements/icons";
import TruncateText from "common/components/elements/truncate-text";

const ShareOption = ({ img, title, iconName, onClick, hideRightIcon = false }) => {
   return (
      <div
         className='w-full rounded-lg bg-grey-main dark:bg-black-field flex items-center justify-between gap-3 px-4 py-2 cursor-pointer hover:bg-hover'
         role='presentation'
         onClick={ onClick }
      >
         <div className='flex items-center gap-3'>
            <div className='w-6 h-6 flex items-center justify-center'>
               {img ? (
                  <img src={ img } alt={ title } />
               ) : (
                  iconName && <Icon name={ iconName } className='text-2xl text-secondary dark:text-secondary-dark' />
               )}
            </div>
            <TruncateText
               text={ title }
               className='text-sm text-major dark:text-major-dark leading-[18px]'
               textSize='14px'
            />
         </div>
         {!hideRightIcon && (
            <div className='h-5 flex items-center'>
               <Icon name='arrow-right' className='text-secondary text-xl' darkColor='secondary-dark' />
            </div>
         )}
      </div>
   );
};

ShareOption.propTypes = {
   img: PropTypes.string,
   title: PropTypes.string.isRequired,
   iconName: PropTypes.string,
   onClick: PropTypes.func,
   hideRightIcon: PropTypes.bool,
};

export default ShareOption;
