
import queryString from 'query-string';
import { history } from 'admin/state/store';
import { isEmpty } from 'lodash'
export default class QueryParams {
   static getParam(key = '', defaultValue = null) {
      const parsedParams = QueryParams.getParsedSearchParams();
      return parsedParams[key] || defaultValue;
   }

   static getParsedSearchParams() {
      const querySearchParams = history.location.search;
      return queryString.parse(querySearchParams, { decode: false });
   }

   static setQueryParam(key, value, except = []) {
      const parsedParams = QueryParams.getParsedSearchParams();
      const constructedParams = {};
      Object.keys(parsedParams).forEach((key) => {
         if(!except.includes(key)) {
            constructedParams[key] = parsedParams[key];
         }
      })
      constructedParams[key] = value;
      let stringify = queryString.stringify('', { encode: false })
      if(constructedParams[key]) {
         stringify = queryString.stringify(constructedParams, { encode: false });
      }

      history.replace({
         path: window.location.pathname,
         search: stringify,
      });
   }
   static pushQueryParam(key, value, except = []) {
      const parsedParams = QueryParams.getParsedSearchParams();
      const constructedParams = {};
      Object.keys(parsedParams).forEach((key) => {
         if(!except.includes(key)) {
            constructedParams[key] = parsedParams[key];
         }
      })
      constructedParams[key] = value;
      const stringify = queryString.stringify(constructedParams, { encode: false });
      history.push({
         path: window.location.pathname,
         search: stringify,
      });
   }

   static deletQueryParam() {
      window.history.pushState('', '', window.location.pathname);
   }
   static popQueryParam() {
      if(history.action === 'POP') {
         history.replace({
            path: window.location.pathname,
            search: '',
         });
         history.go(-1)
      } else {
         history.go(-1)
      }
   }

   static addQueryParam(key, value, except = []) {
      const parsedParams = QueryParams.getParsedSearchParams();
      const constructedParams = {};
      Object.keys(parsedParams).forEach((key) => {
         if(!except.includes(key)) {
            constructedParams[key] = parsedParams[key];
         }
      })
      constructedParams[key] = value;
      const stringify = queryString.stringify(constructedParams, { encode: false });
      history.replace({
         path: window.location.pathname,
         search: stringify,
      });
   }

   static removeQueryParam(param) {
      const parsedParams = QueryParams.getParsedSearchParams();
      const constructedParams = {};
      Object.keys(parsedParams).forEach((key) => {
         if(key !== param) {
            constructedParams[key] = parsedParams[key];
         }
      })
      const stringify = queryString.stringify(constructedParams, { encode: false });
      history.replace({
         path: window.location.pathname,
         search: stringify,
      });
   }

   static removeAllQueryParam() {
      history.replace({
         path: window.location.pathname,
      });
   }

   static defineFilters(currentFilters) {
      if(!isEmpty(currentFilters)) {
         const currentFiltersJson = encodeURIComponent(JSON.stringify(currentFilters));
         const parsedQueryParams = queryString.parse(window.location.search);
         parsedQueryParams.q = currentFiltersJson;
         history.replace({
            path: window.location.pathname,
            search: queryString.stringify(parsedQueryParams, {
               encode: false,
            }),
         });
      } else {
         const parsedQueryParams = queryString.parse(window.location.search);
         if('q' in parsedQueryParams){
            delete parsedQueryParams.q;
         }
         history.replace({
            path: window.location.pathname,
            search: queryString.stringify(parsedQueryParams, {
               encode: false,
            }),
         });
      }
   }

   static getFilters() {
      const parsedQueryParams = queryString.parse(window.location.search);
      return parsedQueryParams.q ? JSON.parse(decodeURIComponent(parsedQueryParams.q)) : {};
   }
}
