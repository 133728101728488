import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Router, { isTopLevelRoute, getCurrentRoute } from 'admin/routes/router';
import { push } from 'connected-react-router';
import { deleteStore } from 'admin/state/modules/common/operations';
import { collapseMenu, openOnlineMembersModal, setShareModalAction, resetCommonDetails, updateSiteInfoAction } from 'admin/state/modules/common/actions';
import {
   screenWidthSelector,
   authUserSelector,
   siteInfoSelector,
   isOpenOnlineMembersModalSelector,
   shareModalSelector,
} from 'admin/state/modules/common/selectors';
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';
import { videoInitStorySelector } from 'admin/state/modules/stories/selectors';
import {
   unreadMessagesCountSelector,
   onlineMembersSelector,
   openDkChatNotificationSelector,
} from 'admin/state/modules/chat/selectors';
import { resetUploadsVideos } from 'admin/state/modules/uploads/actions';
import PageHeader from 'common/components/modules/page-header';
import QueryParams from 'admin/utils/QueryParams';
import {
   complitedUploadsCountSelector,
} from 'admin/state/modules/uploads/selectors';
import { history, isPreviousLocationWithinApp } from 'admin/state/store';
import Auth from 'admin/utils/Auth';
import ViewAsContainer from 'admin/containers/modules/view-as';

import { clearChatInfo, setOpenDkChatNotification } from 'admin/state/modules/chat/actions';
import {
   isOpenPopupSelector as isOpenNptificationPopupSelector,
   // replyCommentSelector as replyCommentNotificationSelector,
   listSelector as notificationsList,
   unseenNotificationsCountSelector,
   notificationsPanelSelector,
} from 'admin/state/modules/notifications/selectors';
import {
   openHeaderPopup as openHeaderNotificationsPopup,
   setNotificationsPanelAction,
} from 'admin/state/modules/notifications/actions.js';
import {
   markAllAsSeenNotificationsOperations,
   fetchNotificationsOperation,
} from 'admin/state/modules/notifications/operations';
import NotificationsContainer from '../notifications';
import ChatNotificationsContainer from '../chat/other';
import OnlineMembersModal from 'admin/containers/modules/online-members-modal';
import ShareModal from 'common/components/modules/share-popup';
import { customizeQR } from 'admin/api/AuthApi';
class PageHeaderContainer extends Component {

   static propTypes = {
      locationPath: PropTypes.string.isRequired,
      goTo: PropTypes.func.isRequired,
      collapseMenu: PropTypes.func.isRequired,
      deleteStore: PropTypes.func,
      siteInfo: PropTypes.object,
      video: PropTypes.object,
      user: PropTypes.object.isRequired,
      children: PropTypes.any,
      screenWidth: PropTypes.number,
      complitedUploadsCount: PropTypes.number.isRequired,
      onClickChat: PropTypes.func,
      unreadChatMessagesCount: PropTypes.any,
      logout: PropTypes.func,
      isOpenNptificationPopup: PropTypes.bool,
      openHeaderNotificationsPopup: PropTypes.func,
      // replyCommentNotification: PropTypes.object,
      unseenNotificationsCount: PropTypes.object,
      notificationsPanel: PropTypes.object,
      setNotificationsPanel: PropTypes.func,
      markAllAsSeenNotifications: PropTypes.func,
      fetchNotifications: PropTypes.func,
      onlineMembers: PropTypes.array,
      isOpenOnlineMembersModal: PropTypes.bool,
      openOnlineMembersModal: PropTypes.func,
      openDkChatNotification: PropTypes.bool,
      setOpenDkChatNotification: PropTypes.func,
      shareModal: PropTypes.object,
      setShareModal: PropTypes.func,
      updateSiteInfo: PropTypes.func,
   };

   constructor(props) {
      super(props);

      this.chat = React.createRef();
      this.notification = React.createRef();
   }


   componentDidMount() {
      document.addEventListener('mousedown', this.handleClickOutside);

      if(QueryParams.getParam('online-members-modal') === 'true'){
         this.openOnlineMembersModal(true)
      }

      if(QueryParams.getParam('manage') === 'edit-nickname'){
         QueryParams.removeQueryParam('manage')
      }

      if(QueryParams.getParam('share')){
         QueryParams.removeQueryParam('share')
      }

   }

   componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
   }

   collapseMenu = () => {
      const { collapseMenu } = this.props;
      collapseMenu();
   }

   getViewAsModal = () => {
      const viewAs = {
         component: (<ViewAsContainer />),
      }
      return viewAs;
   }

   handleClickOutside = (event) => {
      const {
         isOpenNptificationPopup,
         openHeaderNotificationsPopup,
         screenWidth,
         openDkChatNotification,
         setOpenDkChatNotification,
         // replyCommentNotification,
      } = this.props;
      if(screenWidth > 1024) {
         if(this.chat && this.chat.current && !this.chat.current.contains(event.target)) {
            if(openDkChatNotification) {
               const tooltip = document.querySelector('#admin-chat-notification')
               if(!tooltip?.contains(event.target)){
                  setOpenDkChatNotification(false)
               }
            }
         }
      }

      if(screenWidth < 701){
         return
      }
      if(this.notification && this.notification.current &&  !this.notification.current.contains(event.target)) {
         if(isOpenNptificationPopup) {
            openHeaderNotificationsPopup(false)
         }
      }
   }

   getNotificationsList = () => {
      const {
         user,
         unseenNotificationsCount,
         setNotificationsPanel,
         markAllAsSeenNotifications,
         notificationsPanel: {
            isOpen,
         },
         fetchNotifications,
      } = this.props
      if(!user) {
         return null
      }
      const notifications = {
         show: true,
         component: <NotificationsContainer />,
         count: unseenNotificationsCount?.badgeCount,
         ref: this.notification,
         onClick: async () => {
            if(unseenNotificationsCount?.count > 0) {
               await markAllAsSeenNotifications();
            }
            setNotificationsPanel({ isOpen: !isOpen });
            if(!isOpen) {
               await fetchNotifications();
            }
         },
      }
      return notifications;
   }

   getChatModal  = () => {
      const {
         unreadChatMessagesCount,
         locationPath,
         screenWidth,
         goTo,
         user,
      } = this.props;
      const isLivestreamPage = Router.route('LIVE_STREAM_STUDIO').match(locationPath) || Router.route('LIVE_STREAM_SCHEDULE').match(locationPath)
      if(Router.route('CHAT').match(locationPath) || !user) {
         return {
            // show: true,
            // component: <ChatNotificationsContainer />,
         }
      }
      const chat = {
         show: true,
         hiddeChatIcon: !!isLivestreamPage,
         count: unreadChatMessagesCount,
         ref: this.chat,
         id: 'admin-chat-notification',
         hasIntercomClass: true,
         onClickChat: () => {
            if(screenWidth < 1025) {
               goTo(Router.route('CHAT').getCompiledPath())
            } else {
               const { setOpenDkChatNotification, openDkChatNotification } = this.props;
               setOpenDkChatNotification(!openDkChatNotification)
            }
         },
         component: (<ChatNotificationsContainer isLivestreamPage={ Boolean(isLivestreamPage) } />),
      }
      return chat
   }

   openOnlineMembersModal = (bool) => {
      this.props.openOnlineMembersModal(bool)
   }

   getHomepageHeader = () => {
      const {
         children,
         locationPath,
         screenWidth,
         user,
         goTo,
         complitedUploadsCount,
         logout,
         siteInfo,
         onlineMembers,
      } = this.props;
      let title = 'Home';
      const currentRoute = getCurrentRoute(locationPath);
      if(currentRoute && currentRoute.getTitle) {
         title = currentRoute.getTitle();
      }
      const headerProps = {
         title: title,
         fontIconSize: 'doubleLarge',
         fontIconColor: 'secondary',
         leftIconAction: this.collapseMenu,
         chat: this.getChatModal(),
         onClickMyAccount: () => goTo(Router.route('MY_ACCOUNT').getCompiledPath()),
         toMyAccount: Router.route('MY_ACCOUNT').getCompiledPath(),
         onClickLogOut: () => logout(locationPath, window.location.search),
         user: {
            avatar: user.compressed_avatar_src,
            screen_name: user.screen_name,
            uuid: user.uuid,
         },
         siteStatus: siteInfo ? siteInfo.site_status : null,
         isHelpIcon: screenWidth <= 700,
         isMobile: screenWidth <= 700,
         viewAs: this.getViewAsModal(),
         notifications: this.getNotificationsList(),
         isAdmin: true,
         onlineMembers,
         openOnlineMembersModal: () => this.openOnlineMembersModal(true),
         isUploadsPage: Router.route('UPLOADS').getCompiledPath() === window?.location.pathname,
      }
      let showUser = false;
      if(complitedUploadsCount > 0) {
         headerProps['uploads'] = {
            show: true,
            count: complitedUploadsCount,
         };
         headerProps['uploadsIconAction'] = this.handleUploadsIconAction;
      }
      if(screenWidth <= 700) {
         headerProps['leftIconName'] = 'menu';
      } else {
         showUser = true;
         headerProps['leftOffset'] = '56px';
      }
      headerProps['showUser'] = showUser;
      return (
         <PageHeader
            { ...headerProps }
         >
            { children }
         </PageHeader>
      );
   }

   handleUploadsIconAction = () => {
      const { locationPath, goTo } = this.props;
      if(false === !!Router.route('UPLOADS').match(locationPath)) {
         goTo(Router.route('UPLOADS').getCompiledPath());
      }
   }

   getTopLevelRoutesHeader = () => {
      const {
         locationPath,
         children,
         screenWidth,
         user,
         goTo,
         complitedUploadsCount,
         logout,
         siteInfo,
         onlineMembers,
      } = this.props;
      let title = null;
      const currentRoute = getCurrentRoute(locationPath);
      if(currentRoute) {
         title = currentRoute.getTitle();
      }
      const headerProps = {
         title: title,
         fontIconSize: 'doubleLarge',
         fontIconColor: 'secondary',
         leftIconAction: this.collapseMenu,
         chat: this.getChatModal(),
         onClickMyAccount: () => goTo(Router.route('MY_ACCOUNT').getCompiledPath()),
         toMyAccount: Router.route('MY_ACCOUNT').getCompiledPath(),
         onClickLogOut: () => logout(locationPath, window.location.search),
         user: {
            avatar: user.compressed_avatar_src,
            screen_name: user.screen_name,
            status: user.status,
            uuid: user.uuid,
         },
         siteStatus: siteInfo ? siteInfo.site_status : null,
         isHelpIcon: screenWidth <= 700,
         isMobile: screenWidth <= 700,
         viewAs: this.getViewAsModal(),
         notifications: this.getNotificationsList(),
         isAdmin: true,
         onlineMembers,
         openOnlineMembersModal: () => this.openOnlineMembersModal(true),
         isUploadsPage: Router.route('UPLOADS').getCompiledPath() === window?.location.pathname,
      }
      let showUser = false;
      if(complitedUploadsCount > 0) {
         headerProps['uploads'] = {
            show: true,
            count: complitedUploadsCount,
         };
         headerProps['uploadsIconAction'] = this.handleUploadsIconAction;
      }
      if(screenWidth <= 700) {
         headerProps['leftIconName'] = 'menu';
      } else {
         showUser = true;
         headerProps['leftOffset'] = '56px';
      }
      headerProps['showUser'] = showUser;
      return (
         <PageHeader
            { ...headerProps }
         >
            { children }
         </PageHeader>
      );
   }

   getChildLevelRoutesHeader = () => {
      const {
         locationPath,
         goTo,
         deleteStore,
         video,
         children,
         screenWidth,
         user,
         complitedUploadsCount,
         logout,
         siteInfo,
         onlineMembers,
      } = this.props;
      let title = null;
      const currentRoute = getCurrentRoute(locationPath);
      let parents = this.getParents(currentRoute, [], currentRoute.getParent().title);
      const cancel = currentRoute.getCancel();
      if(currentRoute) {
         title = currentRoute.getTitle();
      }
      const params = getCurrentRoute(locationPath).match(locationPath).params;
      let id = params.id
      if(video.id){
         id = video.id
      }
      const headerProps = {
         title: title,
         fontIconSize: 'doubleLarge',
         fontIconColor: 'secondary',
         chat: this.getChatModal(),
         leftIconAction: cancel && id ? () => deleteStore(id) : () => this.goBack(params),
         onClickMyAccount: () => goTo(Router.route('MY_ACCOUNT').getCompiledPath()),
         toMyAccount: Router.route('MY_ACCOUNT').getCompiledPath(),
         onClickLogOut: () => logout(locationPath, window.location.search),
         user: {
            avatar: user && user.compressed_avatar_src,
            screen_name: user && user.screen_name,
            status: user.status,
            uuid: user.uuid,
         },
         siteStatus: siteInfo ? siteInfo.site_status : null,
         isHelpIcon: screenWidth <= 700,
         isMobile: screenWidth <= 700,
         viewAs: this.getViewAsModal(),
         notifications: this.getNotificationsList(),
         isAdmin: true,
         onlineMembers,
         openOnlineMembersModal: () => this.openOnlineMembersModal(true),
         extraTitle: currentRoute?.getExtraTitle() ? currentRoute?.getExtraTitle() : null,
         lastItemCallback: !!currentRoute?.getLastItemCallback ? currentRoute?.getLastItemCallback() : null,
         isUploadsPage: Router.route('UPLOADS').getCompiledPath() === window?.location.pathname,
      };
      let showUser = false;
      if(!user || screenWidth <= 700) {
         headerProps['leftIconName'] = cancel ? 'close' : 'arrow-left';
      } else {
         showUser = true;
         headerProps['leftOffset'] = '56px';
         headerProps['parents'] = parents;
         if(complitedUploadsCount > 0) {
            headerProps['uploads'] = {
               show: true,
               count: complitedUploadsCount,
            };
            headerProps['uploadsIconAction'] = this.handleUploadsIconAction;
         }
      }
      headerProps['showUser'] = showUser;

      return (
         <PageHeader
            { ...headerProps }
         >
            { children }
         </PageHeader>
      );
   }

   getParents = (parent, parents) => {
      if(parent.getParent()) {
         let backId = null
         const backSearchParams = parent.getBackSearchParams();
         const backSearchParamsTitle = parent.getBackSearchParamsTitle();
         let titleSuffix = '';
         let parentLink  = Router.route(`${ parent.parentKey }`).getCompiledPath()
         if(!!parent.getGoBackById()) {
            const { locationPath } = this.props
            const params = getCurrentRoute(locationPath).match(locationPath).params;
            backId = params.id;
            parentLink = Router.route(`${ parent.parentKey }`).getCompiledPath({ id: backId })
         }
         if(parent.getParent()?.hasBackSearchParams){
            if(backSearchParams){
               parentLink = `${ parentLink }?${ backSearchParams }`
            }
            if(backSearchParamsTitle){
               titleSuffix = `: ${ backSearchParamsTitle }`;
            }

         }
         parents.unshift({
            parentKey: parent.parentKey,
            title: parent.getParent().title + titleSuffix,
            link: parentLink,
            extraTitle: parent.getExtraTitle() ? parent.getExtraTitle() : null,
            lastItemCallback: !!parent.getLastItemCallback ? parent.getLastItemCallback() : null,
         })
      }
      if(!parent.topLevel) {
         this.getParents(parent.getParent(), parents);
      }
      return parents;
   }

   goBack = (params) => {
      const {
         locationPath,
         goTo,
      } = this.props
      if(QueryParams.getParam('goTo')) {
         return goTo(QueryParams.getParam('goTo'))
      } else if(!isPreviousLocationWithinApp()) {
         return goTo(getCurrentRoute(locationPath).getParent().getCompiledPath(params))
      } else {
         return history.goBack()
      }
   }

   updateQrStyles = async (data, callback) => {
      const {
         updateSiteInfo,
      } = this.props

      try {
         await customizeQR(data)
         updateSiteInfo({ my_member_site_qr_styles: data })
         callback('succeed')
      } catch (error) {
         console.log(error);
         callback('failed')
      }


   }

   getPreviewHeader = () => {
      const {
         children,
         screenWidth,
         user,
         goTo,
         logout,
         siteInfo,
         locationPath,
         onlineMembers,
      } = this.props;
      const headerProps = {
         fontIconSize: 'doubleLarge',
         fontIconColor: 'secondary',
         chat: this.getChatModal(),
         leftIconAction: () => {},
         onClickMyAccount: () => goTo(Router.route('MY_ACCOUNT').getCompiledPath()),
         toMyAccount: Router.route('MY_ACCOUNT').getCompiledPath(),
         onClickLogOut: () => logout(locationPath, window.location.search),
         user: {
            avatar: user.compressed_avatar_src,
            screen_name: user.screen_name,
            status: user.status,
            uuid: user.uuid,
         },
         isMobile: screenWidth <= 700,
         siteStatus: siteInfo ? siteInfo.site_status : null,
         viewAs: this.getViewAsModal(),
         notifications: this.getNotificationsList(),
         isAdmin: true,
         onlineMembers,
         openOnlineMembersModal: () => this.openOnlineMembersModal(true),
         isUploadsPage: Router.route('UPLOADS').getCompiledPath() === window?.location.pathname,
      }
      if(screenWidth <= 700) {
         headerProps['leftIconName'] = 'menu';
      } else {
         headerProps['leftOffset'] = '56px';
      }
      return (
         <PageHeader
            { ...headerProps }
         >
            { children }
         </PageHeader>
      );
   }


   render() {
      let header = null
      const {
         locationPath,
         isOpenOnlineMembersModal,
         siteInfo,
         screenWidth,
         shareModal,
         setShareModal,
         user,
      } = this.props;

      if(Router.route('HOMEPAGE').match(locationPath)){
         header = this.getHomepageHeader();
      } else if(isTopLevelRoute(locationPath)) {
         header = this.getTopLevelRoutesHeader();
      } else if(Router.route('MY_SITE_COLORS_PREVIEW').match(locationPath)) {
         header = this.getPreviewHeader();
      } else {
         header = this.getChildLevelRoutesHeader();
      }
      return (
         <>
            { header }
            {
               isOpenOnlineMembersModal && (
                  <OnlineMembersModal />
               )
            }
            {
               shareModal?.isOpen &&
                  <ShareModal
                     isAdmin
                     onCloseModal={ () => setShareModal({}) }
                     siteLink={ siteInfo?.site_url }
                     siteName={ siteInfo.site_name }
                     shareDescription={ '' }
                     sitePrefix={ siteInfo?.site_name }
                     savedQrStyles={ siteInfo?.my_member_site_qr_styles }
                     updateQrStyles={ this.updateQrStyles }
                     screenWidth={ screenWidth }
                     additionalInfo={ shareModal?.additionalInfo }
                     referralCode={ user?.referral_code }
                     siteDomain={ siteInfo?.site_domain_details?.domain_type === 'default_domain' ? siteInfo?.site_domain_details?.default_domain : siteInfo?.site_domain_details?.custom_domain }
                     appIcon={ siteInfo?.app_icon }
                     componentDidMountCallback={ (isOpened) => {
                        if(isOpened){
                           QueryParams.setQueryParam('share', true)
                        } else {
                           QueryParams.removeQueryParam('share')
                        }
                     } }
                  />
            }
         </>
      );
   }
}

const mapStateToProps = (state) => {
   return {
      locationPath: currentLocationPathSelector(state),
      video: videoInitStorySelector(state),
      screenWidth: screenWidthSelector(state),
      user: authUserSelector(state),
      complitedUploadsCount: complitedUploadsCountSelector(state),
      unreadChatMessagesCount: unreadMessagesCountSelector(state),
      siteInfo: siteInfoSelector(state),
      isOpenNptificationPopup: isOpenNptificationPopupSelector(state),
      // replyCommentNotification: replyCommentNotificationSelector(state),
      notificationsList: notificationsList(state),
      unseenNotificationsCount: unseenNotificationsCountSelector(state),
      notificationsPanel: notificationsPanelSelector(state),
      onlineMembers: onlineMembersSelector(state),
      isOpenOnlineMembersModal: isOpenOnlineMembersModalSelector(state),
      openDkChatNotification: openDkChatNotificationSelector(state),
      shareModal: shareModalSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      goTo: (location) => {
         dispatch(push(location))
      },
      collapseMenu: () => {
         dispatch(collapseMenu())
      },
      deleteStore: (id) => {
         dispatch(deleteStore(id))
      },
      openHeaderNotificationsPopup: bool => {
         dispatch(openHeaderNotificationsPopup(bool))
      },
      setNotificationsPanel: data => dispatch(setNotificationsPanelAction(data)),
      markAllAsSeenNotifications: () => dispatch(markAllAsSeenNotificationsOperations()),
      fetchNotifications: () => dispatch(fetchNotificationsOperation()),
      logout: (path, search) => {
         Auth.logout();
         dispatch(resetCommonDetails());
         dispatch(resetUploadsVideos());
         dispatch(clearChatInfo());
         let existPath = path && path !== '/';
         let goBack = existPath ? `go-back=${ path }` : '';
         if(!!search) {
            goBack = `${ goBack }${ encodeURIComponent(search) }`
         }
         Auth.redirectSignIn(goBack, () => {
            dispatch(push({
               pathname: Router.route('SIGN_IN').getCompiledPath(),
               search: goBack,
            }))
         });
      },
      openOnlineMembersModal: (bool) => dispatch(openOnlineMembersModal(bool)),
      setOpenDkChatNotification: (bool) => dispatch(setOpenDkChatNotification(bool)),
      setShareModal: (data) => dispatch(setShareModalAction(data)),
      updateSiteInfo: (data) => dispatch(updateSiteInfoAction(data)),
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(PageHeaderContainer);
