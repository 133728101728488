import React, { useState, useEffect, useRef } from 'react';
// import 'emoji-mart/css/emoji-mart.css';
import './style.scss';
import Modal from 'common/components/modules/modal';
import MuteMember from 'common/components/modules/chat/member-muted';
import PropTypes from 'prop-types';
import { isEmpty  } from 'lodash'
import ChatSellingContent from './selling-content';

import QueryParams from 'admin/utils/QueryParams';
import WarningModal from 'common/components/modules/chat/content-warning-modal';
import toast, { positionToasterContainer } from 'common/utils/toast';
import { getImageFileHash } from 'common/utils/utils'
import TextMessageField from './text-message';
import ConfirmModal from 'common/components/modules/modal-wrapper';
import { isLocalhost } from 'common/utils/helper';
import MessageDurationModal from '../message-duration-modal';
import { getPhotosetPathsById } from 'admin/api/AuthApi';
import CreateMultipleVaultPopup from 'admin/views/vault/modules/create-vaults-popup';


function NewMessageField({
   replyMessage,
   canelReplyMessage,
   mutedInfo,
   inputRef,
   onReadMessage,
   isMobile,
   inputForIosFocus,
   setMutedMemberInfo,
   isConnected,
   isMessageToLong,
   setErrorToLong,
   selectByLiber,
   removeSelectedContent,
   deleteVaultVideo,
   userUuid,
   content,
   onSaveUploadPhotos,
   maxPrice,
   isShowUser,
   userAvatar,
   onSendVoiceMessage,
   privateAttachmentSettings,
   defaultVoicePrice,
   intoNotificationCard,
   onUpdateRecordStatus,
   handleUploadImage,
   cancelVideoUplaod,
   checkVaultByHash,
   createVault,
   checkContentTypeInTheChat,
   getMediaBlurhash,
   chatRoomId,
   containerRef,
   isUpdateState,
   onClickCancelEditMessage,
   showNickname,
   isMiniChat,
   hideVoiceMessage,
   isMassMessage,
   onCustomAction,
   showVariables,
   variableList,
   showFrequentlyUsedEmojis,
   attachments,
   attachmentsForShow,
   isMultipleAttachments,
   onRemoveMedia,
   onClickCancel,
   isDemoMode,
   authUser,
   updateMessageType,
   isMaxAttachmentCountReached,
   onClickSavedReplies,
   isSavedReplies,
   onInputBlur,
   idForField,
   textLimit,
   calcTextLengthFor,
   setReplyData,
   repliesModalState,
   onInputRefMount,
   onInputRefUnmount,
   replyData,
   setMessageType,
   readyToSendReply,
   errors,
   onInputFocus,
   durationInfo,
   onSaveDurationData,
   isFetchingChatSettingsUpdate,
   getQueryParam,
   lastMessage,
   memberVideoDownloadMultipleWatermarks,
   ...props
}) {
   const [files, setFiles] = useState([]);
   const [prevFiles, setPrevFiles] = useState([]);
   const [unlockMessageType, setUnlockMessageType] = useState();
   const [sendMediaDisabled, setSendMediaDisabled] = useState(false);
   const [startSendContent, setStartSendContent] = useState(false);
   const [checkContentState, setCheckContentState] = useState({});
   const [newVaultPhotos, setNewVaultPhotos] = useState([]);
   const [price, setPrice] = useState(null);
   const [allowDownload, setAllowDownload] = useState(false);
   const [blur, setBlur] = useState(isMassMessage ? content?.isBlurPoster || false : true);
   const [isLoadingSendBtn, setIsLoadingSendBtn] = useState(false)
   const [disabledSendTo, setDisabledSendTo] = useState(true);
   const attachmentsStatuses = useRef([]);
   const [openDiscardModal, setOpenDiscardModal] = useState(false);

   const [activeState, setActiveState] =  useState('setContent');
   const [showUnsentDurationModal, setShowUnsentDurationModal] = useState(false)
   const [isOpenCreateMultipleVaultPopup, setIsOpenCreateMultipleVaultPopup] = useState(false)
   const [newVaultsData, setNewVaultsData] = useState([])

   const [openVoiceMessage, setOpenVoiceMessage] = useState(false);
   const [allowDownloadWithWatermark, setAllowDownloadWithWatermark] = useState(false);

   const onClearState = () => {
      setPrevFiles([]);
      setFiles([]);
      setNewVaultPhotos([]);
      setPrice(null);
      if(onSaveUploadPhotos && !isMultipleAttachments) {
         onSaveUploadPhotos({}, 'remove', chatRoomId);
      }
      isMassMessage ? setBlur(false) : setBlur(true);
      // !!onCustomAction && onCustomAction('locked_poster_is_blur', Boolean(true))
      setAllowDownload(false);
      setAllowDownloadWithWatermark(false);
      // !!onCustomAction && onCustomAction('allow_download', Boolean(false))

   }

   useEffect(() => {
      if(isMassMessage) {
         if(content?.prevFiles?.length > 0) {
            setPrevFiles([...content?.prevFiles])
         }
      }
   }, [isMassMessage, content, content?.prevFiles])

   useEffect(() => {
      return () => {
         if(chatRoomId) {
            window.draftMessages = {
               ...window.draftMessages,
               [chatRoomId]: {
                  ...window?.draftMessages?.[chatRoomId],
                  draftText: !!attachmentsForShow?.length ? 'Media added' : !isSavedReplies ? (window?.draftMessages?.[chatRoomId]?.text || '') : '',
                  userUuid, price, blur, allowDownload, allowDownloadWithWatermark,
               },
            }
         }
      }
   }, [attachmentsForShow, chatRoomId, userUuid, price, blur, allowDownload, allowDownloadWithWatermark, isSavedReplies])

   const sendMessage = async (e, callback, sendWithDuration) => {

      if(inputRef.current?.innerText?.trim().length > textLimit && !([46, 8].includes(e.keyCode))) {
         inputRef.current?.blur()
         toast.error(`${ textLimit } character limit has been reached`, {
            toastId: 'limit_error',
         })
         e.preventDefault()
         e.stopPropagation()

         return
      }
      let text = inputRef.current.innerText.trim();

      if(e.keyCode === 13 && !!e.preventDefault) {
         if(e.shiftKey){
         } else {
            e.preventDefault()
            e.stopPropagation()
         }
      }

      if(updateMessageType === 'text_message' && !text) {
         setIsLoadingSendBtn(false)
         return
      } else if(!updateMessageType && !text) {
         setIsLoadingSendBtn(false)
         return
      }


      if(text.length > textLimit) {
         toast.error(`${ textLimit } character limit has been reached`, {
            toastId: 'limit_error',
         })
         inputRef.current.innerText = text.slice(0, textLimit)
         return
      }

      saveAsDraftTextMessage(inputRef.current.innerText.trim())

      if(e.keyCode === 13  && !e.shiftKey) {
         if(props.sendMessage){
            let parent = null
            if(replyMessage){
               parent = replyMessage

               if(!isEmpty(replyMessage) && replyMessage.user)
                  parent.user = {
                     username: replyMessage.user.username,
                  }
            }
            props.sendMessage(text, parent, () => {
               if(window?.draftMessages && window.draftMessages[chatRoomId]) {
                  window.draftMessages[chatRoomId] = null
               }
               attachmentsStatuses.current = []
            }, sendWithDuration);
         }

         await setPrevFiles([]);
         await setFiles([]);
         await setNewVaultPhotos([]);
         if(onSaveUploadPhotos) {
            onSaveUploadPhotos({}, 'remove')
         }
         if(!isMobile &&  inputRef.current) {
            inputRef.current.focus()
         }
         if(replyMessage && replyMessage.parentMessageId) {
            canelReplyMessage()
         }
         if(inputRef.current && !isMassMessage) {
            inputRef.current.innerText = '';
         }
         await setIsLoadingSendBtn(false)

         if(callback) callback()

         return false;
      }
   }

   useEffect(() => {
      if(content && 'photo' === content.type){
         if(content.prevFiles && !!content.prevFiles?.[0]){
            setPrevFiles(content.prevFiles)
         }
         if(content.filesArr && !!content.filesArr?.[0]){
            setFiles(content.filesArr)
         }
         if(content.vaultPhotos && !!content.vaultPhotos?.[0]){
            setNewVaultPhotos(content.vaultPhotos)
         }

      } else {
         setPrevFiles([]);
         setFiles([]);
         setNewVaultPhotos([]);
      }
      if(content) {
         if(content.type){
            setUnlockMessageType(content.type.includes('video') ? 'video_unlock' : 'photo_unlock')
            if('upload_video' === content.type && content.videoUpload){
               setSendMediaDisabled(content.videoUpload.video_optimization_status !== 'completed')
            } else {
               setSendMediaDisabled(false)
            }

         }

         if(content.price){
            setPrice(+content.price)
            // !!onCustomAction && onCustomAction('unlock_price', content.price)
         }
         if('lockedPosterIsBlur' in content){
            setBlur(content.lockedPosterIsBlur)
            // !!onCustomAction && onCustomAction('locked_poster_is_blur', Boolean(content.lockedPosterIsBlur))
         }
         if('allow_download' in content){
            setAllowDownload(Boolean(content.allow_download))
            // !!onCustomAction && onCustomAction('allow_download', Boolean(content.allow_download))
         }
         if('add_member_info_to_watermark' in content){
            setAllowDownloadWithWatermark(Boolean(content.add_member_info_to_watermark))
            // !!onCustomAction && onCustomAction('add_member_info_to_watermark', Boolean(content.add_member_info_to_watermark))
         }
      }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [content])

   useEffect(() => {
      let ollContents = window.currentConversationContent ? JSON.parse(window.currentConversationContent) : {}
      let alldraftMessages = window.draftMessages ? window.draftMessages : {}

      if(ollContents && !ollContents?.[chatRoomId] && !isMassMessage) {
         onClearState()
      }

      if(alldraftMessages && !!alldraftMessages?.[chatRoomId]) {
         if(!!alldraftMessages?.[chatRoomId]?.text && !isSavedReplies)  inputRef.current.innerText = alldraftMessages?.[chatRoomId]?.text
         if(!!alldraftMessages?.[chatRoomId]?.price)  setPrice(alldraftMessages?.[chatRoomId]?.price)

         setBlur(!!alldraftMessages?.[chatRoomId]?.blur)
         setAllowDownload(!!alldraftMessages?.[chatRoomId]?.allowDownload)
         setAllowDownloadWithWatermark(!!alldraftMessages?.[chatRoomId]?.allowDownloadWithWatermark)
         setDisabledSendTo(false)
      } else {
         if(inputRef.current) {
            inputRef.current.innerText = ''
            setDisabledSendTo(true)
         }
      }

      if(isUpdateState && !!onClickCancelEditMessage) onClickCancelEditMessage()

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [chatRoomId])

   useEffect(() => {
      if(isMultipleAttachments && !isMassMessage && attachmentsForShow?.length > 0) {

         const uploadingItems = attachmentsForShow.filter(it => {
            if(it?.status && ['uploading', 'optimizing', 'in_optimization_queue'].includes(it?.status)) {
               return true
            }
            if(it?.uploadingStatus && ['uploading', 'in_optimization_queue'].includes(it?.uploadingStatus)) {
               return true
            }
            return false
         })

         setDisabledSendTo(uploadingItems?.length > 0)
         setSendMediaDisabled(uploadingItems?.length > 0)
      }

   }, [attachmentsForShow?.length, attachmentsForShow, isMultipleAttachments, isMassMessage])

   useEffect(() => {
      if(!!inputRef && !!inputRef.current && onInputRefMount) {
         onInputRefMount(inputRef)
      }
      return () => {
         if(!!inputRef && onInputRefUnmount) {
            onInputRefUnmount(inputRef)
         }
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])


   const uploadImage = async (e) => {
      if(props.isAdmin){
         uploadImageByAdmin(e);
         return;
      }
      uploadImageByMember(e);
   }
   const uploadImageByMember = async (e) => {
      if(files.length > 0){
         setPrevFiles([]);
         setFiles([]);
         setNewVaultPhotos([]);
      }
      if(!handleUploadImage){
         return;
      }
      const uploadFiles = [...e.target.files];
      e.target.value = null;
      let filesArr = [];
      let vaultPhotos = [];
      let loadsData = [];
      setSendMediaDisabled(true)

      for(let i = 0; i < uploadFiles.length; i++) {
         loadsData.push({ status: 'uploading', file: uploadFiles[i] })
      }
      let newData = [...loadsData];
      setPrevFiles(newData)
      for(let i = 0; i < newData.length; i++) {
         const { file } = newData[i];
         try {
            const data = new FormData();
            data.append('file', file);
            const { fullSrc, path } =  await handleUploadImage(data);
            const imageSrc = fullSrc.data[path];
            newData = [...newData].map(elm => {
               let { ...item } = elm
               item = { src: imageSrc, status: 'completed' }
               return item
            })
            setPrevFiles(newData)
            filesArr = [...filesArr, path]
         } catch (error) {

         }
      }
      setFiles(filesArr);
      setNewVaultPhotos(vaultPhotos)

      let saveData = newData.filter(i => i.status !== 'uploading')
      if(saveData.length === newData.length){
         setSendMediaDisabled(false)
      }
      onSaveUploadPhotos({ prevFiles: saveData, filesArr, vaultPhotos, type: 'photo' }, 'add')
   }
   const uploadImageByAdmin = async (e) => {
      const uploadFiles = [...e.target.files];
      e.target.value = null;
      let filesArr = [...files];
      let vaultPhotos = [...newVaultPhotos];
      let loadsData = [];
      let showImages = [...prevFiles]
      let hashs = []
      setSendMediaDisabled(true)

      for(let i = 0; i < uploadFiles.length; i++) {
         await getImageFileHash(uploadFiles[i]).then(
            (result) => {
               if(attachmentsForShow?.find(item => item?.hash === result && item?.status === 'completed')) return
               loadsData.push({
                  status: 'uploading',
                  hash:
                  result,
                  file: uploadFiles[i],
                  original_name: uploadFiles[i]?.name,
               })
               hashs.push(result)
               showImages.push({ [result]: uploadFiles[i] })
            });
      }
      if(!loadsData?.length) return

      if(isMultipleAttachments) {
         onSaveUploadPhotos({ prevFiles: [], filesArr: [], vaultPhotos: [], type: 'photo', hashs: loadsData.map(it => it.hash), loadingData: loadsData }, 'add', chatRoomId)
      }
      let newData = [...prevFiles, ...loadsData];
      setPrevFiles(newData)
      const data = await checkVaultByHash(hashs)

      console.log(newData);

      const sortItems = data.reduce((acc, curr) => {
         let value =  Object.values(curr)[0]
         let hash =  Object.keys(curr)[0]

         if(value) {
            newData = [...newData].filter(item => item.hash !== hash);
            newData.push({
               status: 'completed',
               src: value.image_thumb_src,
               hash,
               title: value?.title,
               isUpdated: true,
               vaultId: value?.id,
               path: value?.src,
            });
            setPrevFiles(data)
            filesArr = [...filesArr, value.src]
         } else {
            let current = showImages.find(el => !!el[hash])
            acc.push({ hash, file: current[hash], isUpdated: false })
         }
         return acc
      }, []);

      for(let i = 0; i < sortItems.length; i++) {
         const { file, hash } = sortItems[i];
         try {
            const data = new FormData();
            data.append('file', file);
            const { fullSrc, path } =  await handleUploadImage(data);
            const imageSrc = fullSrc.data[path];

            newData = [...newData].map(elm => {
               let { ...item } = elm
               if(item.hash === hash) {
                  item = { src: imageSrc, status: 'completed', hash, original_name: file?.name, path, title: elm?.title, isUpdated: false, vaultId: elm?.id }
               }
               return item
            })
            setPrevFiles(newData)
            let vaultPhoto = {
               hash: hash,
               src: path,
               type: 'image',
               original_name: file?.name,
               title: sortItems?.title,
               isUpdated: false,
            }
            filesArr = [...filesArr, path]
            vaultPhotos.push(vaultPhoto)
         } catch (error) {

         }
      }
      let saveData = newData.filter(i => i.status !== 'uploading')

      filesArr = saveData?.map(it => {
         if(!it?.path){
            return Object?.values(it)?.[0]?.src
         }
         return it?.path
      })

      setFiles(filesArr)
      setNewVaultPhotos(vaultPhotos)

      if(saveData.length === newData.length){
         setSendMediaDisabled(false)
      }

      console.log({
         prevFiles: saveData,
         filesArr,
         vaultPhotos,
         type: 'photo',
         hashs: saveData.map(it => it.hash),
         loadingData: [],
      });
      onSaveUploadPhotos(
         {
            prevFiles: saveData,
            filesArr,
            vaultPhotos,
            type: 'photo',
            hashs: saveData.map(it => it.hash),
            loadingData: [],
         },
         'add',
         chatRoomId,
         (data) => openCloseMultipleCreateVaultsPopup(true, data)
      )
   }


   const removeImage = (e, index, isPrevent = false) => {
      if(e){
         const element = e.target.parentNode.parentNode;
         element.classList.add('remove');
      }

      // remove prev img
      const prevFilesArr = [...prevFiles];
      const sortedPrevFilesArr = prevFilesArr.filter((prevFile, i) => {
         return i !== index;
      });
      const vaultPhotos = [...newVaultPhotos].filter((_, i) => {
         return i !== index;
      });
      // remove file img
      const filesArr = [...files];
      const sortedFilesArr = filesArr.filter((file, i) => {
         return i !== index;
      });

      // console.log({ prevFiles: sortedPrevFilesArr, filesArr: sortedFilesArr, vaultPhotos });
      setFiles(sortedFilesArr);
      setPrevFiles(sortedPrevFilesArr);
      setNewVaultPhotos(vaultPhotos);

      if(isPrevent) return

      if(sortedPrevFilesArr.length === 0){
         onSaveUploadPhotos({}, 'remove')
      } else {
         onSaveUploadPhotos({ prevFiles: sortedPrevFilesArr, filesArr: sortedFilesArr, vaultPhotos, type: 'photo', hashs: vaultPhotos?.map(it => it?.hash), loadingData: [] }, 'update')
      }
   }

   const onSavePrice = (data) => {
      if('allow_download' in data){
         setAllowDownload(data.allow_download)
         !!onCustomAction && onCustomAction('allow_download', Boolean(data.allow_download))
      }

      if('price' in data){
         setPrice(data.price)
         !!onCustomAction && onCustomAction('unlock_price', data.price)
      }
      if('isBlurPoster' in data){
         setBlur(data.isBlurPoster)
         !!onCustomAction && onCustomAction('locked_poster_is_blur', Boolean(data.isBlurPoster))
      }
      if('add_member_info_to_watermark' in data){
         setAllowDownloadWithWatermark(data.add_member_info_to_watermark)
         !!onCustomAction && onCustomAction('add_member_info_to_watermark', Boolean(data.add_member_info_to_watermark))
      }
   }


   // media message

   const onSendMediaMessage = async (unlockPrice, resources, isBlur, hash, status, callback, singleAttachment, sendWithDuration) => {
      onSaveUploadPhotos({ price, lockedPosterIsBlur: blur }, 'update')
      let text = inputRef.current.innerText.trim()

      if(text.length > textLimit) {
         toast.error(`${ textLimit } character limit has been reached`)
         inputRef.current.innerText = text.slice(0, textLimit)
         return
      }

      saveAsDraftTextMessage(inputRef.current.innerText.trim())

      let type = singleAttachment ? (singleAttachment?.type.includes('photo') ? 'photo_unlock' : 'video_unlock') : unlockMessageType;
      setStartSendContent(true)
      if(!type) {
         return
      }

      const currentAttachment = singleAttachment ? singleAttachment : content
      const typeKey = singleAttachment && !!singleAttachment?.resource_type ? 'resource_type' : 'type'
      const resourcesArr = singleAttachment ? (singleAttachment.type === 'photo' ? singleAttachment.filesArr  : singleAttachment.resources) : resources

      let data = {
         unlockPrice: +price || 0,
         lockedPosterIsBlur: blur,
         resources: resourcesArr,
         type: type,
         resourceId: null,
         text,
         allowDownload,
         attachmentsStatuses: attachmentsStatuses.current,
         publish_date: currentAttachment?.publish_date,
         memberId: currentAttachment?.memberId,
         vaultId: currentAttachment?.resource_id,
         if_free_for_member_by_access: currentAttachment?.if_free_for_member_by_access,
         allowDownloadWithWatermark,
      }

      if('locked_free_content' === status){
         data.unlockPrice = 0;
      }

      if(currentAttachment && (currentAttachment?.[typeKey] === 'video' || currentAttachment?.[typeKey] === 'photoset' || currentAttachment?.[typeKey].includes('_vault'))) {
         data.resourceId = currentAttachment.id || currentAttachment.resource_id
         data.resourceType = currentAttachment?.[typeKey]
         if('photo_vault' === currentAttachment?.[typeKey] && hash)  data.resourceId = hash
      }
      if(currentAttachment && 'upload_video' === currentAttachment.type){
         data.resourceId = currentAttachment.videoUpload ?  currentAttachment.videoUpload.id : null
         data.resourceType = 'video_vault'
      }
      if(currentAttachment && 'photo' === currentAttachment.type){
         data.resourceId = singleAttachment ? singleAttachment?.hashs?.[0] : hash
         data.resourceType = 'photo_vault'
      }
      if(currentAttachment && 'photoset' === currentAttachment?.[typeKey] && currentAttachment?.photos_count){
         data.photos_count = currentAttachment?.photos_count

         const res = await getPhotosetPathsById(data.resourceId)

         data.resources = res?.data//?.photos?.map(ph => ph?.path)

      }
      if(props.isAdmin){
         if('photo_unlock' === type){
            setSendMediaDisabled(true)
            try {
               const blurhash = await getMediaBlurhash(resourcesArr?.[0])
               data.blurhash = blurhash
               setSendMediaDisabled(false)
            } catch (error) {
               setSendMediaDisabled(false)
            }
         } else {
            if('upload_video' === currentAttachment.type) {
               data.coverImage = currentAttachment.videoUpload.video_poster
               data.duration = currentAttachment.videoUpload.video_duration || 1200
               data.resources = [currentAttachment.videoUpload.src]
            } else {
               data.coverImage = currentAttachment.cover_image || currentAttachment.posterImage || currentAttachment.poster || currentAttachment?.resources?.[0]
               data.duration = currentAttachment.duration || currentAttachment.video_duration || 1200
               if(!currentAttachment.resources) {
                  data.resources = [currentAttachment.src]
               }

            }
         }
      }
      if(!props.isAdmin){
         data.fullSrc = content.prevFiles?.[0]
      }

      if(singleAttachment && singleAttachment?.basename && singleAttachment?.type === 'video_unlock') {
         data.basename = singleAttachment?.basename
      }

      if(props.sendMessage) {
         let parent = null
         if(replyMessage){
            parent = replyMessage
            if(!isEmpty(replyMessage) && replyMessage.user)
               parent.user = {
                  username: replyMessage.user.username,
               }
         }

         props.sendMessage(data, parent, async () => {
            console.log({
               newVaultPhotos,
               singleAttachment,
               type,
            });
            if(props.isAdmin && 'photo_unlock' === type && newVaultPhotos && newVaultPhotos.length > 0 && !singleAttachment) {
               try {
                  await createVault(newVaultPhotos, data)
               } catch (error) {

               }
            }
            if(props.isAdmin && 'photo' === singleAttachment.type && singleAttachment.vaultPhotos && singleAttachment.vaultPhotos.length > 0) {
               try {
                  await createVault(singleAttachment.vaultPhotos, data)
               } catch (error) {

               }
            }
            setSendMediaDisabled(false)
            setStartSendContent(false)
            if(!isMobile &&  inputRef.current) {
               inputRef.current.focus()
            }
            if(replyMessage && replyMessage.parentMessageId) {
               canelReplyMessage()
            }
            if(inputRef.current) {
               inputRef.current.innerText = '';
            }

            onClearState();
            setIsLoadingSendBtn(false)

            if(window?.draftMessages && window.draftMessages[chatRoomId]) {
               window.draftMessages[chatRoomId] = null
            }
            attachmentsStatuses.current = []
         }, sendWithDuration);
      }
      if(callback) callback()
      removeMedia()
   }

   const  removeMedia = () => {
      if(!isEmpty(content)) {
         removeSelectedContent()
      } else {
         setFiles([])
         setPrevFiles([])
         setNewVaultPhotos([])
      }
      onSaveUploadPhotos({}, 'remove')
   }
   const onSelectLibery = (liberyType, messageType, callback) => {
      setUnlockMessageType(messageType)
      selectByLiber(liberyType)
      if(callback) {
         callback()
      }
   }
   const onUploadFile = (e, messageType) => {

      let filesCount = Object.keys(e.target.files)?.length || 0
      if(attachmentsForShow?.length + filesCount > 20) {
         toast.error('Limit of 20 attachments has been reached')
         return
      }

      setUnlockMessageType(messageType)
      if(messageType === 'photo_unlock') {
         uploadImage(e)

      } else {
         setSendMediaDisabled(true)
         selectByLiber('upload_video', e, (data) => {
            openCloseMultipleCreateVaultsPopup(true, data)
         })
      }

   }

   const checkMediaLockedState = async (unlockPrice, resources, isBlur, e, callback, singleAttachment, sendWithDuration) => {
      let currentContent = singleAttachment ? singleAttachment : content

      const currentAttachment = singleAttachment ? singleAttachment : content

      if('upload_video' === currentAttachment.type){
         currentContent = {
            type: 'video_vault',
            id: currentAttachment.videoUpload ?  currentAttachment.videoUpload.id : null,
         }
      }
      try {
         let info = {
            type: currentContent.type,
            resource_id: currentContent.id,
            member_uuid: userUuid,
            // is_group_chat: !!props.isGroup,
            is_group_chat: false,
         }
         if(!props.isGroup){
            info = {
               ...info,
               price: +unlockPrice,
               member_uuid: userUuid,
            }
         }
         if('photo_vault' === currentAttachment.type) {
            info = {
               ...info,
               paths: currentAttachment.resources,
               hashs: [],
            }
         }
         if('photo' === currentAttachment.type){
            const { prevFiles } = currentAttachment;
            let hashs = [];
            if(prevFiles) {
               prevFiles.forEach(element => {
                  hashs.push(element.hash)
               });
            }
            info = {
               ...info,
               type: 'photo_vault',
               paths: currentAttachment.filesArr ?  currentAttachment.filesArr : [],
               hashs: hashs,
            }
         }


         if(singleAttachment) {
            if("photo_vault" === currentContent?.resource_type) {
               info = {
                  ...info,
                  type: currentContent?.resource_type,
                  paths: currentContent?.resources,
                  hashs: currentContent?.hashes || [],
               }

            }

            if("video_vault" === currentContent?.resource_type) {
               info = {
                  ...info,
                  type: currentContent?.resource_type,
                  resource_id: currentContent?.resource_id,
               }
            }

            if((currentContent.resource_type === 'video' || currentContent.resource_type === 'photoset')) {
               info.resource_id = currentContent.resource_id
               info.type = currentContent.resource_type
            }

         }

         setSendMediaDisabled(true)
         let data = {}

         if(props.isAdmin){
            const checkData = await checkContentTypeInTheChat(info)
            data = checkData.data

            attachmentsStatuses.current = [data]
         }
         let hash = data.hash || null
         let status = data.status || null

         if(data.status) {
            let data = {
               locked_free_content: {
                  text: 'The content you are about to send has already been unlocked by this member, or there is access through content settings. Payment will not be required to view.',
                  icon: 'attention-outline',
                  title: 'No payment required to view',
                  color: 'action',
               },
               is_free_unlock_content: {
                  text: 'You are about to send locked content for free',
                  icon: 'warning',
                  title: 'Locked content for free',
                  color: 'warning',
               },
               is_hidden_content: {
                  text: `You are about to give access to content that is hidden for their current membership`,
                  icon: 'warning',
                  title: 'Hidden content',
                  color: 'warning',
               },
            }

            if(status === 'locked_free_content') {
               attachmentsStatuses.current = [{ status: null }]
            }

            let checkData = {
               ...checkContentState,
               unlockPrice: 'locked_free_content' === status ? 0 : unlockPrice,
               resources,
               isBlur,
               openWarningModal: true,
               warningText: data[status]?.text,
               modalTitle: data[status]?.title,
               icon: data[status]?.icon,
               color: data[status]?.color,
               hash,
               status,
               publish_date: currentAttachment?.publish_date,
               sendWithDuration,
            }
            setCheckContentState(checkData)
            setSendMediaDisabled(false)
            setIsLoadingSendBtn(false)
            if(inputRef.current){
               inputRef.current.blur()
            }

         } else {
            setSendMediaDisabled(false)
            setIsLoadingSendBtn(true)
            onSendMediaMessage(unlockPrice, resources, isBlur, hash, null, callback, singleAttachment, sendWithDuration)
         }
      } catch (error) {
         setSendMediaDisabled(false)
         setIsLoadingSendBtn(false)
         toast.error('Something went wrong');
      }
   }

   const getUniqueContentStatuses = (statusesResult, unlockPrice) => {
      const allowedStatuses = ['locked_free_content', 'is_free_unlock_content', 'non_access', 'is_hidden_content', 'already_unlocked', 'locked_for_member']
      const filteredUniqueStatuses = Array.from(new Set(statusesResult.filter(item => item.status && allowedStatuses.includes(item.status)).map(item => JSON.stringify(item)))).map(item => JSON.parse(item)?.status);
      return filteredUniqueStatuses
   }

   const  onSendMessage = async (e, unlockPrice, isBlur, resources, callback, sendWithDuration) => {

      if(inputRef.current.innerText.length > textLimit) {
         return
      }
      if(e.keyCode === 13 && e.shiftKey) {
         return
      }

      if(isMultipleAttachments && attachmentsForShow?.length > 0 && attachments?.length > 0 && attachments?.length === attachmentsForShow?.length  && !isMassMessage && props.isAdmin) {


         setStartSendContent(true)
         setIsLoadingSendBtn(true)

         if(attachmentsForShow?.length === 1 && attachments?.length === 1) {
            let singleAttachment = attachmentsForShow[0]?.type === 'photo' ? attachmentsForShow[0] : attachments[0]
            // console.log({ singleAttachment });
            checkMediaLockedState(unlockPrice, resources, isBlur, null, callback, singleAttachment, sendWithDuration)

         } else {

            /////////////////////////////////// Start of check content state

            const attachmentsStatus = []

            attachments.forEach(async (ctnt) => {

               try {
                  let info = {
                     // is_group_chat: !!props.isGroup,
                     is_group_chat: false,
                  }

                  if(!props.isGroup){
                     info = {
                        ...info,
                        price: +unlockPrice,
                        member_uuid: userUuid,
                     }
                  }

                  if("photo_vault" === ctnt?.resource_type) {
                     info = {
                        ...info,
                        type: ctnt?.resource_type,
                        paths: ctnt?.resources,
                        hashs: ctnt?.hashes || [],
                     }

                  }

                  if("photoset" === ctnt?.resource_type) {
                     info = {
                        ...info,
                        type: ctnt?.resource_type,
                        resource_id: ctnt?.resource_id,
                     }
                  }


                  if("video" === ctnt?.resource_type) {
                     info = {
                        ...info,
                        type: ctnt?.title ? ctnt?.resource_type : 'video_vault',
                        resource_id: ctnt?.resource_id,
                     }
                  }


                  if("video_vault" === ctnt?.resource_type) {
                     info = {
                        ...info,
                        type: ctnt?.resource_type,
                        resource_id: ctnt?.resource_id,
                     }
                  }


                  setSendMediaDisabled(true)

                  if(props.isAdmin){
                     attachmentsStatus.push(checkContentTypeInTheChat(info))
                  }

               } catch (error) {
                  setSendMediaDisabled(false)
                  setIsLoadingSendBtn(false)
                  toast.error('Something went wrong');
               }

            })

            let statusesResult = (await Promise.allSettled(attachmentsStatus)).map(({ value }) => {
               let status  = value?.data?.status
               if(+unlockPrice > 0 && !status) {
                  status = 'locked_for_member'
               }
               //  else if((!unlockPrice || +unlockPrice === 0) && !status) {
               //    status = 'already_unlocked'
               // }
               return { status }
            })

            attachmentsStatuses.current = (await Promise.allSettled(attachmentsStatus)).map(it => it.value.data)

            const statuses = getUniqueContentStatuses(statusesResult, unlockPrice)

            const showAccessWarningPopup = !props.isGroup && statuses.length > 1
            const isThereOnlyHiddenContent = statuses?.length === 1 && statuses?.[0] === 'is_hidden_content'
            const isThereOnlyLockedContent = statuses?.length === 1 && statuses?.[0] === 'is_free_unlock_content'

            if(isThereOnlyHiddenContent) statusesResult = [{ status: 'is_hidden_content' }]
            if(isThereOnlyLockedContent && !unlockPrice) statusesResult = [{ status: 'is_free_unlock_content' }]


            let data = {
               locked_free_content: {
                  text: 'The content you are about to send has already been unlocked by this member, or there is access through content settings. Payment will not be required to view.',
                  icon: 'attention-outline',
                  title: 'No payment required to view',
                  color: 'action',
               },
               is_free_unlock_content: {
                  text: 'You are about to send locked content for free',
                  icon: 'warning',
                  title: 'Locked content for free',
                  color: 'warning',
               },
               is_hidden_content: {
                  text: `You are about to give access to content that is hidden for their current membership`,
                  icon: 'warning',
                  title: 'Hidden content',
                  color: 'warning',
               },
               access_warning: {
                  text: 'You are about to send content that may already be unlocked, accessible, or hidden, based on the current content access settings',
                  icon: 'warning',
                  title: 'Access warning',
                  color: 'warning',
               },
               is_unpublished: {
                  text: 'You are about to send content that may already be unlocked, accessible, or hidden, based on the current content access settings',
                  icon: 'warning',
                  title: 'Access warning',
                  color: 'warning',
               },
            }

            if(!!showAccessWarningPopup) {
               let checkData = {
                  ...checkContentState,
                  unlockPrice,
                  resources,
                  isBlur,
                  openWarningModal: true,
                  warningText: data['access_warning']?.text,
                  modalTitle: data['access_warning']?.title,
                  icon: data['access_warning']?.icon,
                  color: data['access_warning']?.color,
                  hash: null,
                  status: 'access_warning',
                  sendWithDuration,
               }


               setCheckContentState(checkData)
               setSendMediaDisabled(false)
               setIsLoadingSendBtn(false)
               if(inputRef.current){
                  inputRef.current.blur()
               }
               return
            }

            if(!props.isGroup && statusesResult.every(it => it?.status === 'locked_free_content')) {

               let checkData = {
                  ...checkContentState,
                  unlockPrice: 0,
                  resources,
                  isBlur,
                  openWarningModal: true,
                  warningText: data['locked_free_content']?.text,
                  modalTitle: data['locked_free_content']?.title,
                  icon: data['locked_free_content']?.icon,
                  color: data['locked_free_content']?.color,
                  hash: null,
                  status: 'locked_free_content',
                  sendWithDuration,
               }


               setCheckContentState(checkData)
               setSendMediaDisabled(false)
               setIsLoadingSendBtn(false)
               if(inputRef.current){
                  inputRef.current.blur()
               }

            }
            else if(statusesResult.every(it => it?.status === 'is_free_unlock_content')) {
               let checkData = {
                  ...checkContentState,
                  unlockPrice,
                  resources,
                  isBlur,
                  openWarningModal: true,
                  warningText: data['is_free_unlock_content']?.text,
                  modalTitle: data['is_free_unlock_content']?.title,
                  icon: data['is_free_unlock_content']?.icon,
                  color: data['is_free_unlock_content']?.color,
                  hash: null,
                  status: 'is_free_unlock_content',
                  sendWithDuration,
               }


               setCheckContentState(checkData)
               setSendMediaDisabled(false)
               setIsLoadingSendBtn(false)
               if(inputRef.current){
                  inputRef.current.blur()
               }

            }
            else if(!props.isGroup && statusesResult.every(it => it?.status === 'is_hidden_content')) {

               let checkData = {
                  ...checkContentState,
                  unlockPrice,
                  resources,
                  isBlur,
                  openWarningModal: true,
                  warningText: data['is_hidden_content']?.text,
                  modalTitle: data['is_hidden_content']?.title,
                  icon: data['is_hidden_content']?.icon,
                  color: data['is_hidden_content']?.color,
                  hash: null,
                  status: 'is_hidden_content',
                  sendWithDuration,
               }


               setCheckContentState(checkData)
               setSendMediaDisabled(false)
               setIsLoadingSendBtn(false)
               if(inputRef.current){
                  inputRef.current.blur()
               }

            }
            else {
               sendMultipleAttachments(callback, null, sendWithDuration)
            }

            ////////////////////////// End of check content state
         }




      } else {

         if(content && ('photoset' === content?.type || 'video' === content?.type || (('photo' === content?.type || 'upload_video' === content?.type || content?.type?.includes('_vault'))))){
            checkMediaLockedState(unlockPrice, resources, isBlur, null, callback, null, sendWithDuration)
         } else {
            onSendMediaMessage(unlockPrice, resources, isBlur, null, null, callback, null, sendWithDuration)
         }

      }

   }

   const showContent = isMultipleAttachments ? attachmentsForShow?.length > 0 : prevFiles.length > 0 || !isEmpty(content)
   let type = null
   let srcData = prevFiles || content?.prevFiles;
   let isCanUploadNew = true;
   let count = 1;
   let status = 'complited'
   let disabled = sendMediaDisabled
   let resources = files;
   let isBlurPoster = true
   let useContent = null;
   if(showContent && !isMultipleAttachments){
      type = unlockMessageType === 'photo_unlock' ? 'photo' : 'video';
      useContent = !isEmpty(content) && content.type !== 'photo'
      if(useContent) {
         type = content.type;
         count = content.count;
         resources = content.resources;
         isBlurPoster = content.isBlurPoster;
         if('upload_video' !== type) {
            disabled = sendMediaDisabled
            srcData = 'object' !== typeof content.src ? [content.src] : content.src;
         } else {
            srcData = [content.videoUpload]
            disabled = sendMediaDisabled && content.videoUpload.video_optimization_status !== 'completed'
         }
      }
      if(isMassMessage && type === 'photo' && Array.isArray(srcData)) {
         srcData = srcData.map(file => {

            if(file?.full_src && file?.full_src.startsWith('https://')) {
               return {
                  ...file,
                  path: file?.src,
                  src: file?.full_src,
               }
            } else {
               return file
            }
         })
      }
   }

   const saveAsDraftTextMessage = (text) => {
      if(chatRoomId) {
         window.draftMessages = {
            ...window.draftMessages,
            [chatRoomId]: {
               ...window?.draftMessages?.[chatRoomId],
               text,
            },
         }
      }
   }

   const sendMultipleAttachments = async (callback, isFree = false, sendWithDuration) => {

      let text = inputRef.current.innerText.trim()

      if(text.length > textLimit) {
         toast.error(`${ textLimit } character limit has been reached`)
         inputRef.current.innerText = text.slice(0, textLimit)
         return
      }

      saveAsDraftTextMessage(inputRef.current.innerText.trim())

      let data = {
         // resources: resources,
         // type: type,
         // resourceId: null,
         unlockPrice: isFree ? 0 : +price || 0,
         lockedPosterIsBlur: blur,
         text,
         allowDownload,
         type: 'multiple_attachment',
         resources: [],
         attachmentsStatuses: attachmentsStatuses.current,
         allowDownloadWithWatermark,
      }

      data.attachments = (await Promise.allSettled(attachments
         .map(async (media) => {
            if(media?.resource_type === 'photoset') {

               let newMedia = {
                  ...media,
               }

               const res = await getPhotosetPathsById(media.resource_id)
               newMedia.resources = res?.data//?.photos?.map(ph => ph?.path)
               return newMedia
            } else {
               return media
            }
         })
         .map(async (media) => {
            const mediaResult = await media
            if('photo_vault' === mediaResult.resource_type || 'photoset' === mediaResult.resource_type || (('video' === mediaResult.resource_type || 'video_vault' === mediaResult.resource_type) && !isLocalhost() && mediaResult?.type !== 'upload_video')){
               setSendMediaDisabled(true)

               let newMedia = {
                  ...mediaResult,
               }
               try {
                  const res = await getMediaBlurhash(
                     mediaResult.resource_type.includes('video') ? mediaResult.cover_image : mediaResult.resources[0],
                     mediaResult.resource_type.includes('video') ? 'video' : 'photoset',
                     mediaResult.resource_type.includes('video') ? mediaResult?.basename || mediaResult?.resources[0].split('.')[0] : null
                  )

                  newMedia.blurhash = res

                  setSendMediaDisabled(false)
               } catch (error) {
                  console.log(error);
                  setSendMediaDisabled(false)
               }

               return newMedia

            } else if((mediaResult?.type === 'upload_video' && mediaResult?.status === 'completed')){
               let newMedia = {
                  cover_image: mediaResult?.cover_image || mediaResult?.videoUpload?.video_poster || mediaResult?.resources[0],
                  resource_id: mediaResult?.id,
                  resource_type: 'video_vault',
                  resources: mediaResult?.resources,
                  src: mediaResult?.src,
                  type: 'video_unlock',
                  video_duration: mediaResult?.count || mediaResult?.video_duration,
               }

               if(!isLocalhost()) {
                  try {
                     const res = await getMediaBlurhash(
                        newMedia.resource_type.includes('video') ? newMedia.cover_image : newMedia.resources[0],
                        newMedia.resource_type.includes('video') ? 'video' : 'photoset',
                        newMedia.resource_type.includes('video') ? newMedia?.resources[0].split('.')[0] : null
                     )

                     newMedia.blurhash = res

                     setSendMediaDisabled(false)
                  } catch (error) {
                     console.log(error);
                     setSendMediaDisabled(false)
                  }
               }

               return newMedia

            } else {
               return mediaResult
            }
         }))
      ).map(it => it.value)


      setSendMediaDisabled(false)

      let parent = null
      if(replyMessage){
         parent = replyMessage
         if(!isEmpty(replyMessage) && replyMessage.user)
            parent.user = {
               username: replyMessage.user.username,
            }
      }

      if(props.sendMessage) {
         props.sendMessage(data, parent, async () => {
            console.log(attachmentsForShow.filter(ct => ct?.type === 'photo' && ct?.vaultPhotos?.length > 0));
            if(attachmentsForShow.filter(ct => ct?.type === 'photo' && ct?.vaultPhotos?.length > 0).length > 0) {
               try {
                  const newVaults = attachmentsForShow.reduce((prev, curr) => {
                     if(!!curr && curr?.type === 'photo' && curr?.vaultPhotos?.length > 0) {
                        prev.push(curr?.vaultPhotos?.[0])
                     }
                     return prev
                  }, []).filter(it => !!it)
                  console.log(newVaults);
                  await createVault(newVaults, data)
               } catch (error) {

               }
            }
            setSendMediaDisabled(false)
            setStartSendContent(false)
            if(!isMobile &&  inputRef.current) {
               inputRef.current.focus()
            }
            if(replyMessage && replyMessage.parentMessageId) {
               canelReplyMessage()
            }
            if(inputRef.current) {
               inputRef.current.innerText = '';
            }

            onClearState();
            setIsLoadingSendBtn(false)

            if(window?.draftMessages && window.draftMessages[chatRoomId]) {
               window.draftMessages[chatRoomId] = null
            }

            attachmentsStatuses.current = []
         }, sendWithDuration);
      }
      if(callback) callback()

      removeMedia()

   }

   const onClickClose = (openDisacard = true) => {

      if(openDisacard) {
         setOpenDiscardModal(true)
         return
      }

      onClearState()
      if(!!useContent) {
         if('upload_video' === type){
            QueryParams.removeQueryParam('upload_video')
            if(content.videoUpload.video_optimization_status) {
               deleteVaultVideo(content.videoUpload.id)
            } else {
               if(cancelVideoUplaod){
                  cancelVideoUplaod(content.videoUpload, () => removeSelectedContent(null))
               }
            }
         } else {
            removeSelectedContent(null)
         }
         setSendMediaDisabled(false)
      } else {
         removeMedia()
      }
      if(isMultipleAttachments && !!onClickCancel) {
         onClickCancel()
         setDisabledSendTo(true);
         setSendMediaDisabled(true)
      }
   }

   const onCloseUnsentDurationModal = () => {
      setShowUnsentDurationModal(!showUnsentDurationModal)
   }

   const disableUpload = (isMassMessage) && attachmentsForShow?.find(it => it?.type?.includes('video'))
   const disableVideoUpload = (isMassMessage) && attachmentsForShow?.find(it => it?.type?.includes('photo'))

   const openCloseMultipleCreateVaultsPopup = (open, vaults) => {
      // return
      if(open){
         const date = Number(localStorage.getItem('dont_show_create_multiple_vault_popup_30_days'))
         let newVaultsData = []
         console.log({ open, vaults });

         if(!!date) {
            let now = Date.now()
            if(Math.floor((now - date) / (1000 * 60 * 60 * 24)) > 30) return
         }

         const isUploadingProcess = vaults?.find(it => it?.uploadingStatus === 'uploading')

         if(isUploadingProcess) return

         newVaultsData = vaults.filter(it => (
            ["photo"]?.includes(it?.type) && !it?.prevFiles?.[0]?.isUpdated) ||
            (it.type === 'video' && ['in_optimization_queue', 'completed'].includes(it?.video_optimization_status) && !it?.isUpdated))
            .map(it => {
               return {
                  full_src: it?.full_src || it?.prevFiles?.[0]?.src,
                  id: it?.id || it?.prevFiles?.[0]?.vaultId,
                  hash: it?.prevFiles?.[0]?.hash,
                  type: ["photo", "photo_vault"]?.includes(it?.type) ? "image" : "video",
                  original_name: it?.original_name || it?.prevFile?.[0]?.original_name,
                  src: it?.src || it?.prevFiles?.[0]?.path,
               }
            })

         if(!!newVaultsData?.length){
            // console.log({ newVaultsData, vaults });
            setIsOpenCreateMultipleVaultPopup(true)
            setNewVaultsData([...newVaultsData])
         }
      } else {
         setIsOpenCreateMultipleVaultPopup(false)
         const newVaults = vaults
         let updatedPrevFiles = [...prevFiles]
         let vaultPhotos = [...newVaultPhotos]

         console.log({ updatedPrevFiles });

         if(!!newVaults?.length){

            updatedPrevFiles = updatedPrevFiles.map(it => {
               const newVault = newVaults?.find(item => it?.hash === item.hash)
               if(newVault?.hash === it?.hash) {
                  // console.log(it, 'prevFile');
                  return {
                     ...it,
                     isUpdated: true,
                     title: newVault?.title,
                     vaultId: newVault?.id,
                  }
               }
               return { ...it, isUpdated: true }
            })

            vaultPhotos = vaultPhotos.map(it => {
               const newVault = newVaults?.find(item => it?.hash === item.hash)
               if(newVault?.hash === it?.hash) {
                  // console.log(it, 'vaultPhoto');
                  return {
                     ...it,
                     isUpdated: true,
                     title: newVault?.title,
                     vaultId: newVault?.id,
                  }
               }
               return { ...it, isUpdated: true }
            })

         } else {
            updatedPrevFiles = updatedPrevFiles.map(it => ({ ...it, isUpdated: true }))
            vaultPhotos = vaultPhotos.map(it => ({ ...it, isUpdated: true }))
         }
         // console.log({ updatedPrevFiles, vaultPhotos });

         setPrevFiles([...updatedPrevFiles])
         setNewVaultPhotos([...vaultPhotos])
         // let newFilesArr = []
         let newHashs = []

         updatedPrevFiles?.forEach(it => {
            if(!it?.path) {
               // const path = Object.values(it)?.[0]?.src
               const hash = Object.values(it)?.[0]?.hash
               // newFilesArr.push(path)
               newHashs.push(hash)
               return
            }
            // newFilesArr.push(it?.path)
            newHashs.push(it?.hash)
            return
         })
         // console.log({ updatedPrevFiles, newFilesArr, newHashs, attachments, attachmentsForShow });
         console.log({
            prevFiles: updatedPrevFiles,
            filesArr: files,
            vaultPhotos,
            type: 'photo',
            hashs: newHashs,
            loadingData: [],
            newVaults,
         });


         onSaveUploadPhotos(
            {
               prevFiles: updatedPrevFiles,
               filesArr: files,
               vaultPhotos,
               type: 'photo',
               hashs: newHashs,
               loadingData: [],
               newVaults,
            },
            'update-vaults',
            chatRoomId
         )
      }
   }


   return (
      <>
         <TextMessageField
            replyMessage={ replyMessage }
            canelReplyMessage={ canelReplyMessage }
            inputRef={ inputRef }
            onReadMessage={ onReadMessage }
            isMobile={ isMobile }
            inputForIosFocus={ inputForIosFocus }
            setMutedMemberInfo={ setMutedMemberInfo }
            isConnected={ isConnected }
            isMessageToLong={ isMessageToLong }
            setErrorToLong={ setErrorToLong }
            isShowUser={ isShowUser }
            userAvatar={ userAvatar }
            onSelectLibery={ onSelectLibery }
            prevFiles={ prevFiles }
            sendMessage={ (e, callback, sendWithDuration) => {

               if(showContent){
                  if(e.keyCode === 13 && !sendMediaDisabled){
                     onSendMessage(e, price, isBlurPoster, resources, callback, sendWithDuration)
                  }
               } else {
                  sendMessage(e, callback, sendWithDuration)
               }
            } }
            onUploadFile={ onUploadFile }
            isAdmin={ props.isAdmin }
            isLivstream={ props.isLivstream }
            onUserTyping={ props.onUserTyping }
            disabled={ !showContent }
            showContent={ showContent  ? !sendMediaDisabled : false }
            sendVoiceMessage={ onSendVoiceMessage }
            privateAttachmentSettings={ privateAttachmentSettings }
            isGroup={ props.isGroup }
            defaultVoicePrice={ defaultVoicePrice }
            intoNotificationCard={ intoNotificationCard }
            onUpdateRecordStatus={ onUpdateRecordStatus }
            containerRef={ containerRef }
            isLoadingSendBtn={ isLoadingSendBtn }
            setIsLoadingSendBtn={ setIsLoadingSendBtn }
            isUpdateState={ isUpdateState }
            onClickCancelEditMessage={ () => {
               onClickCancelEditMessage()
               saveAsDraftTextMessage('')
            } }
            disabledSendTo={ disabledSendTo }
            setDisabledSendTo={ setDisabledSendTo }
            showNickname={ showNickname }
            isMiniChat={ isMiniChat }
            hideVoiceMessage={ hideVoiceMessage }
            isMassMessage={ isMassMessage }
            onCustomAction={ onCustomAction }
            showVariables={ showVariables }
            variableList={ variableList }
            showFrequentlyUsedEmojis={ showFrequentlyUsedEmojis }
            isDemoMode={ isDemoMode }
            authUser={ authUser }
            onClearState={ onClearState }
            // disableAttachments={ !!(!!props.isAdmin && !!replyMessage?.parentMessageId) }
            disableAttachments={ false }
            updateMessageType={ updateMessageType }
            isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
            saveAsDraftTextMessage={ saveAsDraftTextMessage }
            onClickSavedReplies={ onClickSavedReplies }
            isSavedReplies={ isSavedReplies }
            onInputBlur={ onInputBlur }
            idForField={ idForField }
            textLimit={ textLimit }
            calcTextLengthFor={ calcTextLengthFor }
            setReplyData={ setReplyData }
            repliesModalState={ repliesModalState }
            replyData={ replyData }
            setMessageType={ setMessageType }
            errors={ errors }
            onInputFocus={ onInputFocus }
            isMultipleAttachments={ isMultipleAttachments }
            attachments={ attachments }
            attachmentsForShow={ attachmentsForShow }
            disableUpload={ disableUpload }
            disableVideoUpload={ disableVideoUpload }
            onClickUnsenDuration={ () => {
               setShowUnsentDurationModal(!showUnsentDurationModal)
            } }
            onClickUnlockPrice={ () => {
               setActiveState('setPrice')
            } }
            durationInfo={ durationInfo }
            disableSelectMedia={ !!props?.disableSelectMedia }
            openVoiceMessage={ openVoiceMessage }
            setOpenVoiceMessage={ setOpenVoiceMessage }
            getQueryParam={ getQueryParam }
         >
            {
               ((props.isAdmin) || showContent) && (
                  <>
                     {
                        !!checkContentState.openWarningModal && (
                           <WarningModal
                              isMobile={ isMobile }
                              info={ checkContentState.warningText }
                              actionText='Send anyway'
                              action={ () => {
                                 const {
                                    unlockPrice,
                                    resources,
                                    isBlur,
                                    hash,
                                    status,
                                    sendWithDuration,
                                 } = checkContentState
                                 if(isMultipleAttachments && attachments.length > 1 && attachmentsForShow.length > 1)  {
                                    sendMultipleAttachments(() => {
                                       setDisabledSendTo(true);
                                       setSendMediaDisabled(true)
                                    }, !unlockPrice, sendWithDuration)
                                 }
                                 else if(isMultipleAttachments && attachments.length === 1 && attachmentsForShow.length === 1)  {

                                    let singleAttachment = attachmentsForShow[0]?.type === 'photo' ? attachmentsForShow[0] : attachments[0]
                                    onSendMediaMessage(unlockPrice, resources, isBlur, hash, status, null, singleAttachment, sendWithDuration)
                                 }
                                 else {
                                    onSendMediaMessage(unlockPrice, resources, isBlur, hash, status, null, null, sendWithDuration)
                                 }

                                 setCheckContentState({})
                                 setIsLoadingSendBtn(true)
                                 setDisabledSendTo(true);
                              } }
                              closeModal={ () => {
                                 setCheckContentState({})
                                 setIsLoadingSendBtn(false)
                                 setDisabledSendTo(false);
                                 setSendMediaDisabled(false)
                                 setStartSendContent(false)
                              } }
                              showNewStyles
                              hideCloseButton
                              iconColor={ checkContentState?.color }
                              modalTitle={ checkContentState?.modalTitle }
                              iconName={ checkContentState?.icon }
                           />
                        )
                     }
                     <ChatSellingContent
                        isAdmin={ props.isAdmin }
                        uploadFile={ (e) => {
                           if('photo_vault' === type) {
                              onSelectLibery('photo_vault', 'photo_unlock')
                           } else {
                              onUploadFile(e, 'photo_unlock')
                           }
                        } }
                        removeFile={ (e, i, status) => {
                           if(!!useContent) {

                              if('upload_video' === type){
                                 QueryParams.removeQueryParam('upload_video')
                                 if(content.videoUpload.video_optimization_status) {
                                    if(!content.videoUpload.canNotDeleted){
                                       deleteVaultVideo(content.videoUpload.id)
                                    } else {
                                       removeSelectedContent(i)
                                    }

                                 } else {
                                    if(cancelVideoUplaod){
                                       cancelVideoUplaod(content.videoUpload, () => removeSelectedContent(i))
                                    }
                                 }

                              } else {
                                 removeSelectedContent(i)
                              }
                              setSendMediaDisabled(false)
                           } else {
                              removeImage(e, i)
                           }
                           toast.remove('Content has been removed',
                              isMobile ? { onOpen: () => {
                                 positionToasterContainer('60px')
                              } } : {})
                        }  }
                        onClickClose={ onClickClose }
                        prevFiles={ srcData }
                        isCanUploadNew={ isCanUploadNew && props.isAdmin }
                        type={ type }
                        count={ count }
                        status={ status }
                        sendButtonDisabled={ disabled }
                        isMobile={ isMobile }
                        inputForIosFocus={ inputForIosFocus }
                        isBlurPoster={ blur }
                        onSavePrice={ onSavePrice }
                        price={ price }
                        isGroup={ props.isGroup }
                        startSendContent={ startSendContent }
                        maxPrice={ maxPrice }
                        allowDownload={ allowDownload }
                        isUpdateState={ (props.isAdmin && replyMessage) || isUpdateState }
                        isMassMessage={ isMassMessage }
                        onCustomAction={ onCustomAction }
                        isMultipleAttachments={ isMultipleAttachments }
                        attachments={ attachments }
                        attachmentsForShow={ attachmentsForShow }
                        onRemoveMedia={ (media, index) => {
                           removeImage(null, index, true)
                           onRemoveMedia(media, index)
                        } }
                        isMaxAttachmentCountReached={ isMaxAttachmentCountReached }
                        disableUpload={ disableUpload }
                        activeState={ activeState }
                        setActiveState={ setActiveState }
                        durationInfo={ durationInfo }
                        isSavedReplies={ isSavedReplies }
                        openVoiceMessage={ openVoiceMessage }
                        lastMessage={ lastMessage }
                        allowDownloadWithWatermark={ allowDownloadWithWatermark }
                        memberVideoDownloadMultipleWatermarks={ memberVideoDownloadMultipleWatermarks }
                     />

                  </>
               )
            }
         </TextMessageField>
         {
            mutedInfo && mutedInfo.isMutedModal && (
               <Modal
                  contentPosition={ isMobile ? 'bottom' : 'center' }
                  onClose={ () => setMutedMemberInfo({ isMutedModal: false }) }
                  isCloseAction={ false }
                  roundedModal='xlg'
               >
                  <div className='bg-panel dark:bg-panel-dark p-4'>
                     <MuteMember
                        onClickButton={ () => setMutedMemberInfo({ isMutedModal: false }) }
                        mutedInfo={ mutedInfo }
                     />
                  </div>
               </Modal>
            )
         }
         {
            openDiscardModal &&
               <ConfirmModal
                  type='confirm'
                  icon='cancel'
                  iconColor='error'
                  descriptionColorClass='text-secondary'
                  onCloseModal={ () => {
                     setOpenDiscardModal(false)
                  }  }
                  title='Discard changes?'
                  description='Changes you have made so far will not be saved'
                  cancelText='Keep editing'
                  nonPrimaryColor
                  contentWidth='375px'
                  isMobile={ isMobile }
                  iconSize='2xl'
                  buttonText='Discard changes'
                  textColor='error'
                  action={ () => {
                     onClickClose(false)
                     setOpenDiscardModal(false)
                  } }
                  buttonClassName='mt-8'
                  modalClassName='!z-[100]'
               />
         }
         {
            showUnsentDurationModal &&
               <MessageDurationModal
                  onCloseUnsentDurationModal={ onCloseUnsentDurationModal }
                  durationInfo={ durationInfo }
                  onSaveDurationData={ onSaveDurationData }
                  isFetchingChatSettingsUpdate={ isFetchingChatSettingsUpdate }
               />
         }
         {
            isOpenCreateMultipleVaultPopup &&
               <CreateMultipleVaultPopup
                  isMobile={ isMobile }
                  closeModal={ (newVaults = []) => {
                     openCloseMultipleCreateVaultsPopup(false, newVaults)
                  } }
                  vaults={ newVaultsData }
               />
         }
      </>
   )
}
NewMessageField.propTypes = {
   isMobile: PropTypes.bool,
   isAdmin: PropTypes.bool,
   sendMessage: PropTypes.func,
   onUserTyping: PropTypes.func,
   canelReplyMessage: PropTypes.func,
   replyMessage: PropTypes.object,
   mutedInfo: PropTypes.object,
   inputRef: PropTypes.any,
   inputForIosFocus: PropTypes.func,
   onReadMessage: PropTypes.func,
   setMutedMemberInfo: PropTypes.func,
   isConnected: PropTypes.bool,
   isGroup: PropTypes.bool,
   isMessageToLong: PropTypes.bool,
   setErrorToLong: PropTypes.func,
   selectByLiber: PropTypes.func,
   removeSelectedContent: PropTypes.func,
   deleteVaultVideo: PropTypes.func,
   userUuid: PropTypes.string,
   content: PropTypes.object,
   onSaveUploadPhotos: PropTypes.func,
   maxPrice: PropTypes.number,
   isLivstream: PropTypes.bool,
   isShowUser: PropTypes.bool,
   userAvatar: PropTypes.string,
   onSendVoiceMessage: PropTypes.func,
   privateAttachmentSettings: PropTypes.object,
   defaultVoicePrice: PropTypes.number,
   intoNotificationCard: PropTypes.bool,
   onUpdateRecordStatus: PropTypes.func,
   handleUploadImage: PropTypes.func,
   cancelVideoUplaod: PropTypes.func,
   checkVaultByHash: PropTypes.func,
   createVault: PropTypes.func,
   checkContentTypeInTheChat: PropTypes.func,
   getMediaBlurhash: PropTypes.func,
   chatRoomId: PropTypes.number,
   containerRef: PropTypes.any,
   isUpdateState: PropTypes.bool,
   onClickCancelEditMessage: PropTypes.func,
   showNickname: PropTypes.bool,
   isMiniChat: PropTypes.bool,
   hideVoiceMessage: PropTypes.bool,
   isMassMessage: PropTypes.bool,
   onCustomAction: PropTypes.func,
   showVariables: PropTypes.bool,
   variableList: PropTypes.array,
   showFrequentlyUsedEmojis: PropTypes.bool,
   attachments: PropTypes.array,
   attachmentsForShow: PropTypes.array,
   isMultipleAttachments: PropTypes.bool,
   onRemoveMedia: PropTypes.func,
   onClickCancel: PropTypes.func,
   isDemoMode: PropTypes.bool,
   authUser: PropTypes.object,
   updateMessageType: PropTypes.string,
   isMaxAttachmentCountReached: PropTypes.bool,
   onClickSavedReplies: PropTypes.func,
   textLimit: PropTypes.number,
   isSavedReplies: PropTypes.bool,
   onInputBlur: PropTypes.func,
   idForField: PropTypes.string,
   calcTextLengthFor: PropTypes.func,
   onInputRefMount: PropTypes.func,
   setReplyData: PropTypes.func,
   repliesModalState: PropTypes.string,
   onInputRefUnmount: PropTypes.func,
   replyData: PropTypes.any,
   setMessageType: PropTypes.func,
   readyToSendReply: PropTypes.bool,
   errors: PropTypes.any,
   onInputFocus: PropTypes.func,
   durationInfo: PropTypes.object,
   onSaveDurationData: PropTypes.func,
   isFetchingChatSettingsUpdate: PropTypes.bool,
   disableSelectMedia: PropTypes.bool,
   getQueryParam: PropTypes.func,
   lastMessage: PropTypes.object,
   memberVideoDownloadMultipleWatermarks: PropTypes.bool,
}
NewMessageField.defaultProps = {
   isAdmin: false,
   sendMessage: () => {},
   onReadMessage: () => {},
   onUserTyping: () => {},
   inputForIosFocus: () => {},
   selectedMedia: {},
   isShowUser: false,
   isLivstream: false,
   removeSelectedContent: () => {},
   onClickCancelEditMessage: () => {},
   isMiniChat: false,
   isDemoMode: false,
   authUser: {},
   updateMessageType: '',
   textLimit: 20000,
}
export default NewMessageField;
