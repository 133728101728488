import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'common/components/modules/modal';
import SelectInputItem from './item';
import ModalContent from './modal-content';
import Button from 'common/components/elements/buttons/primary';
import QueryParams from 'admin/utils/QueryParams';
import toast from 'common/utils/toast';
import './style.scss'
import { useSelector } from 'react-redux';
import { screenWidthSelector } from 'admin/state/modules/common/selectors';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_CAST_MEMBERS, ARTICLE_ID_ON_COLLECTIONS_AND_CONTENT_TAGS } from 'common/utils/intercom-articles';


const MODAL_SETTINGS = {
   collections: {
      title: 'Select collection',
      label: 'Collections',
      searchTitle: 'Search collections',
      manageButtonLink: 'COLLECTIONS',
      emptyDescription: 'You haven’t created any collections',
      manageTitle: 'Manage collections',
      moreInfoText: 'More info on collections',
      defaultLabel: 'Add collections',
      textKeyName: 'title',
      romeveToasterText: 'Collection has been removed',
   },
   tags: {
      title: 'Select content tags',
      label: 'Content tags',
      searchTitle: 'Search content tags',
      manageButtonLink: 'TAGS',
      emptyDescription: 'You don’t have any tags in your tag list',
      manageTitle: 'Manage content tags',
      moreInfoText: 'More info on tags',
      defaultLabel: 'Add tags',
      textKeyName: 'name',
      romeveToasterText: 'Tag has been removed',
   },
   casts: {
      title: 'Select models',
      label: 'Models',
      searchTitle: 'Search models',
      manageButtonLink: 'CAST_MEMBERS',
      emptyDescription: 'You don’t have any tags in your tag list',
      manageTitle: 'Manage models',
      moreInfoText: 'More Info on models',
      defaultLabel: 'Add models',
      textKeyName: 'screen_name',
      romeveToasterText: 'Model has been removed',
   },
}

const Tags = ({
   selectedList,
   onSaveChange,
   onRemove,
   list,
   type,
   onSaveEditChange,
   tagsError,
   createTagsError,
   isNewMedia = false,
   onChangeErrors = () => {},
   error = '',
   contentType,
}) => {
   const [openModal, setOpenModal] = useState(false);
   const [animate, setAnimate] = useState(null);
   let timerFetching = null;
   const screenWidth = useSelector(screenWidthSelector)

   function closeOrOpenModal(_bool, newData) {
      clearTimeout(timerFetching);
      let time = _bool ? 100 : 800
      if(screenWidth > 700){
         time = 0
      }

      setAnimate(_bool ? 'up' : 'down');
      timerFetching = setTimeout(() => {
         setOpenModal(_bool);
         if(!_bool){
            QueryParams.removeQueryParam('open-resources-modal');
         } else {
            if(!!onChangeErrors && error){
               onChangeErrors()
            }
            QueryParams.addQueryParam('open-resources-modal', type)
         }
         if(!!newData){
            onSaveChange(newData);
         }

      }, time);
   }
   useEffect(() => {
      if(QueryParams.getParam('open-resources-modal') === type)  {
         setOpenModal(true);
      }
      document.addEventListener('mousedown', closeOnClickOutside, true);
      return () => {
         clearTimeout(timerFetching)
         document.removeEventListener('mousedown', closeOnClickOutside, true);
      };
   // eslint-disable-next-line
   }, []);

   function closeOnClickOutside(e) {
      const ele = document.querySelector(".selector-empty");
      if(ele && !ele.contains(e.target)) {
         closeOrOpenModal(false);
      }
   }

   return (
      <div className='flex flex-col gap-2 relative select-none'>
         <div className='flex gap-2'>
            <p className='font-medium leading-tight text-major dark:text-major-dark'>{  MODAL_SETTINGS[type].label }</p>
            {
               <Button
                  iconName='help-outline'
                  classNames='!w-fit'
                  backgroundColor='transparent'
                  padding={ 0 }
                  fontIconSize='extraLarge'
                  fontIconColor='placeholder'
                  onClick={ (e) => {
                     e.stopPropagation()
                     e.preventDefault()
                     Intercom.showArticle(['casts'].includes(type) ? ARTICLE_ID_ON_CAST_MEMBERS : ARTICLE_ID_ON_COLLECTIONS_AND_CONTENT_TAGS)
                  } }
               />
            }
         </div>
         <div className='flex flex-wrap gap-2 items-center'>
            {
               selectedList?.map((item, index) => (
                  <SelectInputItem
                     key={ !!item?.id ? item.id : (item || index.toString()) }
                     data={ item }
                     textKeyName={ MODAL_SETTINGS[type].textKeyName }
                     borderColor='divider dark:border-divider-dark'
                     height='9 !py-2'
                     onClick={ () => {
                        if(!!onChangeErrors && error){
                           onChangeErrors()
                        }
                        if(type === 'casts' && selectedList.length === 1 && contentType !== 'vault' && !isNewMedia) {
                           toast.error('At least one model must be selected')
                        } else {
                           onRemove(item)
                           toast.success(MODAL_SETTINGS[type].romeveToasterText)
                        }
                     } }
                     type={ type }
                  />
               ))
            }
            <Button
               text={ MODAL_SETTINGS[type].defaultLabel }
               classNames='!w-fit !h-fit'
               padding='0'
               backgroundColor='transparent'
               primaryColor
               iconPosition='left'
               textClassName='text-sm !mx-0 !ml-2'
               iconName={ 'add' }
               onClick={ () => {
                  setTimeout(() => {
                     clearTimeout(timerFetching)
                     closeOrOpenModal(!openModal)
                  }, 100);
               } }
            />
         </div>
         {
            Boolean(error) && isNewMedia && <div className='absolute -bottom-4 right-0 text-xs text-error'>{error}</div>
         }
         {
            openModal &&
            <Modal
               contentPosition={ screenWidth > 700 ? 'center' : 'bottom' }
               contentWidth={ screenWidth > 700 ? '375px' : '100%' }
               onClose={ () => closeOrOpenModal(false) }
               isCloseAction={ false }
               animate={ screenWidth <= 700 ? animate : null }
            >
               <ModalContent
                  selectedItems={ selectedList }
                  title={ MODAL_SETTINGS[type].title }
                  textKeyName={ MODAL_SETTINGS[type].textKeyName }
                  searchTitle={ MODAL_SETTINGS[type].searchTitle }
                  manageTitle={ MODAL_SETTINGS[type].manageTitle }
                  onSaveChange={ (data) => {
                     clearTimeout(timerFetching)
                     closeOrOpenModal(false, data);
                  } }
                  isMobile={ screenWidth <= 700 }
                  list={ list }
                  emptyDescription={ MODAL_SETTINGS[type].emptyDescription }
                  onCloseModal={ () => {
                     closeOrOpenModal(false);
                  } }
                  manageButtonLink={ MODAL_SETTINGS[type].manageButtonLink }
                  onSaveEditChange={ onSaveEditChange }
                  moreInfoText={ MODAL_SETTINGS[type].moreInfoText }
                  tagsError={ tagsError }
                  createTagsError={ createTagsError }
                  type={ type }
               />
            </Modal>
         }

      </div>
   );
};


Tags.propTypes = {
   selectedList: PropTypes.array,
   onRemove: PropTypes.func,
   onSaveChange: PropTypes.func,
   list: PropTypes.array,
   type: PropTypes.string,
   onSaveEditChange: PropTypes.func,
   tagsError: PropTypes.object,
   createTagsError: PropTypes.func,
   isNewMedia: PropTypes.bool,
   onChangeErrors: PropTypes.func,
   error: PropTypes.string,
   contentType: PropTypes.string,
};

export default memo(Tags);
export {
   SelectInputItem,
};
