export const FETCH_CAST_MEMBERS_LIST_START = 'cast-members-v2/FETCH_CAST_MEMBERS_LIST_START'
export const FETCH_CAST_MEMBERS_LIST_COMPLETED = 'cast-members-v2/FETCH_CAST_MEMBERS_LIST_COMPLETED'
export const OPEN_CLOSE_CONFIRM_DIALOGS = 'cast-members-v2/OPEN_CLOSE_CONFIRM_DIALOGS'
export const SET_IS_FETCHING_ACTION_WITH_CAST_MEMBER = 'cast-members-v2/SET_IS_FETCHING_ACTION_WITH_CAST_MEMBER'
export const DELETE_CAST_MEMBER_COMPLETED = 'cast-members-v2/DELETE_CAST_MEMBER_COMPLETED'
export const SET_IS_FETCHING_CAST_MEMBER_DETAILS = 'cast-members-v2/SET_IS_FETCHING_CAST_MEMBER_DETAILS'
export const SET_IS_FETCHING_CREATE_CAST_MEMBER = 'cast-members-v2/SET_IS_FETCHING_CREATE_CAST_MEMBER'
export const FETCH_CREATE_EDIT_CAST_MEMBER_FAILED = 'cast-members-v2/FETCH_CREATE_EDIT_CAST_MEMBER_FAILED'
export const RESET_ERRORS = 'cast-members-v2/RESET_ERRORS'
export const SET_IS_FETCHING_UPDATE_CAST_MEMBER = 'cast-members-v2/SET_IS_FETCHING_UPDATE_CAST_MEMBER'
export const SET_CAST_MEMBER_ADDRESS_EDIT_MODE = 'cast-members-v2/SET_CAST_MEMBER_ADDRESS_EDIT_MODE'
export const TOGGLE_LIST_VIEW_TYPE = 'cast-members-v2/TOGGLE_LIST_VIEW_TYPE'
