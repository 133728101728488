import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
   createNewConversationWithUser,
   muteMember,
   unMuteMember,
   updateMember,

   fetchConversationById,
   getObjectUrl,
   updateSettings,
   toggleOpenCloseConversation,
   getVideoById,
} from 'admin/api/AuthApi'

import {
   screenWidthSelector,
   siteInfoSelector,
   authUserSelector,

} from 'admin/state/modules/common/selectors';
import {
   vaultVideosDataSelector,
   vaultImagesDataSelector,
} from 'admin/state/modules/vault/selectors';
import {
   videosDataInsideModal,
} from 'admin/state/modules/videos/selectors';
import {
   listInsideModalSelector,
} from 'admin/state/modules/photosets/selectors';
import {
   updateVaultMessageAction,
} from 'admin/state/modules/vault/actions';
import {
   updateMessageAction as updatePhotosetMessageAction,
} from 'admin/state/modules/photosets/actions';
import {
   updateMessageAction as updateVideoMessageAction,
} from 'admin/state/modules/videos/actions';
import {
   contentMetaInfo as contentMetaInfoAction,
} from 'admin/state/modules/common/actions';
import {
   // initChatSocket,
   initNotificationsSocket,
} from 'admin/utils/socket';
import {
   fetchNewConversationOperation,
   fetchChatSettingsOperation,
   fetchConversationsOperation,
} from 'admin/state/modules/chat/operations';
import {
   setSocketConnectedAction,
   createConversation,

   ////////////////////////
   onLikeMessageAction as onLikeMessage,
   deleteMessageAction as deleteMessage,
   addNewMessageAction as addNewMessage,
   updateMessageAction as updateMessage,
   updateConverstionAction as updateConverstion,
   setOnlineUsersAction as setOnlineUsers,
   setTyperAction as setTyper,
   setMuteMemberAction as setMuteMember,

   setBlockMemberAction,
   setSoket,

   addRemoveConverstionItemAction,
   updateUnreamPmMessageCountAction,
   updateConversationFromListAction,
} from 'admin/state/modules/chat/actions';
import { push } from 'connected-react-router';
import {
   conversationsListSelector,
   groupConversationSelector,
   isFetchConversationSelector,
   isFetchNewConversationSelector,
   isSocketConnectedSelector,
   activeConverstaionSelector,
   initedConverstionMessagesSelector,
   onlineUsersSelector,
   soketSelector,
   settingsDataSelector,
   initedConversationSelector,
   conversationsFilterSelector,
} from 'admin/state/modules/chat/selectors';
import { Provider } from 'admin/containers/modules/chat/context';
import {
   updateMemberByUuid,
} from 'admin/state/modules/members/actions';
import DateFormatter from 'common/utils/DateFormatter';
import Modal from 'common/components/modules/modal'
import ConfirmDialog from  'common/components/modules/confirm-dialog';
import MuteMember from  'admin/views/chat/modules/mute-block-member';
import toast, { positionToasterContainer } from "common/utils/toast";
import { currentLocationPathSelector } from 'admin/state/modules/router/selectors';
import IntercomMoreInfo, { CLIENT_REQUEST_CONTACT_SUPPORT_ABOUT_MEMBER } from 'admin/utils/IntercomMoreInfo.js';
import ChatHepler from 'common/utils/chat';
import QueryParams from 'admin/utils/QueryParams';
import { addFreeMemberOperation, fetchStatisticsData, memberFetchDetailsOperation } from 'admin/state/modules/members/operations';
import ChooseMembership from 'admin/views/members/add-free-member/choose-membership';
import { statisticsDataSelector } from 'admin/state/modules/members/selectors';
import Router from 'admin/routes/router';
import moment from 'moment';
import { flushSync } from 'react-dom';

class ChatProviderContainer extends Component {

   static propTypes = {
      siteInfo: PropTypes.object,
      setSocketConnectedAction: PropTypes.func,
      isSocketConnected: PropTypes.bool,
      children: PropTypes.any,
      activeConverstaion: PropTypes.object,
      authUser: PropTypes.object,

      likeMessage: PropTypes.func,
      updateMessage: PropTypes.func,
      addNewMessage: PropTypes.func,
      typersMessage: PropTypes.object,
      groupChatTypersMessage: PropTypes.object,
      deleteMessage: PropTypes.func,
      updateConverstion: PropTypes.func,
      setOnlineUsers: PropTypes.func,
      onlineUsers: PropTypes.array,
      groupConversation: PropTypes.object,
      conversationsList: PropTypes.object,
      setTyper: PropTypes.func,
      setChatConversation: PropTypes.func,
      blockMemberAction: PropTypes.func,
      setMuteMember: PropTypes.func,
      screenWidth: PropTypes.number,
      settingsData: PropTypes.object,
      fetchSettingsData: PropTypes.func,
      addRemoveConverstionItemAction: PropTypes.func,
      fetchConversations: PropTypes.func,
      updateMemberByUuid: PropTypes.func,
      updateMUnreamPmMessageCount: PropTypes.func,
      initedConversation: PropTypes.bool,
      addFreeMemberOperation: PropTypes.func,
      statistics: PropTypes.object,
      fetchStatisticsData: PropTypes.func,
      memberFetchDetails: PropTypes.func,
      contentMetaInfo: PropTypes.func,
      locationPath: PropTypes.string,
      conversationsFilter: PropTypes.object,
      updateConversationFromList: PropTypes.func,
      updateVaultMessageAction: PropTypes.func,
      vaultVideosData: PropTypes.object,
      vaultImagesData: PropTypes.object,
      videosData: PropTypes.object,
      photosetData: PropTypes.object,
      updatePhotosetMessageAction: PropTypes.func,
      updateVideoMessageAction: PropTypes.func,
      initedConverstionMessages: PropTypes.object,
   }

   socket = null
   notificationSocket = null
   socetSTartConnected = false
   unlisten = null
   publicThreadTypersRemovers = {}
   privateThreadTypersRemovers = {}
   timeOut = null
   searchRes = null
   state = {
      memberOptionModal: {
         type: null,
         isLoading: false,
         isOpen: false,
      },
      addFreeMemberModal: false,
      warningMessage: null,
      selectedMembership: null,
   }
   member = {}
   componentDidMount(){
      // window.scrolledPosition = {}
      this.socket = window.adminChatSocket
      this._bindEvents();
      // window.addEventListener('scroll', this.handleScroll);
      const { fetchSettingsData, settingsData, locationPath } =  this.props
      if(!settingsData || (settingsData && !settingsData.settings)) {
         fetchSettingsData()
      }
      const { fetchConversations, initedConversation, fetchStatisticsData } =  this.props
      if(!Router.route('CHAT').match(locationPath)) {
         fetchConversations([], false, initedConversation)
      }
      if(QueryParams.getParam('upload_video') &&  QueryParams.getParam('upload_video') !== 'start' && QueryParams.getParam('conversation')){

         let video = {
            resources: [],
            src: [],
            videoUpload: {
               uploadingStatus: 'uploading',
            },
            type: 'upload_video',
         }
         let ollContents = window.currentConversationContent ? JSON.parse(window.currentConversationContent) : {}
         ollContents[QueryParams.getParam('conversation')] = video
         window.currentConversationContent = JSON.stringify(ollContents)
      }
      fetchStatisticsData()
   }

   static getDerivedStateFromProps(nextProps){
      const { statistics: { free, free_membership_count } } = nextProps;
      let obj = {
         warningMessage: null,
      }
      if(free >= free_membership_count){
         obj.warningMessage = 'You’ve reached the maximum number of complimentary memberships'
      } else {
         obj.warningMessage = null
      }
      return obj
   }


   componentWillUnmount(){
      window.currentConversationContent  = null
      clearTimeout(this.timeOut)
      window.chatVoiceMessageRecorded = null;
      if(QueryParams.getParam('recorded_voice')){
         QueryParams.removeQueryParam('recorded_voice');
      }
   }

   _bindEvents = () => {
      const { socket } = this;
      const { authUser } = this.props
      // socket.removeAllListeners('connect');
      // socket.removeAllListeners('connect_error');
      // socket.removeAllListeners('disconnect');
      // socket.removeAllListeners('ready');
      socket.removeAllListeners('users:online');
      socket.removeAllListeners('user:online');
      socket.removeAllListeners('user:offline');
      socket.removeAllListeners('typing');
      socket.removeAllListeners('message:like');
      socket.removeAllListeners('message:delete');
      socket.removeAllListeners('message:send');

      socket.removeAllListeners('message:update');
      socket.removeAllListeners('message:deletePhotos');
      socket.removeAllListeners('conversation:read');
      socket.removeAllListeners('user:mute');
      socket.removeAllListeners('user:unmute');
      socket.removeAllListeners('conversation:seen');
      socket.removeAllListeners('message:updateSentMedia');
      // socket.on('connect', (r) => {
      //    socket.emit('online')
      // });

      // socket.on('connect_error', () => {
      //    this.setSocketConnectedAction(false)
      // });

      // socket.on('disconnect', (res) => {
      //    this.setSocketConnectedAction(false)
      // });

      // socket.on('ready', () => {
      //    this.setSocketConnectedAction(true)
      // });

      socket.on('users:online', (users) => {
         this.setOnlineMembers(users)
      });

      socket.on('user:online', (user) => {
         this.setOnlineMembers(user, 'push')
      });

      socket.on('user:offline', (user) => {
         this.setOnlineMembers(user, 'pop')
      });

      socket.on('typing', (data) => {
         const {
            conversationId,
            user,
         } = data
         const { activeConverstaion, screenWidth } = this.props
         if(data.user.uuid !== authUser.uuid) {
            this.handleTyping(conversationId, user)
         }
         if(activeConverstaion && activeConverstaion.chat_room_id === conversationId) {
            const dialogue = document.querySelector(`#messages-content-${ conversationId }`)
            if(dialogue){
               const { scrollHeight, clientHeight, scrollTop } = dialogue
               let diff =  (scrollHeight - clientHeight) - scrollTop
               if(diff < 110) {
                  ChatHepler.syncDialogueScroll(null, activeConverstaion.chat_room_id)
               }
            }
            if(screenWidth > 1024) {
               ChatHepler.inputState()
            }
         }
      });
      socket.on('message:like', (data) => {
         this.handleLikeMessage({ ...data, isMyLiked: data.user.uuid === authUser.uuid })
      });

      socket.on('message:send', async (data) => {
         const {
            message, conversationId, user,
         } = data
         const {
            groupConversation,
            activeConverstaion,
            screenWidth,
            conversationsList,
            locationPath,
            conversationsFilter,
            updateConversationFromList,
            addRemoveConverstionItemAction,
         } = this.props
         const messageData = { ...message, user, parent: message.parentMessage, sent_at: message.sentAt, unlock_details: message.unlockDetails }
         let isEmptyArr = conversationsList.data.length === 0
         let index = conversationsList.data.findIndex(chat => chat.chat_room_id === conversationId)
         if('text_message' !== message.type && (isEmptyArr || index < 0) && conversationId !== groupConversation.chat_room_id) {
            return
         }
         let notIncrement = false
         const isFilteredByClosed = conversationsFilter?.type?.[0] === 'closed'
         const isGroup = conversationId === groupConversation.chat_room_id
         if(isGroup) {
            this.stopTyping(conversationId === groupConversation.chat_room_id, user.uuid, conversationId)
            this.handleAddNewMessage({ id: conversationId, message: messageData,  isMyMessage: data.user.uuid === authUser.uuid, notIncrement })
         } else {
            let conversation = conversationsList.data[index]
            if(Router.route('CHAT').match(locationPath)) {
               if(isFilteredByClosed) {
                  if(conversationId === activeConverstaion?.chat_room_id) {
                     updateConversationFromList(conversationId, {
                        changed_to_type: 'open',
                        type: 'open',
                     })
                  } else {
                     updateConversationFromList(conversationId, {
                        hidden: true,
                        type: 'open',
                     })
                  }
               } else {
                  updateConversationFromList(conversationId, {
                     type: 'open',
                     changed_to_type: null,
                  })
               }
            }

            if(isEmptyArr || index < 0) {
               try {
                  const { data } = await fetchConversationById(conversationId)
                  conversation = { ...data }
                  data.isNewMessage = true
                  data.type = 'open'
                  if(!isFilteredByClosed) {
                     addRemoveConverstionItemAction(data)
                  }
               } catch (error) {
                  console.log(error);
               }
               notIncrement = true
            }
            this.stopTyping(conversationId === groupConversation.chat_room_id, user.uuid, conversationId)
            this.handleAddNewMessage({ id: conversationId, message: messageData,  isMyMessage: data.user.uuid === authUser.uuid, notIncrement }, isFilteredByClosed)

            if(conversation && 'open' !== conversation.type) {
               try {
                  await toggleOpenCloseConversation(conversation.user_uuid, 'open')
               } catch (error) {
                  console.log(error);
               }
            }
         }

         const dialogue = document.querySelector(`#messages-content-${ conversationId }`)
         if(dialogue){
            const { scrollHeight, clientHeight, scrollTop } = dialogue
            let diff =  (scrollHeight - clientHeight) - scrollTop
            if(diff < 110) {
               ChatHepler.syncDialogueScroll(null, activeConverstaion.chat_room_id)
            }
         }
         if(screenWidth > 1024) {
            ChatHepler.inputState()
         }
      });

      socket.on('message:update', (data) => {
         const {
            message, conversationId,
         } = data
         this.handleUpdateThisMessage(conversationId, message.id, { ...message });
         if(message?.unlock_details?.is_unlocked && ['video_unlock', 'photo_unlock', 'private_mass_message', 'multiple_attachment'].includes(message?.type)){
            const onDeleteContentMessag = (data, onChange, resourceType = null) => {
               const content = data.find(el => el?.messages && el.messages.find(m => m.conversation_id === conversationId && m.id === message.id));
               const contentsIds = data.filter(el => el?.messages && el.messages.find(m => m.conversation_id === conversationId && m.id === message.id)).map(it => it.id);

               const chatMessages = content?.messages;
               if(chatMessages){
                  const valutMessage = chatMessages.find(m => m.id === message.id);
                  if(valutMessage && valutMessage?.status === 'sent'){
                     onChange({ ids: [...contentsIds], data: { status: 'unlocked' }, messageId: valutMessage.id, resourceType: resourceType });
                  }
               }
            }

            switch (message?.unlock_details?.resource_type) {
               case 'video':{
                  const { videosData, updateVideoMessageAction } = this.props;
                  const data = videosData.data || [];
                  onDeleteContentMessag(data, updateVideoMessageAction)
                  break;
               }
               case 'photoset':{
                  const { photosetData, updatePhotosetMessageAction } = this.props;
                  const data = photosetData.data || [];
                  onDeleteContentMessag(data, updatePhotosetMessageAction)
                  break;
               }
               case 'video_vault':{
                  const { vaultVideosData, updateVaultMessageAction } = this.props;
                  const data = vaultVideosData.data || [];
                  onDeleteContentMessag(data, updateVaultMessageAction, message.unlock_details.resource_type)
                  break;
               }
               case 'photo_vault':{
                  const { vaultImagesData, updateVaultMessageAction } = this.props;
                  const data = vaultImagesData.data || [];
                  const content = data.filter(el => el?.messages && el.messages.find(m => m.conversation_id === conversationId && m.id === message.id));
                  let ids = [];
                  content.forEach(element => {
                     ids.push(element.id)

                  });
                  if(ids?.length){
                     updateVaultMessageAction({ ids: ids, data: { status: 'unlocked' }, messageId: message.id, resourceType: message.unlock_details.resource_type });
                  }
                  break;
               }

               case 'multiple_attachment':{
                  message.unlock_details.attachments.forEach(cntn => {
                     switch (cntn.resource_type) {
                        case 'video':{
                           const { videosData, updateVideoMessageAction } = this.props;
                           const data = videosData.data || [];
                           onDeleteContentMessag(data, updateVideoMessageAction)
                           break;
                        }
                        case 'photoset':{
                           const { photosetData, updatePhotosetMessageAction } = this.props;
                           const data = photosetData.data || [];
                           onDeleteContentMessag(data, updatePhotosetMessageAction)
                           break;
                        }
                        case 'video_vault':{
                           const { vaultVideosData, updateVaultMessageAction } = this.props;
                           const data = vaultVideosData.data || [];
                           onDeleteContentMessag(data, updateVaultMessageAction, cntn.resource_type)
                           break;
                        }
                        case 'photo_vault':{
                           const { vaultImagesData, updateVaultMessageAction } = this.props;
                           const data = vaultImagesData.data || [];
                           const content = data.filter(el => el?.messages && el.messages.find(m => m.conversation_id === conversationId && m.id === message.id));
                           let ids = [];
                           content.forEach(element => {
                              ids.push(element.id)

                           });

                           if(ids?.length){
                              updateVaultMessageAction({ ids: ids, data: { status: 'unlocked' }, messageId: message.id, resourceType: cntn.resource_type });
                           }
                           break;
                        }
                        default:
                           break;
                     }
                  })
                  break;
               }
               default:
                  break;
            }
         }

      });

      socket.on('message:delete', (data) => {
         this.handleDeleteMessage(data)
      });

      socket.on('conversation:read', (data) => {
         const {
            conversationId,
         } = data
         this.handleReadConverstionId(conversationId)
      });
      socket.on('user:mute', (data) => {
         const { setMuteMember } = this.props
         const {
            userUuid,
            muteInfo,
         } = data
         setMuteMember({ userUuid, ...muteInfo })
      });
      socket.on('user:unmute', (data) => {
         const { setMuteMember } = this.props
         setMuteMember(data)
      });
      socket.on('conversation:seen', (data) => {
         const state = {
            unread_messages_count_of_second_participant: 0,
            last_seen_of_second_participant: data.lastSeenTime,
            conversationId: data.conversationId,
         }
         this.handleSeenConverstionId(state)
      });
   }
   setOnlineMembers = (data, action) => {
      const { setOnlineUsers, onlineUsers } = this.props
      let index = null
      if(action) {
         index = onlineUsers.findIndex(elm => elm.uuid === data.uuid)
      }
      const returnFunction = (index > -1 && 'push' === action) || (index < 0 && 'pop' === action)
      if(returnFunction) {
         return
      }
      setOnlineUsers(data, action)
   }

   _initConnection = () => {
      const { siteInfo: { site_name: siteName } } = this.props;
      // this.socket = initChatSocket(siteName)
      this.notificationSocket = initNotificationsSocket(siteName);
   }

   setSocketConnectedAction = bool => {
      const { setSocketConnectedAction, isSocketConnected } = this.props
      if(isSocketConnected !== bool) {
         setSocketConnectedAction(bool)
      }
   }

   // start user typing
   onUserTyping = () => {
      const { activeConverstaion: { chat_room_id: id } } = this.props
      const data = { conversationId: id }
      if(!QueryParams.getParam('repliesModal')) {
         this.socket.emit('typing', data)
      }
   }

   handleTyping = (id, user, type) => {
      const { groupConversation, setTyper, conversationsList } =  this.props
      let activeChat = {}
      const userUuid = user.uuid
      let timeout = {}
      if(groupConversation.chat_room_id === id) {
         activeChat = groupConversation
         timeout = this.publicThreadTypersRemovers;
      } else {
         activeChat = conversationsList.data.find(i => i.chat_room_id === id)
         timeout = this.privateThreadTypersRemovers;
      }
      if(activeChat && activeChat.chat_room_id === id) {
         const typers = { ...activeChat.typers }
         const userTyper = typers[userUuid]
         if(!userTyper) {
            typers[userUuid] = user;
         }
         setTyper(id, groupConversation.chat_room_id === id, typers)
         const timeoutId = timeout[userUuid];
         if(timeoutId) {
            clearTimeout(timeoutId);
         }
         timeout[userUuid] = setTimeout(() => {
            this.stopTyping(groupConversation.chat_room_id === id, userUuid, id)
         }, 5000);
      }
   }
   stopTyping = (isGroup, userUuid, id) => {
      let typers = {}
      const { groupConversation, setTyper } =  this.props
      if(isGroup) {
         typers = { ...groupConversation.typers }
         delete typers[userUuid]
         setTyper(id, true, typers)

         delete this.publicThreadTypersRemovers[userUuid];
      } else {
         delete typers[userUuid]
         setTyper(id, false, typers)
         delete this.privateThreadTypersRemovers[userUuid];
      }
   }


   // end user typing

   // start message like
   onLikeMessage = data => {
      const { activeConverstaion: { chat_room_id: id  } } = this.props
      this.socket.emit('message:like', { ...data, conversationId: id })
      this.handleLikeMessage({ ...data, conversationId: id, isMyLiked: true })
   }

   handleLikeMessage = (data) => {
      const { likeMessage } = this.props
      likeMessage(data)
   }

   // end message like

   // start add&edit message
   onSendMessage =  (text, parent, updateMessageId, callback, id, clearStateCb, messageData, sendWithDuration) => {
      if(updateMessageId) {
         this.updateThisMessage(updateMessageId, text, callback, id, messageData)
      } else {
         this.sendNewMessage(text, parent, callback, id, clearStateCb, sendWithDuration)
      }

   }

   updateMessageUnlockDetails = async (updateMessageId, callback, roomId, unlockDetails, message) => {
      const {  activeConverstaion: { chat_room_id: id, member: { id: memberId } } } = this.props
      let messageData = {
         ...message,
         unlock_details: {
            ...message?.unlock_details,
            memberId: memberId || roomId || id,
            type: !!message?.type ? message?.type : message?.unlock_details?.type,
            vaultId: message?.unlock_details?.vaultId || message?.vaultId,
            // eslint-disable-next-line array-callback-return
            attachments: message?.unlock_details?.attachments?.filter((item, index) => {
               if(index === unlockDetails?.indexOfDeletedMedia) {
                  return {
                     ...item,
                     memberId: memberId || roomId || id,
                  }
               }
            }),
         },
      }
      const newData = {
         conversationId: roomId || id,
         messageId: updateMessageId,
         messageData,
         ...unlockDetails,
      }

      this.socket.emit('message:deletePhotos', newData, (data) => {
         if(data.error) {
            toast.error('Message must be 20000 characters or less')
         }
      })
      if(callback) {
         callback()
      }
   }

   updateThisMessage = async (messageId, text, callback, roomId, message) => {
      const {  activeConverstaion: { chat_room_id: id } } = this.props

      let attachmentsId = []

      if(!!message?.unlock_details) {
         attachmentsId = message?.unlock_details?.attachments.map(it => it?.resource_id)
      }

      const newData = {
         messageId, text,
         conversationId: roomId || id,
         unlock_details: message?.unlock_details,
         updateMessageType: message.updateMessageType,
         messageData: {
            ...message,
            unlock_details: {
               ...message?.unlock_details,
               attachments: message?.attachments?.filter(it => ![...attachmentsId]?.includes(it.resource_id)),
            },
         },
      }

      this.socket.emit('message:update', newData, (data) => {
         if(data.error) {
            toast.error('Message must be 20000 characters or less')
         }
      })
      if(text.length < 20001) {
         await this.handleUpdateThisMessage(id, messageId, { text, edited: true, id: messageId  })
      }
      if(callback && text.length < 20001) {
         callback()
      }
   }

   sendNewMessage = async (newMessage, parent, callback, roomId, clearStateCb, sendWithDuration) => {

      let randomid = Math.random() * (700 - 2000) + 2000;
      const {
         authUser,
         activeConverstaion: { isFake, user_uuid, member  },
         setChatConversation,
         conversationsFilter,
         locationPath,
         updateConversationFromList,
         conversationsList,
         addRemoveConverstionItemAction,
         groupConversation,
         // settingsData,
      } = this.props

      const memberId = member?.id

      let id = null;
      if(isFake) {
         try {
            const { data } = await createNewConversationWithUser(user_uuid)
            id = data.chat_room_id;
            QueryParams.setQueryParam('conversation', `${ data.chat_room_id }`)
            setChatConversation({ ...data, user_uuid }, { username: data.username, uuid: data.user_uuid, ...member }, this.searchRes)

         } catch (error) {

         }
      }
      if(!isFake){
         const {  activeConverstaion: { chat_room_id  } } = this.props
         id = roomId || chat_room_id;
         id = Number(id)
      }
      const parentMessageId = parent ? parent.parentMessageId : null
      const parentMessageUnlockDetails = parent ? parent.parentMessageUnlockDetails : null
      var sentAt = DateFormatter.getCurentTimeMilliseconds()
      let newData = {
         parentMessageId,
         sentAt,
         conversationId: id,
         parentMessageUnlockDetails,
      }

      let newMessageData = {
         sent_at: sentAt,
         id: randomid,
         is_liked_by_user: false,
         likes_count_from_other_users: 0,
         parent: parentMessageId ? parent : null,
         user: authUser,
      }

      let sendData = {
         parentMessageId,
         sentAt,
         conversationId: id,
         parentMessageUnlockDetails,
      }



      if(typeof newMessage === 'string') {
         newMessageData = {
            ...newMessageData,
            text: newMessage,
         }
         newData = {
            ...newData,
            text: newMessage,
         }
         sendData = {
            ...sendData,
            text: newMessage,
         }
      }
      if(typeof newMessage === 'object' && newMessage?.type !== 'multiple_attachment') {
         let cover = null;
         let voiceDetails = {};
         if(newMessage.resources[0]) {
            let path = ''
            if('video_unlock' === newMessage.type){
               let basename = newMessage?.basename || newMessage.resources[0].split('.')[0];
               try {
                  const { data } = await getObjectUrl([newMessage.coverImage],  `videos/${ basename }/outputs/thumbnails/`)

                  path = data[newMessage.coverImage]
               } catch (error) {
               }
            } else if('voice_message' === newMessage.type){
               try {
                  const { data } = await getObjectUrl([newMessage.resources[0]], 'audio-recordings/')
                  path = data[newMessage.resources[0]]
                  voiceDetails = {
                     src: path,
                     audio_duration: newMessage.duration,
                  }
               } catch (error) {
               }
            } else {
               try {
                  const { data } = await getObjectUrl([newMessage.resources[0]], 'images/')
                  path = data[newMessage.resources[0]]
               } catch (error) {
               }
            }
            cover = path
         }

         if('video' === newMessage.resourceType && +newMessage?.resourceId){
            try {
               const { data } = await getVideoById(newMessage?.resourceId, [''])

               newMessage.lockedPosterIsBlur = data?.['locked_image_is_blur']
            } catch (error) {
            }
         }


         newMessageData = {
            ...newMessageData,
            ...newMessage,
            unlock_details: {
               is_unlocked: !!newMessage.isUnlocked,
               unlock_price: newMessage.unlockPrice,
               photos_count: newMessage?.photos_count || newMessage.resources.length,
               photos_blurhash: newMessage.blurhash,
               video_duration: newMessage.duration,
               locked_poster_is_blur: newMessage.lockedPosterIsBlur,
               allow_download: Boolean(newMessage?.allowDownload),
               cover,
               publish_date: newMessage?.publish_date,
               resource_type: newMessage?.resourceType,
               resource_id: newMessage?.resourceId,
               type: newMessage?.type,
               if_free_for_member_by_access: newMessage?.if_free_for_member_by_access,
               memberId: newMessage?.memberId || memberId,
               add_member_info_to_watermark: Boolean(newMessage?.allowDownloadWithWatermark),
               ...voiceDetails,
            },
            memberId: newMessage?.memberId || memberId,
         }
         newData = {
            ...newData,
            ...newMessage,
         }
         sendData = {
            ...sendData,
            ...newMessage,
            memberId: newMessage?.memberId || memberId,
            if_free_for_member_by_access: newMessage?.if_free_for_member_by_access,
         }

         if(newMessage?.attachmentsStatuses) {
            newMessageData.unlock_details.attachmentsStatuses = newMessage?.attachmentsStatuses
            newData.attachmentsStatuses = newMessage?.attachmentsStatuses
            sendData.attachmentsStatuses = newMessage?.attachmentsStatuses
         }

      }

      if(typeof newMessage === 'object' && newMessage?.type === 'multiple_attachment') {

         let attachmentsData = [...newMessage.attachments]

         try {
            attachmentsData = await Promise.allSettled(newMessage.attachments.map(async ct => {

               if(ct.type === 'upload_video'){
                  let basename = ct.resources[0].split('.')[0];

                  let content = {
                     ...ct,
                     cover: null,
                  }
                  try {
                     const { data } = await getObjectUrl([ct?.videoUpload?.video_poster], `videos/${ basename }/outputs/thumbnails/`)

                     content.cover = data[ct?.videoUpload?.video_poster]

                  } catch (error) {}

                  return content

               }  else if(ct.type === "video_unlock"){

                  let basename = ct?.basename || ct.resources[0].split('.')[0];

                  let content = {
                     ...ct,
                     cover: null,
                  }
                  try {
                     const { data } = await getObjectUrl([ct?.cover_image], `videos/${ basename }/outputs/thumbnails/`)

                     content.cover = data[ct?.cover_image]

                  } catch (error) {}

                  return content

               } else if(ct.type === "photo_unlock"){

                  let content = {
                     ...ct,
                     cover: null,
                  }

                  try {
                     const { data } = await getObjectUrl([ct.resources[0]], 'images/')
                     content.cover = data[ct.resources[0]]
                  }
                  catch (error){}

                  return content

               } else {
                  return ct
               }
            }))
         } catch (error) {
            console.error(error);
         }

         const attachmentsArr =  attachmentsData.map(it => ({ ...it.value, memberId }))

         newMessageData = {
            ...newMessageData,
            ...newMessage,
            attachments: attachmentsArr,
            unlock_details: {
               // photos_count: newMessage.resources.length,
               // photos_blurhash: newMessage.blurhash,
               // video_duration: newMessage.duration,
               is_unlocked: !!newMessage.isUnlocked,
               unlock_price: newMessage.unlockPrice,
               locked_poster_is_blur: newMessage.lockedPosterIsBlur,
               allow_download: Boolean(newMessage?.allowDownload),
               attachments: attachmentsArr,
               type: 'multiple_attachment',
               resource_type: 'multiple_attachment',
               memberId,
               add_member_info_to_watermark: Boolean(newMessage?.allowDownloadWithWatermark),

            },
         }


         newData = {
            ...newData,
            ...newMessage,
            attachments: attachmentsArr,
         }

         sendData = {
            ...sendData,
            ...newMessage,
            attachments: newMessage.attachments.map(it => ({ ...it, memberId })),
         }

         if(newMessage?.attachmentsStatuses) {
            newMessageData.unlock_details.attachmentsStatuses = newMessage?.attachmentsStatuses
            newData.attachmentsStatuses = newMessage?.attachmentsStatuses
            sendData.attachmentsStatuses = newMessage?.attachmentsStatuses
         }

      }

      let durationData = {}

      if(this.props?.activeConverstaion?.durationInfo?.duration_in_minutes) {
         durationData = {
            show_duration_countdown_to_member: this.props?.activeConverstaion?.durationInfo?.show_duration_countdown_to_member,
            duration_in_minutes: +this.props?.activeConverstaion?.durationInfo?.duration_in_minutes,
         }
      }

      if(newMessage?.duration_in_minutes) {
         durationData = {
            show_duration_countdown_to_member: newMessage?.show_duration_countdown_to_member,
            duration_in_minutes: +newMessage?.duration_in_minutes,
         }
      }

      if(sendWithDuration && durationData?.duration_in_minutes) {

         const dateToUnsend = moment(DateFormatter.convertToUtc(Date.now())).add(durationData?.duration_in_minutes, 'minutes').format('YYYY-MM-DD HH:mm:ss')  //.format('YYYY:MM:DD-HH:mm:ss') //2024-12-01 18:44:57

         let newDurationData = {
            show_duration_countdown_to_member: durationData?.show_duration_countdown_to_member,
            duration_in_minutes: +durationData?.duration_in_minutes,
            unsend_date: !!newMessage?.unlockPrice ? null : dateToUnsend,
         }

         newMessageData = {
            ...newMessageData,
            ...newDurationData,
         }
         newData = {
            ...newData,
            ...newDurationData,
         }
         sendData = {
            ...sendData,
            ...newDurationData,
         }

      }

      if(!!parent) {

         let parentDurationData = {
            parentUnsendDate: parent?.parentUnsendDate,
            parentShowDurationCountdownToMember: parent?.parentShowDurationCountdownToMember,
            parentDurationInMinutes: +parent?.parentDurationInMinutes,
         }

         newMessageData = {
            ...newMessageData,
            ...parentDurationData,
         }
         newData = {
            ...newData,
            ...parentDurationData,
         }
         sendData = {
            ...sendData,
            ...parentDurationData,
         }
      }

      const isFilteredByClosed = Router.route('CHAT').match(locationPath) && conversationsFilter?.type?.[0] === 'closed'
      const isFilteredByUnread = Router.route('CHAT').match(locationPath) && 'unread' === conversationsFilter?.message_status?.[0]

      let chatListItem = conversationsList.data.find(item => item.chat_room_id === id)

      const isGroup = id === groupConversation.chat_room_id

      if(!chatListItem && !isGroup) {
         try {
            const { data } = await fetchConversationById(id)
            chatListItem = { ...data }
            if(!isFilteredByClosed && !isFilteredByUnread) {
               addRemoveConverstionItemAction(data)
            }
         } catch (error) {
            console.log(error);
         }
      }
      const dontNeedToAddConversationToList = isFilteredByClosed || isFilteredByUnread
      await this.handleAddNewMessage({ id, message: newMessageData, isMyMessage: true }, dontNeedToAddConversationToList)

      await this.socket.emit('message:send', sendData,  async data => {

         const { updateMessage, updateConverstion, activeConverstaion: { chat_room_id: id } } = this.props
         let message = {}
         if(data.error) {
            switch (data.error) {
               case 'message_is_too_long':
                  toast.error('Message must be 20000 characters or less')
                  break;
               case 'conversation_is_not_active':

                  break;
               case 'incorrect_payload':
                  break;

               case 'internal_server_error':
                  break;

               case 'access_denied':
                  break;

               default:
                  break;
            }
         } else {
            const { messageId, parentMessage, attachments, singleAttachmentUnlockDetails } = data.data
            message = {
               ...newMessage,
               id: messageId,
               parent: parentMessage,
            }

            if(message?.parent?.user?.isAdmin === 0 && message?.parent?.user?.username === member?.username) {
               message.parent.user.nickname = member?.nickname
            }

            let newDataWithPreview = {}

            if(attachments?.length && newMessage?.attachments?.length){
               newDataWithPreview = {
                  type: 'multiple_attachment',
                  attachments: message?.attachments.map(it => {
                     if(it?.type === 'video_unlock'){
                        const videoItem = attachments.find(item => item?.resource_id === it?.resource_id)
                        return {
                           ...it,
                           system_preview_video_full_src: videoItem?.system_preview_video_full_src,
                           custom_preview_video_full_src: videoItem?.custom_preview_video_full_src,
                        }
                     }
                     return it
                  }),
               }
            }

            if(['video_vault', 'video'].includes(singleAttachmentUnlockDetails?.resource_type)) {
               newDataWithPreview = {
                  type: 'single_attachment',
                  previews: {
                     system_preview_video_full_src: singleAttachmentUnlockDetails?.system_preview_video_full_src,
                     custom_preview_video_full_src: singleAttachmentUnlockDetails?.custom_preview_video_full_src,
                  },
               }
            }

            updateMessage({ conversationId: newData.conversationId, messageId: randomid,  message, newDataWithPreview  })
            let ollContents = window.currentConversationContent ? JSON.parse(window.currentConversationContent) : {}

            if(ollContents?.[newData.conversationId]) {
               delete ollContents[newData.conversationId]
            }
            if('voice_message' === newMessage?.type){
               const { contentMetaInfo } = this.props;
               try {
                  let payload = { content_chat_voice_message_default_price: newMessage.unlockPrice }
                  if(newMessage?.makeDefaultPrice && newMessage?.unlockPrice){
                     payload = { content_chat_voice_message_default_price: newMessage.unlockPrice }
                  }
                  if(!newMessage?.makeDefaultPrice){
                     payload = { content_chat_voice_message_default_price: null }
                  }
                  await updateSettings(payload)
                  contentMetaInfo(payload)
               } catch (error) {

               }
            }

            updateConverstion({ conversationId: id, data: { attachments: [], attachmentsForShow: [], durationInfo: { duration_in_minutes: 0, show_duration_countdown_to_member: true } } })

         }
      })


      if(chatListItem && 'open' !== chatListItem.type) {
         try {
            await toggleOpenCloseConversation(chatListItem.user_uuid, 'open')

            if(Router.route('CHAT').match(locationPath)) {
               updateConversationFromList(id, {
                  changed_to_type: 'open',
                  type: 'open',
               })
            }

         } catch (error) {
            console.log(error);
         }
      }

      if(callback) {
         callback()
      }

      if(clearStateCb) {
         clearStateCb()
      }

      if(window?.new_conversations && window?.new_conversations[`new_${ user_uuid }`]) {
         delete window?.new_conversations[`new_${ user_uuid }`]
      }

   }
   handleAddNewMessage = (data, dontNeedToAddConversationToList) => {
      const { addNewMessage, updateConverstion } = this.props
      const conversationId = this.props?.activeConverstaion?.chat_room_id
      let modifiedData = {
         ...data,
         message: {
            ...data.message,
            type: data.message.type || 'text_message',
         },
      }

      if(data.message.type === 'voice_message') {
         flushSync(() => {
            updateConverstion({ conversationId: conversationId, data: { durationInfo: { duration_in_minutes: 0, show_duration_countdown_to_member: true } } })
         })
      }


      addNewMessage(modifiedData, this.searchRes, dontNeedToAddConversationToList)
   }

   handleUpdateThisMessage = (conversationId, messageId, message) => {
      const { updateMessage } = this.props
      updateMessage({ conversationId, messageId,  message })
   }
   // end add&edit message



   // start delete message
   onDeleteMessag = (data, callBack, tooltipText = null, showTooltip = true, messageData = null) => {
      const {  activeConverstaion: { chat_room_id: id }, screenWidth, initedConverstionMessages } = this.props
      const { activeConverstaion } = this.props
      const memberId = activeConverstaion?.member?.id;
      const messagesList = initedConverstionMessages?.[id]?.data
      let lastMessage = !!messagesList?.[1] ? {
         text: messagesList?.[1]?.text,
         user_uuid: messagesList?.[1]?.user?.uuid,
         created_at: messagesList?.[1]?.sent_at,
         username: messagesList?.[1]?.user?.username,
      } : {}

      if(!lastMessage?.text) {
         if('photo_unlock' === messagesList?.[1]?.type){
            let photos_count =  messagesList?.[1]?.unlock_details?.photos_count
            lastMessage.text = photos_count === 1 ? 'New photo!' : `${ photos_count } new photos!`
         }
         if('video_unlock' === messagesList?.[1]?.type){
            lastMessage.text = 'New video!'
         }
         if('voice_message' === messagesList?.[1]?.type){
            lastMessage.text = 'Voice message!'
         }
         if('multiple_attachment' === messagesList?.[1]?.type){
            lastMessage.text = 'Multiple attachments!'
         }
      }

      const isMobile = screenWidth < 1025
      this.socket.emit(
         'message:delete', {
            ...data,
            conversationId: id,
            lastMessage,
            messageData: {
               ...messageData,
               type: !!messageData?.type ? messageData?.type : messageData?.unlock_details?.type,
               unlock_details: {
                  ...messageData?.unlock_details,
                  memberId: memberId || id,
                  attachments: messageData?.unlock_details?.attachments?.map(it => ({ ...it, memberId: memberId || id })),
                  vaultId: messageData?.unlock_details?.vaultId || messageData?.vaultId,
               },
            } })
      this.handleDeleteMessage({ ...data, conversationId: id });
      if(showTooltip){
         let text = tooltipText || 'Message has been unsent';
         toast.remove(text, isMobile ? { onOpen: async () => {
            positionToasterContainer('60px');
         } } : {});
      }
      if(callBack){
         callBack();
      }
   }

   handleDeleteMessage = data => {
      const { deleteMessage } = this.props
      deleteMessage(data)
   }
   // end delete message

   onReadMessage = id => {
      const { activeConverstaion, authUser } = this.props
      if(id && this.socket) {
         let unreamPmCount = 0;
         if(!activeConverstaion.isGroup) {
            unreamPmCount = activeConverstaion.unread_messages_count;
         }
         this.handleReadConverstionId(id, unreamPmCount)
         this.socket.emit('conversation:read', { conversationId: id })
         if(activeConverstaion && !!activeConverstaion.last_message && activeConverstaion.last_message.user_uuid !== authUser.uuid) {
            this.socket.emit('conversation:seen', { conversationId: id, lastSeenTime: DateFormatter.convertToUtc(Date.now()) })
         }
      }
   }

   handleReadConverstionId = (id, unreamPmCount) => {
      const {
         updateConverstion,
         updateMUnreamPmMessageCount,
         locationPath,
         conversationsFilter,
         groupConversation,
      } = this.props
      const data = {
         unread_messages_count: 0,
      }
      if(Router.route('CHAT').match(locationPath) && id !== groupConversation.chat_room_id) {
         const isFilteredByUnread = 'unread' === conversationsFilter?.message_status?.[0]
         if(isFilteredByUnread) {
            data.hidden = true
         }
      }
      updateConverstion({ conversationId: id, data })
      updateMUnreamPmMessageCount(-unreamPmCount);
   }
   handleSeenConverstionId = data => {
      const { updateConverstion } = this.props
      const {
         conversationId,
         unread_messages_count_of_second_participant,
         last_seen_of_second_participant,
      } = data
      updateConverstion({ conversationId, data: { unread_messages_count_of_second_participant, last_seen_of_second_participant } })
   }


   onMuteMember = async (mutedPeriod, memberUuid) => {
      const { memberOptionModal } = this.state
      const { activeConverstaion } = this.props
      this.setState({
         memberOptionModal: {
            ...memberOptionModal,
            isLoading: true,
         },
      })
      try {
         await muteMember(memberUuid, { mute_period_in_seconds: mutedPeriod })
         const { updateMemberByUuid } = this.props
         updateMemberByUuid(activeConverstaion.user_uuid, { muted_since: Date.now() })
         toast.remove(`${ activeConverstaion?.member_nickname || activeConverstaion?.member?.nickname || activeConverstaion.username } has been muted`)
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
               type: null,
               isOpen: false,
            },
         })
      } catch (error) {

      }
   }

   onBlockMember = async () => {
      const { memberOptionModal } = this.state
      const { activeConverstaion, blockMemberAction } = this.props
      const banned = activeConverstaion.member && !activeConverstaion.member.banned ? 1 : 0
      if(banned) {
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: true,
            },
         })
      }
      try {
         await updateMember(activeConverstaion.user_uuid, { banned }, 'uuid');
         const { updateMemberByUuid } = this.props
         updateMemberByUuid(activeConverstaion.user_uuid, { banned })
         toast.remove(`${ activeConverstaion?.member_nickname || activeConverstaion?.member?.nickname || activeConverstaion.username } has been ${ !banned ? 'unblocked' : 'blocked' }`)
         blockMemberAction({ banned, uuid: activeConverstaion.user_uuid })
         if(banned){
            this.setState({
               memberOptionModal: {
                  ...memberOptionModal,
                  isLoading: false,
                  type: null,
                  isOpen: false,
               },
            })
         }
      } catch (error) {

      }
   }
   onUnMuteMember = async () => {
      const { activeConverstaion } = this.props
      const { memberOptionModal } = this.state
      this.setState({
         memberOptionModal: {
            ...memberOptionModal,
            isLoading: true,
         },
      })
      try {
         await unMuteMember(activeConverstaion.user_uuid)
         const { updateMemberByUuid } = this.props
         updateMemberByUuid(activeConverstaion.user_uuid, { muted_since: null })
         toast.remove(`${ activeConverstaion?.member_nickname || activeConverstaion?.member?.nickname || activeConverstaion.username } has been unmuted`)
         this.setState({
            memberOptionModal: {
               ...memberOptionModal,
               isLoading: false,
            },
         })
      } catch (error) {

      }
   }

   // end connect socket

   onSetMemberOption = type => {
      switch (type) {
         case 'unmute':
            this.onUnMuteMember()
            break;
         case 'unblock':
            this.onBlockMember()
            break;
         case 'help':
            IntercomMoreInfo(CLIENT_REQUEST_CONTACT_SUPPORT_ABOUT_MEMBER)
            break;
         default:
            this.setState({
               memberOptionModal: {
                  type,
                  isOpen: !!type,
               },
            })
            break;
      }
   }

   setSearchResult = (value) => {
      this.searchRes = value
   }


   addFreeMemberByMembership = () => {
      const { addFreeMemberOperation, fetchStatisticsData, memberFetchDetails } = this.props;
      const { selectedMembership } = this.state;
      if(selectedMembership && this.member.id) {
         const data = {
            membership_period: selectedMembership,
         }
         addFreeMemberOperation(this.member.id, data, () => {
            this.setState({
               warningMessage: null,
               addFreeMemberModal: false,
            })
            memberFetchDetails(this.member.id)
            fetchStatisticsData();
         });
      }
   }

   onCloseMembershipModal = () => {
      this.setState({
         addFreeMemberModal: false,
         selectedMembership: null,
      });
   }

   openMembershipModal = () => {
      this.setState({
         addFreeMemberModal: true,
      });
   }

   setSelectedMembership = (membership) => {
      this.setState({
         selectedMembership: membership,
      })
   }


   updateSentMedia = async (data) => {
      await this.socket.emit('message:updateSentMedia', data)
   }


   render(){
      const {
         groupChatTypersMessage,
         activeConverstaion,
         typersMessage,
         children,
         screenWidth,
      } = this.props
      const {
         memberOptionModal,
         addFreeMemberModal,
         warningMessage,
         selectedMembership,
      } = this.state
      const isMobile = screenWidth < 1025
      if(activeConverstaion){
         this.member = activeConverstaion.member;
      }
      return (

         <Provider
            value={ {
               onDeleteMessag: this.onDeleteMessag,
               onUserTyping: this.onUserTyping,
               groupChatTypersMessage: groupChatTypersMessage,
               typersMessage: typersMessage,
               onLikeMessage: this.onLikeMessage,
               onSendMessage: this.onSendMessage,
               onClickChat: this.onClickChat,
               converstionInfo: activeConverstaion,
               onClickLeftItem: this.onClickLeftItem,
               onReadMessage: this.onReadMessage,
               userTyping: this.userTyping,
               onMuteMember: this.onMuteMember,
               onUnMuteMember: this.onUnMuteMember,
               onSetMemberOption: this.onSetMemberOption,
               setSerachResult: this.setSearchResult,
               giveFreeMembership: this.openMembershipModal,
               updateMessageUnlockDetails: this.updateMessageUnlockDetails,
               updateMessageData: this.updateThisMessage,
               updateSentMedia: this.updateSentMedia,
            } }
         >

            {children}

            {
               memberOptionModal.isOpen && (
                  <Modal
                     contentPosition={ isMobile ? 'bottom' : 'center' }
                     onClose={ () => this.onSetMemberOption(null) }
                     isCloseAction={ false }
                     roundedModal='xlg'
                  >
                     {
                        'block' === memberOptionModal.type && (
                           <ConfirmDialog
                              modalInfo={ {
                                 title: `Block ${ activeConverstaion?.member_nickname || activeConverstaion?.member?.nickname || activeConverstaion.username }?`,
                                 description: `Are you sure you want to block ${ activeConverstaion?.member_nickname || activeConverstaion?.member?.nickname || activeConverstaion.username }? This member will be shown an error page instead of your site`,
                                 actionText: 'Yes, block this member',
                                 action: () =>  this.onBlockMember() }  }
                              closeConfirmDialog={ () => this.onSetMemberOption(null) }
                              disabled={ memberOptionModal.isLoading }
                              showBlockIcon={ true }
                              iconName='block'
                           />

                        )
                     }
                     {
                        'mute' === memberOptionModal.type && (
                           <MuteMember
                              isMobile={ isMobile }
                              onCloseModal={ () => this.onSetMemberOption(null) }
                              onMuteTimeChange={ (time) => {
                                 this.onMuteMember(time, activeConverstaion.user_uuid)
                              } }
                              muteTime={ activeConverstaion.muted_period_in_seconds }
                              username={ activeConverstaion?.member_nickname || activeConverstaion?.member?.nickname || activeConverstaion.username }
                              disabled={ memberOptionModal.isLoading }
                           />

                        )
                     }
                  </Modal>
               )
            }
            {
               addFreeMemberModal && (
                  <Modal
                     contentPosition={ screenWidth < 700 ? 'bottom' : 'center'  }
                     onClose={ this.onCloseMembershipModal }
                     contentWidth={ screenWidth < 700 ? '100%' : '375px' }
                     isCloseAction={ false }
                  >
                     <ChooseMembership
                        warningMessage={ warningMessage }
                        avatar={ this.member.avatar_compressed_src }
                        username={ this.member.username }
                        memberships={ [1, 2, 3, 5, 7, 14, 30, 60, 90] }
                        selectedMembership={ selectedMembership }
                        setSelectedMembership={ this.setSelectedMembership }
                        addFreeMember={ this.addFreeMemberByMembership }
                        onclose={ this.onCloseMembershipModal }
                     />
                  </Modal>
               )
            }
         </Provider>
      );

   }

}

const mapStateToProps = (state) => {
   return {
      screenWidth: screenWidthSelector(state),
      authUser: authUserSelector(state),
      siteInfo: siteInfoSelector(state),
      conversationsList: conversationsListSelector(state),
      groupConversation: groupConversationSelector(state),
      isFetchConversation: isFetchConversationSelector(state),
      isFetchNewConversation: isFetchNewConversationSelector(state),
      isSocketConnected: isSocketConnectedSelector(state),
      activeConverstaion: activeConverstaionSelector(state),
      initedConverstionMessages: initedConverstionMessagesSelector(state),
      onlineUsers: onlineUsersSelector(state),
      soket: soketSelector(state),
      locationPath: currentLocationPathSelector(state),
      settingsData: settingsDataSelector(state),
      initedConversation: initedConversationSelector(state),
      statistics: statisticsDataSelector(state),
      conversationsFilter: conversationsFilterSelector(state),
      vaultVideosData: vaultVideosDataSelector(state),
      videosData: videosDataInsideModal(state),
      photosetData: listInsideModalSelector(state),
      vaultImagesData: vaultImagesDataSelector(state),
   }
}

const mapDispatchToProps = (dispatch) => {
   return {
      goTo: (location) => {
         dispatch(push(location));
      },
      fetchNewConversation: (query) => dispatch(fetchNewConversationOperation(query)),
      setSocketConnectedAction: (bool) => dispatch(setSocketConnectedAction(bool)),
      setChatConversation: (data, member, search) => dispatch(createConversation(data, member, search)),
      likeMessage: data => dispatch(onLikeMessage(data)),
      deleteMessage: data => dispatch(deleteMessage(data)),
      addNewMessage: (data, search, dontNeedToAddConversationToList) => dispatch(addNewMessage(data, search, dontNeedToAddConversationToList)),
      updateMessage: data => dispatch(updateMessage(data)),
      updateConverstion: data => dispatch(updateConverstion(data)),
      setOnlineUsers: (data, action) => dispatch(setOnlineUsers(data, action)),
      setTyper: (data, isGroup, typers) => dispatch(setTyper(data, isGroup, typers)),
      setMuteMember: info => dispatch(setMuteMember(info)),
      blockMemberAction: info => dispatch(setBlockMemberAction(info)),
      setSoket: info => dispatch(setSoket(info)),
      fetchSettingsData: () => dispatch(fetchChatSettingsOperation()),
      addRemoveConverstionItemAction: (data) => dispatch(addRemoveConverstionItemAction(data)),
      fetchConversations: (query, bool, inited, serach, callback) => dispatch(fetchConversationsOperation(query, bool, inited, serach, callback)),
      updateMemberByUuid: (uuid, data) => dispatch(updateMemberByUuid(uuid, data)),
      addFreeMemberOperation: (id, data, callBack) => {
         dispatch(addFreeMemberOperation(id, data, callBack));
      },
      fetchStatisticsData: params => dispatch(fetchStatisticsData(params)),
      updateMUnreamPmMessageCount: (count) => dispatch(updateUnreamPmMessageCountAction(count)),
      memberFetchDetails: (id) => dispatch(memberFetchDetailsOperation(id)),
      contentMetaInfo: (data) => dispatch(contentMetaInfoAction(data)),
      updateConversationFromList: (conversationId, data) => dispatch(updateConversationFromListAction(conversationId, data)),
      updateVaultMessageAction: (data) => dispatch(updateVaultMessageAction(data)),
      updatePhotosetMessageAction: (data) => dispatch(updatePhotosetMessageAction(data)),
      updateVideoMessageAction: (data) => dispatch(updateVideoMessageAction(data)),
   }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChatProviderContainer);
