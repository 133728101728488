export const SITE_DETAILS_INIT_START = 'common/SITE_DETAILS_INIT_START';
export const SITE_DETAILS_INIT_COMPLETED = 'common/SITE_DETAILS_INIT_COMPLETED';
export const SITE_DETAILS_INIT_FAILED = 'common/SITE_DETAILS_INIT_FAILED';

export const SITE_DETAILS_REFRESH_START = 'common/SITE_DETAILS_REFRESH_START';

export const RESET_COMMON_DETAILS = 'common/RESET_COMMON_DETAILS';
export const COLLAPSE_MENU = "common/COLLAPSE_MENU";
export const UPDATE_AUTH_USER = "common/UPDATE_AUTH_USER";

export const SCREEN_RESIZE = "common/SCREEN_RESIZE";

export const SET_VIEW_AS_MODAL = "common/SET_VIEW_AS_MODAL";


export const SET_IS_ONLINE_STREAM = "common/SET_IS_ONLINE_STREAM";
export const UPDATE_LIVE_STREAM_CURRENT_SESSION_STATE = "common/UPDATE_LIVE_STREAM_CURRENT_SESSION_STATE";
export const OPEN_ONLINE_MEMBERS_MODAL = "common/OPEN_ONLINE_MEMBERS_MODAL";

export const UPDATE_SITE_LONG_NAME_START = "common/UPDATE_SITE_LONG_NAME_START";
export const UPDATE_SITE_LONG_NAME_COMPLETED = "common/UPDATE_SITE_LONG_NAME_COMPLETED";
export const UPDATE_SITE_NAME_FAILED = "common/UPDATE_SITE_NAME_FAILED";
export const UPDATE_CONTENT_META_INFO = "common/UPDATE_CONTENT_META_INFO";
export const SET_MASS_MESSAGE_LIMIT = "common/SET_MASS_MESSAGE_LIMIT";


export const UPDATE_CONTENTS_REORDERING_INFO = "common/UPDATE_CONTENTS_REORDERING_INFO"

export const UPDATE_RECEIVED_ORDERS_COUNT = "common/UPDATE_RECEIVED_ORDERS_COUNT"
export const UPDATE_IS_OFFLINE_MODE_COMPLETED = "common/UPDATE_IS_OFFLINE_MODE_COMPLETED"
export const UPDATE_IS_OFFLINE_MODE_START = "common/UPDATE_IS_OFFLINE_MODE_START"

export const SET_IS_LOADING_CONNECT_TO_MYLIST = "common/SET_IS_LOADING_CONNECT_TO_MYLIST"
export const CONNECT_TO_MYLIST_COMPLETED = "common/CONNECT_TO_MYLIST_COMPLETED"

export const UPDATE_SITE_INFO = "common/UPDATE_SITE_INFO"

export const SET_SHARE_MODAL = "common/SET_SHARE_MODAL"
