import React from 'react';
import PropTypes from 'prop-types';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

const EmojiPicker = ({
   onEmojiSelect,
   style,
   className,
}) => {
   return (
      <div
         className={ `notranslate ${ className }` }
         style={ {
            ...style,
         } }
      >
         <Picker
            className='asd'
            emojiButtonColors='#041527'
            data={ data }
            onEmojiSelect={  onEmojiSelect }
            showPreview={ false }
            emojiSize={ 20 }
            emojiButtonRadius='100%'
            emojiButtonSize={ 36 }
            navPosition='top'
            set='native'
            previewPosition='none'
            theme={ window.site_dark_bg ? 'dark' : 'light' }
         />
      </div>

   )
}

EmojiPicker.defaultProps = {
   className: '',
};
EmojiPicker.propTypes = {
   onEmojiSelect: PropTypes.func,
   style: PropTypes.object,
   className: PropTypes.string,
}
export default EmojiPicker;
