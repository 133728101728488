import React, {  useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";
import ReactNbsp from 'react-nbsp';
import Button from "common/components/elements/buttons/primary";
import SiteRouter from 'sites/routes/router';
import AdminRouter from 'admin/routes/router';
// import ChateActions from "common/components/modules/chat/actions";
// import Modal fromd "common/components/modules/modal";
import cx from "classnames";
import GenerateColor from "common/utils/generateTextColor.js";
import ChatTools from 'common/components/modules/chat/tools/message-action'
import ChatLikesModal from "common/components/modules/chat/chat-likes-modal";
import './style.scss';
import Icon from "common/components/elements/icons";
import DateFormatter from 'common/utils/DateFormatter';
import { copyText } from 'common/utils/utils.js';
import toast from 'common/utils/toast';
import ConfirmDialog from  'common/components/modules/confirm-dialog';

import ModalDropDown from  'common/components/modules/chat/chat-modal-drop-down';
import Modal from 'common/components/modules/modal'
import NotificationMessage, { LiveStreamNotification, NotificationMessageBody } from 'common/components/modules/chat/notifiaction-message';
import UnlockContentMessage from 'common/components/modules/chat/unlock-content-message';
import { getPhrasesFromMessage } from 'common/utils/messageParser'
import Waveform from 'common/components/modules/audio-player';
import WaveformUnlockContent from 'common/components/modules/audio-player/unlock-content';
import Avatar from "common/components/modules/avatar";
import MultipleAttachmentMessage from "../../multiple-attachment-message";
import TruncateText from "common/components/elements/truncate-text";
import moment from "moment";


const Message = ({
   message,
   nextIsMyMessage,
   isMobile,
   isMyMessage,
   user,
   onClickUserAvatar,
   parent,
   likes = {
      data: [],
      fetching: false,
      fetchingByScroll: false,
   },
   onFetchLikesInfo,
   onLikedMemberPagination,
   onLikeMessage,
   onDeleteMessag,
   onReplyMessage,
   updateMessage,
   isUpdate,
   isGroup,
   isAdmin = false,
   closeChatPopup,
   onStream,
   // goToContentMessage,
   paymentButtonLoading,
   isMiniChat,
   goToJoin,
   downloadFile,
   adminUuid,
   lastElement,
   goToDetails,
   onClickAttachment,
   onRemoveMedia,
   replyMessage,
   authUser,
   isLivstream,
   screenWidth,
   getContentLink,
   onClickNotificationMessageButton,
   currentLiveStream,
}) => {
   let messageText = useRef();
   let messageContent = useRef();
   let messageOptions = useRef();
   const type =  message.type || 'text_message'

   const isPrivateMassMessage = type === 'private_mass_message' && (!message?.unlock_details || (message?.unlock_details && !(['multiple_attachment'].includes(message?.unlock_details?.type))))
   const isTextMessage = 'text_message' === type
   const isTipMessage = 'tip' === type
   const isUnlockContentMessage = type.includes('_unlock')
   const isLiveStreamNotification = type === 'live_stream'
   const isMultipleAttachmentsMessage = ['multiple_attachment'].includes(type) || (['private_mass_message', 'mass_message'].includes(type) && message?.unlock_details && ['multiple_attachment'].includes(message?.unlock_details?.type))
   const isChatNotification = !isUnlockContentMessage && !!type && !['voice_message', 'private_mass_message', 'multiple_attachment', 'mass_message'].includes(type) && !isTextMessage && !isLiveStreamNotification
   const isMassMessage = type === 'mass_message' && (!message?.unlock_details || (message?.unlock_details && !(['multiple_attachment'].includes(message?.unlock_details?.type))))


   useEffect(() => {
      if(isMobile) {
         document.addEventListener("touchstart", handleClickOutside);
      } else {
         document.addEventListener("mousedown", handleClickOutside);
      }
      return () => {
         if(isMobile) {
            document.removeEventListener("touchstart", handleClickOutside);
         } else {
            document.removeEventListener("mousedown", handleClickOutside);
         }
      };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);
   const refContainer = useRef(null);
   const [showDate, setShowDate] = useState();
   const [showTools, setShowTools] = useState(false);
   const [chatLikesModal, setChatLikesModal] = useState(false);
   const [openDeleteConfirmModal, setOpenDeleteConfirmModla] = useState(false);
   let  timeoutOprion = ''

   let rest = {  onClick: (e) => setShowDate(!showDate) };


   useEffect(() => {
      return function cancel() {
         // target.current = null
         // clearTimeout(timeout.current)
         clearTimeout(timeoutOprion)
         refContainer.current = null
      };

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   function chatLikesModalTogglehandler() {
      if(!chatLikesModal) {
         onFetchLikesInfo()
      }
      setChatLikesModal(!chatLikesModal)
   }

   function toolsTogglehandler() {
      if(!showTools && isUnlockContentMessage)  setShowDate(!showDate)
      setShowTools(!showTools)
   }


   function handleClickOutside(event) {
      if(refContainer.current && !refContainer.current.contains(event.target)) {
         setShowDate(false)
      }
      if(!isMobile &&  messageOptions && messageOptions.current && !messageOptions.current.contains(event.target)) {
         setShowTools(false)
      }
   }



   function onClickAction(action) {
      switch (action) {
         case 'reply':
            const { id, text, user, type, unsend_date, duration_in_minutes, show_duration_countdown_to_member } = message

            const replyMessage = {
               parentMessageId: id,
               text: text || '',
               user,
               type,
               parentMessageUnlockDetails: message?.unlock_details,
               parentUnsendDate: unsend_date,
               parentShowDurationCountdownToMember: !!Boolean(show_duration_countdown_to_member),
               parentDurationInMinutes: duration_in_minutes,
            }
            onReplyMessage(replyMessage)
            break;
         case 'edit':
            updateMessage({ messageId: message.id, text: (isPrivateMassMessage || isMassMessage) && message?.text.body ? message?.text.body : message.text, updateMessageType: message?.type ?? 'text_message' })
            break;
         case 'copy':
            copyText((isPrivateMassMessage || isMassMessage) && message?.text?.body ? message?.text?.body : message.text)
            toast.info('Message has been copied')
            break;
         case 'delete':
            setOpenDeleteConfirmModla(true)
            // onDeleteMessag()
            break;
         case 'download':
            downloadFile(message.id)
            break;
         default:
            break;
      }
      if('delete' !== action) {
         setShowTools(false)
      } else {
         timeoutOprion = setTimeout(() => {
            setShowTools(false)
         }, 20)
      }
   }

   const renderMessages = (text, className = '') =>  {
      /*start message phrase rendering, links new lines spaces*/
      const phrases_array = getPhrasesFromMessage(text);
      const final_messages = [];
      // eslint-disable-next-line no-useless-escape
      const link_find_pattern = /^(((http|https):\/\/|)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}(:[0-9]{1,5})?(\/.*)?)$/;
      // const link_find_pattern = /((?:(http|https|Http|Https|rtsp|Rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)+(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnrwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eouw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agkmsyz]|v[aceginu]|w[fs]|y[etu]|z[amw]))|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi;
      const linksStyle = {
         color: 'inherit',
         textDecoration: 'underline',
      };
      let phrases_key = 1;
      for(const item of phrases_array) {
         const { value, type } = item;

         let matches = null
         if(type === 'other' && (matches = value.match(link_find_pattern))) {
            let [link] = matches;
            const lowerCased = link.toLowerCase();
            let link_text = link;

            let link_right_chars, link_left_chars = '';
            link_left_chars = value.substring(0, value.indexOf(link));
            link_right_chars = value.substring(value.indexOf(link) + link.length, value.length);
            if(!lowerCased.includes('http')) {
               link = 'http://' + link;
            }
            if(link_left_chars) {
               final_messages.push(<span className={ `${ link_left_chars.length > 15 ? 'word-break' : 'word-normal' }` } key={ ++phrases_key }>{link_left_chars}</span>);
            }
            final_messages.push(
               <a key={ ++phrases_key } style={ linksStyle } target='_blank' href={ link } rel='noreferrer'>{link_text}</a>
            );
            if(link_right_chars) {
               final_messages.push(<span className={ `${ link_right_chars.length > 15 ? 'word-break' : 'word-normal' }` } key={ ++phrases_key }>{link_right_chars}</span>);
            }
         } else if(type === 'new_line') {
            final_messages.push(<br key={ ++phrases_key } />);
         } else if(type === 'space'){
            final_messages.push(!window.google_translte_language || 'en' === window.google_translte_language ? <ReactNbsp key={ ++phrases_key } /> : ' ');
         } else if(type === 'emoji') {
            final_messages.push(<span className='emoji-char' key={ ++phrases_key }>{value}</span>);
         } else {
            final_messages.push(<span className={ `${ value.length > 15 ? 'word-break' : 'word-normal' }` }
               key={ ++phrases_key }>{value}</span>);
         }
      }
      /*end message phrase rendering, links new lines spaces*/
      return (
         <p
            className={ cx(
               {
                  'chat-message-text': true,
                  'notranslate': !window.google_translte_language || 'en' === window.google_translte_language,
                  [className]: !!className,
               }
            ) }
         >
            {final_messages}
         </p>
      );
   }
   const renderedMessageText = useMemo(() => {
      if(!message.text || !((isMultipleAttachmentsMessage && message?.text) || ((isPrivateMassMessage || isMassMessage) && message?.text) || isTextMessage || isUnlockContentMessage)) return null

      return renderMessages(message.text)
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [message.text, message.text?.body])

   const getOnlyDownloadOption = () => {
      if(isAdmin){
         return false
      }
      if(message?.unlock_details?.allow_download && !isMultipleAttachmentsMessage){
         return message?.unlock_details?.is_unlocked || !message?.unlock_details?.unlock_price
      }
      return false
   }

   let generateTextColor = "major dark:text-major-dark";
   if(isMyMessage && !isUnlockContentMessage && !isMultipleAttachmentsMessage) {
      generateTextColor = GenerateColor.adaptColor(window.skinPrimaryColor);
   }

   if((isPrivateMassMessage || isMassMessage)) {
      generateTextColor = "major dark:text-major-dark";

      if(isMyMessage && !message?.unlock_details && message?.text) {
         generateTextColor = GenerateColor.adaptColor(window.skinPrimaryColor);
      }
   }

   if(isMyMessage && isMultipleAttachmentsMessage && !message?.unlock_details?.attachments?.length) {
      generateTextColor = 'major-dark dark:text-major-dark';
   }


   const getWaveformColor = () => {
      let waveColor = window.site_dark_bg ? '#e0e0e01f' : '#6670851f'
      let progressColor = window.site_dark_bg ? '#E0E0E0' : '#667085'
      return {
         waveColor,
         progressColor,
      }

   }

   const hasAccess = (content, index = 0) => {
      return   (
         (!!Number(content?.unlock_details?.resource_id) || (typeof content?.unlock_details?.resource_id === 'string' && content?.unlock_details?.resource_id.length === 32))
      && ['video', 'photoset'].includes(content?.unlock_details?.resource_type)
      && (
         (
            ['video', 'photoset'].includes(content?.unlock_details?.resource_type)
            && content?.unlock_details?.attachmentsStatuses
            && content?.unlock_details?.attachmentsStatuses?.[index]
            && 'status' in content?.unlock_details?.attachmentsStatuses?.[index]
            && content?.unlock_details?.attachmentsStatuses?.[index]?.status !== 'is_hidden_content'
            && (!!content?.unlock_details?.publish_date)
         )
         ||
         (((content?.unlock_details?.unlock_price === 0) || (content?.unlock_details?.is_unlocked && +content?.unlock_details?.unlock_price > 0)))
         ||
         (!!content.locked_image_is_blur && !!content.cover && !content?.publish_date)
      )
      )
   }

   const hideDetails = (message) => {
      return !isAdmin && (
         (!!message?.unlock_details?.is_unlocked && +message?.unlock_details?.unlock_price > 0)
         || (
            (+message?.unlock_details?.unlock_price === 0 && !message?.publish_date)
            && ['photo_vault', 'video_vault']?.includes(message?.unlock_details?.resource_type)
         )
         || message?.unlock_details?.is_unlocked_for_view
      )
   }

   const SERVER_DATE_FORMAT =  'YYYY-MM-DD HH:mm:ss' //'YYYY-MM-DDTHH:mm:ss.SSSSSS[Z]'

   const timeLeftToUnsend = DateFormatter.calculateTimeLeft(DateFormatter.convertToUtc((moment(Date.now()).add('minutes', message?.duration_in_minutes))))
   const parentTimeLeftToUnsend = DateFormatter.calculateTimeLeft(DateFormatter.convertToUtc((moment(Date.now()).add('minutes', parent?.duration_in_minutes))))

   const unsendDate = DateFormatter.calculateTimeLeft(DateFormatter.convertToUserTz((moment(message?.unsend_date, SERVER_DATE_FORMAT).local())))  // (String(message?.unsend_date)?.endsWith('Z')) ?  DateFormatter.calculateTimeLeft(DateFormatter.convertToUtc(message?.unsend_date)) :
   const parentUnsendDate = DateFormatter.calculateTimeLeft(DateFormatter.convertToUserTz((moment(parent?.unsend_date, SERVER_DATE_FORMAT).local()))) // (String(parent?.unsend_date)?.endsWith('Z')) ?  DateFormatter.calculateTimeLeft(DateFormatter.convertToUtc(parent?.unsend_date)) :

   const messageDurationText = `Message unsends in ${ unsendDate }`
   const parentMessageDurationText = `Message unsends in ${ parentUnsendDate }`

   const [countDown, setCountDown] = useState(0)
   const [parentCountDown, setParentCountDown] = useState(0)

   const durationTextWidth = (unsend_date, duration_in_minutes) => {

      let currentDate  = DateFormatter.convertToUtc((moment(Date.now()).add('minutes', duration_in_minutes)))

      let targetTime = DateFormatter.getDiff(currentDate)

      if(+targetTime?.days * -1 > 0) {
         return 'min-w-[235px]'
      }
      if(+targetTime?.hours * -1 > 0) {
         return 'min-w-[215px]'
      }
      if(+targetTime?.minutes * -1 > 0) {
         return 'min-w-[190px]'
      }
      return 'min-w-[160px]'
   }

   let unsendTimeout = null
   let parentUnsendTimeout = null

   const setMessageCountDown = () => {

      unsendTimeout = setTimeout(() => {

         if(unsendDate && message?.duration_in_minutes && (isAdmin || (!isAdmin && Boolean(message?.show_duration_countdown_to_member)))) {
            if(countDown < message?.duration_in_minutes * 60) {
               setCountDown(countDown + 1)
            }
         }

      }, 1000);
   }

   const setParentMessageCountDown = () => {

      parentUnsendTimeout = setTimeout(() => {

         if(parentUnsendDate && parent?.duration_in_minutes && (isAdmin || (!isAdmin && Boolean(parent?.show_duration_countdown_to_member)))) {
            if(parentCountDown < parent?.duration_in_minutes * 60) {
               setParentCountDown(parentCountDown + 1)
            }
         }

      }, 1000);
   }

   useEffect(() => {
      setMessageCountDown()

      return () => {}
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [countDown, unsendDate])

   useEffect(() => {
      setParentMessageCountDown()
      return () => {}
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [parentCountDown, parentUnsendDate])

   useEffect(() => {

      return () => {
         clearTimeout(unsendTimeout)
         clearTimeout(parentUnsendTimeout)
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])




   if(isTipMessage) {
      const tipMessage = message?.text;
      const tipAction = tipMessage?.tip_action
      return (
         <div
            ref={ refContainer }
            className={ cx({
               "h-auto w-auto flex message-item message-tip-item dpr-3 items-center justify-start": true,
               "mt-2": nextIsMyMessage,
               "mt-4": !nextIsMyMessage,
               // "justify-start": !isMyMessage,
               // "justify-end": isMyMessage,
               "": isMobile,
               "message-hover": !isMobile,
               "z-20": isUpdate,
               "ams-chat-last-message-item": lastElement,
            }) }
         >
            <div className='mr-3 divider skin-primary-background' />
            <div className='flex'>
               <div
                  className={ cx({
                     'w-7 h-7': true,
                     "cursor-pointer": onClickUserAvatar,
                     "mt-auto": messageText.current && messageText.current.offsetHeight > 32,
                     "mb-auto": parent && (parent.text || parent.type),
                     "my-auto": !messageText.current,
                     // "invisible": nextIsMyMessage,

                  }) }
                  role='presentation'
                  onClick={ onClickUserAvatar }
                  style={ { minWidth: '28px' } }>
                  {
                     Boolean(adminUuid) && adminUuid === user.uuid ? (
                        <img
                           src={ user.avatar_compressed_src || user.avatar }
                           alt=''
                           className='h-full w-full object-cover object-center rounded-full'
                        />
                     ) : (
                        <Avatar
                           avatarSrc={ user.avatar_compressed_src || user.avatar }
                           username={ isAdmin ? user?.nickname || user?.username || null : user?.username || null }
                           firstLetterClassName='text-sm'
                        />
                     )
                  }
               </div>
               <div
                  className='flex flex-col ml-3'
               >
                  <span className='text-major text-sm dark:text-major-dark'><span className='font-medium'>{isAdmin ? user.nickname || user.username : user.username}</span> {
                     tipAction ? (
                        `tipped for ${ tipAction.emoji }  ${ tipAction.name }`
                     ) : (
                        `sent a tip: ${ tipMessage?.price } USD `
                     )
                  }</span>
                  <span className='text-placeholder text-sm dark:text-placeholder-dark '> {tipMessage?.message} </span>
               </div>
            </div>
            <>

               {message.likes_count_from_other_users > 0 && (
                  <div
                     className={ cx({
                        "likes-rectangle bg-panel dark:bg-black-field flex items-center justify-between rounded-full absolute z-10 dark:border-divider-dark": true,
                        "cursor-pointer": message.likes_count_from_other_users > 1,
                        "": message.likes_count_from_other_users <= 1,
                        "likes-text-message-position": isTextMessage,
                        "likes-content-message-position": isUnlockContentMessage,
                        "likes-mass-message-position": !isUnlockContentMessage && !isTextMessage,
                     }) }
                     onClick={ isGroup ? () => chatLikesModalTogglehandler() : null }
                     role='presentation'
                  >
                     <div className='w-3 h-3 flex items-center justify-center '>
                        <Icon name='heart-filled' size='small' color='likes' darkColor='likes-dark' className='heart-icon' />
                     </div>
                     {
                        message.likes_count_from_other_users > 1 && (
                           <span className='text-xs leading-4 likes-count'>{ message.likes_count_from_other_users > 100 ? '99+' : message.likes_count_from_other_users}</span>
                        )
                     }
                  </div>
               )}

               {
                  chatLikesModal &&
                     <ChatLikesModal
                        newFetching={ likes.fetchingScroll }
                        etching={ likes.fetchin }
                        members={ likes.data || [] }
                        chatLikesModalTogglehandler={ () => setChatLikesModal(false) }
                        isMobile={ isMobile }
                        headerText={ `Likes • ${ message.likes_count_from_other_users } members` }
                        onScroll={ onLikedMemberPagination }
                     />
               }

            </>
         </div>
      )
   }
   return (
      <>
         {
            openDeleteConfirmModal && (
               <Modal
                  contentPosition={ screenWidth > 1024 ? 'center' : 'bottom' }
                  onClose={ () => setOpenDeleteConfirmModla(false) }
                  isCloseAction={ false }
                  contentWidth={ screenWidth > 1024 ? '375px' : '' }
               >
                  <ConfirmDialog
                     modalInfo={ {
                        title: 'Unsend message?',
                        description: 'Are you sure you want to unsend this message? This cannot be undone.',
                        actionText: 'Yes, unsend',
                        action: () =>  onDeleteMessag() }  }
                     closeConfirmDialog={ () => setOpenDeleteConfirmModla(false) }
                     iconName='delete'
                  />

               </Modal>
            )
         }
         <div
            style={ {
               // minHeight: '33px',
            } }
            ref={ refContainer }
            className={ cx({
               "h-auto w-auto flex-col message-item": true,
               "mt-2": nextIsMyMessage,
               // "mt-4": nextIsMyMessage && !!parent,
               "mt-4": !nextIsMyMessage,
               "justify-start": !isMyMessage,
               "justify-end": isMyMessage,
               "": isMobile,
               "message-hover": !isMobile,
               "z-20": isUpdate,
               "ams-chat-last-message-item": lastElement,
            }) }
            onMouseLeave={ () => {
            } }
         >
            {
               isGroup && !isMyMessage && (
                  <div
                     className={ cx({
                        [`${ onClickUserAvatar ? 'cursor-pointer' : '' } group-chat-username`]: true,
                        'hidden': nextIsMyMessage,
                        // 'hover:cursor-pointer': !!goToDetails,

                     }) }
                     role='presentation'
                     onClick={ () => {
                        !!onClickUserAvatar && onClickUserAvatar()
                        // !!goToDetails && goToDetails()
                     } }
                  >
                     <span className='text-secondary dark:text-secondary-dark notranslate'> {isAdmin ? user.nickname || user.username : user.username} </span>
                  </div>
               )
            }
            <div
               className={ `flex items-center ${
                  isMyMessage ? "flex-row-reverse" : ""
               }` }
            >
               {!isMyMessage && (
                  <div
                     className={ cx({
                        "w-7 h-7 mr-3 ": true,
                        "cursor-pointer": onClickUserAvatar,
                        "mt-auto": messageText.current && !isMyMessage && messageText.current.offsetHeight > 32,
                        "mb-auto": parent && (parent.text || parent.type),
                        "my-auto": !messageText.current,
                        "invisible": nextIsMyMessage,
                        "hover:cursor-pointer": !!goToDetails,

                     }) }
                     role='presentation'
                     onClick={ () => {
                        !!onClickUserAvatar && onClickUserAvatar()
                        !!goToDetails && goToDetails()
                     } }
                     style={ { minWidth: '28px' } }>
                     {
                        Boolean(adminUuid) && adminUuid === user.uuid ? (
                           <img
                              src={ user.avatar_compressed_src || user.avatar }
                              alt=''
                              className='h-full w-full object-cover object-center rounded-full'
                           />
                        ) : (
                           <Avatar
                              avatarSrc={ user.avatar_compressed_src || user.avatar }
                              username={ isAdmin ? user?.nickname || user?.username || null : user?.username || null }
                              firstLetterClassName='text-sm'
                           />
                        )
                     }
                  </div>
               )}
               <div
                  ref={ messageContent }
                  className={ cx({
                     'message-content select-all lg:select-auto': true,
                     'flex flex-col items-end': !!parent && isMyMessage,
                  }) }
               >
                  {
                     parent &&
                     <div className={ cx({
                        'flex': true,
                        'mt-2': nextIsMyMessage,
                        'justify-end': isMyMessage,
                     }) }>
                        <div className='flex gap-1 w-fit items-center'>
                           <Icon
                              name='reply'
                              color='secondary'
                           />
                           <TruncateText
                              className='flex text-secondary dark:text-secondary-dark !cursor-default'
                              text={
                                 `${ isMyMessage ? 'You' : isAdmin ? user.nickname || user.username : user.username }
                                 replied to
                                 ${ parent.user ? isAdmin && message?.user?.uuid !== authUser?.uuid && !parent?.user?.isAdmin ?
         'their own message'
         : (isAdmin ? authUser.screen_name ===  parent.user.username ?
            'your message' : (parent.user.nickname || parent.user.username)
            : authUser?.uuid === message?.user?.uuid && parent?.user?.username === message?.user?.username
               ? 'your message'
               :
               parent.user.username) : '' }`
                              }
                              textClass='text-xs font-medium'
                              textSize='12px'
                              fontWeight='500'
                              width={ isMiniChat ? '200px' : isMobile ? '200px' : '300px' }
                           />
                        </div>
                     </div>
                  }

                  <div
                     className={ cx({
                        'bg-grey-main dark:bg-black-field rounded-[20px] mt-1 w-fit ': parent,
                        "flex flex-col items-end": !!isMyMessage && parent,
                     }) }
                  >
                     {
                        parent && (
                           <div
                              className={ cx({
                                 'reply-message-text flex flex-col text-end dark:border-divider-dark border-divider w-fit': true,
                                 'max-w-[282px]': !!isMiniChat && screenWidth < 700,
                              }) }
                           >
                              <div className={ cx({
                                 'text-secondary py-2.5 pb-2 px-3': true,
                                 '!px-1': 'voice_message' === parent.type,
                              }) }>
                                 <p
                                    style={ {
                                       whiteSpace: 'pre-line',
                                       maxWidth: isMiniChat ? '230px' : parent?.type === 'text_message' ? "282px" : '326px',
                                    } }
                                    className={ cx({
                                       // 'max-w-[250px]': true,
                                       "select-text": !isMobile,
                                       'flex justify-start': !isMyMessage,
                                       // 'flex flex-col gap-2 mb-2': parentUnsendDate && ['voice_message', 'text_message'].includes(parent.type) && parent?.unsend_date && (isAdmin || (!isAdmin && Boolean(parent?.show_duration_countdown_to_member))),
                                       // 'pl-2': parentUnsendDate && ['voice_message'].includes(parent.type) && parent?.unsend_date && (isAdmin || (!isAdmin && Boolean(parent?.show_duration_countdown_to_member))),
                                    }) }
                                 >
                                    {
                                       parent.text && !['multiple_attachment', 'private_mass_message', 'mass_message', 'voice_message']?.includes(parent.type) &&
                                          renderMessages(parent.text, 'text-xs text-placeholder dark:text-placeholder-dark leading-4')
                                    }
                                 </p>
                                 {
                                    parent?.unlock_details &&
                                    <>
                                       {
                                          'voice_message' !== parent.type ?
                                             <>
                                                {
                                                   !['multiple_attachment']?.includes(parent?.unlock_details?.type) &&
                                                      <UnlockContentMessage
                                                         restFunction={ { ...rest } }
                                                         isAdmin={ isAdmin }
                                                         type={ parent?.type }
                                                         data={ parent?.unlock_details }
                                                         onStream={ () => onStream(true) }
                                                         closeChat={ ()  => {
                                                            if(!!isMobile) {
                                                               closeChatPopup()
                                                            }
                                                         } }
                                                         isMobile={ isMobile }
                                                         paymentButtonLoading={ paymentButtonLoading }
                                                         adminMessage={ adminUuid === user.uuid }
                                                         styles={ {
                                                            marginTop: parent.text ? '8px' : '0',
                                                            width: 'auto',
                                                            minWidth: isMiniChat || screenWidth < 425 ? '200px' : '250px',
                                                            maxWidth: '400px',
                                                         } }
                                                         contentLink={ () => {
                                                            if(((!isAdmin && (hasAccess(parent) || hideDetails(parent)))) || isAdmin) {
                                                               return getContentLink(parent?.unlock_details, parent?.unlock_details?.attachmentsStatuses?.[0]?.status, parent)
                                                            }
                                                            return null
                                                         } }
                                                         message={ parent }
                                                         isMyMessage={ isMyMessage }
                                                      />
                                                }
                                                {
                                                   ['multiple_attachment', 'private_mass_message', 'mass_message']?.includes(parent.type) &&
                                                   <div className={ cx({
                                                      'flex flex-col bg-action-12 dark:bg-action-dark-12 p-3 rounded-[20px]': true,
                                                      '!bg-transparent !p-0': !parent?.unlock_details?.attachments?.length,
                                                   }) }>
                                                      {
                                                         ((!parentCountDown && !!parent?.duration_in_minutes && !parent?.unsend_date && parent?.unlock_details?.attachments?.length) || (parentUnsendDate && parent?.unlock_details?.attachments?.length && parent?.unsend_date)) && (isAdmin || (!isAdmin && Boolean(parent?.show_duration_countdown_to_member))) && <>
                                                            <div className='flex items-center gap-1 mb-2'>
                                                               <Icon
                                                                  name={ `duration` }
                                                                  color={ `secondary` }
                                                                  darkColor={ `secondary-dark` }
                                                               />
                                                               <p className={ cx({
                                                                  'text-secondary dark:text-secondary-dark text-xs leading-[15px]': true,
                                                                  [`${ durationTextWidth(parent?.unsend_date, parent?.duration_in_minutes) } panel-description text-left`]: true,
                                                               }) }>
                                                                  {
                                                                     !!(!parentCountDown && !!parent?.duration_in_minutes && !parent?.unsend_date) && parentTimeLeftToUnsend
                                                                  }
                                                                  {
                                                                     !(!parentCountDown && !!parent?.duration_in_minutes && !parent?.unsend_date) && parentMessageDurationText
                                                                  }
                                                               </p>
                                                            </div>
                                                         </>
                                                      }
                                                      {
                                                         (isAdmin && !parentCountDown && !!parent?.duration_in_minutes && !parent?.unsend_date && parent?.unlock_details?.attachments?.length) && (isAdmin || (!isAdmin && Boolean(parent?.show_duration_countdown_to_member))) && <>
                                                            <div className='flex items-center gap-1 pb-2'>
                                                               <p className={ cx({
                                                                  'text-placeholder dark:text-placeholder-dark text-xs leading-[15px]': true,
                                                               }) }>
                                                                  Countdown starts when seen
                                                               </p>
                                                            </div>
                                                         </>
                                                      }
                                                      {
                                                         parent?.text && typeof parent?.text === 'string' ?
                                                            <div
                                                               role='presentation'
                                                               style={ {
                                                                  maxWidth: "242px",
                                                                  borderRadius: "20px",
                                                               } }
                                                               className={ cx({
                                                                  "flex relative w-fit": true,
                                                                  "p-2": !!window.site_dark_bg && !isAdmin,
                                                                  "mb-2": !isAdmin && !!parent?.unlock_details?.attachments?.length,
                                                                  'pb-2': !!parent?.unlock_details?.attachments?.length,
                                                               }) }
                                                               { ...rest }
                                                            >
                                                               <div
                                                                  style={ {
                                                                     whiteSpace: 'pre-line',
                                                                  } }
                                                                  className={ cx({
                                                                     [`leading-tight max-w-full text-sm text-${ generateTextColor }`]: true,
                                                                     "select-text": !isMobile,
                                                                  }) }
                                                               >
                                                                  {renderMessages(parent.text, 'text-xs text-placeholder dark:text-placeholder-dark leading-4')}
                                                               </div>

                                                            </div>
                                                            :
                                                            !parent?.text?.body && !parent?.text?.subject ?
                                                               <></>
                                                               :
                                                               <div className={ cx({
                                                                  'unlocking-content-header max-w-[300px] mx-1 mb-4': true,
                                                                  'text-left': !isMyMessage,
                                                               }) }>
                                                                  <span role='img' className='block text-major text-xl leading-tight mb-1 dark:text-major-dark' aria-label='' > { parent?.text?.subject } </span>
                                                                  { parent?.text?.body && <NotificationMessageBody body={ parent?.text?.body } />}
                                                               </div>
                                                      }
                                                      {
                                                         !isGroup && (parent.unlock_details.unlock_price > 0 || !!parent?.unlock_details?.allow_download) && !!parent?.unlock_details?.attachments?.length &&
                                                         <div
                                                            className={ cx({
                                                               "w-full flex flex-wrap justify-between items-center pb-2 gap-2": true,
                                                               // "mt-2": !isAdmin,
                                                               "!m-0 !p-0": !isAdmin && !parent?.unlock_details?.allow_download,
                                                               // "flex-col items-start": parent?.unlock_details && isMiniChat,
                                                            }) }
                                                         >
                                                            {
                                                               parent.unlock_details.unlock_price > 0 && isAdmin &&
                                                                  <div className='flex gap-1'>
                                                                     <Icon
                                                                        name={ parent?.unlock_details?.is_unlocked ? `unlocked-new` : `lock` }
                                                                        color='secondary'
                                                                        darkColor='secondary-dark'
                                                                     />

                                                                     <p className='text-xs text-secondary dark:text-secondary-dark cursor-default'>
                                                                        {
                                                                           parent?.unlock_details?.is_unlocked ? `Unlocked for ${ parent?.unlock_details?.unlock_price } USD` : `${ parent?.unlock_details?.unlock_price } USD to unlock`
                                                                        }
                                                                     </p>
                                                                  </div>
                                                            }
                                                            {
                                                               parent?.unlock_details?.allow_download &&
                                                               <div className='flex gap-1 cursor-default'>
                                                                  <Icon
                                                                     name={ `download` }
                                                                     color='secondary'
                                                                     darkColor='secondary-dark'
                                                                  />
                                                                  <p className='text-xs text-secondary dark:text-secondary-dark'>
                                                                     Download allowed
                                                                  </p>
                                                               </div>
                                                            }
                                                         </div>
                                                      }
                                                      {
                                                         !!parent?.unlock_details?.attachments?.length &&
                                                         <MultipleAttachmentMessage
                                                            restFunction={ { ...rest } }
                                                            isAdmin={ isAdmin }
                                                            contentLink={ () => {
                                                               if(isAdmin) return AdminRouter.route('CHAT_GET_CONTENT').getCompiledPath({ id: parent.id })
                                                               return SiteRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: parent.id })
                                                            } }
                                                            type={ parent.type }
                                                            data={ parent.unlock_details }
                                                            onStream={ () => onStream(true) }
                                                            closeChat={ ()  => {
                                                               if(!!isMobile) {
                                                                  closeChatPopup()
                                                               }
                                                            } }
                                                            isMobile={ isMobile }
                                                            paymentButtonLoading={ paymentButtonLoading }
                                                            adminMessage={ adminUuid === user.uuid }
                                                            attachments={ parent.unlock_details.attachments }
                                                            isMiniChat={ isMiniChat }
                                                            onClickAttachment={ !!onClickAttachment ? (e) => {
                                                               e.stopPropagation()
                                                               onClickAttachment(parent)

                                                            } : null }
                                                            onRemoveMedia={ (content, index) => {
                                                               if(parent?.unlock_details?.attachments?.length === 1 && index === 0) {
                                                                  onClickAction('delete')
                                                               } else {
                                                                  onRemoveMedia(content, index, parent)
                                                               }
                                                            } }
                                                            isGroup={ isGroup }
                                                            isParent
                                                         />
                                                      }

                                                   </div>
                                                }
                                             </>
                                             :
                                             <div
                                                ref={ refContainer }
                                                style={ {
                                                   maxWidth: '266px',
                                                   borderRadius: "20px",
                                                } }
                                                className={ cx({
                                                   "flex relative px-3 h-10 !w-fit": true,
                                                   "bg-[#F3F4F5] dark:bg-panel-dark": !isMyMessage,
                                                   "bg-[#E5E4FF] dark:bg-[#616161]": isMyMessage,
                                                }) }
                                             >
                                                {
                                                   !isAdmin && Boolean(parent?.unlock_details?.unlock_price) && !parent?.unlock_details?.is_unlocked &&
                                                   <WaveformUnlockContent
                                                      price={ parent.unlock_details.unlock_price  || 10 }
                                                      onClickUnlockButton={ () => onStream(true)  }
                                                   />
                                                }
                                                {
                                                   (isAdmin || (parent?.unlock_details?.src  && (!(Boolean(parent?.unlock_details?.unlock_price) && !parent?.unlock_details?.is_unlocked)))) &&
                                                   <Waveform
                                                      type='mp3'
                                                      duration={ parent?.unlock_details?.audio_duration || 0 }
                                                      url={ parent?.unlock_details?.src }
                                                      height={ 24 }
                                                      colorState={ getWaveformColor() }
                                                      isUnlocked={ parent?.unlock_details?.is_unlocked }
                                                      price={ parent?.unlock_details?.unlock_price }
                                                      isAdmin={ isAdmin }
                                                   />
                                                }
                                             </div>
                                       }

                                    </>
                                 }
                                 {
                                    ((!parentCountDown && !!parent?.duration_in_minutes && !parent?.unsend_date) || (parentUnsendDate && parent?.unsend_date)) && ['voice_message', 'text_message'].includes(parent.type)  && (isAdmin || (!isAdmin && Boolean(parent?.show_duration_countdown_to_member))) &&  <>
                                       <div className='flex items-center gap-1 my-2'>
                                          <Icon
                                             name={ `duration` }
                                             color={ `secondary` }
                                             darkColor={ `secondary-dark` }
                                          />
                                          <p className={ cx({
                                             // 'text-major-dark text-xs leading-[15px]': isMyMessage,
                                             'text-secondary dark:text-secondary-dark text-xs leading-[15px] text-left': true, // !isMyMessage,
                                             [`${ durationTextWidth(parent?.unsend_date, parent?.duration_in_minutes) } panel-description`]: true,
                                          }) }>
                                             {
                                                !!(!parentCountDown && !!parent?.duration_in_minutes && !parent?.unsend_date) && parentTimeLeftToUnsend
                                             }
                                             {
                                                !(!parentCountDown && !!parent?.duration_in_minutes && !parent?.unsend_date) && parentMessageDurationText
                                             }
                                          </p>
                                       </div>
                                    </>
                                 }
                                 {
                                    (isAdmin && !parentCountDown && !!parent?.duration_in_minutes && !parent?.unsend_date)  && ['voice_message', 'text_message'].includes(parent.type) && (isAdmin || (!isAdmin && Boolean(parent?.show_duration_countdown_to_member))) && <>
                                       <div className='flex items-center gap-1 pb-2'>
                                          <p className={ cx({
                                             'text-placeholder dark:text-placeholder-dark text-xs leading-[15px]': true,
                                          }) }>
                                             Countdown starts when seen
                                          </p>
                                       </div>
                                    </>
                                 }
                              </div>
                           </div>

                        )
                     }
                     <div
                        ref={ messageText }
                        className={ cx({
                           "flex relative": true,
                           "justify-end": !!isMyMessage,
                           "justify-start": !isMyMessage,
                           "flex-col-reverse rounded-[20px] pt-1 pb-3 px-4": message.text &&  isUnlockContentMessage,
                           "bg-grey-main dark:bg-black-field !w-fit": !isMyMessage && isUnlockContentMessage,
                           "bg-hover dark:bg-hover-dark": isMyMessage && isUnlockContentMessage,
                           " flex-col-reverse rounded-[20px] pt-1 pb-3 px-4": message.text && (isPrivateMassMessage || isMassMessage) && message?.unlock_details,
                           " bg-grey-main dark:bg-black-field": !isMyMessage && (isPrivateMassMessage || isMassMessage) && message?.unlock_details,
                           " bg-hover dark:bg-hover-dark": isMyMessage && (isPrivateMassMessage || isMassMessage) && message?.unlock_details,
                           'bg-panel dark:bg-panel-dark': !parent && !message.text &&  isUnlockContentMessage,
                           'bg-grey-main dark:!bg-black-field rounded-b-[20px] pt-1 pb-3 px-4': parent && !message.text &&  isUnlockContentMessage,
                        }) }
                     >
                        {
                           (isPrivateMassMessage || isMassMessage) &&
                           <>
                              {
                                 message?.unlock_details &&
                                 <UnlockContentMessage
                                    restFunction={ { ...rest } }
                                    isAdmin={ isAdmin }
                                    type={ message.type }
                                    data={ message.unlock_details }
                                    onStream={ () => onStream(false) }
                                    closeChat={ ()  => {
                                       if(!!isMobile) {
                                          closeChatPopup()
                                       }
                                    } }
                                    isMobile={ isMobile }
                                    paymentButtonLoading={ paymentButtonLoading }
                                    adminMessage={ adminUuid === user.uuid }
                                    contentLink={ () => {
                                       if((!isAdmin && (hasAccess(message) || hideDetails(message))) || isAdmin) {
                                          return getContentLink(message?.unlock_details, message?.unlock_details?.attachmentsStatuses?.[0]?.status, message)
                                       }
                                       return null
                                    } }
                                 />
                              }
                              {
                                 message?.text && typeof message?.text !== 'string' &&
                                 <>
                                    <div
                                       className={ cx({
                                          "unlocking-content-header max-w-[300px]": true,
                                          "mx-1 mt-2 mb-4": message?.unlock_details,
                                          "border border-divider dark:border-divider-dark rounded-lg px-3 py-4": !message?.unlock_details,
                                       }) }
                                    >
                                       <span role='img' className='block text-major text-xl leading-tight mb-1 dark:text-major-dark' aria-label='' > { message?.text?.subject } </span>
                                       { message?.text?.body && <NotificationMessageBody body={ message?.text?.body } />}
                                    </div>
                                 </>
                              }
                              {
                              // !message?.unlock_details &&
                                 message?.text && typeof message?.text === 'string' &&
                                 <div
                                    role='presentation'
                                    style={ {
                                    // wordBreak: "break-word",
                                       maxWidth: isMiniChat ? '230px' : '282px',
                                       borderRadius: "20px",
                                    } }
                                    className={ cx({
                                       "flex relative py-2.5": true,
                                       "bg-grey-main dark:bg-black-field": !isMyMessage,
                                       "skin-primary-background": isMyMessage && !message?.unlock_details,
                                       "px-3": !isUnlockContentMessage && !message?.unlock_details,
                                    }) }
                                    { ...rest }
                                 >
                                    <div
                                       style={ {
                                          whiteSpace: 'pre-line',
                                       } }
                                       className={ cx({
                                          [`leading-tight max-w-full text-sm text-${ generateTextColor }`]: true,
                                          "select-text": !isMobile,
                                       }) }
                                    >
                                       {renderedMessageText}
                                    </div>

                                 </div>
                              }

                           </>
                        }
                        {
                           isMultipleAttachmentsMessage &&
                              <div className={ cx({
                                 'flex flex-col bg-action-12 dark:bg-action-dark-12 p-3 rounded-[20px]': true,
                                 'skin-primary-background': isMyMessage && !message?.unlock_details?.attachments?.length,
                                 'bg-grey-main dark:bg-black-field': !isMyMessage && !message?.unlock_details?.attachments?.length,
                              })
                              }>
                                 {
                                    ((!countDown && !!message?.duration_in_minutes && !message?.unsend_date) || (unsendDate && message?.unsend_date)) && (isAdmin || (!isAdmin && Boolean(message?.show_duration_countdown_to_member))) && <>
                                       <div className='flex items-center gap-1 mb-2'>
                                          <Icon
                                             name={ `duration` }
                                             color={ `secondary` }
                                             darkColor={ `secondary-dark` }
                                          />
                                          <p className={ cx({
                                             'text-secondary dark:text-secondary-dark text-xs leading-[15px]': true,
                                             [`${ durationTextWidth(message?.unsend_date, message?.duration_in_minutes) } panel-description`]: true,
                                          }) }>
                                             {
                                                !!(!countDown && !!message?.duration_in_minutes && !message?.unsend_date) && timeLeftToUnsend
                                             }
                                             {
                                                !(!countDown && !!message?.duration_in_minutes && !message?.unsend_date) && messageDurationText
                                             }
                                          </p>
                                       </div>
                                    </>
                                 }
                                 {
                                    isAdmin && !countDown && !!message?.duration_in_minutes && !message?.unsend_date && (isAdmin || (!isAdmin && Boolean(message?.show_duration_countdown_to_member))) && <>
                                       <div className='flex items-center gap-1 pb-2'>
                                          <p className={ cx({
                                             'text-placeholder dark:text-placeholder-dark text-xs leading-[15px]': true,
                                          }) }>
                                             Countdown starts when seen
                                          </p>
                                       </div>
                                    </>
                                 }
                                 {
                                    message?.text && typeof message?.text !== 'string' &&
                                       <>
                                          <div className='unlocking-content-header max-w-[300px] mx-1 mt-2 mb-4'>
                                             <span role='img' className='block text-major text-xl leading-tight mb-1 dark:text-major-dark' aria-label='' > { message?.text?.subject } </span>
                                             { message?.text?.body && <NotificationMessageBody body={ message?.text?.body } />}
                                          </div>
                                       </>
                                 }
                                 {
                                 // !message?.unlock_details &&
                                    message?.text && typeof message?.text === 'string' &&
                                    <div
                                       role='presentation'
                                       style={ {
                                       // wordBreak: "break-word",
                                          maxWidth: "242px",
                                          borderRadius: "20px",
                                       } }
                                       className={ cx({
                                          "flex relative": true,
                                          "p-2": !!window.site_dark_bg && !isAdmin,
                                          // "bg-grey-main dark:bg-black-field": !isMyMessage,
                                          "mb-2": !isAdmin && !!message?.unlock_details?.attachments?.length,
                                          'pb-2': !!message?.unlock_details?.attachments?.length,
                                       // "skin-primary-background": isMyMessage && !message?.unlock_details,
                                       // "px-3": !isUnlockContentMessage && !message?.unlock_details,
                                       }) }
                                       { ...rest }
                                    >
                                       <div
                                          style={ {
                                             whiteSpace: 'pre-line',
                                          } }
                                          className={ cx({
                                             [`leading-tight max-w-full text-sm text-${ generateTextColor }`]: true,
                                             "select-text": !isMobile,
                                          }) }
                                       >
                                          {renderedMessageText}

                                       </div>

                                    </div>
                                 }
                                 {
                                    !isGroup && (message.unlock_details.unlock_price > 0 || !!message?.unlock_details?.allow_download) && !!message?.unlock_details?.attachments?.length &&
                                    <div
                                       className={ cx({
                                          "w-full flex flex-col pb-2 gap-2": true,
                                          // "mt-2": !isAdmin,
                                          "!m-0 !p-0": !isAdmin && !message?.unlock_details?.allow_download,
                                       }) }
                                    >
                                       {
                                          message.unlock_details.unlock_price > 0 && isAdmin &&
                                             <div className='flex gap-1 cursor-default'>
                                                <Icon
                                                   name={ message?.unlock_details?.is_unlocked ? `unlocked-new` : `lock` }
                                                   color='secondary'
                                                   darkColor='secondary-dark'
                                                />

                                                <p className='text-xs text-secondary dark:text-secondary-dark'>
                                                   {
                                                      message?.unlock_details?.is_unlocked ? `Unlocked for ${ message?.unlock_details?.unlock_price } USD` : `${ message?.unlock_details?.unlock_price } USD to unlock`
                                                   }
                                                </p>
                                             </div>
                                       }
                                       {
                                          message?.unlock_details?.allow_download &&
                                          <div className='flex gap-1 cursor-default'>
                                             <Icon
                                                name={ `download` }
                                                color='secondary'
                                                darkColor='secondary-dark'
                                             />

                                             <p className='text-xs text-secondary dark:text-secondary-dark'>
                                                Download allowed
                                             </p>
                                          </div>
                                       }
                                       {
                                          message.unlock_details.unlock_price > 0 && message?.unlock_details?.locked_poster_is_blur && isAdmin &&
                                          <div className='flex gap-1 cursor-default'>
                                             <Icon
                                                name='blur'
                                                color='secondary'
                                                darkColor='secondary-dark'
                                             />
                                             <p className='text-xs text-secondary dark:text-secondary-dark'>
                                                Blurred
                                             </p>
                                          </div>
                                       }
                                    </div>
                                 }
                                 {
                                    message?.unlock_details &&
                                    <MultipleAttachmentMessage
                                       restFunction={ { ...rest } }
                                       isAdmin={ isAdmin }
                                       contentLink={ () => {
                                          if(isAdmin) return AdminRouter.route('CHAT_GET_CONTENT').getCompiledPath({ id: message.id })
                                          return SiteRouter.route('CHAT_UNLCOKED_CONTENT').getCompiledPath({ id: message.id })
                                       } }
                                       type={ message?.unlock_details?.type || message.type }
                                       data={ message.unlock_details }
                                       onStream={ onStream }
                                       closeChat={ ()  => {
                                          if(!!isMobile) {
                                             closeChatPopup()
                                          }
                                       } }
                                       isMobile={ isMobile }
                                       paymentButtonLoading={ paymentButtonLoading }
                                       adminMessage={ adminUuid === user.uuid }
                                       attachments={ message.unlock_details.attachments }
                                       isMiniChat={ isMiniChat }
                                       onClickAttachment={ !!onClickAttachment ? (e) => {
                                          e.stopPropagation()
                                          onClickAttachment(message)

                                       } : null }
                                       onRemoveMedia={ (content, index) => {
                                          if(message?.unlock_details?.attachments?.length === 1 && index === 0) {
                                             onClickAction('delete')
                                          } else {
                                             onRemoveMedia(content, index, message)
                                          }
                                       } }
                                       isGroup={ isGroup }
                                    />
                                 }

                              </div>
                        }
                        {
                           'voice_message' === message.type && (
                              <div className={ cx({
                                 'flex flex-col py-[10px] px-3 bg-grey-main dark:bg-black-field rounded-[20px]': ((!countDown && !!message?.duration_in_minutes && !message?.unsend_date) || (unsendDate && message?.unsend_date)) && (isAdmin || (!isAdmin && Boolean(message?.show_duration_countdown_to_member))),
                              }) }>
                                 <div
                                    ref={ refContainer }
                                    style={ {
                                       maxWidth: '266px',
                                       borderRadius: "20px",
                                    } }
                                    className={ cx({
                                       "flex relative px-3 h-10": true,
                                       "bg-[#F3F4F5] dark:bg-black-field": !isMyMessage,
                                       "bg-[#E5E4FF] dark:bg-[#616161]": isMyMessage,
                                       "dark:bg-panel-dark": !isMyMessage && !!parent,
                                       // 'flex flex-col h-18': unsendDate && message?.unsend_date && (isAdmin || (!isAdmin && Boolean(message?.show_duration_countdown_to_member))),
                                    }) }
                                 >
                                    {
                                       !isMyMessage && (Boolean(message?.unlock_details?.unlock_price) && !message?.unlock_details?.is_unlocked) && (
                                          <WaveformUnlockContent
                                             price={ message.unlock_details.unlock_price  || 10 }
                                             onClickUnlockButton={ () => onStream(false) }
                                          />
                                       )
                                    }
                                    {

                                       message?.unlock_details?.src  && (!(Boolean(message?.unlock_details?.unlock_price) && !message?.unlock_details?.is_unlocked) || isMyMessage) && (
                                          <Waveform
                                             type='mp3'
                                             duration={ message?.unlock_details?.audio_duration || 0 }
                                             url={ message?.unlock_details?.src }
                                             height={ 24 }
                                             colorState={ getWaveformColor() }
                                             isUnlocked={ message?.unlock_details?.is_unlocked }
                                             price={ message?.unlock_details?.unlock_price }
                                             isAdmin={ isAdmin }
                                          />
                                       )
                                    }
                                 </div>
                                 {
                                    ((!countDown && !!message?.duration_in_minutes && !message?.unsend_date) || (unsendDate && message?.unsend_date)) && (isAdmin || (!isAdmin && Boolean(message?.show_duration_countdown_to_member))) && <>
                                       <div className='flex items-center gap-1 pt-2'>
                                          <Icon
                                             name={ `duration` }
                                             color={ `secondary` }
                                             darkColor={ `secondary-dark` }
                                          />
                                          <p className={ cx({
                                             'text-secondary dark:text-secondary-dark text-xs leading-[15px]': true,
                                             [`${ durationTextWidth(message?.unsend_date, message?.duration_in_minutes) } panel-description`]: true,
                                          }) }>
                                             {
                                                !!(!countDown && !!message?.duration_in_minutes && !message?.unsend_date) && timeLeftToUnsend
                                             }
                                             {
                                                !(!countDown && !!message?.duration_in_minutes && !message?.unsend_date) && messageDurationText
                                             }
                                          </p>
                                       </div>
                                    </>
                                 }
                                 {
                                    isAdmin && !countDown && !!message?.duration_in_minutes && !message?.unsend_date && (isAdmin || (!isAdmin && Boolean(message?.show_duration_countdown_to_member))) && <>
                                       <div className='flex items-center gap-1 pt-2'>
                                          <p className={ cx({
                                             'text-placeholder dark:text-placeholder-dark text-xs leading-[15px]': true,
                                          }) }>
                                             Countdown starts when seen
                                          </p>
                                       </div>
                                    </>
                                 }
                              </div>
                           )
                        }
                        {
                           !isMultipleAttachmentsMessage && isChatNotification && (
                              <NotificationMessage
                                 type={ message.type }
                                 data={ message.promo_resource ? message.promo_resource : {} }
                                 subject={ message.text?.subject }
                                 body={ message.text?.body }
                                 isAdmin={ isAdmin }
                                 closeChat={ closeChatPopup }
                                 onClickUnlockButton={ () => onStream(false) }
                                 unlockDetails={ message.unlock_details }
                                 payload={ message.payload || {} }
                                 messageId={ message.id }
                                 onStream={ () => onStream(false) }
                                 sentAt={ message.sent_at }
                                 goToJoin={ goToJoin }
                                 isPromotionExpired={ message?.promotion_expired }
                                 onClickNotificationMessageButton={ onClickNotificationMessageButton }
                              />
                           )
                        }
                        {
                           !isMultipleAttachmentsMessage && isUnlockContentMessage && !!message.unlock_details && !['live_stream', 'voice_message', 'private_mass_message', 'multiple_atachment'].includes(message.type) && (
                              <UnlockContentMessage
                                 restFunction={ { ...rest } }
                                 isAdmin={ isAdmin }
                                 type={ message.type }
                                 data={ message.unlock_details }
                                 onStream={ () => onStream(false) }
                                 closeChat={ ()  => {
                                    if(!!isMobile) {
                                       closeChatPopup()
                                    }
                                 } }
                                 isMobile={ isMobile }
                                 paymentButtonLoading={ paymentButtonLoading }
                                 adminMessage={ adminUuid === user.uuid }
                                 styles={ {
                                    width: 'auto',
                                    minWidth: isMiniChat || screenWidth < 425 ? '200px' : '250px',
                                 } }
                                 contentLink={ () => {
                                    if((!isAdmin && (hasAccess(message) || hideDetails(message))) || isAdmin) {
                                       return getContentLink(message?.unlock_details, message?.unlock_details?.attachmentsStatuses?.[0]?.status, message)
                                    }
                                    return null
                                 } }
                                 message={ message }
                                 isMyMessage={ isMyMessage }
                                 countDown={ countDown }
                                 parentCountDown={ parentCountDown }
                              />
                           )
                        }
                        {
                           isLiveStreamNotification &&
                              <LiveStreamNotification
                                 paymentButtonLoading={ paymentButtonLoading }
                                 type={ message.type }
                                 data={ message.unlock_details ? message.unlock_details : {} }
                                 subject={ message.text?.subject }
                                 body={ message.text?.body }
                                 isAdmin={ isAdmin }
                                 messageId={ message.id }
                                 onStream={ () => onStream(false) }
                                 contentLink={ isAdmin ? AdminRouter.route('LIVE_STREAM_STUDIO').getCompiledPath() : SiteRouter.route('LIVE_STREAM_STUDIO').getCompiledPath() }
                                 membershipLinks={ SiteRouter.route('JOIN').getCompiledPath() }
                                 isMiniChat={ isMiniChat }
                                 currentLiveStream={ currentLiveStream }
                                 message={ message }
                              />
                        }
                        {
                           !isMultipleAttachmentsMessage && !(isPrivateMassMessage || isMassMessage) && message.text && (isTextMessage || isUnlockContentMessage) && (
                              <div
                                 role='presentation'
                                 style={ {
                                 // wordBreak: "break-word",
                                    maxWidth: isMiniChat ? '230px' : isTextMessage ? "282px" : '326px',
                                    borderRadius: "20px",
                                 } }
                                 className={ cx({
                                    "flex relative  py-2.5": true,
                                    "bg-grey-main dark:bg-black-field": !isMyMessage && isTextMessage,
                                    "skin-primary-background": isMyMessage && isTextMessage,
                                    "px-3": !isUnlockContentMessage,
                                    'flex-col gap-2': unsendDate && message?.unsend_date,
                                 }) }
                                 { ...rest }
                              >
                                 {
                                    message.text && (isTextMessage || isUnlockContentMessage) && (
                                       <div
                                          style={ {
                                             whiteSpace: 'pre-line',
                                          } }
                                          className={ cx({
                                             [`leading-tight max-w-full text-base text-${ generateTextColor }`]: true,
                                             "select-text": !isMobile,
                                          }) }
                                       >
                                          {renderedMessageText}
                                       </div>
                                    )
                                 }
                                 {
                                    unsendDate && ['text_message'].includes(message?.type) && message?.unsend_date && (isAdmin || (!isAdmin && Boolean(message?.show_duration_countdown_to_member))) && <>
                                       <div className='flex items-center gap-1'>
                                          <Icon
                                             name={ `duration` }
                                             color={ isMyMessage ? `major-dark` : `secondary` }
                                             darkColor={ isMyMessage ? `major-dark` : `secondary-dark` }
                                          />
                                          <p className={ cx({
                                             'text-major-dark text-xs leading-[15px]': isMyMessage,
                                             'text-secondary dark:text-secondary-dark text-xs leading-[15px]': !isMyMessage,
                                             [`${ durationTextWidth(message?.unsend_date, message?.duration_in_minutes) } panel-description`]: true,
                                          }) }>
                                             {messageDurationText}
                                          </p>
                                       </div>
                                    </>
                                 }
                              </div>
                           )
                        }
                        <>

                           {isMyMessage && message.likes_count_from_other_users > 0 && (
                              <div
                                 className={ cx({
                                    "likes-rectangle bg-panel dark:bg-black-field flex items-center justify-between rounded-full absolute z-10 dark:border-divider-dark": true,
                                    "cursor-pointer": message.likes_count_from_other_users > 1,
                                    "": message.likes_count_from_other_users <= 1,
                                    "likes-text-message-position": isTextMessage,
                                    "likes-content-message-position": isUnlockContentMessage,
                                    "likes-mass-message-position": !isUnlockContentMessage && !isTextMessage,
                                    "likes-private-mass-message-text-position": (isPrivateMassMessage || isMassMessage) && message?.text?.body && !message?.unlock_details,
                                    "likes-private-mass-message-content-position": (isPrivateMassMessage || isMassMessage) && message?.unlock_details,
                                 }) }
                                 onClick={ isGroup ? () => chatLikesModalTogglehandler() : null }
                                 role='presentation'
                              >
                                 <div className='w-3 h-3 flex items-center justify-center '>
                                    <Icon name='heart-filled' size='small' color='likes' darkColor='likes-dark' className='heart-icon' />
                                 </div>
                                 {
                                    message.likes_count_from_other_users > 1 && (
                                       <span className='text-xs leading-4 likes-count'>{ message.likes_count_from_other_users > 100 ? '99+' : message.likes_count_from_other_users}</span>
                                    )
                                 }
                              </div>
                           )}

                           {
                              chatLikesModal &&
                              <ChatLikesModal
                                 newFetching={ likes.fetchingScroll }
                                 etching={ likes.fetchin }
                                 members={ likes.data || [] }
                                 chatLikesModalTogglehandler={ () => setChatLikesModal(false) }
                                 isMobile={ isMobile }
                                 headerText={ `Likes • ${ message.likes_count_from_other_users } members` }
                                 onScroll={ onLikedMemberPagination }
                              />
                           }

                        </>
                     </div>
                  </div>

                  {
                     message.error_message && isMyMessage && (
                        <div className='flex h-4 items-center error-message justify-end'>
                           <span className='text-xs text-error dark:text-error-dark'> Message is too long</span>
                           <div
                              className='h-4 w-4 bg-panel dark:bg-black-field rounded-full flex items-center justify-center'
                           >
                              <Icon
                                 name='attention'
                                 color='error'
                                 darkColor='error-dark'
                                 size='base'
                              />
                           </div>
                        </div>
                     )
                  }
               </div>
               <div id='chat-options-modal' className='h-6 items-center flex mr-4'>
                  <div className='h-6 w-6 flex ml-2'>
                     <OptionsDesktopButton
                        onClickOption={ () => {} }
                        isMyMessage={ isMyMessage }
                        toolsTogglehandler={ toolsTogglehandler }
                        showTools={ showTools }
                        isLiked={ message.is_liked_by_user }
                        onLikeMessage={ onLikeMessage }
                        onClickAction={ (...arg)  => onClickAction(...arg) }
                        isMobile={ isMobile }
                        closeToolse={ (bool) => {
                           setShowTools(bool) } }
                        width={ messageContent.current ? messageContent.current.getBoundingClientRect().width : null }
                        messageOptionsRef={ messageOptions }
                        isAdmin={ isAdmin }
                        isTextMessage={ isTextMessage }
                        messageType={ message.type }
                        isUnlockContentMessage={ isUnlockContentMessage }
                        onlyDeleteOption={ isAdmin && (isUnlockContentMessage || ['mass_message', 'private_mass_message', 'multiple_attachment'].includes(type) || (['voice_message'].includes(type) && !!message?.unlock_details?.is_unlocked)) }
                        onlyDownloadOption={ getOnlyDownloadOption() }
                        isUpdate={ isUpdate }
                        message={ message }
                        showReplyOption={ !isChatNotification && !isLiveStreamNotification }
                        isMiniChat={ isMiniChat }
                        isLivstream={ isLivstream }
                        isMultipleAttachmentsMessage={ isMultipleAttachmentsMessage }
                     />
                  </div>
               </div>

            </div>
            {message.edited &&
            <div
               className={ cx({
                  "w-full flex- h-h items-start mt-1 text-by-edited": true,
                  "ju-start justify-start": !isMyMessage,
                  "text-edit-end justify-end": isMyMessage,
               }) }
            >
               <span
                  className={ cx({
                     "text-xs block text-placeholder dark:text-placeholder-dark": true,
                     "pl-8 mr-auto": !isMyMessage,
                     "mr-auto": isMyMessage,
                  }) }
               >
                  (edited)
               </span>
            </div>
            }

            {
               showDate &&
               <div
                  className={ cx({
                     "w-full flex mt-1": true,
                     "justify-start pl-8": !isMyMessage,
                     "justify-end": isMyMessage,
                  }) }
               >
                  <span
                     style={ {
                        lineHeight: '12px',
                        fontSize: '10px',
                     } }
                     className='text-placeholder dark:text-placeholder-dark text-xs ml-3'>
                     {" "}
                     {`${ DateFormatter.convertToUserTzV2(message.sent_at, 'MMM DD, YYYY • hh:mm A') }`}{" "}
                  </span>
               </div>
            }
         </div>
      </>
   );
};

const OptionsDesktopButton = ({
   isMyMessage,
   showTools,
   toolsTogglehandler,
   isLiked,
   onLikeMessage,
   onClickAction,
   isMobile,
   closeToolse,
   width,
   messageOptionsRef,
   isAdmin,
   isTextMessage,
   onlyDeleteOption,
   onlyDownloadOption,
   messageType,
   isUpdate,
   message,
   showReplyOption,
   isMiniChat,
   isLivstream,
   isMultipleAttachmentsMessage,
}) => {
   let timeout = null
   useEffect(() => {
      return () => {
         clearTimeout(timeout)
      };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const [animet, setAnimet] = useState(null)
   let dropdownStyle = {}
   let top = 0
   let parentWidth = 0
   const isExistTextIntoMessage =  !!message?.text

   if(!!document.querySelector('.chat-dialogue-content')) {
      parentWidth = document.querySelector('.chat-dialogue-content').getBoundingClientRect().width
   }
   if(messageOptionsRef.current) {
      top = messageOptionsRef.current.getBoundingClientRect().top
   }
   let diff = parentWidth - width


   if(top - 100 < 150 || (isLivstream && top - 300 < 200)) {
      dropdownStyle = {
         ...dropdownStyle,
         top: 28,
      }
   } else {
      dropdownStyle = {
         ...dropdownStyle,
         bottom: 28,
      }
   }

   if(isMyMessage) {
      if(diff < 250) {
         dropdownStyle = {
            ...dropdownStyle,
            left: width < 200 ? 50 : isMultipleAttachmentsMessage ? 0 : -50,
         }
      } else {
         dropdownStyle = {
            ...dropdownStyle,
            left: -(width < 220 ? 150 : 100),
         }
      }
   } else {
      if(diff < 250) {
         dropdownStyle = {
            ...dropdownStyle,
            left: -(width < 200 ? 100 : 200),
         }
      } else {
         dropdownStyle = {
            ...dropdownStyle,
            left: width < 220 ? 150 : 30,
         }
      }

      if(isMiniChat && width < 100) {
         dropdownStyle = {
            ...dropdownStyle,
            left: -10,
         }
      }

      if(!isMiniChat && width < 230) {
         dropdownStyle = {
            ...dropdownStyle,
            left: -50,
         }
      }
   }

   if(isMyMessage && isMultipleAttachmentsMessage && window?.innerWidth > 1024 && isMiniChat){
      dropdownStyle = {
         right: -175,
         top: -60,
      }
   }

   return (
      <div className='flex w-full h-full'>
         {
            !isMyMessage &&
            <Button
               backgroundColor='transparent'
               fontIconColor={ isLiked ? 'likes' : 'secondary' }
               darkIconColor={ isLiked ? 'likes-dark' : 'secondary-dark' }
               iconName={ isLiked ? 'heart-filled' : 'heart-outline' }
               padding='none'
               classNames={ `h-full flex items-center ${ isLiked ? 'text-likes dark:text-likes-dark' : 'text-secondary dark:text-secondary-dark' }` }
               onClick={ () => {
                  onLikeMessage(!isLiked)
               } }
            />
         }
         {
            ('voice_message' === messageType || isTextMessage || onlyDeleteOption || onlyDownloadOption ||
               (messageType === "private_mass_message" && !isMultipleAttachmentsMessage) ||
               (isMultipleAttachmentsMessage && isMyMessage)
                || showReplyOption)  &&
            !isUpdate  &&
               <div
                  ref={ messageOptionsRef }
                  className='justify-center mx-2 items-center relative message-options' style={ {
                     minWidth: '20px',
                  } }>
                  <div
                     className={ `${ isMobile ? 'flex' : 'options-desktop-button hidden' }` }>
                     <Button
                        backgroundColor='transparent'
                        onClick={ () => {
                           toolsTogglehandler()
                        }  }
                        fontIconColor='secondary'
                        darkIconcolor='secondary-dark'
                        iconName='options-vertical'
                        padding='none'
                        classNames='h-full flex items-center'
                     />
                  </div>
                  {
                     showTools &&

                     <ModalDropDown
                        isDropDown={ !isMobile }
                        isMobile={ isMobile }
                        contentPosition='bottom'
                        height='max-content'
                        width={ 194 }
                        zIndex={ 100 }
                        { ...dropdownStyle }
                        onCloseModal={ () => {
                           setAnimet('down')
                           timeout =  setTimeout(() => {
                              closeToolse(false)
                              setAnimet(null)
                           }, 800)
                        } }
                        animate={ animet || 'up' }
                        className='bg-transparent'
                     >
                        <ChatTools
                           onClickAction={ onClickAction }
                           isMobile={ isMobile }
                           isUnlockedContent={ onlyDeleteOption && message?.unlock_details?.is_unlocked }
                           showDownloadOption={ onlyDownloadOption }
                           showReplyOption={ showReplyOption }
                           showCopyOption={ isTextMessage || isExistTextIntoMessage }
                           showEditOption={ isMyMessage && 'voice_message' !== messageType && 'mass_message' !== messageType }
                           showUnsendOption={ isMyMessage || isAdmin }
                           deleteButtonText={ 'Unsend' }
                           // deleteButtonText={ isMyMessage ? 'Unsend' : 'Delete' }
                        />
                     </ModalDropDown>
                  }
               </div>
         }

      </div>
   );
};

Message.defaultProps = {
   onStream: () => {},
}
Message.propTypes = {
   message: PropTypes.any,
   nextIsMyMessage: PropTypes.bool,
   isMobile: PropTypes.bool,
   isMyMessage: PropTypes.bool,
   user: PropTypes.object,
   onClickUserAvatar: PropTypes.func,
   parent: PropTypes.object,
   likes: PropTypes.object,
   onFetchLikesInfo: PropTypes.func,
   onLikedMemberPagination: PropTypes.func,
   onLikeMessage: PropTypes.func,
   onDeleteMessag: PropTypes.func,
   onReplyMessage: PropTypes.func,
   updateMessage: PropTypes.func,
   closeChatPopup: PropTypes.func,
   isUpdate: PropTypes.bool,
   isGroup: PropTypes.bool,
   isAdmin: PropTypes.bool,
   paymentButtonLoading: PropTypes.bool,
   onStream: PropTypes.func,
   isMiniChat: PropTypes.bool,
   downloadFile: PropTypes.func,
   // goToContentMessage: PropTypes.func,
   goToJoin: PropTypes.func,
   adminUuid: PropTypes.string,
   lastElement: PropTypes.bool,
   goToDetails: PropTypes.func,
   onClickAttachment: PropTypes.func,
   onRemoveMedia: PropTypes.func,
   replyMessage: PropTypes.object,
   authUser: PropTypes.object,
   isLivstream: PropTypes.bool,
   screenWidth: PropTypes.number,
   getContentLink: PropTypes.func,
   onClickNotificationMessageButton: PropTypes.func,
   currentLiveStream: PropTypes.object,
};
OptionsDesktopButton.propTypes = {
   isMobile: PropTypes.bool,
   isMyMessage: PropTypes.bool,
   showTools: PropTypes.bool,
   toolsTogglehandler: PropTypes.func,
   isLiked: PropTypes.bool,
   onLikeMessage: PropTypes.func,
   onClickAction: PropTypes.func,
   closeToolse: PropTypes.func,
   width: PropTypes.number,
   isAdmin: PropTypes.bool,
   messageOptionsRef: PropTypes.any,
   isTextMessage: PropTypes.bool,
   onlyDeleteOption: PropTypes.bool,
   onlyDownloadOption: PropTypes.bool,
   adminUuid: PropTypes.string,
   messageType: PropTypes.string,
   message: PropTypes.object,
   isUpdate: PropTypes.bool,
   showReplyOption: PropTypes.bool,
   isMiniChat: PropTypes.bool,
   isLivstream: PropTypes.bool,
   isMultipleAttachmentsMessage: PropTypes.bool,
};

export default Message;
