import * as types from './types';
import createReducer from '../../utils/createReducer';

import initialState from './initialState';

const reducersMap = {
   [types.SITE_DETAILS_INIT_START]: (state) => {
      return {
         ...state,
         authUser: null,
         siteInfo: null,
         isSiteInited: false,
         menu: {
            ...state.menu,
            isMenuOpened: false,
            animationClass: 'default',
         },
      };
   },
   [types.SITE_DETAILS_INIT_COMPLETED]: (state, action) => {
      return {
         ...state,
         isSiteInited: true,
         isSiteDetailsRefreshing: false,
         ...action.payload,
      };
   },
   [types.SITE_DETAILS_INIT_FAILED]: (state) => {
      return {
         ...state,
         isSiteInited: true,
      };
   },
   [types.SITE_DETAILS_REFRESH_START]: (state) => {
      return {
         ...state,
         isSiteDetailsRefreshing: true,
      };
   },
   [types.RESET_COMMON_DETAILS]: () => {
      return {
         ...initialState,
      };
   },
   [types.SCREEN_RESIZE]: (state, action) => {
      return {
         ...state,
         screenWidth: action.payload.screenWidth,
      }
   },
   [types.COLLAPSE_MENU]: (state, action) => {
      return {
         ...state,
         menu: {
            ...state.menu,
            isMenuOpened: !state.menu.isMenuOpened,
            animationClass: !state.menu.isMenuOpened ? 'menu-hidden-animation' : 'menu-block-animation',
         },
      }
   },
   [types.UPDATE_AUTH_USER]: (state, action) => {
      const { payload } = action
      const data = {
         ...state.authUser,
         ...payload,
         compressed_avatar_src: payload.avatar_src,
      }
      return {
         ...state,
         authUser: data,
      }
   },

   [types.SET_VIEW_AS_MODAL]: (state, action) => {
      const { payload: {
         bool,
         url,
      } } = action
      let viewAsModal = {
         isOpen: bool,
      }
      if(url) {
         viewAsModal = {
            ...viewAsModal,
            url,
         }
      }
      return {
         ...state,
         viewAsModal,
      }
   },

   [types.SET_IS_ONLINE_STREAM]: (state, action) => {
      return {
         ...state,
         isOnlineStream: action.payload,
      };
   },

   [types.UPDATE_LIVE_STREAM_CURRENT_SESSION_STATE]: (state, action) => {
      return {
         ...state,
         liveStreamCurrentSessionState: {
            ...state.liveStreamCurrentSessionState,
            ...action.payload,
         },
      };
   },

   [types.OPEN_ONLINE_MEMBERS_MODAL]: (state, action) => {
      return {
         ...state,
         isOpenOnlineMembersModal: action.payload,
      }
   },
   [types.UPDATE_SITE_LONG_NAME_START]: (state, action) => {
      return {
         ...state,
         isUpdateSiteLongInfo: true,
         siteLongNameUpdateError: null,
      }
   },
   [types.UPDATE_SITE_LONG_NAME_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         isUpdateSiteLongInfo: false,
         siteInfo: {
            ...state.siteInfo,
            site_long_name: payload,
         },
      }
   },
   // [types.UPDATE_SITE_INFO_COMPLETED]: (state, action) => {
   //    const { payload } = action
   //    return {
   //       ...state,
   //       siteInfo: {
   //          ...state.siteInfo,
   //          ...payload,
   //       },
   //    }
   // },
   [types.UPDATE_SITE_NAME_FAILED]: (state, action) => {
      const { payload } = action

      return {
         ...state,
         isUpdateSiteLongInfo: false,
         siteLongNameUpdateError: payload,
      }
   },
   [types.UPDATE_CONTENT_META_INFO]: (state, action) => {
      const { payload } = action
      const defaultMetaInfo = state.siteInfo?.content_meta_info || {};
      return {
         ...state,
         siteInfo: {
            ...state.siteInfo,
            content_meta_info: {
               ...defaultMetaInfo,
               ...payload,
            },
         },
         // state.common.siteInfo?.content_meta_info: false,
         // siteLongNameUpdateError: payload,
      }
   },
   [types.SET_MASS_MESSAGE_LIMIT]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         massMessageLimit: {
            ...state.massMessageLimit,
            ...payload,
         },
      }
   },
   [types.UPDATE_RECEIVED_ORDERS_COUNT]: (state, action) => {
      console.log(action.payload);
      return {
         ...state,
         received_orders_badge_count: action.payload,
      }
   },
   [types.UPDATE_IS_OFFLINE_MODE_START]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         isUpdateOfflineMode: payload,
      }
   },
   [types.UPDATE_IS_OFFLINE_MODE_COMPLETED]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         isUpdateOfflineMode: false,
         siteInfo: {
            ...state.siteInfo,
            ...payload,
         },
      }
   },
   [types.SET_IS_LOADING_CONNECT_TO_MYLIST]: (state, action) => {
      return {
         ...state,
         isLoadingConnectToMylist: action.payload,
      }
   },
   [types.CONNECT_TO_MYLIST_COMPLETED]: (state) => {
      return {
         ...state,
         accountType: 'all',
         isLoadingConnectToMylist: false,
      }
   },
   [types.UPDATE_SITE_INFO]: (state, action) => {
      const newState = action.payload || {}

      return {
         ...state,
         siteInfo: {
            ...state.siteInfo,
            ...newState,
         },
      }
   },
   [types.SET_SHARE_MODAL]: (state, action) => {
      const { payload } = action
      return {
         ...state,
         shareModal: payload,
      }
   },
};

export default createReducer(initialState)(reducersMap);
