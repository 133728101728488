import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import md5 from 'md5';
import './style.scss';
import 'react-image-gallery/styles/css/image-gallery.css';
import Icon from 'common/components/elements/icons';
import Button from 'common/components/elements/buttons/primary';
import ImageZoomPanPinch from '../image-zoom-pan-pinch';
import cx from 'classnames'
import classNames from 'classnames';

const ImgGallery = ({
   images,
   onClickFullScreen,
   showFullScreen,
   classNameIconDiv,
   triggerImageLoad,
   isLockedForSFW,
   startIndex,
   showPlayButton,
   infinite,
   openModalInsteadOfFullScreen,
   disableKeyDown,
   showBullets,
   withZoomFuntion = false,
   isOpenFullScreenImmediately,
   onCloseFullScreen,
   onChangeScreen,
   isPreventFullScreenAction = false,
   className = '',
   onClickVerifyButton,
}) => {

   let numClicks = 0;
   let singleClickTimer = 0;
   const galleryRef = useRef(null)
   const [isFullScreen, setIsFullScreen] = useState(false)
   let currentIndex = startIndex
   const [isShowAgeVerifyButton, setIsShowAgeVeryfyButton] = useState(false)
   const [isHoveredOnTheImage, setIsHoveredOnTheImage] = useState(false)
   const isThereOnlyOneImage = images?.length === 1

   useEffect(() => {

      setIsShowAgeVeryfyButton(!!images?.[startIndex]?.isShowAgeVerifyButton)

      if(isOpenFullScreenImmediately){
         toogleOpenFullScreen()
      }

      return () => {
         toggleBodyClass(false);
      };
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   const handleImageLoad = (event) => {
      const src = event.target.src;
      const id = md5(src);
      if(document.getElementById(id) && !triggerImageLoad) {
         return;
      }
      const parentElement = event.target.parentElement;
      const newElement = document.createElement("div");
      newElement.id = id;
      newElement.style.backgroundImage = `url(${ event.target.src })`;
      newElement.style.width = '100%';
      newElement.style.height = '100%';
      newElement.style.filter = 'blur(30px)';
      newElement.style.backgroundRepeat = 'no-repeat';
      newElement.style.backgroundPosition = 'center';
      newElement.style.backgroundSize = 'cover';
      parentElement.insertBefore(newElement, event.target);
   };

   const toggleBodyClass = (add) => {
      if(onChangeScreen) onChangeScreen(add)
      if(add) {
         document.body.classList.add('photosets-fullscreen')
         setTimeout(() => {
            const elements = document.getElementsByClassName('fullscreen-modal')
            if(elements.length > 0) {
               elements[0].setAttribute('id', 'photosets-fullscreen');
            }
         }, 0)
      } else {
         document.body.classList.remove('photosets-fullscreen')
         const elements = document.getElementsByClassName('fullscreen-modal')
         if(elements.length > 0) {
            elements[0].removeAttribute('id')
         }
      }
   };

   const handleFullscreenChange = (isFullScreenMode) => {
      setIsFullScreen(isFullScreenMode)
      if(isFullScreenMode){
         onClickFullScreen()
      }
      toggleBodyClass(isFullScreenMode)
   };

   const handleSlide = (newIndex) => {
      currentIndex = newIndex
      let bool = images?.find((image, index) => image.isShowAgeVerifyButton && index === newIndex)
      if(bool !== isShowAgeVerifyButton){
         setIsShowAgeVeryfyButton(bool)
      }
   }

   let rightNewState = classNameIconDiv ? {
      renderRightNav: (onClick, disabled) => (
         <button
            type='button'
            className='!filter-none image-gallery-icon **z-40 z-10 top-[50%] -translate-y-[50%] right-2.5'
            onClick={ (e) => {
               e.preventDefault()
               e.stopPropagation()
               if(!disabled) {
                  onClick()
               }
            }
            }
            aria-label='Next Slide'
         >
            <div className={ `${ classNameIconDiv } next-icon-classes ${ disabled ? '!hidden' : '' }` }>
               <Icon
                  name='arrow-right'
                  size='[20px]'
                  color='major-dark'
               />
            </div>
         </button>
      ),

   } : ''

   let leftNewState = classNameIconDiv ? {
      renderLeftNav: (onClick, disabled) => (
         <button
            type='button'
            className='!filter-none image-gallery-icon **z-40 z-10 top-[50%] -translate-y-[50%] left-2.5'
            onClick={ (e) => {
               e.preventDefault()
               e.stopPropagation()
               if(!disabled) {
                  onClick()
                  if(showPlayButton){
                     galleryRef.current.pause()
                  }
               }
            } }
            aria-label='Previous Slide'
         >
            <div className={ `${ classNameIconDiv } next-icon-classes ${ disabled ? '!hidden' : '' }` }>
               <Icon
                  name='arrow-left'
                  size='[20px]'
                  color='major-dark'
               />
            </div>
         </button>
      ),

   } : ''

   const fullscreenButton = {
      renderFullscreenButton: (onClick, isFullscreen) => (
         <button
            className='image-gallery-fullscreen-button absolute z-10'
            onClick={ () => {
               if(!!openModalInsteadOfFullScreen) {
                  openModalInsteadOfFullScreen(galleryRef.current.getCurrentIndex())
                  return
               }

               if(onCloseFullScreen && isOpenFullScreenImmediately) {
                  onCloseFullScreen()
               }

               onClick()
            } }
            data-tooltip-content={ isFullScreen ? ' Exit fullscreen' : 'Fullscreen mode' }
            data-tooltip-id='ams-top-tooltip'
         >
            <Icon
               name={ isFullScreen ? 'fullscreen-exit' : 'full-screen' }
               size='2xl'
            />
         </button>
      ),
   }

   const renderPlayPauseButton = !isThereOnlyOneImage ? {
      renderPlayPauseButton: (onClick, isPlaying) => (
         <button
            onClick={ onClick }
            isPlaying={ isPlaying }
            className={ cx({
               'absolute bottom-0 left-0 image-gallery-play-pause-button': true,
               '!translate-x-[-350%]': withZoomFuntion,
               '!translate-x-[-410%]': withZoomFuntion && window?.innerWidth < 430,
            }) }
            data-tooltip-content='Slideshow'
            data-tooltip-id='ams-top-tooltip'
         >
            <Icon
               name={ isPlaying ? 'pause' : 'play-default' }
               size='2xl'
            />
         </button>
      ),
   } : null

   const closeButton = (
      <Button
         backgroundColor='transparent'
         borderRadius='large'
         onClick={ () => {
            galleryRef.current.toggleFullScreen()

            if(onCloseFullScreen && isOpenFullScreenImmediately) {
               onCloseFullScreen()
            }
         } }
         classNames='!absolute z-50 image-gallery-fullscreen-close-button !w-9 !h-9 '
         textMarginX='0'
         padding='0'
         fontIconColor={ 'major-dark' }
         darkIconColor='secondary-dark'
         iconName='close'
         iconSize='extraLarge                                                                                 '
      />
   )

   const toogleOpenFullScreen = () => {
      clearTimeout(singleClickTimer);
      numClicks = 0;
      if(galleryRef && galleryRef.current) {
         galleryRef.current.toggleFullScreen();
      }
   }

   const imageGalleryView = (extraProps) => {
      return (
         <div
            className='relative'
            onMouseEnter={ () => setIsHoveredOnTheImage(true) }
            onMouseLeave={ () => setIsHoveredOnTheImage(false) }
         >
            <ImageGallery
               ref={ galleryRef }
               onImageLoad={ handleImageLoad }
               onScreenChange={ handleFullscreenChange }
               infinite={ infinite }
               showBullets={ !isFullScreen && showBullets && images?.length > 1 }
               showFullscreenButton={ showFullScreen }
               showPlayButton={ showPlayButton }
               showThumbnails={ false }
               disableKeyDown={ disableKeyDown }
               showNav={ true }
               slideDuration={ 120 }
               additionalClass={ classNames({
                  'app-image-gallery': true,
                  '_images_blured_for_sfw': isLockedForSFW,
                  [className]: className,
               }) }
               useWindowKeyDown={ true }
               lazyLoad={ true }
               useBrowserFullscreen={ false }
               onClick={ (e) => {

                  if(isPreventFullScreenAction) return

                  if(!!openModalInsteadOfFullScreen) {
                     openModalInsteadOfFullScreen(galleryRef.current.getCurrentIndex())
                     return
                  }

                  numClicks++;
                  if(numClicks === 1) {
                     if(!showFullScreen){
                        toogleOpenFullScreen()
                        return
                     }
                     singleClickTimer = setTimeout(() => {
                        numClicks = 0;
                     }, 400);
                  } else if(numClicks === 2) {
                     if(showFullScreen) {
                        if(onCloseFullScreen && isOpenFullScreenImmediately) onCloseFullScreen()
                        toogleOpenFullScreen()
                     }
                  }
               } }
               renderCustomControls={ () => !!isFullScreen && closeButton }
               startIndex={ startIndex }
               onSlide={ handleSlide }
               { ...rightNewState }
               { ...leftNewState }
               { ...fullscreenButton }
               { ...renderPlayPauseButton }
               { ...extraProps }
            />
            {
               isShowAgeVerifyButton && isHoveredOnTheImage &&
               <div
                  className='absolute top-0 flex flex-col items-center justify-center gap-4 w-full h-full z-0'
                  style={ {
                     backdropFilter: 'blur(32px)',
                  } }
               >
                  <p className='text-lg font-semibold text-major-dark text-center'>Verify age to unblur content</p>
                  <Button
                     text={  'Verify my age' }
                     borderRadius='large'
                     backgroundColor={ 'transparent' }
                     textColor='major-dark'
                     textSize='small'
                     onClick={ (e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        if(onClickVerifyButton) onClickVerifyButton()
                     } }
                     classNames='!w-fit border border-major-dark py-2.5 px-3.5'
                     textMarginX='0'
                  />
               </div>
            }
         </div>
      )
   }

   if(withZoomFuntion) {
      return (
         imageGalleryView({
            items: images.map((image, index) => ({
               ...image,
               renderItem: () => (
                  <ImageZoomPanPinch
                     isTherePlayPauseButton={ !isThereOnlyOneImage }
                     isFullScreen={ isFullScreen }
                     isShowControlButtons={ currentIndex === index }
                  >
                     <img
                        src={ image.original }
                        alt={ image.originalAlt }
                        onLoad={ handleImageLoad }
                        className='image-gallery-image'
                     />
                  </ImageZoomPanPinch>
               ),
            })),
         })
      )
   }


   return imageGalleryView({ items: images })
}

ImgGallery.defaultProps = {
   onClickFullScreen: () => {},
   showFullScreen: true,
   startIndex: 0,
   showPlayButton: false,
   infinite: true,
   disableKeyDown: true,
   showBullets: false,
};

ImgGallery.propTypes = {
   images: PropTypes.array,
   onClickFullScreen: PropTypes.func,
   showFullScreen: PropTypes.bool,
   classNameIconDiv: PropTypes.string,
   triggerImageLoad: PropTypes.bool,
   isLockedForSFW: PropTypes.bool,
   startIndex: PropTypes.number,
   infinite: PropTypes.bool,
   showPlayButton: PropTypes.bool,
   openModalInsteadOfFullScreen: PropTypes.func,
   disableKeyDown: PropTypes.bool,
   showBullets: PropTypes.bool,
   withZoomFuntion: PropTypes.bool,
   isPreventFullScreenAction: PropTypes.bool,
   className: PropTypes.string,
   onClickVerifyButton: PropTypes.func,
};

export default React.memo(ImgGallery);

