import React, { memo } from 'react';
import PropTypes from 'prop-types';
import ModalWrapper from 'common/components/modules/modal-wrapper';


const MassMessageLimitModal = ({
   isMobile,
   onCloseModal,
   onClick,
   data,
}) => {
   const getWarningMessage = () => {
      if(data?.is_monthly_limit_reached) {
         return {
            title: 'Email sending limit reached',
            descrtiption: <div className='flex flex-col items-center justify-center gap-6 leading-5 text-secondary mt-4'>
               <p>
                  Your monthly email sending limit of {data?.mass_message_monthly_limit} has been reached
               </p>
               <p>
                  Once reached, chat messages will still be sent, but will not be followed up by email notifications if unread.
               </p>
               <p>
                  Monthly email limits refresh on the first of each month.
               </p>
            </div>,
         }
      } else {
         return {
            title: 'Email sending limit almost reached',
            descrtiption: <div className='flex flex-col items-center justify-center gap-6 leading-5 text-secondary mt-4'>
               <p>
                  You have almost reached your monthly email sending limit of {data?.mass_message_monthly_limit}. You have sent {data?.sent_messages_count_for_this_month} emails this month.
               </p>
               <p>
                  Once reached, chat messages will still be sent, but will not be followed up by email notifications if unread
               </p>
               <p>
                  Monthly email limits refresh on the first of each month.
               </p>
            </div>,
         }
      }
   }

   return (
      <ModalWrapper
         type='confirm'
         icon='warning'
         iconColor='warning'
         onCloseModal={ onCloseModal }
         action={ () => {
            onClick(onCloseModal)
         } }
         title={ getWarningMessage()?.title }
         // description='You have reached the limit for sending mass messages at the moment. Your message cannot be sent via email however, it will still be delivered through the chat.'
         // buttonText='I want higher limits'
         buttonText='Ok'
         cancelText='Contact support to increase your limit'
         contentWidth='375px'
         isMobile={ isMobile }
         iconSize='2xl'
         buttonClassName='mt-8'
         secondaryButtonClassName='intercom-contact-support-to-increase-your-email-limit'
         cancelButtonAction={ onCloseModal }
      >
         {
            getWarningMessage()?.descrtiption
         }
      </ModalWrapper>
   );
};

MassMessageLimitModal.propTypes = {
   isMobile: PropTypes.bool,
   onCloseModal: PropTypes.func,
   onClick: PropTypes.func,
   data: PropTypes.object,
};

export default memo(MassMessageLimitModal);
