export function getSiteName(){
   return '<span style="font-weight:400 !important;">my</span><span style="font-weight: bold;">member.</span><span style="font-weight: 400 !important;">site</span>';
   // return 'mymember.site';
   // return 'adultmembersites.com';
}
export function getCapitalizeSiteName(){
   return '<span style="font-weight:400 !important;">my</span><span style="font-weight: bold;">member.</span><span style="font-weight: 400 !important;">site</span>';
   // return 'mymember.site';
   // return 'AdultMemberSites.com';
}
export function getSiteLink(){
   return 'https://mymember.site/';
   // return 'https://adultmembersites.com/';
}
export function getSiteTitle(){
   return '<span style="font-weight:400 !important;">my</span><span style="font-weight: bold;">member.</span><span style="font-weight: 400 !important;">site</span>';
   // return 'mymember.site';
   // return 'AdultMemberSites';
}
export function getSiteNameAbbreviatur(){
   return 'MMS';
   // return 'AMS';
}
export function getSiteMediainName(){
   return 'MMSMEDIAIN';
   // return 'AMSMEDIAIN';
}
export function getSupportEmail(){
   return 'support@mymember.site';
}
export function getNoRepliesEmail(){
   return 'no-reply@mymember.site';
}
export function getLegalEmail(){
   return 'legal@mymember.site';
}
export function getNickName(){
   return '@mymember_site';
}
export function getSupportEmailText(){
   return 'support@my<span style="font-weight: bold;">member.</span>site';
}

export function isLocalhost(){
   return Boolean(
      window.location.hostname === 'localhost'
        // [::1] is the IPv6 localhost address.
        || window.location.hostname === '[::1]'
        // 127.0.0.1/8 is considered localhost for IPv4.
        || window.location.hostname.match(
           /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
        || window.location.hostname.match(
           /^192(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
   );
}

export const getSiteNameHtml = (name = 'member') => {
   return `my<span style="font-weight: bold;">${ name }.</span>site`;
}

export const getAffiliateDisplayHtml = () => {
   return `my.site<span style="font-weight: bold;">affiliate</span>`
}

export const replacePartOfText = (text, searchValue, replaceValue) => {
   return text.replaceAll(searchValue, replaceValue)
}
