import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons'
import './style.scss';
import Modal from '../../modal';
import Button from 'common/components/elements/buttons/primary';
import generateTextColor from 'common/utils/generateTextColor';
import cx from "classnames";
import Search from 'common/components/elements/inputs/search';
import TruncateText from 'common/components/elements/truncate-text';
import RepliesOptionsModal from './options-modal';
import CircleLoader from 'common/components/elements/loader/circle';
import { getBaseVariablesList, getSubjectVariablesList } from 'admin/views/my-site/settings/outgoing-notification-and-auto-tweet/data';
import { useSelector } from 'react-redux';
import VariableList from '../variable-list';
import { insertTextMac, getBrowserName } from 'common/utils/utils';
import Input from 'common/components/elements/inputs/inputs';
import EmojiContent from '../../emoji-content';
import toast from 'common/utils/toast';
import NewMessageField from '../new-message-field';
import randomstring from 'randomstring';
import QueryParams from 'admin/utils/QueryParams';
import { flushSync } from 'react-dom';
import {
   sortableContainer,
   sortableElement,
   sortableHandle,
} from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_SAVED_REPLIES } from 'common/utils/intercom-articles';
// import emptyStateSvg from 'assets/images/empty-states/message.svg'

const defaultState = {
   "name": "",
   "is_pinned": 0,
   "pinned_at": null,
   "body": "",
   "attachments": null,
   "attachmentsForShow": null,
   "locked_poster_is_blur": false,
   "unlock_price": null,
   "allow_download": false,
   "voice_message_data": null,
   "voice_message_data_src": null,
   "voice_message_make_default_price_for_voice_messages": null,
   "voice_message_price": null,
   "voice_message_duration": null,
   "show_duration_countdown_to_member": true,
   "duration_in_minutes": null,
   "add_member_info_to_watermark": false,
}


const SortableContainer = sortableContainer(({ children, wrapperProps }) => {
   return <div { ...wrapperProps }>{children}</div>;
});

const DragHandle = sortableHandle(() => (
   <div className='flex drag-cursor' >
      <Icon name='drag' color='secondary' size='xl' />
      {/* <p className='text-major-dark ml-1 '>Drag to reorder</p> */}
   </div>
));

const SortableItem = sortableElement(({
   reply,
   isSendingReply,
   isReordering,
   currentReply,
   openOptionsModal,
   setOpenOptionsModal,
   setCurrentReply,
   setSelectedReply,
   setReplyData,
   onChangeModalState,
   onClickDeleteReply,
   onChangeRepliesFilter,
   onClickSendReply,
   setDialogueContainerState,
   sendingReply,
   optionsStyles,
   setOptionsStyles,
   replies,
}) => {
   const replyText = () => {

      if(!!reply?.attachments && !!reply?.attachmentsForShow && !reply?.voice_message_data) {
         return `${ reply?.attachments?.length } attachment${ reply?.attachments?.length > 1 ? "s" : '' }`
      } else if(!reply?.attachments && !reply?.attachmentsForShow && reply?.voice_message_data) {
         return 'Voice message'
      } else {
         return reply?.body
      }
   }
   // console.log(!isReordering && currentReply?.id ===  reply?.id && openOptionsModal);

   return <div
      // key={ ind }
      // className='flex flex-col py-[10px] px-4 hover:bg-hover hover:cursor-pointer gap-1 **lg:absolute'
      className={ cx({
         'flex flex-col py-[10px] px-4  gap-1 **lg:absolute relative': true,
         [`pl-[30px] lg:pl-[38px]`]: true,
         [`hover:bg-hover hover:cursor-pointer`]: !isSendingReply && !isReordering,
         // [`pr-4 lg:pr-6`]: document.getElementById('repliesList')?.scrollHeight === document.getElementById('repliesList')?.clientHeight,
      }) }
      role='presentation'
      onClick={ () => {
         if(!isSendingReply && !isReordering) {
            onChangeModalState('edit')
            setReplyData(replies?.find(it => it.id === reply?.id), 'edit')
            // onChangeRepliesFilter({ search: '' })
         }
      } }
      style={ {
         // zIndex: 250,
      } }
   >
      <div
         className={ `saved-replies-option${ reply?.id ? `-${ reply?.id }` : '' }` }
         style={ {
            ...optionsStyles,
         } }
      />

      <div className='flex gap-3 items-center justify-between'>
         <div className='flex gap-3 items-center justify-between w-full'>
            <div
               role='presentation'
               onClick={ (e) => {
                  e.preventDefault()
                  e.stopPropagation()

                  // const X = e.clientX
                  const Y = e.clientY
                  const mainWrapperHeight = (document.getElementById('repliesList')?.clientHeight)
                  const windowHeight =  window?.innerHeight
                  // const scrollTop = document.getElementById('repliesList')?.scrollTop
                  // const clientHeight = e.clientHeight

                  // console.log({
                  //    x: e.clientX,
                  //    y: e.clientY,
                  //    windowHeight: window?.innerHeight,
                  //    scrollTop: document.getElementById('repliesList')?.scrollTop,
                  // });

                  const isOptionsVisible = (Y - (windowHeight / 2) > 200)
                  // const isOptionsVisible2 = (Y - (mainWrapperHeight / 2) > 200)
                  const isOptionsVisible2 = ((windowHeight / 2) >= Y)
                  // console.log({ isOptionsVisible });
                  // console.log({ mainWrapperHeight, Y, windowHeight });

                  let newOptionsStyles = {}

                  if(mainWrapperHeight < 200) {
                     newOptionsStyles = {
                        top: 0, //-52,
                        position: 'relative',
                     }
                  } else if(mainWrapperHeight > 200 && mainWrapperHeight < 408) {
                     newOptionsStyles = {
                        top: isOptionsVisible2 ? 0 /*-52*/ : 0, //-84,
                        position: 'relative',
                     }
                  } else {
                     newOptionsStyles = {
                        top: isOptionsVisible ? -86 : 0,
                        position: 'relative',
                     }
                  }

                  setOptionsStyles(newOptionsStyles)

                  if(!openOptionsModal && currentReply?.id !== reply?.id && !isSendingReply) {
                     // console.log('CLick');
                     setSelectedReply(reply)
                     setCurrentReply(reply)
                     setOpenOptionsModal(true)
                  } else {
                     setOpenOptionsModal(false)
                     setSelectedReply(null)
                     setCurrentReply(null)
                  }

               } }
               className='w-5 h-5 relative'>

               {
                  !isReordering &&
                  <Button
                     classNames='h-full w-full'
                     iconName='options-vertical'
                     backgroundColor='transparent'
                     fontIconColor='secondary'
                     fontIconSize='extraLarge'
                     padding='none'
                     onClick={ (e) => {
                     // e.stopPropagation()
                     } }
                     fontIconClassName='replies-options-icon'
                  />
               }
               {
                  isReordering && <DragHandle />
               }
               {
                  currentReply?.id ===  reply?.id && openOptionsModal && (
                     <RepliesOptionsModal
                        isMobile={ window?.innerWidth < 1025 }
                        onClickOption={ (option) => {
                           if(option === 'delete') {
                              onClickDeleteReply(reply)
                           }
                           if(option === 'edit') {
                              onChangeModalState('edit')
                              setReplyData(reply, 'edit')
                           }
                           setOpenOptionsModal(!openOptionsModal)
                        } }
                        onCloseModal={ () => setOpenOptionsModal(!openOptionsModal) }
                        optionSelector={ `.saved-replies-option-${ reply?.id }` }
                     />
                  )
               }
            </div>
            <div className='flex flex-col w-full gap-1'>
               <TruncateText
                  className={ `text-major text-left text-base leading-5 select-none font-normal` }
                  text={ reply?.name }
                  textSize='16px'
                  fontWeight='400'
                  textClass=''
                  width='calc(100% - 32px)'
               />
               <div className='flex items-center'>
                  <TruncateText
                     className={ ` text-secondary panel-description leading-4 select-none font-normal` }
                     text={ replyText() }
                     textSize='13px'
                     fontWeight='400'
                     textClass=''
                     width='calc(100%)'
                     tooltipIdForHtmlContent={ reply?.name + '' }
                     hideCopyButton
                  />
               </div>
            </div>
         </div>
         <div
            role='presentation'
            onClick={ (e) => {
               e.preventDefault()
               e.stopPropagation() } }
            className='w-10 h-10 relative hover:cursor-pointer'
            data-tooltip-id={ !isSendingReply ? 'ams-top-tooltip' : '' }
            data-tooltip-content={ !isSendingReply ? 'Send' : '' }
         >
            {
               !isReordering &&
                  <Button
                     classNames='h-full w-full'
                     iconName='send'
                     backgroundColor='transparent'
                     fontIconColor='secondary'
                     fontIconSize='extraLarge'
                     padding='none'
                     onClick={ (e) => {
                        e.stopPropagation()

                        setDialogueContainerState({
                           isSendingReply: true,
                           sendingReply: reply,
                        })

                        onClickSendReply(reply)
                     } }
                     isLoading={ isSendingReply && sendingReply?.id === reply?.id }
                     disabled={ isSendingReply && sendingReply?.id !== reply?.id }
                     loaderColor='primary'
                     fontIconClassName={ `${ (isSendingReply && sendingReply?.id !== reply?.id) ? `` : `hover:!text-action` }  p-[10px]` }
                     loaderClassName='!border-[2px] !w-5 !h-5'
                     // primaryColor
                     // loaderSize={ 5 }
                  />
            }
         </div>
      </div>

   </div>
}
);

const SavedRepliesModal = ({
   isAdmin,
   onClose,
   setSelectedReply,
   onClickDeleteReply,
   onClickEditReply,
   repliesModalState,
   onChangeModalState,
   replies,
   isChangingRepliesModalState,
   changeRepliesModalState,
   isEmptyList,
   repliesRef,
   repliesTextFieldRef,
   onChangeData,
   errors,
   textFieldElementProps,
   isEditMode,
   setReplyData,
   repliesData,
   newReplyData,
   currentReplyData,
   onChangeReply,
   getReplies,
   repliesFilter,
   onChangeRepliesFilter,
   handleRepliesModalScroll,
   setDialogueContainerState,
   isLoadingReplies,
   isLoadingRepliesNextPage,
   readyToSendReply,
   onClickSendReply,
   isSavingReply,
   replyTimeout,
   clearReplyTimeout,
   isSendingReply,
   sendingReply,
   selectedAttachments,
   onReorderEnd,
   isRepliesMaxAttachmentCountReached,
   getQueryParam,
   memberVideoDownloadMultipleWatermarks,
}) => {

   const [animate, setAnimate] = useState('up')
   let timeout
   const [openOptionsModal, setOpenOptionsModal] = useState(false)
   const [currentReply, setCurrentReply] = useState(null)
   const [messageType, setMessageType] = useState(null)
   const [optionsStyles, setOptionsStyles] = useState({})
   const conversationId = QueryParams.getParam('conversation')
   const { siteInfo: { site_long_name, site_url, single_or_multiple_admins }, authUser: { screen_name }, screenWidth } = useSelector(state => state.common)
   const [inputCursorPosition, setInputCursorPosition] = useState(0);
   const [titleIsError, setTitleIsError] = useState(false);
   const [textFieldCursorPosition, setTextFieldCursorPosition] = useState(0);
   const [textFieldLength, setTextFieldLength] = useState(0);
   const [isReordering, setIsReordering] = useState(false);

   useEffect(() => {
      window.addEventListener('beforeunload', beforeUnloadAlertPopup);
      return () => {
         window.removeEventListener('beforeunload', beforeUnloadAlertPopup);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [defaultState, newReplyData, repliesModalState, repliesData, currentReplyData])


   useEffect(() => {

      if(getReplies) {
         getReplies()
      }

      return () => {
         if(replyTimeout) {
            clearReplyTimeout()
         }

         onChangeRepliesFilter({ search: '' }, true)

         setTextFieldLength(0)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {

      setReplyData({ body: repliesTextFieldRef?.current?.innerText || '' }, repliesModalState)

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [repliesTextFieldRef, repliesTextFieldRef.current, repliesTextFieldRef?.current?.innerText])


   const checkStateChange = (defaultState, currentState, repliesModalState) => {

      let showModal = false

      Object.keys(defaultState).forEach((key, index) => {

         if((JSON.stringify(defaultState[key]) !== JSON.stringify(currentState[key])) && (JSON.stringify(defaultState[key])?.length !== JSON.stringify(currentState[key])?.length)) {
            // console.log({
            // 'JSON.stringify(defaultState[key])': JSON.stringify(defaultState[key]),
            // 'JSON.stringify(currentState[key])': JSON.stringify(currentState[key]),
            // repliesModalState,
            // key,
            // defaultState,
            // currentState,
            // 'JSON.stringify(currentState[key]?.toString())': JSON.stringify(currentState[key]?.toString()),
            // 'JSON.stringify(defaultState[key]?.toString())': JSON.stringify(defaultState[key]?.toString()),
            // 'Number(null)': Number(null),
            // [`JSON.parse('/"/"')`]: JSON.parse(JSON.stringify(defaultState[key])),
            // [`(defaultState[key] === currentState[key])`]: (defaultState[key] === currentState[key]),
            // [`defaultState[key]`]: defaultState[key],
            // [`currentState[key]`]: currentState[key],
            // 'Boolean(defaultState[key]) === Boolean(currentState[key])': Boolean(defaultState[key]) === Boolean(currentState[key]),
            // 'Boolean(defaultState[key])': Boolean(defaultState[key]),
            // 'Boolean(currentState[key])': Boolean(currentState[key]),
            // [`(key === 'body' && !!(Boolean(defaultState[key]) !== Boolean(currentState[key])))`]: (key === 'body' && !!(Boolean(defaultState[key]) !== Boolean(currentState[key]))),
            // });
            if(![`attachmentsForShow`, `voice_message_data_src`].includes(key)) {
               showModal = true
               if(key === 'body' && (defaultState[key] === null && currentState[key] === '')) {
                  showModal = false
               }
               if(key === 'voice_message_price' && (defaultState[key] === null && currentState[key] === '')) {
                  showModal = false
               }
            }
         }
      })

      return showModal
   }

   const beforeUnloadAlertPopup = (e) => {

      if(repliesModalState === 'create') {

         if(checkStateChange(defaultState, newReplyData, repliesModalState)) {
            e.preventDefault();
            e.returnValue = '';
         }
      }

      if(repliesModalState === 'edit') {

         if(checkStateChange(repliesData?.data?.find(it => it?.id === currentReplyData?.id), currentReplyData, repliesModalState)) {
            e.preventDefault();
            e.returnValue = '';
         }
      }
   }

   const onRefMount = (iRef) => {

      if(currentData?.attachmentsForShow) {

         setDialogueContainerState({
            selectedAttachments: {
               ...selectedAttachments,
               [+conversationId]: [...currentData?.attachmentsForShow],
            },
         })

      }

      if(repliesModalState === 'edit' && repliesTextFieldRef.current && currentReplyData?.body) {
         repliesTextFieldRef.current.innerText = currentReplyData?.body
         setTextFieldLength(calcTextLength(currentReplyData?.body, getSubjectVariablesList(getAllVariables())))

      }

   }

   const onRefUnmount = (iRef) => {

      if(currentData?.attachmentsForShow) {

         setDialogueContainerState({
            selectedAttachments: {
               ...selectedAttachments,
               [+conversationId]: [],
            },
         })

      }

      if(repliesModalState === 'edit' && repliesTextFieldRef.current && currentReplyData?.body) {
         repliesTextFieldRef.current.innerText = ''
         setTextFieldLength(0)

      }

   }


   const headerState = () => {
      let headerOptions

      switch (repliesModalState) {
         case 'create':

            headerOptions = {
               title: 'Create new reply',
               icon: 'add',
            }
            break;
         case 'edit':

            headerOptions = {
               title: 'Edit saved reply',
               icon: 'pencil',
            }
            break;

         default:
            headerOptions = {
               title: 'Saved replies',
               icon: 'saved-replies',
            }
            break;
      }

      return headerOptions
   }

   const headerSection = () => {
      return <>
         <div
            id='saved_replies_modal_header'
            className='w-full flex flex-col items-center [@media(max-width:1024)]:px-2 h-[76px]'>
            <div
               className={ `h-10 w-10 mx-auto flex items-center justify-center mb-3 rounded-full ` }
               style={ {
                  backgroundColor: generateTextColor.generateRGBColor(!isAdmin ? window?.skinSecondaryColor : window?.skinPrimaryColor, 0.12),
               } }
            >
               <Icon
                  name={ headerState().icon }
                  size={ '2xl' }
                  color={ `action` }
                  // primaryColor={ !isAdmin ? window?.skinSecondaryColor : window?.skinPrimaryColor }
               />
            </div>
            <p className='text-major dark:text-major-dark text-xl font-semibold leading-6'>
               { headerState().title }
            </p>
         </div>
      </>
   }
   // eslint-disable-next-line no-unused-vars

   const getAllVariables = () => {
      const info = {
         siteName: site_long_name,
         screenName: screen_name,
         adminsState: single_or_multiple_admins,
         url: site_url,
      }
      let variables = getBaseVariablesList(info, false ? ['<member-username>'] : []);

      return variables;
   }


   const onCloseModal = () => {

      if(window.innerWidth < 1024) {
         setAnimate('down')
         timeout = setTimeout(() => {
            onClose()
            setDialogueContainerState({
               replyErrors: {},
            })
         }, 800);
         return
      }
      onClose()
      setDialogueContainerState({
         replyErrors: {},
      })
   }

   useEffect(() => {

      return () => {
         clearTimeout(timeout)
         onChangeModalState('list')
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])


   const calcTextLength = (value, variables) => {
      if(!value) return 0;
      let result = value;
      for(const iterator of variables) {
         if(iterator.valueLimit){
            let editorVariabel = iterator.text;
            editorVariabel = editorVariabel.replace('<', '&lt;');
            editorVariabel = editorVariabel.replace('>', '&gt;');
            result = result.replaceAll(iterator.text, randomstring.generate(iterator.valueLimit));
            result = result.replaceAll(editorVariabel, randomstring.generate(iterator.valueLimit));
         }
      }
      return result.length;
   }

   const addVariableIntoInput = (value, type = 'input') => {
      let el


      if(type === 'input') {

         el = repliesRef.current;

         const selectionStart = el.selectionStart;

         if('safari' !== getBrowserName()){
            window.document.execCommand('insertText', false, value);
         } else {
            insertTextMac(el, selectionStart, value)
         }
         el.selectionStart = el.selectionEnd = selectionStart + value.length;
         el.focus();

      }

      if(type === 'textField') {
         el = repliesTextFieldRef.current;

         if(el?.textContent?.length + value?.length > 1000) {
            repliesTextFieldRef?.current.blur()
            toast.error(`${ 1000 } character limit has been reached`, {
               toastId: 'limit_error',
            })
            return

         }

         // Create a range and selection
         const range = document.createRange();
         const selection = window.getSelection();

         // Set the range to the end of the contenteditable div
         range.selectNodeContents(el);
         range.collapse(false); // Collapse the range to the end

         // Clear any existing selections and add the new range
         selection.removeAllRanges();
         selection.addRange(range);

         // Insert the text at the current selection
         const textNode = document.createTextNode(value);
         // const textNode = document.createTextNode(calcTextLength(value, getSubjectVariablesList(getAllVariables())));
         range.insertNode(textNode);


         // Move the cursor after the inserted text
         range.setStartAfter(textNode);
         range.collapse(true);
         selection.removeAllRanges();
         selection.addRange(range);

         el.focus();
      }

      return
   }

   const switchShowEmojis = async (type) => {
      if('input' === type) {

         let ctrl = repliesRef.current;
         let start = inputCursorPosition

         if(!start) {
            start = ctrl?.value?.length
         }
         if(ctrl != null) {
            if(ctrl.createTextRange) {
               let range = ctrl.createTextRange();
               range.move('character', start);
               range.select();
            }
            else {
               if(ctrl.selectionStart) {
                  ctrl.focus();
                  ctrl.setSelectionRange(start, start);
               }
               else
                  ctrl.focus();
            }
         }
      }

      if('textField' === type) {
         let ctrl = repliesTextFieldRef.current;
         let start = textFieldCursorPosition

         if(!start) {
            start = ctrl.textContent.length
         }
         if(ctrl != null) {
            if(ctrl.createTextRange) {
               let range = ctrl.createTextRange();
               range.move('character', start);
               range.select();
            }
            else {
               if(ctrl.selectionStart) {
                  ctrl.focus();
                  ctrl.setSelectionRange(start, start);
               }
               else
                  ctrl.focus();
            }
         }
      }

   }

   const onFocusInput = async (type, value) => {

      if('input' === type) {
         let ctrl = repliesRef.current;
         let start = inputCursorPosition
         if(!start) {
            start = ctrl.value.length
         }
         if(ctrl != null) {
            if(ctrl.createTextRange) {
               let range = ctrl.createTextRange();
               range.move('character', start);
               range.select();
            }
            else {
               if(ctrl.selectionStart) {
                  ctrl.focus();
                  ctrl.setSelectionRange(start, start);
               }
               else
                  ctrl.focus();
            }
         }
      }

      if('textField' === type) {
         let ctrl = repliesTextFieldRef.current;
         let start = textFieldCursorPosition

         if(!start) {
            start = ctrl.textContent.length; // Use textContent for contenteditable
         }

         if(ctrl != null) {
            ctrl.focus();

         }
      }

   }

   const getCurrentPosition = (type) => {
      let oField = repliesRef.current;
      if('textField' === type){
         oField = repliesTextFieldRef.current;
      }
      let iCaretPos = 0;
      if(document.selection) {
         oField.focus();
         let oSel = document.selection.createRange();
         oSel.moveStart('character', -oField.value.length);
         iCaretPos = oSel.text.length;
      }

      else if(oField.selectionStart || +oField.selectionStart === 0)
         iCaretPos = oField.selectionDirection === 'backward' ? oField.selectionStart : oField.selectionEnd;

      return iCaretPos;
   }

   const addEmoji = (e, type) => {
      const sym = e.unified.split('-');
      const codesArray = [];
      sym.forEach(el => codesArray.push(`0x${ el }`));
      const emojiPic = String.fromCodePoint(...codesArray);
      if('input' === type || 'textField' === type) {
         let el = repliesRef.current;
         if('textField' === type){
            el = repliesTextFieldRef.current;
         }
         const selectionStart = el.selectionStart;
         if('safari' !== getBrowserName()){
            window.document.execCommand('insertText', false, emojiPic);
         } else {
            insertTextMac(el, selectionStart, emojiPic)
         }
         el.selectionStart = el.selectionEnd = selectionStart + emojiPic.length;
         el.focus();

      }
      // if('editor' === type) {
      //    let el = editorRef.current
      //    el.editor.insertContent(`${ emojiPic } `)
      // }
   }


   function onSortEnd({ oldIndex, newIndex }){
      const newData = arrayMoveImmutable(replies, oldIndex, newIndex)
      if(oldIndex !== newIndex){
         const orderedData = newData.map((item, index) => {
            return {
               id: item.id,
               order: newData.length - index,
               name: item?.name,
            };
         })//.reverse()//.sort((a, b) => a.order - b.order);
         onReorderEnd(orderedData, newData)
      }
   }

   const onSaveDurationData = (data, callback) => {

      const {
         chat_countdown_default_duration,
         chat_countdown_default_duration_checked,
         duration_in_minutes,
         show_duration_countdown_to_member,
      } = data

      textFieldElementProps?.onSaveDurationData({
         chat_countdown_default_duration,
         chat_countdown_default_duration_checked,
         duration_in_minutes,
         show_duration_countdown_to_member,
      },  () => {
         callback && callback()
         setReplyData({
            // chat_countdown_default_duration,
            // chat_countdown_default_duration_checked,
            duration_in_minutes,
            show_duration_countdown_to_member,
         }, repliesModalState)
      }, true)


   }

   const currentData = isEditMode ? currentReplyData : newReplyData
   const disableSaveButton = (QueryParams.getParam('recorded_voice') === 'start') || (currentData?.attachments && !!currentData?.attachments?.find(el => el?.status && el?.status?.includes('loading')))
   const isVoiceMessage = (QueryParams.getParam('recorded_voice') === 'start' || QueryParams.getParam('recorded_voice') === 'stop')

   return (
      <Modal
         contentPosition={ window.innerWidth < 1024 ? 'bottom' : 'center' }
         onClose={ () => {

            if(repliesModalState === 'create') {

               if(checkStateChange(defaultState, newReplyData, repliesModalState)) {
                  setDialogueContainerState({
                     isOpenRepliesDiscardModal: true,
                  })
                  return
               }
            }

            if(repliesModalState === 'edit') {

               if(checkStateChange(repliesData?.data?.find(it => it?.id === currentReplyData?.id), currentReplyData, repliesModalState)) {
                  setDialogueContainerState({
                     isOpenRepliesDiscardModal: true,
                  })
                  return
               }
            }

            if(isSendingReply) {
               return
            }

            if(['create', 'edit'].includes(repliesModalState)) {
               onChangeModalState('list')
               setReplyData('initial', 'create')
               setReplyData('initial', 'edit')
               setDialogueContainerState({
                  replyErrors: {},
               })
               return
            }
            setTextFieldLength(0)

            onCloseModal()
         } }
         contentWidth={ window.innerWidth < 1024 ? '100%' : '880px' }
         animate={ window.innerWidth < 1024 ? animate : null }
         className='h-full overflow-hidden'
         closeOnClickOutside={ false }
         otherClassName={ cx({
            '!max-h-[80vh] lg:!max-h-[90vh] overflow-hidden ': true,
            'h-full ': ['list'].includes(repliesModalState) && ((window?.innerHeight <= 1080 && replies?.length > 10) || (window?.innerHeight > 1080 && window?.innerHeight < 1200 && replies?.length > 12) || (window?.innerHeight >= 1200 && window?.innerHeight < 1400 && replies?.length > 14) || (window?.innerHeight >= 1400 && window?.innerHeight < 1600 && replies?.length > 18)),
            'lg:min-h-[650px]': ['list', 'edit', 'create'].includes(repliesModalState),
            'lg:!min-h-[582px]': messageType === 'voice' || isVoiceMessage,
            'lg:!min-h-[590px]': !isChangingRepliesModalState && isEmptyList,
         }) }
      >
         <div
            className={ cx({
               [`flex flex-col [@media(max-width:1024px)]:min-h-[55vh] [@media(max-width:1024px)]:max-h-[80vh] **lg:!min-h-[638px] h-full **!h-fit **lg:max-h-[90vh]`]: true,
            }) }>
            <div
               className='h-14 w-full flex items-center justify-end pt-2 px-2'
            >
               <Button
                  backgroundColor='transparent'
                  iconName='close'
                  fontIconColor='secondary'
                  classNames='!w-fit !p-[10px]'
                  fontIconSize='extraLarge'
                  onClick={ () => {

                     if(repliesModalState === 'create') {

                        if(checkStateChange(defaultState, newReplyData, repliesModalState)) {
                           setDialogueContainerState({
                              isOpenRepliesDiscardModal: true,
                           })
                           return
                        }
                     }

                     if(repliesModalState === 'edit') {

                        if(checkStateChange(repliesData?.data?.find(it => it?.id === currentReplyData?.id), currentReplyData, repliesModalState)) {
                           setDialogueContainerState({
                              isOpenRepliesDiscardModal: true,
                           })
                           return
                        }
                     }

                     if(isSendingReply) {
                        return
                     }

                     if(['create', 'edit'].includes(repliesModalState)) {
                        onChangeModalState('list')
                        setReplyData('initial', 'create')
                        setReplyData('initial', 'edit')
                        setDialogueContainerState({
                           replyErrors: {},
                        })
                        // return
                     }
                     onCloseModal()
                  } }
               />
            </div>
            <div className='saved-replies-variables' />
            {
               isChangingRepliesModalState && (
                  <>
                     <div className='py-4 **mt-8 flex w-full h-[calc(100%-56px)] items-center justify-center **absolute z-20 **top-0 [@media(max-width:1024px)]:my-auto lg:py-[245px]'>
                        <CircleLoader />
                     </div>
                  </>
               )
            }
            {
               !isChangingRepliesModalState && isEmptyList && <>
                  <div
                     className='flex flex-col justify-between bg-panel dark:bg-panel-dark px-6 pb-6 max-lg:px-4 max items-center text-center select-none gap-7 lg:rounded-b-xlg'
                     style={ {
                        maxHeight: window?.innerWidth > 1024 ? 460 : '55vh',
                        minHeight: window?.innerWidth > 1024 ? 390 : 240,
                     } }
                  >
                     {
                        !isChangingRepliesModalState &&
                            headerSection()
                     }
                     <div className='min-h-[294px] lg:min-h-[294px] h-full w-full flex flex-col items-center justify-center gap-3 lg:my-[56px] lg:py-6 lg:max-h-[406px]'>
                        <div className='flex flex-col text-center gap-1 items-center justify-center'>
                           {/* {
                              !!emptyStateSvg && <img src={ emptyStateSvg  } alt='empty-state' className={ `h-18 w-18 hidden` } />
                           } */}
                           <p className='text-secondary leading-5 font-medium'>
                              You don’t have any saved replies
                           </p>
                           <p className='text-placeholder leading-[18px] text-sm'>
                              Start by clicking on “Create new reply”
                           </p>
                        </div>
                        <Button
                           text='Create new reply'
                           backgroundColor='action'
                           textSize='base'
                           primaryColor
                           classNames='!h-11 !w-fit !rounded-lg !py-3 !px-5 mt-3'
                           textClassName='!leading-5'
                           fontWeight='medium'
                           iconName='add'
                           fontIconSize='extraLarge'
                           onClick={ () => {
                              setTextFieldLength(0)
                              onChangeModalState('create')
                           } }
                           iconPosition='left'
                           textMarginX='2'
                        />
                        <Button
                           text='More info on saved replies'
                           backgroundColor='transparent'
                           textSize='small'
                           primaryColor
                           classNames='!h-9 !w-fit !rounded-lg !py-3 !px-5'
                           textClassName='!leading-5'
                           fontWeight='medium'
                           iconName='attention-outline'
                           fontIconSize='extraLarge'
                           onClick={ () => {
                              Intercom.showArticle(ARTICLE_ID_ON_SAVED_REPLIES)
                           } }
                           iconPosition='left'
                           textMarginX='2'
                        />
                     </div>
                  </div>
               </>
            }
            {
               !isChangingRepliesModalState && !isEmptyList && (
                  <div
                     className='flex flex-col flex-1 h-full bg-panel dark:bg-panel-dark **px-6 pb-4 lg:pb-6 max-lg:px-4 max items-center text-center select-none gap-1 lg:rounded-b-xlg **lg:max-h-[calc(90vh-136px)] overflow-y-auto'
                  >
                     {
                        headerSection()
                     }
                     {
                        repliesModalState === 'list' &&
                           <div
                              className={ cx({
                                 // 'w-full mt-8 overflow-y-auto ams-custom-scrool': true,
                                 'w-full mt-8 h-full flex-1 **overflow-y-auto overflow-hidden': true,
                                 // [`lg:max-h-[calc(90vh-212px)]`]: true,
                              }) }
                              // style={ {
                              //    maxHeight: window.innerWidth > 1024 ? '55vh' : 400,
                              //    minHeight: 80,
                              // } }
                              style={ {
                                 // maxHeight: window?.innerWidth > 1024 ? 460 : '55vh',
                                 // minHeight: window?.innerWidth > 1024 ? 390 : 240,
                                 // maxHeight: window?.innerWidth > 1024 ? 'calc(100%-40px)' : '55vh',
                              } }
                           >
                              <div className='flex w-full flex-col lg:flex-row h-[84px] lg:h-10 items-center justify-between **px-4 lg:px-6'>
                                 <div className='flex w-full gap-2'>
                                    <Button
                                       text='Create new reply'
                                       backgroundColor='transparent'
                                       textSize='small'
                                       primaryColor
                                       classNames='!w-fit py-2 !h-9 px-3.5 min-w-[170px]'
                                       textClassName='!leading-[18px] !ml-2'
                                       padding='none'
                                       textMarginX='0'
                                       onClick={ () => {
                                          setTextFieldLength(0)
                                          onChangeModalState('create')
                                       // onChangeRepliesFilter({ search: '' })
                                       } }
                                       iconName='add'
                                       iconPosition='left'
                                       disabled={ isReordering }
                                    />
                                    <Button
                                       text={ isReordering ? 'Done' : 'Reorder' }
                                       backgroundColor='transparent'
                                       textSize='small'
                                       textColor={ isReordering ? 'action' : 'secondary' }
                                       fontIconColor='secondary'
                                       // primaryColor
                                       classNames={ isReordering ? '**px-3.5 !w-fit !min-w-[41px]' : '!w-fit py-2 !h-9 px-3.5 !min-w-[110px]' }
                                       textClassName={ isReordering ? '!leading-[18px]' : '!leading-[18px] !ml-2' }
                                       padding='none'
                                       textMarginX='0'
                                       onClick={ () => {
                                          setIsReordering(!isReordering)
                                       } }
                                       iconName={ isReordering ? null : 'drag' }
                                       iconPosition='left'
                                    />
                                 </div>
                                 <Search
                                    onChange={ (e) => {
                                       onChangeRepliesFilter({ search: e })
                                    } }
                                    placeholder='Search'
                                    height='h-10'
                                    iconSize='xl'
                                    className='lg:min-w-[320px] lg:max-w-[320px]'
                                    version='2'
                                    value={ repliesFilter?.search || '' }
                                    disabled={ false }
                                    clearIcon='xl'
                                    // isDebounce
                                    showTooltip
                                 />
                              </div>
                              {/* <div
                                 className={ cx({
                                    'flex flex-col mt-2 w-full overflow-x-hidden **ams-custom-scrool custom-scrollbar relative overflow-y-auto ': true,
                                    // [`[@media(max-width:1024px)]:h-[calc()]`]: window?.innerWidth > 1024 ? (document.getElementById('repliesList')?.clientHeight > 384) : true,
                                    [`[@media(max-width:1024px)]:h-[calc(80vh-234px)]`]: window?.innerWidth < 1024,
                                    [`max-h-[calc(100%-56px)] h-full`]: window?.innerWidth > 1024,
                                 }) }
                                 style={ {
                                    // maxHeight: window?.innerWidth > 1024 ? 384 : 'auto',
                                    // minHeight: window?.innerWidth > 1024 ? 384 : 'auto',
                                    // maxHeight: window?.innerWidth > 1024 ? `${ document.getElementById('repliesList')?.clientHeight - (8 + 40 + 32 + 76 + 48) }` : 'auto',
                                 } }
                                 id='repliesList'
                                 onScroll={ handleRepliesModalScroll }
                              > */}

                              {
                                 (
                                    <SortableContainer
                                       helperClass='sortable-helper'
                                       useDragHandle
                                       axis='xy'
                                       onSortEnd={ onSortEnd }
                                       wrapperProps={ {
                                          className: cx({
                                             'flex flex-col mt-2 w-full overflow-x-hidden **ams-custom-scrool custom-scrollbar relative overflow-y-auto ': true,
                                             // [`[@media(max-width:1024px)]:h-[calc()]`]: window?.innerWidth > 1024 ? (document.getElementById('repliesList')?.clientHeight > 384) : true,
                                             [`[@media(max-width:1024px)]:h-[calc(80vh-270px)]`]: window?.innerWidth < 1024,
                                             [`max-h-[calc(100%-56px)] h-full`]: window?.innerWidth > 1024,
                                             [`!min-h-[272px]`]: window?.innerWidth > 1024 && replies?.length <= 3 && replies?.length > 0 && !isLoadingReplies,
                                             [`!min-h-[340px]`]: window?.innerWidth > 1024 && replies?.length <= 5 && replies?.length > 0 && !isLoadingReplies,
                                          }),
                                          id: 'repliesList',
                                          onScroll: handleRepliesModalScroll,
                                       } }
                                    >
                                       {
                                          (isLoadingReplies) &&
                                             <div className='py-4 **mt-8 flex w-full h-full items-center justify-center **absolute z-20 **top-0 [@media(max-width:1024px)]:my-[30vh] lg:my-[150px]'>
                                                <CircleLoader />
                                             </div>
                                       }
                                       {
                                          (!isLoadingReplies && replies?.length === 0 && !!repliesFilter?.search) &&
                                      (
                                         <div className='py-4 **mt-8 flex w-full h-full items-center justify-center **absolute z-20 **top-0 [@media(max-width:1024px)]:my-[30vh] lg:my-[160px]'>
                                            <p className='text-secondary font-medium  leading-6'>
                                               No results
                                            </p>
                                         </div>
                                      )
                                       }
                                       {
                                          (!isLoadingReplies && replies?.length > 0) && replies?.map((reply, ind) => {
                                             return <SortableItem
                                                onSortEnd={ onSortEnd }
                                                useDragHandle
                                                shouldUseDragHandle={ true }
                                                key={ `item-${ ind }` }
                                                index={ ind }
                                                reply={ reply }
                                                isSendingReply={ isSendingReply }
                                                isReordering={ isReordering }
                                                currentReply={ currentReply }
                                                openOptionsModal={ openOptionsModal }
                                                setOpenOptionsModal={ setOpenOptionsModal }
                                                setCurrentReply={ setCurrentReply }
                                                setSelectedReply={ setSelectedReply }
                                                setReplyData={ setReplyData }
                                                onChangeModalState={ onChangeModalState }
                                                onClickDeleteReply={ onClickDeleteReply }
                                                onChangeRepliesFilter={ onChangeRepliesFilter }
                                                onClickSendReply={ onClickSendReply }
                                                setDialogueContainerState={ setDialogueContainerState }
                                                sendingReply={ sendingReply }
                                                optionsStyles={ optionsStyles }
                                                setOptionsStyles={ setOptionsStyles }
                                                replies={ replies }

                                             />
                                          })
                                       }
                                       {
                                          !isLoadingReplies && isLoadingRepliesNextPage &&
                                          <div className='py-4 flex w-full h-full items-center justify-center z-20 '>
                                             <CircleLoader />
                                          </div>
                                       }
                                    </SortableContainer>
                                 )
                              }
                              {/* </div> */}

                           </div>
                     }
                     {
                        (repliesModalState === 'create' || repliesModalState === 'edit') &&
                           <div
                              className={ cx({
                              // 'w-full mt-8 overflow-y-auto ams-custom-scrool': true,
                                 'w-full pt-8 [@media(max-width:1024px)]:overflow-y-auto custom-scrollbar  **[@media(max-width:1024px)]:max-h-[45vh] **lg:max-h-[60vh] overflow-y-auto lg:px-6 h-full': true,
                                 'max-h-[calc(80vh-160px)] lg:max-h-[calc(90vh-160px)]': repliesModalState === 'create',
                                 'max-h-[calc(80vh-208px)] lg:max-h-[calc(90vh-208px)]': repliesModalState === 'edit',
                              }) }
                              // style={ {
                              //    maxHeight: window.innerWidth > 1024 ? '55vh' : 400,
                              //    minHeight: 80,
                              // } }
                              style={ {
                                 // maxHeight: window?.innerWidth > 1024 ? 524 : '55vh',
                                 // minHeight: window?.innerWidth > 1024 ? 390 : 240,
                              } }
                           >
                              <div className='relative flex flex-col w-full mt-4'>
                                 <div className='flex justify-between mb-2 items-center h-6'>
                                    <span className='text-base leading-tight select-none font-medium text-major dark:text-major-dark'>
                                       Reply name
                                    </span>
                                    {/* <VariableList
                                       variableList={ getSubjectVariablesList(getAllVariables()) }
                                       selectVariable={ (variable) => {
                                          // selectVariable(variable, 'input')
                                          switchShowEmojis('input');
                                          addVariableIntoInput(variable, 'input')
                                       } }
                                       onClickOpen={ (variable) => onFocusInput('input') }
                                       name={ `saved_replies_subject_variable_list` }
                                       className=''
                                       isInsideModal={ false }
                                    /> */}
                                 </div>
                                 <Input
                                    onChange={ (name, value) => {
                                       if(value?.length > 50) {
                                          toast.error(`50 character limit has been reached`, { toastId: 'reply_name_limit_reached' })
                                          return
                                       } else {
                                          setReplyData({ name: value }, repliesModalState)
                                       }
                                    } }
                                    type='text'
                                    name='reply_name'
                                    value={ isEditMode ? currentReplyData?.name : newReplyData?.name }
                                    initialvalue={ isEditMode ? currentReplyData?.name : newReplyData?.name }
                                    errorMessage={ errors && errors.name && errors.name[0] }
                                    placeholder='What’s your message about?'
                                    description={ `${  isEditMode ? currentReplyData?.name?.length || 0 : newReplyData?.name?.length || 0 } of 50 characters used` }
                                    descriptionFontSize='xs'
                                    descriptionFontWeight='normal'
                                    onInputBlur={ (e) => {
                                       const positionCursor = getCurrentPosition('input')
                                       setInputCursorPosition(positionCursor);
                                    } }
                                    inputRef={ repliesRef }
                                    className='input-with-emoji'
                                    height='10'
                                    descriptionPosition='right'
                                    hideDescriptionWhenError
                                    onSetError={ bool => setTitleIsError(bool) }
                                    onInputFocus={ () => {
                                       if(errors?.name) {
                                          setDialogueContainerState({
                                             replyErrors: {
                                                ...errors,
                                                name: [],
                                             },
                                          })

                                       }
                                       // console.log('x');
                                       // if(!!inputRef) {
                                       //    console.log(inputRef.current.value);
                                       //    const length = inputRef.current.value.length;
                                       //    console.log(length);
                                       //    inputRef.current.setSelectionRange(length, length);
                                       //    inputRef.current.focus()
                                       //    inputRef.current.setSelectionRange(length, length);
                                       //    inputRef.current.focus()
                                       // }
                                       // console.log('y');
                                    } }
                                 />
                                 {
                                    !titleIsError && (
                                       <EmojiContent
                                          switchShowEmojis={ (e) => switchShowEmojis('input') }
                                          // showEmojis={ e => showEmojis(e, textRef) }
                                          addEmoji={  (e) => addEmoji(e, 'input') }
                                          isMobile={ screenWidth < 1025 }
                                          id={ `saved_replies_name_emoji` }
                                          style={ {
                                             width: '20px',
                                             height: '20px',
                                             top: '32px',
                                             marginTop: '10px',
                                             marginBottom: '6px',
                                             zIndex: 1,
                                          } }
                                          className='absolute right-0 mr-4 h-5 w-5'
                                          size='h-5 w-5'
                                          primaryIconSize='20px'
                                       />
                                    )
                                 }
                              </div>
                              <div
                                 className={ cx({
                                    // 'flex flex-col mt-2 w-full [@media(max-width:1024px)]:overflow-y-auto **ams-custom-scrool custom-scrollbar': true,
                                    'flex flex-col mt-2 w-full ': true,
                                 }) }
                                 // style={ {
                                 //    maxHeight: 420,
                                 //    minHeight: 80,
                                 // } }
                              >
                                 <div className='relative flex flex-col w-full mt-4'>
                                    <div className='flex w-full justify-between items-center mb-2 h-5'>
                                       <div className='flex justify-between items-center h-5'>
                                          <span className='text-base leading-tight select-none font-medium text-major dark:text-major-dark'>
                                             Message body
                                          </span>
                                       </div>
                                       <div
                                          className={ cx({
                                             'flex justify-between items-center h-9': true,
                                             [`invisible`]: messageType === 'voice',
                                          }) }>
                                          <VariableList
                                             variableList={ getSubjectVariablesList(getAllVariables()) }
                                             selectVariable={ (variable) => {
                                                // selectVariable(variable, 'input')
                                                switchShowEmojis('textField');
                                                addVariableIntoInput(variable, 'textField')
                                                setTextFieldLength(calcTextLength(repliesTextFieldRef?.current?.textContent?.trim(), getSubjectVariablesList(getAllVariables())))

                                             } }
                                             onClickOpen={ (variable) => onFocusInput('textField', variable) }
                                             name={ `saved_replies_body` }
                                             className=''
                                             selectorForTooltip={ `.saved-replies-variables` }
                                          />
                                       </div>
                                    </div>
                                    <div
                                       // className='h-full w-full [@media(max-width:1024px)]:overflow-y-auto **ams-custom-scrool [@media(max-width:1024px)]:custom-scrollbar'
                                       className={ cx({
                                          'h-full w-full **[@media(max-width:1024px)]: overflow-y-auto **ams-custom-scrool custom-scrollbar': true,
                                          // 'lg:overflow-y-scroll': !(document?.getElementById('savedRepliesTextField') && document?.getElementById('savedRepliesTextField')?.scrollHeight < 280),
                                          'lg:overflow-y-visible': !!(document?.getElementById('savedRepliesTextField') && document?.getElementById('savedRepliesTextField')?.scrollHeight < 280),
                                          // 'lg:overflow-y-visible': true,
                                       }) }
                                       style={ {
                                          maxHeight: 458,
                                          minHeight: 80,
                                       } }
                                    >
                                       <NewMessageField
                                          mutedInfo={ textFieldElementProps?.mutedInfo }
                                          isMobile={ textFieldElementProps?.isMobile }
                                          sendMessage={ textFieldElementProps?.sendMessage }
                                          isAdmin={ textFieldElementProps?.isAdmin }
                                          onUserTyping={ textFieldElementProps?.onUserTyping }
                                          replyMessage={ textFieldElementProps?.replyMessage }
                                          canelReplyMessage={ textFieldElementProps?.canelReplyMessage }
                                          inputRef={ repliesTextFieldRef }
                                          onReadMessage={ textFieldElementProps?.onReadMessage }
                                          inputForIosFocus={ textFieldElementProps?.inputForIosFocus }
                                          setMutedMemberInfo={ textFieldElementProps?.setMutedMemberInfo }
                                          isConnected={ textFieldElementProps?.isConnected }
                                          isMessageToLong={ textFieldElementProps?.isMessageToLong }
                                          setErrorToLong={ textFieldElementProps?.setErrorToLong }
                                          selectByLiber={ textFieldElementProps?.selectByLiber }
                                          removeSelectedContent={ textFieldElementProps?.removeSelectedContent }
                                          deleteVaultVideo={ textFieldElementProps?.deleteVaultVideo }
                                          isGroup={ textFieldElementProps?.isGroup  }
                                          userUuid={ textFieldElementProps?.userUuid }
                                          content={ {
                                             allow_download: isEditMode ? !!currentReplyData?.allow_download : !!newReplyData?.allow_download,
                                             isBlurPoster: isEditMode ? !!currentReplyData?.locked_poster_is_blur : !!newReplyData?.locked_poster_is_blur,
                                             price: (isEditMode ? currentReplyData?.unlock_price : newReplyData?.unlock_price) || '0',
                                             add_member_info_to_watermark: isEditMode ? !!currentReplyData?.add_member_info_to_watermark : !!newReplyData?.add_member_info_to_watermark,
                                          } }
                                          chatRoomId={ textFieldElementProps?.chatRoomId }
                                          onSaveUploadPhotos={ textFieldElementProps?.onSaveUploadPhotos }
                                          maxPrice={ textFieldElementProps?.maxPrice }
                                          isLivstream={ textFieldElementProps?.isLivstream }
                                          onSendVoiceMessage={ textFieldElementProps?.onSendVoiceMessage }
                                          privateAttachmentSettings={ textFieldElementProps?.privateAttachmentSettings }
                                          defaultVoicePrice={ textFieldElementProps?.defaultVoicePrice }
                                          onUpdateRecordStatus={ textFieldElementProps?.onUpdateRecordStatus }
                                          handleUploadImage={ textFieldElementProps?.handleUploadImage }
                                          cancelVideoUplaod={ textFieldElementProps?.cancelVideoUplaod }
                                          checkVaultByHash={ textFieldElementProps?.checkVaultByHash }
                                          createVault={ textFieldElementProps?.createVault }
                                          checkContentTypeInTheChat={ textFieldElementProps?.checkContentTypeInTheChat }
                                          getMediaBlurhash={ textFieldElementProps?.getMediaBlurhash }
                                          containerRef={ textFieldElementProps?.containerRef }
                                          isUpdateState={ textFieldElementProps?.isUpdateState }
                                          onClickCancelEditMessage={ textFieldElementProps?.onClickCancelEditMessage }
                                          isMiniChat={ textFieldElementProps?.isMiniChat }
                                          showFrequentlyUsedEmojis={ textFieldElementProps?.showFrequentlyUsedEmojis }
                                          isDemoMode={ textFieldElementProps?.isDemoMode }
                                          authUser={ textFieldElementProps?.authUser }
                                          // attachments={ textFieldElementProps?.attachments }
                                          // attachmentsForShow={ textFieldElementProps?.attachmentsForShow }
                                          attachments={ isEditMode ? currentReplyData?.attachments : newReplyData?.attachments }
                                          attachmentsForShow={ isEditMode ? currentReplyData?.attachmentsForShow : newReplyData?.attachmentsForShow }
                                          isMultipleAttachments={ true }
                                          onRemoveMedia={ textFieldElementProps?.onRemoveMedia }
                                          onClickCancel={ textFieldElementProps?.onClickCancel }
                                          updateMessageType={ textFieldElementProps?.updateMessageType }
                                          isMaxAttachmentCountReached={ isRepliesMaxAttachmentCountReached ? isRepliesMaxAttachmentCountReached() : textFieldElementProps?.isMaxAttachmentCountReached }
                                          onClickSavedReplies={ textFieldElementProps?.onClickSavedReplies }
                                          isSavedReplies
                                          onInputBlur={ (e) => {
                                             console.log('onInputBlur', e);
                                             setTextFieldCursorPosition(e)
                                          } }
                                          onInputFocus={ () => {
                                             if(errors?.body) {
                                                setDialogueContainerState({
                                                   replyErrors: {
                                                      ...errors,
                                                      body: [],
                                                   },
                                                })

                                             }

                                          } }
                                          textLimit={ 1000 }
                                          onCustomAction={ (action, value, text) => {
                                             // console.log({ action, value, text });
                                             if(action === 'text_length') {

                                                flushSync(() => {
                                                   setReplyData({ body: text }, repliesModalState)
                                                   setTextFieldLength(calcTextLength(text, getSubjectVariablesList(getAllVariables())))
                                                })

                                             }
                                             if(['allow_download', 'locked_poster_is_blur', 'unlock_price', 'add_member_info_to_watermark'].includes(action)) {

                                                const replyData = isEditMode ? currentReplyData : newReplyData

                                                if((action === 'unlock_price' && !('unlock_price' in replyData))) {
                                                   setReplyData({
                                                      [action]: value,
                                                      locked_poster_is_blur: true,
                                                   }, repliesModalState)

                                                }
                                                else {
                                                   setReplyData({ [action]: value }, repliesModalState)
                                                }
                                             }
                                          } }
                                          idForField={ `savedRepliesTextField` }
                                          calcTextLengthFor={ (text) => calcTextLength(text, getSubjectVariablesList(getAllVariables())) }
                                          setReplyData={ textFieldElementProps?.setReplyData }
                                          replyData={ isEditMode ? currentReplyData : newReplyData }
                                          repliesModalState={ repliesModalState }
                                          onInputRefMount={ (iRef) => {
                                             onRefMount(iRef)
                                          } }
                                          onInputRefUnmount={ (iRef) => {
                                             onRefUnmount(iRef)
                                          } }
                                          setMessageType={ (type) => {
                                             setMessageType(type)
                                          } }
                                          readyToSendReply={ !!textFieldElementProps?.readyToSendReply }
                                          errors={ errors && errors.body && errors.body[0] }
                                          getQueryParam={ getQueryParam }
                                          durationInfo={ textFieldElementProps?.durationInfo }
                                          onSaveDurationData={ onSaveDurationData }
                                          isFetchingChatSettingsUpdate={ textFieldElementProps?.isFetchingChatSettingsUpdate }
                                          lastMessage={ textFieldElementProps?.lastMessage }
                                          memberVideoDownloadMultipleWatermarks={ memberVideoDownloadMultipleWatermarks }
                                       />
                                    </div>
                                    <p className={ cx({
                                       'mt-[6px] text-xs leading-[15px] text-secondary text-right': true,
                                       [`hidden`]: messageType === 'voice',
                                       [`!text-error`]: errors?.body?.[0],
                                    }) }>
                                       {
                                          !!errors?.body?.[0] && errors?.body?.[0]
                                       }
                                       {
                                          !errors?.body?.[0] && ` ${ textFieldLength } of 1000 characters`
                                       }
                                    </p>
                                 </div>
                              </div>
                              <Button
                                 text='Save'
                                 backgroundColor='action'
                                 textSize='small'
                                 primaryColor
                                 classNames='mt-8 py-[9px] px-3.5 !rounded-lg'
                                 textClassName='!leading-[18px]'
                                 padding='none'
                                 textMarginX='0'
                                 onClick={ () => {
                                    setDialogueContainerState({
                                       isSavingReply: true,
                                    })
                                    if(['create', 'edit'].includes(repliesModalState)) {
                                       if(repliesModalState === 'create') {
                                          onChangeReply({
                                             ...newReplyData,
                                             // id: Math.random() * (700 - 2000) + 2000,
                                          },
                                          'create',
                                          () => {
                                             onChangeModalState('list')
                                             setReplyData('initial', 'create')
                                             setReplyData('initial', 'edit')
                                             setDialogueContainerState({
                                                isSavingReply: false,
                                                selectedAttachments: {
                                                   ...selectedAttachments,
                                                   [+conversationId]: [],
                                                },
                                             })
                                          })
                                       }
                                       if(repliesModalState === 'edit') {
                                          onChangeReply({ ...currentReplyData },
                                             'edit',
                                             () => {
                                                onChangeModalState('list')
                                                setReplyData('initial', 'create')
                                                setReplyData('initial', 'edit')
                                                setDialogueContainerState({
                                                   isSavingReply: false,
                                                   selectedAttachments: {
                                                      ...selectedAttachments,
                                                      [+conversationId]: [],
                                                   },
                                                })
                                             })
                                       }

                                       return
                                    }
                                 } }
                                 disabled={ !!disableSaveButton }
                                 isLoading={ isSavingReply }
                                 loaderSize={ '6' }
                              />
                              {
                                 isEditMode &&
                                 <>
                                    <div className='w-full flex items-center justify-center'>
                                       <Button
                                          text='Delete reply'
                                          backgroundColor='transparent'
                                          iconName='delete'
                                          textSize='small'
                                          textColor='error'
                                          fontIconColor='error'
                                          iconPosition='left'
                                          // primaryColor
                                          classNames='mt-3 py-[9px] px-3.5 !rounded-lg !h-9'
                                          textClassName='!leading-[18px]'
                                          padding='none'
                                          textMarginX='2'
                                          onClick={ () => {
                                             onClickDeleteReply(currentReplyData)
                                          } }
                                       />
                                    </div>
                                 </>
                              }
                              <div className='w-full flex items-center justify-center'>
                                 <Button
                                    text='Cancel'
                                    backgroundColor='transparent'
                                    // iconName='delete'
                                    textSize='small'
                                    textColor='secondary'
                                    // fontIconColor='error'
                                    iconPosition='left'
                                    // primaryColor
                                    classNames='mt-3 py-[9px] px-3.5 !rounded-lg !h-9 !w-fit'
                                    textClassName='!leading-[18px]'
                                    padding='none'
                                    textMarginX='0'
                                    onClick={ () => {

                                       if(repliesModalState === 'create') {

                                          if(checkStateChange(defaultState, newReplyData, repliesModalState)) {
                                             setDialogueContainerState({
                                                isOpenRepliesDiscardModal: true,
                                             })
                                             return
                                          }
                                       }

                                       if(repliesModalState === 'edit') {

                                          if(checkStateChange(repliesData?.data?.find(it => it?.id === currentReplyData?.id), currentReplyData, repliesModalState)) {
                                             setDialogueContainerState({
                                                isOpenRepliesDiscardModal: true,
                                             })
                                             return
                                          }
                                       }


                                       onChangeModalState('list')
                                       setReplyData('initial', 'create')
                                       setReplyData('initial', 'edit')
                                       return
                                    } }
                                 />
                              </div>

                              {/* <Button
                                 text='Save'
                                 backgroundColor='action'
                                 textSize='small'
                                 primaryColor
                                 classNames='mt-8 py-[9px] px-3.5 !rounded-lg'
                                 textClassName='!leading-[18px]'
                                 padding='none'
                                 textMarginX='0'
                                 onClick={ () => onCloseModal() }
                               /> */}
                           </div>
                     }
                  </div>
               )
            }
         </div>
      </Modal>
   )
}


SavedRepliesModal.propTypes = {
   isAdmin: PropTypes.bool,
   onClose: PropTypes.func,
   replies: PropTypes.array,
   setSelectedReply: PropTypes.func,
   onClickDeleteReply: PropTypes.func,
   onClickEditReply: PropTypes.func,
   repliesModalState: PropTypes.string,
   onChangeModalState: PropTypes.func,
   isChangingRepliesModalState: PropTypes.bool,
   textFieldElementProps: PropTypes.shape({
      isMobile: PropTypes.bool,
      isAdmin: PropTypes.bool,
      sendMessage: PropTypes.func,
      onUserTyping: PropTypes.func,
      canelReplyMessage: PropTypes.func,
      replyMessage: PropTypes.object,
      mutedInfo: PropTypes.object,
      inputRef: PropTypes.any,
      inputForIosFocus: PropTypes.func,
      onReadMessage: PropTypes.func,
      setMutedMemberInfo: PropTypes.func,
      isConnected: PropTypes.bool,
      isGroup: PropTypes.bool,
      isMessageToLong: PropTypes.bool,
      setErrorToLong: PropTypes.func,
      selectByLiber: PropTypes.func,
      removeSelectedContent: PropTypes.func,
      deleteVaultVideo: PropTypes.func,
      userUuid: PropTypes.string,
      content: PropTypes.object,
      onSaveUploadPhotos: PropTypes.func,
      maxPrice: PropTypes.number,
      isLivstream: PropTypes.bool,
      isShowUser: PropTypes.bool,
      userAvatar: PropTypes.string,
      onSendVoiceMessage: PropTypes.func,
      privateAttachmentSettings: PropTypes.object,
      defaultVoicePrice: PropTypes.number,
      intoNotificationCard: PropTypes.bool,
      onUpdateRecordStatus: PropTypes.func,
      handleUploadImage: PropTypes.func,
      cancelVideoUplaod: PropTypes.func,
      checkVaultByHash: PropTypes.func,
      createVault: PropTypes.func,
      checkContentTypeInTheChat: PropTypes.func,
      getMediaBlurhash: PropTypes.func,
      chatRoomId: PropTypes.number,
      containerRef: PropTypes.any,
      isUpdateState: PropTypes.bool,
      onClickCancelEditMessage: PropTypes.func,
      showNickname: PropTypes.bool,
      isMiniChat: PropTypes.bool,
      hideVoiceMessage: PropTypes.bool,
      isMassMessage: PropTypes.bool,
      onCustomAction: PropTypes.func,
      showVariables: PropTypes.bool,
      variableList: PropTypes.array,
      showFrequentlyUsedEmojis: PropTypes.bool,
      attachments: PropTypes.array,
      attachmentsForShow: PropTypes.array,
      isMultipleAttachments: PropTypes.bool,
      onRemoveMedia: PropTypes.func,
      onClickCancel: PropTypes.func,
      isDemoMode: PropTypes.bool,
      authUser: PropTypes.object,
      updateMessageType: PropTypes.string,
      isMaxAttachmentCountReached: PropTypes.bool,
      onClickSavedReplies: PropTypes.func,
      setReplyData: PropTypes.func,
      readyToSendReply: PropTypes.bool,
      durationInfo: PropTypes.object,
      isFetchingChatSettingsUpdate: PropTypes.bool,
      lastMessage: PropTypes.object,
      onSaveDurationData: PropTypes.func,
   }),
   changeRepliesModalState: PropTypes.func,
   isEmptyList: PropTypes.bool,
   repliesRef: PropTypes.object,
   repliesTextFieldRef: PropTypes.object,
   onChangeData: PropTypes.func,
   errors: PropTypes.object,
   isEditMode: PropTypes.bool,
   setReplyData: PropTypes.func,
   repliesData: PropTypes.object,
   newReplyData: PropTypes.object,
   currentReplyData: PropTypes.object,
   isLoadingReplies: PropTypes.bool,
   isLoadingRepliesNextPage: PropTypes.bool,
   onChangeReply: PropTypes.func,
   getReplies: PropTypes.func,
   repliesFilter: PropTypes.object,
   onChangeRepliesFilter: PropTypes.func,
   handleRepliesModalScroll: PropTypes.func,
   setDialogueContainerState: PropTypes.func,
   readyToSendReply: PropTypes.bool,
   onClickSendReply: PropTypes.func,
   isSavingReply: PropTypes.bool,
   replyTimeout: PropTypes.any,
   clearReplyTimeout: PropTypes.func,
   isSendingReply: PropTypes.bool,
   sendingReply: PropTypes.object,
   selectedAttachments: PropTypes.array,
   onReorderEnd: PropTypes.func,
   isRepliesMaxAttachmentCountReached: PropTypes.func,
   getQueryParam: PropTypes.func,
   memberVideoDownloadMultipleWatermarks: PropTypes.bool,
};

export default SavedRepliesModal;
