const initialState = {
   isFetchingList: true,
   isFetchingByFilter: false,
   isFetchingNextPage: false,
   list: {
      data: [],
   },
   confirmDialogs: {
      isOpen: false,
      type: null,
      castMemberData: {},
   },
   isFetchingActionWithCastMember: false,
   isFetchingCastMemberDetails: true,
   isFetchingCreate: false,
   isFetchingUpdate: false,
   createEditErrors: {},
   castMemberAddressEditMode: 'auto',
   listViewType: 'card', // card || table
};

export default initialState;
