import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'common/components/elements/icons';
import Avatar from 'common/components/modules/avatar';


const NotificationMemberAvatar = ({
   isRead,
   avatar,
   iconName,
   iconColor,
   onClick,
   username,
   iconStyles,
}) => {
   let groupSize = '40.62px'
   let position = '3.69'
   return (
      <div
         className='flex cursor-pointer notranslate'
         role='presentation'
         onClick={ onClick }
      >
         {
            !isRead && (
               <div className='flex items-center h-10 mr-2'>
                  <div
                     className='w-2 h-2 rounded-full'
                     style={ { background: '#FF3636' } }
                  />
               </div>
            )
         }
         {
            avatar && !!Array.isArray(avatar) ? (
               <div
                  style={ { minWidth: '40px' } }
                  className='flex justify-center items-center w-10 h-10 rounded-full relative'>
                  {
                     avatar.map((img, index) => {
                        if(index > 2){
                           return null
                        }
                        return (
                           <div
                              style={ {
                                 bottom: `${ index * position }px`,
                                 left: `${ index  * position }px`,
                                 width: groupSize,
                                 height: groupSize,
                              } }
                              key={ index.toString() }
                              className='border absolute rounded-full overflow-hidden p-px flex justify-center items-center border-white'
                           >
                              <Avatar
                                 avatarSrc={ img }
                                 imageClassName='absolute'
                                 styles={ {
                                    filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.12))',
                                    boxSizing: 'border-box',
                                    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.12)',
                                 } }
                              />
                           </div>
                        )
                     })
                  }
               </div>
            ) : (
               <div className={ `relative ${ isRead ? 'w-14 h-14' : 'w-10 h-10' }` }>
                  <div className='w-full h-full rounded-full overflow-hidden notification-item relative'>
                     <Avatar
                        avatarSrc={ avatar }
                        username={ username }
                     />
                  </div>
                  {
                     iconName && (
                        <div
                           className={ `shadow dark:shadow-dark ${ iconColor } flex justify-center items-center absolute z-10 ${ isRead ? 'w-6 h-6' : 'w-4 h-4' }  rounded-full bottom-0 right-0` }
                        >
                           <Icon
                              name={ iconName }
                              color='major-dark'
                              size={ 'medium' }
                              style={ isRead ? { ...iconStyles } : { fontSize: '10px', ...iconStyles } }
                           />
                        </div>
                     )
                  }
               </div>
            )
         }
      </div>

   );
};

NotificationMemberAvatar.propTypes = {
   isRead: PropTypes.bool,
   iconName: PropTypes.string,
   avatar: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
   ]),
   iconColor: PropTypes.string,
   onClick: PropTypes.func,
   username: PropTypes.string,
   iconStyles: PropTypes.object,
};
NotificationMemberAvatar.defaultProps = {
   iconName: 'comment',
   iconColor: 'skin-primary-background',
   username: 'U',
   iconStyles: {},
};

export default React.memo(NotificationMemberAvatar);
