import classNames from "classnames";
import PropTypes from "prop-types";

const HorizontalTabs = ({
   tabs,
   activeTabValue,
   onChangeTab,
}) => {

   return (
      <div
         class='w-full flex gap-2 p-[6px] bg-grey-main dark:bg-black-field rounded-lg border border-divider dark:border-divider-dark'
      >
         {
            tabs.map(({ name, value }) => {
               const isActive = activeTabValue === value
               return (
                  <div
                     key={ value }
                     class={ classNames({
                        'w-full flex items-center justify-center h-10 rounded-lg select-none': true,
                        'bg-panel dark:bg-panel-dark border border-divider dark:border-divider-dark': isActive,
                        'cursor-pointer': !isActive,
                     }) }
                     role='presentation'
                     onClick={ () => onChangeTab(value) }
                  >
                     <span
                        class={ classNames({
                           'text-sm font-medium': true,
                           'text-action dark:text-action-dark': isActive,
                           'text-secondary dark:text-secondary-dark': !isActive,
                        }) }
                     >
                        {name}
                     </span>
                  </div>
               )
            })
         }
      </div>
   )
}

HorizontalTabs.propTypes = {
   tabs: PropTypes.array,
   activeTabValue: PropTypes.string,
   onChangeTab: PropTypes.func,
}

export default HorizontalTabs;
