import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TagSelectors from 'common/components/modules/tag-selectors1';


const SelectTagsAndCast = ({
   tagsList,
   tags,
   membersList,
   members,
   onInputChange = () => {},
   isNewMedia,
   bulkOptions = ['tags', 'casts', 'collections'],
   onSaveEditChange,
   tagsError,
   createTagsError,
   errors = {},
   onChangeErrors,
   collectionsList,
   collections,
   label = 'Info',
   contentType = 'photoset',
}) => {

   const [selectCast, setSelectCast] = useState([]);
   const [selectTags, setSelectTags] = useState([]);
   const [selectCollections, setSelectCollections] = useState([]);

   useEffect(() => {
      if(members && membersList) {
         const newAddCasts = members?.map((element) => {
            let item = element;
            if(!item.id) {
               const cast = membersList?.find((elm) => elm.id === item);
               item = cast;
            }
            return item;
         });
         setSelectCast(newAddCasts);
      }
   }, [members, membersList]);

   useEffect(() => {
      if(tags && tagsList) {
         const newAddTags = tags?.map((element) => {
            let item = element;
            if(!item.id) {
               const tag = tagsList?.find((elm) => elm.id === item);
               item = tag;
            }
            return item;
         });
         setSelectTags(newAddTags);
      }
   }, [tags, tagsList]);

   useEffect(() => {
      if(collections && collectionsList) {
         const newCollections = collections?.map((element) => {
            let item = element;
            if(!item.id) {
               const collection = collectionsList?.find((elm) => elm.id === item);
               item = collection;
            }
            return item;
         });
         setSelectCollections(newCollections);
      }
   }, [collections, collectionsList]);

   function onRemoveItem(type, value, bool) {
      let updatedList = [];
      switch (type) {
         case 'tags':
            updatedList = selectTags.filter((element) => element.id !== value.id);
            setSelectTags(updatedList);
            break;
         case 'casts':
            updatedList = selectCast.filter((element) => element.id !== value.id);
            setSelectCast(updatedList);
            break;
         case 'collections':
            updatedList = selectCollections.filter((element) => element?.id !== value.id);
            setSelectCollections(updatedList);
            break;
         default:
            break;
      }
      if(bool) {
         const selectedIds = updatedList.map((item) => item?.id);
         onInputChange(type, selectedIds, !isNewMedia);
      }
   }

   function onSaveChange(type, data) {
      let selectedData = [];
      switch (type) {
         case 'tags':
            selectedData = tagsList.filter((item) => data.includes(item.id));
            setSelectTags(selectedData);
            break;
         case 'casts':
            selectedData = membersList.filter((item) => data.includes(item.id));
            setSelectCast(selectedData);
            break;
         case 'collections':
            selectedData = collectionsList.filter((item) => data.includes(item.id));
            setSelectCollections(selectedData);
            break;
         default:
            break;
      }
      onInputChange(type, data);
   }

   return (
      <div>
         {!!label && <p className='mb-4 text-major panel-header'>{label}</p>}
         <div className='flex flex-col gap-6'>
            {bulkOptions.includes('casts') && (
               <TagSelectors
                  list={ membersList }
                  selectedList={ selectCast }
                  onSaveChange={ (data) => onSaveChange('casts', data) }
                  onRemove={ (value) => onRemoveItem('casts', value, true) }
                  type='casts'
                  isNewMedia={ isNewMedia }
                  onChangeErrors={ () => onChangeErrors({ ...errors, casts: '' }) }
                  error={ errors?.casts?.[0] }
                  contentType={ contentType }
               />
            )}
            {bulkOptions.includes('collections') && (
               <TagSelectors
                  list={ collectionsList }
                  selectedList={ selectCollections }
                  onSaveChange={ (data) => onSaveChange('collections', data) }
                  onRemove={ (value) => onRemoveItem('collections', value, true) }
                  type='collections'
                  onSaveEditChange={ onSaveEditChange }
                  tagsError={ tagsError }
                  createTagsError={ createTagsError }
                  contentType={ contentType }
               />
            )}
            {bulkOptions.includes('tags') && (
               <TagSelectors
                  list={ tagsList }
                  selectedList={ selectTags }
                  onSaveChange={ (data) => onSaveChange('tags', data) }
                  onRemove={ (value) => onRemoveItem('tags', value, true) }
                  type='tags'
                  onSaveEditChange={ onSaveEditChange }
                  tagsError={ tagsError }
                  createTagsError={ createTagsError }
                  contentType={ contentType }
               />
            )}
         </div>
      </div>
   );
};

SelectTagsAndCast.propTypes = {
   tagsList: PropTypes.array,
   tags: PropTypes.array,
   membersList: PropTypes.array,
   members: PropTypes.array,
   onInputChange: PropTypes.func,
   isNewMedia: PropTypes.bool,
   bulkOptions: PropTypes.array,
   onSaveEditChange: PropTypes.func,
   tagsError: PropTypes.object,
   createTagsError: PropTypes.func,
   errors: PropTypes.object,
   onChangeErrors: PropTypes.func,
   collectionsList: PropTypes.array,
   collections: PropTypes.array,
   label: PropTypes.string,
   contentType: PropTypes.string,
};

export default memo(SelectTagsAndCast);
