import { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import QRCodeStyling from "qr-code-styling";

const CustomizableShareQR = ({
   shareUrl,
   sitePrefix,
   wrapperClass,
   backgroundColor = 'transparent',
   qrColor = '#231FFF',
   wrapperRef,
   appIcon,
}) => {
   let qrRef = useRef(null);

   useEffect(() => {
      if(!qrRef) return;


      const svgString = `
         <svg width='382' height='298' viewBox='0 0 382 298' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M103.571 204C95.5231 204 89 197.477 89 189.429V102.004C89 77.9014 108.611 58.2908 132.714 58.2908C156.817 58.2908 176.429 77.9014 176.429 102.004V145.716C176.429 153.75 182.966 160.287 191 160.287C199.034 160.287 205.571 153.75 205.571 145.716V116.574C205.571 92.4723 225.183 72.8617 249.286 72.8617C273.389 72.8617 293 92.4723 293 116.574V160.287C293 168.335 286.477 174.858 278.429 174.858C270.38 174.858 263.857 168.335 263.857 160.287V116.574C263.857 108.54 257.32 102.004 249.286 102.004C241.251 102.004 234.714 108.54 234.714 116.574V145.716C234.714 169.818 215.103 189.429 191 189.429C166.897 189.429 147.286 169.818 147.286 145.716V102.004C147.286 93.9695 140.749 87.4326 132.714 87.4326C124.68 87.4326 118.143 93.9695 118.143 102.004V189.429C118.143 197.477 111.62 204 103.571 204ZM110.861 21.8599C110.861 33.9285 120.645 43.7199 132.721 43.7199C144.797 43.7199 154.582 33.9356 154.582 21.8599C154.582 9.78422 144.797 0 132.721 0C120.645 0 110.861 9.78422 110.861 21.8599ZM227.432 36.4309C227.432 48.4995 237.217 58.2908 249.293 58.2908C261.369 58.2908 271.153 48.5066 271.153 36.4309C271.153 24.3552 261.369 14.5709 249.293 14.5709C237.217 14.5709 227.432 24.3552 227.432 36.4309Z' fill='${ qrColor }' />
            <path d='M110.203 252.586C107.92 252.586 105.955 253.117 104.292 254.18C102.634 255.243 101.456 256.667 100.757 258.447H100.429C99.8293 256.64 98.7919 255.206 97.3271 254.153C95.857 253.101 94.0586 252.581 91.9317 252.581C89.8048 252.581 88.0167 253.101 86.505 254.138C84.988 255.174 83.9037 256.614 83.2469 258.447H82.8767V254.881C82.8767 253.84 82.0479 253 81.0314 253H77.6325C76.6107 253 75.7871 253.845 75.7871 254.881V283.193C75.7871 284.235 76.616 285.074 77.6325 285.074H81.3546C82.3763 285.074 83.1999 284.235 83.1999 283.193V265.573C83.1999 264.25 83.4606 263.091 83.9767 262.098C84.4928 261.104 85.1913 260.328 86.0671 259.781C86.9429 259.233 87.9125 258.957 88.9759 258.957C90.545 258.957 91.817 259.451 92.797 260.44C93.7718 261.428 94.2619 262.751 94.2619 264.409V283.199C94.2619 284.24 95.0907 285.08 96.1072 285.08H99.6886C100.71 285.08 101.534 284.24 101.534 283.199V265.079C101.534 263.267 102.034 261.8 103.041 260.663C104.047 259.526 105.438 258.962 107.227 258.962C108.728 258.962 110 259.425 111.037 260.349C112.075 261.279 112.596 262.735 112.596 264.723V283.199C112.596 284.24 113.425 285.08 114.441 285.08H118.184C119.206 285.08 120.03 284.24 120.03 283.199V263.554C120.03 259.977 119.112 257.257 117.282 255.392C115.453 253.526 113.091 252.597 110.193 252.597L110.203 252.586Z' fill='${ qrColor }' />
            <path d='M149.475 256.47C148.141 255.147 146.613 254.169 144.888 253.537C143.162 252.904 141.327 252.585 139.388 252.585C136.386 252.585 133.769 253.287 131.543 254.684C129.317 256.082 127.586 258.032 126.351 260.53C125.115 263.027 124.5 265.928 124.5 269.228C124.5 272.528 125.115 275.499 126.345 277.964C127.576 280.435 129.332 282.343 131.621 283.687C133.909 285.031 136.631 285.701 139.784 285.701C142.229 285.701 144.398 285.318 146.301 284.564C148.198 283.804 149.757 282.731 150.982 281.349C151.644 280.594 152.197 279.776 152.629 278.878C153.187 277.725 152.41 276.349 151.159 276.205L147.844 275.828C147.114 275.743 146.426 276.126 146.04 276.769C145.774 277.21 145.461 277.608 145.096 277.953C144.455 278.565 143.694 279.027 142.824 279.34C141.948 279.654 140.973 279.808 139.894 279.808C138.283 279.808 136.876 279.457 135.671 278.756C134.472 278.054 133.534 277.034 132.867 275.706C132.22 274.425 131.892 272.884 131.871 271.104H151.936C152.958 271.104 153.781 270.259 153.781 269.223V268.787C153.781 265.976 153.401 263.548 152.634 261.513C151.868 259.472 150.82 257.793 149.491 256.47H149.475ZM131.876 266.008C131.949 264.754 132.252 263.585 132.82 262.512C133.456 261.305 134.347 260.333 135.494 259.589C136.641 258.845 137.97 258.473 139.487 258.473C140.905 258.473 142.151 258.803 143.225 259.456C144.299 260.11 145.133 261.002 145.732 262.14C146.332 263.277 146.639 264.568 146.655 266.013H131.876V266.008Z' fill='${ qrColor }' />
            <path d='M192.969 252.586C190.685 252.586 188.72 253.117 187.057 254.18C185.4 255.243 184.221 256.667 183.523 258.447H183.194C182.595 256.64 181.558 255.206 180.093 254.153C178.623 253.101 176.824 252.581 174.697 252.581C172.57 252.581 170.782 253.101 169.271 254.138C167.754 255.174 166.669 256.614 166.013 258.447H165.642V254.881C165.642 253.84 164.814 253 163.797 253H160.398C159.376 253 158.553 253.845 158.553 254.881V283.193C158.553 284.235 159.382 285.074 160.398 285.074H164.12C165.142 285.074 165.966 284.235 165.966 283.193V265.573C165.966 264.25 166.226 263.091 166.742 262.098C167.258 261.104 167.957 260.328 168.833 259.781C169.708 259.233 170.678 258.957 171.742 258.957C173.311 258.957 174.583 259.451 175.563 260.44C176.537 261.428 177.028 262.751 177.028 264.409V283.199C177.028 284.24 177.856 285.08 178.873 285.08H182.454C183.476 285.08 184.3 284.24 184.3 283.199V265.079C184.3 263.267 184.8 261.8 185.806 260.663C186.812 259.526 188.204 258.962 189.992 258.962C191.493 258.962 192.765 259.425 193.803 260.349C194.84 261.279 195.362 262.735 195.362 264.723V283.199C195.362 284.24 196.19 285.08 197.207 285.08H200.95C201.972 285.08 202.795 284.24 202.795 283.199V263.554C202.795 259.977 201.878 257.257 200.048 255.392C198.218 253.526 195.857 252.597 192.958 252.597L192.969 252.586Z' fill='${ qrColor }' />
            <path d='M232.293 254.478C230.333 253.218 228.138 252.586 225.709 252.586C223.863 252.586 222.352 252.9 221.163 253.537C219.975 254.17 219.031 254.929 218.338 255.811C217.639 256.694 217.102 257.528 216.716 258.309H216.409V244.196C216.409 243.154 215.58 242.314 214.564 242.314H210.841C209.82 242.314 208.996 243.154 208.996 244.196V283.193C208.996 284.235 209.825 285.075 210.841 285.075H214.444C215.465 285.075 216.289 284.235 216.289 283.193V280.021H216.722C217.118 280.802 217.676 281.626 218.39 282.497C219.104 283.369 220.058 284.107 221.247 284.719C222.435 285.33 223.931 285.638 225.735 285.638C228.206 285.638 230.416 284.99 232.371 283.698C234.326 282.402 235.869 280.526 237 278.06C238.131 275.595 238.7 272.603 238.7 269.08C238.7 265.557 238.121 262.507 236.969 260.047C235.817 257.592 234.258 255.732 232.298 254.472L232.293 254.478ZM230.286 274.399C229.718 275.961 228.884 277.194 227.778 278.092C226.673 278.99 225.302 279.442 223.66 279.442C222.018 279.442 220.757 279.006 219.657 278.135C218.557 277.263 217.717 276.052 217.139 274.489C216.56 272.933 216.268 271.115 216.268 269.043C216.268 266.97 216.55 265.164 217.118 263.633C217.686 262.103 218.52 260.913 219.625 260.063C220.73 259.212 222.075 258.787 223.66 258.787C225.245 258.787 226.694 259.223 227.799 260.1C228.904 260.976 229.738 262.188 230.296 263.734C230.854 265.281 231.136 267.045 231.136 269.038C231.136 271.03 230.854 272.826 230.286 274.394V274.399Z' fill='${ qrColor }' />
            <path d='M266.909 256.47C265.574 255.147 264.047 254.169 262.322 253.537C260.596 252.904 258.761 252.585 256.822 252.585C253.819 252.585 251.202 253.287 248.976 254.684C246.75 256.082 245.02 258.032 243.784 260.53C242.549 263.027 241.934 265.928 241.934 269.228C241.934 272.528 242.549 275.499 243.779 277.964C245.009 280.435 246.766 282.343 249.055 283.687C251.343 285.031 254.064 285.701 257.218 285.701C259.663 285.701 261.832 285.318 263.734 284.564C265.632 283.804 267.19 282.731 268.415 281.349C269.078 280.594 269.63 279.776 270.063 278.878C270.621 277.725 269.844 276.349 268.593 276.205L265.277 275.828C264.547 275.743 263.859 276.126 263.474 276.769C263.208 277.21 262.895 277.608 262.53 277.953C261.889 278.565 261.128 279.027 260.257 279.34C259.381 279.654 258.407 279.808 257.328 279.808C255.717 279.808 254.309 279.457 253.105 278.756C251.901 278.054 250.968 277.034 250.3 275.706C249.654 274.425 249.326 272.884 249.305 271.104H269.369C270.391 271.104 271.215 270.259 271.215 269.223V268.787C271.215 265.976 270.834 263.548 270.068 261.513C269.302 259.472 268.254 257.793 266.925 256.47H266.909ZM249.31 266.008C249.383 264.754 249.685 263.585 250.254 262.512C250.889 261.305 251.781 260.333 252.928 259.589C254.075 258.845 255.404 258.473 256.921 258.473C258.339 258.473 259.585 258.803 260.659 259.456C261.732 260.11 262.567 261.002 263.166 262.14C263.766 263.277 264.073 264.568 264.089 266.013H249.31V266.008Z' fill='${ qrColor }' />
            <path d='M292.201 252.59C291.784 252.564 291.419 252.543 291.267 252.543C289.464 252.543 287.863 253.047 286.466 254.057C285.064 255.067 284.079 256.496 283.505 258.345H283.177V254.881C283.177 253.839 282.348 253 281.332 253H277.834C276.812 253 275.988 253.844 275.988 254.881V283.193C275.988 284.234 276.817 285.074 277.834 285.074H281.556C282.577 285.074 283.401 284.234 283.401 283.193V266.22C283.401 264.855 283.709 263.654 284.324 262.607C284.939 261.56 285.783 260.747 286.852 260.152C287.926 259.562 289.135 259.265 290.491 259.265C290.741 259.265 291.288 259.318 291.877 259.387C292.972 259.514 293.931 258.643 293.931 257.516V254.466C293.931 253.473 293.175 252.654 292.206 252.59H292.201Z' fill='${ qrColor }' />
            <path d='M0.00521806 283.273V254.802C0.00521806 253.803 0.797586 252.995 1.77762 252.995C2.75766 252.995 3.55004 253.803 3.55004 254.802V257.926H3.87845C4.50922 256.268 5.55703 254.961 7.02709 254.01C8.49714 253.059 10.2591 252.581 12.3235 252.581C14.4973 252.581 16.2801 253.101 17.6824 254.138C19.0847 255.174 20.1325 256.598 20.8258 258.41H21.0917C21.8006 256.63 22.9579 255.211 24.5635 254.159C26.1691 253.107 28.1188 252.581 30.4125 252.581C33.3369 252.581 35.6463 253.516 37.3405 255.392C39.0347 257.267 39.8844 259.999 39.8844 263.591V283.278C39.8844 284.277 39.092 285.085 38.112 285.085H38.0077C37.0277 285.085 36.2353 284.277 36.2353 283.278V263.591C36.2353 261.056 35.5994 259.154 34.3274 257.889C33.0554 256.624 31.3821 255.987 29.3073 255.987C26.9041 255.987 25.0535 256.741 23.7555 258.245C22.4575 259.749 21.8111 261.657 21.8111 263.968V283.278C21.8111 284.277 21.0187 285.085 20.0386 285.085H19.851C18.8709 285.085 18.0786 284.277 18.0786 283.278V263.256C18.0786 261.083 17.4843 259.329 16.2957 257.99C15.1072 256.651 13.4338 255.987 11.2757 255.987C9.82645 255.987 8.52842 256.348 7.37114 257.071C6.21908 257.793 5.3068 258.803 4.64476 260.089C3.98271 261.375 3.64908 262.857 3.64908 264.526V283.273C3.64908 284.272 2.85671 285.08 1.87667 285.08H1.77241C0.792376 285.08 0 284.272 0 283.273H0.00521806Z' fill='${ qrColor }' />
            <path d='M48.685 297.111C48.3566 297.111 47.6685 297.005 46.9856 296.878C46.0003 296.692 45.3904 295.671 45.6876 294.694C45.9378 293.865 46.7562 293.376 47.5903 293.519C48.2628 293.631 48.8883 293.657 49.4617 293.594C50.4313 293.487 51.2915 293.041 52.0422 292.255C52.7928 291.468 53.4653 290.267 54.0491 288.652L55.196 285.395L44.4416 255.297C44.0402 254.181 44.8535 252.996 46.0212 252.996H46.3809C47.095 252.996 47.731 253.459 47.9656 254.144L56.9163 280.176H57.203L66.1536 254.144C66.3882 253.459 67.0242 252.996 67.7384 252.996H68.0981C69.2658 252.996 70.079 254.181 69.6776 255.297L57.1821 290.156C56.6243 291.702 55.931 292.993 55.1126 294.029C54.2942 295.066 53.3454 295.841 52.2767 296.346C51.2029 296.856 50.0039 297.106 48.6798 297.106L48.685 297.111Z' fill='${ qrColor }' />
            <path d='M330.974 253.056C329.963 253.056 329.145 253.891 329.145 254.922V283.207C329.145 284.238 329.963 285.072 330.974 285.072C331.985 285.072 332.804 284.238 332.804 283.207V254.922C332.804 253.891 331.985 253.056 330.974 253.056Z' fill='${ qrColor }' />
            <path d='M353.638 283.097C353.45 282.295 352.699 281.764 351.897 281.865C351.605 281.902 351.334 281.928 351.172 281.928C350.354 281.928 349.582 281.774 348.863 281.461C348.138 281.147 347.554 280.61 347.106 279.856C346.658 279.101 346.428 278.055 346.428 276.721V256.295H351.49C352.366 256.295 353.075 255.572 353.075 254.679C353.075 253.786 352.366 253.064 351.49 253.064H346.428V247.006C346.428 246.113 345.719 245.391 344.844 245.391H344.354C343.478 245.391 342.769 246.113 342.769 247.006V253.064H339.673C338.797 253.064 338.088 253.786 338.088 254.679C338.088 255.572 338.797 256.295 339.673 256.295H342.769V277.598C342.769 279.351 343.165 280.818 343.958 281.997C344.75 283.177 345.761 284.07 346.997 284.665C348.232 285.265 349.514 285.563 350.849 285.563C351.198 285.563 351.824 285.473 352.439 285.366C353.335 285.207 353.919 284.309 353.711 283.405L353.643 283.108L353.638 283.097Z' fill='${ qrColor }' />
            <path d='M331.016 242C329.181 242 327.695 243.514 327.695 245.385C327.695 247.255 329.181 248.77 331.016 248.77C332.851 248.77 334.336 247.255 334.336 245.385C334.336 243.514 332.851 242 331.016 242Z' fill='${ qrColor }' />
            <path d='M314.959 267.985L310.215 266.795C308.266 266.295 306.822 265.615 305.889 264.759C304.956 263.904 304.486 262.788 304.486 261.417C304.486 259.791 305.169 258.462 306.53 257.437C307.89 256.411 309.637 255.896 311.764 255.896C313.182 255.896 314.37 256.124 315.329 256.581C316.288 257.038 317.07 257.66 317.67 258.436C317.977 258.834 318.254 259.26 318.488 259.706C318.926 260.54 319.901 260.944 320.782 260.519C321.705 260.073 321.981 258.866 321.486 257.952C320.761 256.613 319.818 255.497 318.66 254.61C316.935 253.282 314.636 252.617 311.758 252.617C309.642 252.617 307.765 253 306.113 253.765C304.46 254.53 303.167 255.582 302.224 256.921C301.286 258.266 300.816 259.817 300.816 261.582C300.816 263.723 301.468 265.514 302.776 266.959C304.085 268.405 306.102 269.462 308.829 270.126L313.964 271.375C315.684 271.795 316.961 272.443 317.811 273.325C318.655 274.207 319.077 275.297 319.077 276.588C319.077 278.256 318.353 279.643 316.909 280.749C315.465 281.854 313.536 282.406 311.122 282.406C310.393 282.406 309.72 282.343 309.089 282.226C308.307 282.082 307.536 282.491 307.244 283.246C306.853 284.25 307.479 285.356 308.526 285.526C309.36 285.664 310.247 285.738 311.185 285.738C313.489 285.738 315.517 285.34 317.269 284.537C319.02 283.74 320.386 282.635 321.371 281.232C322.351 279.829 322.846 278.224 322.846 276.418C322.846 274.276 322.19 272.502 320.871 271.083C319.557 269.664 317.581 268.633 314.949 267.979L314.959 267.985Z' fill='${ qrColor }' />
            <path d='M299.84 278.304C298.005 278.304 296.52 279.818 296.52 281.689C296.52 283.559 298.005 285.074 299.84 285.074C301.675 285.074 303.161 283.559 303.161 281.689C303.161 279.818 301.675 278.304 299.84 278.304Z' fill='${ qrColor }' />
            <path d='M377.633 266.902H364.83C363.943 266.902 363.224 267.635 363.224 268.538C363.224 269.442 363.943 270.175 364.83 270.175H377.852C380.328 270.175 382.251 267.98 381.975 265.472C381.803 263.963 381.485 262.597 381.026 261.365C380.291 259.399 379.29 257.767 378.029 256.465C376.767 255.169 375.344 254.202 373.765 253.569C372.185 252.937 370.543 252.623 368.838 252.623C366.122 252.623 363.735 253.335 361.671 254.76C359.606 256.184 357.99 258.15 356.823 260.658C355.655 263.166 355.076 266.03 355.076 269.256C355.076 272.481 355.66 275.351 356.823 277.832C357.99 280.314 359.643 282.253 361.78 283.651C363.917 285.048 366.456 285.744 369.386 285.744C371.487 285.744 373.342 285.399 374.958 284.713C376.574 284.028 377.925 283.114 379.009 281.971C379.488 281.472 379.906 280.946 380.265 280.404C380.833 279.548 380.656 278.332 379.796 277.784C378.92 277.226 377.789 277.481 377.257 278.326C376.945 278.82 376.574 279.288 376.147 279.729C375.375 280.526 374.427 281.164 373.306 281.637C372.18 282.11 370.877 282.343 369.391 282.343C367.212 282.343 365.32 281.823 363.724 280.781C362.129 279.74 360.899 278.268 360.034 276.371C359.168 274.474 358.736 272.253 358.736 269.713V267.752C358.751 265.642 359.184 263.697 360.034 261.917C360.883 260.137 362.067 258.708 363.584 257.624C365.095 256.54 366.847 255.998 368.838 255.998C370.72 255.998 372.378 256.476 373.817 257.438C375.256 258.4 376.382 259.701 377.19 261.349C377.893 262.783 378.295 264.372 378.383 266.11C378.404 266.54 378.055 266.902 377.633 266.902Z' fill='${ qrColor }' />
         </svg>
      `

      // const svgStringMemberSide = `
      //    <svg width='54' height='54' viewBox='0 0 54 54' fill='none' xmlns='http://www.w3.org/2000/svg'>
      //       <path d='M3.85714 54C1.72669 54 0 52.2734 0 50.143V27.0009C0 20.621 5.19123 15.4299 11.5714 15.4299C17.9516 15.4299 23.1429 20.621 23.1429 27.0009V38.572C23.1429 40.6986 24.8733 42.429 27 42.429C29.1267 42.429 30.8571 40.6986 30.8571 38.572V30.8579C30.8571 24.478 36.0484 19.2869 42.4286 19.2869C48.8088 19.2869 54 24.478 54 30.8579V42.429C54 44.5594 52.2733 46.286 50.1429 46.286C48.0124 46.286 46.2857 44.5594 46.2857 42.429V30.8579C46.2857 28.7313 44.5553 27.0009 42.4286 27.0009C40.3018 27.0009 38.5714 28.7313 38.5714 30.8579V38.572C38.5714 44.9519 33.3802 50.143 27 50.143C20.6198 50.143 15.4286 44.9519 15.4286 38.572V27.0009C15.4286 24.8743 13.6982 23.1439 11.5714 23.1439C9.4447 23.1439 7.71428 24.8743 7.71428 27.0009V50.143C7.71428 52.2734 5.9876 54 3.85714 54ZM5.78665 5.78644C5.78665 8.98108 8.37668 11.5729 11.5733 11.5729C14.7699 11.5729 17.3599 8.98294 17.3599 5.78644C17.3599 2.58994 14.7699 0 11.5733 0C8.37668 0 5.78665 2.58994 5.78665 5.78644ZM36.6438 9.64347C36.6438 12.8381 39.2338 15.4299 42.4304 15.4299C45.627 15.4299 48.2171 12.84 48.2171 9.64347C48.2171 6.44697 45.627 3.85701 42.4304 3.85701C39.2338 3.85701 36.6438 6.44697 36.6438 9.64347Z' fill='${ qrColor }' />
      //    </svg>
      //    `

      const encodedLogo = appIcon ? appIcon : `data:image/svg+xml;base64,${ btoa(svgString) }`;

      const qr = new QRCodeStyling({
         type: "svg",
         width: 240,
         height: 240,
         margin: 0,
         dotsOptions: {
            type: "extra-rounded",
            color: qrColor,
         },
         cornersSquareOptions: {
            type: "extra-rounded",
         },
         imageOptions: {
            margin: 8,
            imageSize: 0.5,
         },
         qrOptions: {
            errorCorrectionLevel: "Q",
         },
         backgroundOptions: {
            round: 0.2,
            color: "transparent",
         },
         data: encodeURI(shareUrl),
         image: encodedLogo,
      });

      qr.append(qrRef);

      if(qrRef?.children?.length > 1){
         qrRef?.firstChild?.remove()
      }

   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [qrColor, shareUrl]);


   return (
      <div
         class={ classNames({
            'p-6 rounded-xlg flex flex-col items-center justify-center gap-8': true,
            [`${ wrapperClass }`]: wrapperClass,
         }) }
         style={ {
            backgroundColor,
         } }
         ref={ wrapperRef }
      >
         <div
            class='flex flex-col items-center gap-4'
         >
            <div
               class='w-[240px] h-[240px]'
               ref={ (ref) => qrRef = ref }
            />
            <span
               class='text-sm font-medium leading-[18px] select-none cursor-default'
               style={ {
                  color: qrColor,
               } }
            >
               @{sitePrefix}
            </span>
         </div>
      </div>
   )
}

CustomizableShareQR.propTypes = {
   shareUrl: PropTypes.string,
   sitePrefix: PropTypes.string,
   wrapperClass: PropTypes.string,
   backgroundColor: PropTypes.string,
   qrColor: PropTypes.string,
   appIcon: PropTypes.any,
   wrapperRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
   ]),
};


export default CustomizableShareQR;
