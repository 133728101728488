
import React, {  useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import CreateEditModal from 'common/components/modules/modal-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { screenWidthSelector } from 'admin/state/modules/common/selectors';
import OutgoingNotificationsAndAutoTweetEditContainer from 'admin/containers/pages/my-site/settings/outgoing-notification-and-auto-tweet/edit/index.js';
import { isFetchingBySlugSelector } from 'admin/state/modules/outgoing-notifications/selectors';
import { repostContentOperation } from 'admin/state/modules/common/operations';
import { TweetDisabledModal } from 'admin/views/media-modules/outgoing-disabled-modal';


function ContentRepostModal({
   onCloseModal,
   insideModalSlug,
   resourceId,
   callbackFunction,
   initialData,
   isDemoMode,
}) {
   const screenWidth = useSelector(screenWidthSelector)
   const isFetchingData = useSelector(isFetchingBySlugSelector)
   const dispatch = useDispatch()
   const childrenRef = useRef(null)
   const [data, setData] = useState(null)
   const [isLoadingPostBtn, setIsLoadingPostBtn] = useState(false)
   const [isDisabledPostBtn, setIsDisabledPostBtn] = useState(false)
   const [isOpenTweetDisabledModal, setIsOpenTweetDisabledModal] = useState(false)

   useEffect(() => {
      let height = window.innerHeight * 0.9
      let elementsHeight =  265
      let maxHeight = height - elementsHeight
      if(childrenRef.current){
         childrenRef.current.style.maxHeight = `${ maxHeight }px`
      }
   // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [screenWidth, childrenRef.current])

   useEffect(() => {
      if(!!data) {
         setIsDisabledPostBtn(!data?.auto_tweet_is_on && !data?.mass_private_message_is_on)
      }
   }, [data])

   const onClickPost = (bool) => {

      setIsLoadingPostBtn(true)
      let types = {
         new_photoset_published: 'photosets',
         new_bundle_published: 'products',
         new_video_published: 'videos',
      }
      dispatch(
         repostContentOperation(
            types?.[insideModalSlug], { ...data, send_anyway: bool }, resourceId,
            (type, date) => {
               if(type === 'error') {
                  setIsLoadingPostBtn(false)
                  return
               } else if(type === 'validationError') {
                  setIsOpenTweetDisabledModal(true)
                  setIsLoadingPostBtn(false)
                  return
               } else {
                  if(callbackFunction) callbackFunction(date)
               }
               onCloseModal(false)
            })
      )
   }

   return (
      <>
         {
            isOpenTweetDisabledModal && (
               <TweetDisabledModal
                  action={ () => onClickPost(true) }
                  onCloseModal={ () => setIsOpenTweetDisabledModal(false) }
                  isMobile={ screenWidth <= 700 }
                  disabled={ isLoadingPostBtn }
               />
            )
         }
         <CreateEditModal
            type='confirm'
            icon='repost'
            iconColor='action'
            action={ () => onClickPost(false) }
            onCloseModal={ () => onCloseModal(false) }
            title='Repost'
            withoutCloseButton={ false }
            withoutCancelButton
            buttonText='Post'
            contentWidth={ isFetchingData ? screenWidth < 1025 ? '100vw' : '1000px' : 'auto' }
            isMobile={ screenWidth < 1025 }
            iconSize='2xl'
            buttonClassName='mt-6'
            confirmButtonProperties={ {
               backgroundColor: 'action',
               borderRadius: 'large',
               classNames: '!py-3 !px-5',
               primaryColor: true,
               textColor: 'major-dark',
               textClassName: '!font-medium !text-base !leading-5 !mx-0',
               disabled: isDemoMode,
               dataToolTipContent: isDemoMode ? 'Not available in demo' : undefined,
            } }
            className='!pb-4'
            isPreventDeafault={ false }
            isLoading={ isLoadingPostBtn }
            confirmButtonHight='11'
            disabled={ isDisabledPostBtn }
         >
            <div
               className={ cx({
                  'mt-6 overflow-y-auto ams-custom-scrool w-full': true,
                  'h-full flex items-center min-h-[350px]': isFetchingData,
               }) }
               ref={ childrenRef }
            >
               <OutgoingNotificationsAndAutoTweetEditContainer
                  isInsideModal
                  insideModalSlug={ insideModalSlug }
                  insideModalCallBack={ (data) => {
                     setData({ ...data })
                  } }
                  insideModalDefaultData={ initialData }
               />
            </div>
         </CreateEditModal>
      </>
   )
}

ContentRepostModal.propTypes = {
   onCloseModal: PropTypes.func,
   insideModalSlug: PropTypes.string,
   resourceId: PropTypes.number,
   callbackFunction: PropTypes.func,
   initialData: PropTypes.object,
   isDemoMode: PropTypes.bool,
}

export default ContentRepostModal
