import React from 'react';
import PropTypes from 'prop-types';
import Button from 'common/components/elements/buttons/primary';
// import cx from 'classnames';
// import BulkEditButton from 'admin/views/media-modules/bulk-edit-button';
import Intercom from 'common/utils/Intercom';
import { ARTICLE_ID_ON_THE_VAULT } from 'common/utils/intercom-articles';

const VaultsHeaderButtons = ({
   isBulkEdit,
   isReorderMode,
   onBulkEdit,
   setReorderMode,
   handleAddNewVault,
   type,
   isDemoMode,
}) => {


   return (
      <div className='flex lg:items-center lg:flex-row flex-col max-w-full gap-2'>
         <div className='flex flex-1 flex-wrap flex-row max-md:flex-col gap-2'>
            {
               !isBulkEdit && (
                  <Button
                     text={ `Add new ${ type }` }
                     backgroundColor='action'
                     iconName='add'
                     iconPosition='left'
                     borderRadius='large'
                     onClick={ () => handleAddNewVault(type) }
                     classNames='px-5 py-2.5 !w-fit h-11'
                     textMarginX='0'
                     textClassName='ml-2'
                     disabled={ type === 'video' && isDemoMode }
                     dataToolTipContent={ type === 'video' && isDemoMode ? 'Not available in demo' : '' }
                     textSize='base'
                  />

               )
            }
            {/* <div className={ `flex ${ isBulkEdit ? 'w-full' : '' }` }>
               <BulkEditButton
                  isBulkEdit={ isBulkEdit }
                  onBulkEdit={ () => onBulkEdit({
                     // ids: selectedItems,
                     // all: isSelectAll,
                     // completed: 1,
                     // count: selectedCount,
                     // excludedIds: isSelectAll ? exceptIds : [],

                  }) }
                  // setIsBulkEdit={ onIsBulkEdit }
                  // onSelectAll={ onSelectAll }
                  // isSelectAll={ isSelectAllValue }
                  // selectedLength={ selectedCount }
                  // type='photoset'
                  // destroyMedais={ (callBack) => destroy({
                  //    ids: selectedItems,
                  //    all: isSelectAll,
                  //    completed: 1,
                  //    count: selectedCount,
                  //    excludedIds: isSelectAll ? exceptIds : [],
                  // }, callBack) }
                  // destroyButtonDisabled={ destroyButtonDisabled }
                  // totalCount={ totalCount }
                  // onCancel={ onCancelBulk }
                  // isReorderMode={ isReorderMode }
                  // isDemoMode={ isDemoMode }
               />
            </div> */}
            {/* {
               !isBulkEdit && <Button
                  text={ isReorderMode ? 'Done' : 'Reorder' }
                  onClick={ () => setReorderMode(type) }
                  iconName={ isReorderMode ? '' : 'drag' }
                  iconPosition='left'
                  borderRadius='large'
                  backgroundColor={ isReorderMode ? '' : 'major-dark' }
                  textColor='major'
                  fontIconColor='secondary'
                  textSize='base'
                  classNames={ cx({
                     'px-5 py-[10px] flex flex-row gap-2 border border-divider h-11 !w-[fit-content] whitespace-nowrap ': true,
                     'hover:!bg-hover intercom-reorder-content': !isReorderMode,
                  }) }
                  textMarginX='0'
                  primaryColor={ isReorderMode }
                  // iconClassName={ `${ isReorderMode ? '!rotate-90' : '' }` }
               />
            } */}
         </div>
         {
            !isBulkEdit && (
               <Button
                  text='More info on the Vault'
                  textSize='small'
                  iconName='attention-outline'
                  fontIconSize='base'
                  iconPosition='left'
                  backgroundColor='transparent'
                  padding='none'
                  classNames='h-full px-3.5 py-2 !w-fit'
                  textMarginX='0'
                  textClassName='ml-2'
                  primaryColor
                  onClick={ () => Intercom.showArticle(ARTICLE_ID_ON_THE_VAULT) }
               />
            )
         }
      </div>
   );
};

VaultsHeaderButtons.propTypes = {
   onBulkEdit: PropTypes.func,
   setReorderMode: PropTypes.func,
   type: PropTypes.string,
   handleAddNewVault: PropTypes.func,
   isBulkEdit: PropTypes.bool,
   isDemoMode: PropTypes.bool,
   isReorderMode: PropTypes.bool,
};


export default React.memo(VaultsHeaderButtons);
